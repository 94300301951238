import { Message as MessageFeature } from '@dimatech/features-core/lib/features/administrate-messages/Message';
import { SearchAdministrateUsersRole } from '@dimatech/features-core/lib/models/user';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Message = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.Messages.Title')}>
      <MessageFeature
        searchableRoles={[
          SearchAdministrateUsersRole.Read,
          SearchAdministrateUsersRole.Write,
        ]}
      />
    </View>
  );
};

Message.displayName = 'Message';
