var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { withTheme } from 'styled-components';
import { Color } from '../themes';
export var Progress = withTheme(function (_a) {
    var value = _a.value, theme = _a.theme, color = _a.color, backgroundColor = _a.backgroundColor, singleValue = _a.singleValue, formatter = _a.formatter, _b = _a.width, width = _b === void 0 ? '130px' : _b;
    if (value === undefined || value === null) {
        return null;
    }
    return (_jsx(Centered, { children: _jsxs("div", __assign({ style: {
                width: width,
                backgroundColor: backgroundColor || theme.colors.secondaryDim,
            } }, { children: [_jsx("div", { style: {
                        width: "".concat(value, "%"),
                        backgroundColor: color || theme.colors.primary,
                    } }), _jsxs("div", __assign({ className: "size-xs", style: {
                        justifyContent: singleValue ? 'center' : 'space-between',
                    } }, { children: [_jsx("span", { children: formatter ? formatter(value) : "".concat(Math.round(value), " %") }), !singleValue && (_jsx("span", { children: formatter
                                ? formatter(100 - value)
                                : "".concat(100 - Math.round(value), " %") }))] }))] })) }));
});
Progress.displayName = 'Progress';
var Centered = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  text-align: center;\n\n  div {\n    height: 25px;\n  }\n\n  > div {\n    width: 130px;\n    border-radius: 3px;\n\n    > div:first-of-type {\n      position: relative;\n      border-radius: 3px 0 0 3px;\n    }\n\n    > div:last-of-type {\n      top: -22px;\n      position: relative;\n      display: flex;\n      padding: 0 10px;\n      color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  text-align: center;\n\n  div {\n    height: 25px;\n  }\n\n  > div {\n    width: 130px;\n    border-radius: 3px;\n\n    > div:first-of-type {\n      position: relative;\n      border-radius: 3px 0 0 3px;\n    }\n\n    > div:last-of-type {\n      top: -22px;\n      position: relative;\n      display: flex;\n      padding: 0 10px;\n      color: ", ";\n    }\n  }\n"])), Color.White);
var templateObject_1;
