var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export var SelectEntities = function (props) {
    var t = useTranslation().t;
    var isLoading = props.isLoading, data = props.data, entityIds = props.entityIds, setEntityIds = props.setEntityIds, placeholder = props.placeholder, filter = props.filter, autoFocus = props.autoFocus, inverted = props.inverted, entities = props.entities, style = props.style;
    var _a = useState(), nodes = _a[0], setNodes = _a[1];
    var handleChange = function (selectedNodes) {
        setEntityIds(selectedNodes.map(function (node) { return node.id; }));
    };
    useEffect(function () {
        if (entities) {
            setNodes(entities
                .filter(function (entity) { return !filter || filter(entity); })
                .map(function (entity) {
                var _a;
                return ({
                    id: entity.id,
                    name: entity.name,
                    parentId: (_a = entity.parentId) !== null && _a !== void 0 ? _a : null,
                    payload: entity,
                });
            }));
        }
        if (entities === undefined && data) {
            setNodes(data
                .filter(function (entity) { return !filter || filter(entity); })
                .map(function (entity) {
                var _a;
                return ({
                    id: entity.id,
                    name: entity.name,
                    parentId: (_a = entity.parentId) !== null && _a !== void 0 ? _a : null,
                    payload: entity,
                });
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, entities]);
    if (isLoading) {
        return (_jsx("div", __assign({ style: { margin: 10 } }, { children: _jsx(LoaderSmall, {}) })));
    }
    return (_jsx(_Fragment, { children: nodes && (_jsx(MultiSelect, { inverted: inverted, style: style, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : t('Search.AnyEntity'), options: nodes, setSelected: function (selectedNodes) {
                handleChange(selectedNodes);
            }, selected: nodes.filter(function (node) { return entityIds === null || entityIds === void 0 ? void 0 : entityIds.includes(node.id); }) })) }));
};
SelectEntities.displayName = 'SelectEntities';
