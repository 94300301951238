import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export var useRedirectToMaintenance = function (flag, logout) {
    var navigate = useNavigate();
    var location = useLocation();
    var isMaintenanceOn = useFlags()[flag];
    useEffect(function () {
        var _a, _b;
        if (isMaintenanceOn === true) {
            logout();
            navigate('/maintenance', {
                state: { from: location.pathname },
            });
        }
        else if (isMaintenanceOn !== true &&
            location.pathname === '/maintenance') {
            navigate((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) !== null && _b !== void 0 ? _b : '/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMaintenanceOn]);
};
