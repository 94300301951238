import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { ChartTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { Careless } from 'models';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import styled from 'styled-components';

export const StatisticsCareless = ({
  careless,
}: {
  careless?: Careless;
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (!careless) {
    return null;
  }

  const data = [
    {
      value: careless.diligent,
      color: Color.CaribbeanGreen,
      name: t('Overview.StatisticsCareless.Diligent'),
    },
    {
      value: careless.borderline,
      color: Color.BrightYellow,
      name: t('Overview.StatisticsCareless.Borderline'),
    },
    {
      value: careless.careless,
      color: Color.KojiOrange,
      name: t('Overview.StatisticsCareless.Careless'),
    },
    {
      value: careless.extreme,
      color: Color.MonzaRed,
      name: t('Overview.StatisticsCareless.Extreme'),
    },
  ];

  return (
    <Style>
      <PieChart width={240} height={120}>
        <Pie
          data={data}
          cx={120}
          cy={110}
          startAngle={180}
          endAngle={0}
          innerRadius={55}
          outerRadius={80}
          stroke="none"
          dataKey="value"
          animationDuration={500}
        >
          <Cell fill={data[0].color} opacity={0.7} />
          <Cell fill={data[1].color} opacity={0.7} />
          <Cell fill={data[2].color} opacity={0.7} />
          <Cell fill={data[3].color} opacity={0.7} />
        </Pie>

        <Tooltip
          formatter={(value: number) => formatAsPercent(value, '0')}
          content={<ChartTooltip />}
        />
      </PieChart>

      <Table>
        <tbody>
          {data.map((item) => (
            <Tr key={item.name}>
              <Td>
                <BsCircleFill
                  style={{
                    marginRight: 8,
                    color: item.color,
                    opacity: 0.7,
                  }}
                />
                {item.name}
              </Td>
              <TdRight>{formatAsPercent(item.value, '0')}</TdRight>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Style>
  );
};

StatisticsCareless.displayName = 'StatisticsCareless';

const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div:first-of-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  table {
    width: fit-content;
    margin-top: 20px;
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

    td {
      border: none;
    }
  }
`;
