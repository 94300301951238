var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isValidAreaCode, isValidEmail, isValidLegacyPassword, isValidOrgNumber, } from '@dimatech/shared/lib/utils';
import { CustomerType, } from '../../models';
export var validateOrganisationAll = function (organisation, t) {
    var errors = {};
    Object.keys(organisation).forEach(function (key) {
        var value = organisation[key];
        if (typeof value !== 'object') {
            errors = validateOrganisation(key, value, organisation, __assign({}, errors), t);
        }
    });
    return errors;
};
export var validateOrganisation = function (fieldName, value, organisation, errors, t
// eslint-disable-next-line max-params
) {
    var _a;
    switch (fieldName) {
        case 'type':
            errors.type = !(value === null || value === void 0 ? void 0 : value.trim())
                ? "".concat(t('Register.Validation.Required'))
                : undefined;
            break;
        case 'lineOfBusiness':
            errors.lineOfBusiness =
                organisation.type === CustomerType.PubliclyOwnedOrganization &&
                    !(value === null || value === void 0 ? void 0 : value.trim())
                    ? "".concat(t('Register.Validation.Required'))
                    : undefined;
            break;
        case 'principalOwnerType':
            errors.principalOwnerType =
                organisation.type === CustomerType.PubliclyOwnedOrganization &&
                    !(value === null || value === void 0 ? void 0 : value.trim())
                    ? "".concat(t('Register.Validation.Required'))
                    : undefined;
            break;
        case 'principalOwner':
            errors.principalOwner =
                organisation.type === CustomerType.PubliclyOwnedOrganization &&
                    !(value === null || value === void 0 ? void 0 : value.trim())
                    ? "".concat(t('Register.Validation.Required'))
                    : undefined;
            break;
        case 'name':
            errors.name =
                (value === null || value === void 0 ? void 0 : value.trim().length) < 2
                    ? "".concat(t('Register.Validation.MinLength'))
                    : undefined;
            break;
        case 'organisationId':
            errors.name =
                ((_a = organisation.name) === null || _a === void 0 ? void 0 : _a.trim().length) < 2
                    ? "".concat(t('Register.Validation.MinLength'))
                    : undefined;
            break;
        case 'orgNumber':
            errors.orgNumber = !isValidOrgNumber(value, organisation.countryCode)
                ? "".concat(t('Register.Validation.OrgNumber'))
                : undefined;
            break;
    }
    return errors;
};
export var validateAdministratorAll = function (administrator, t) {
    var errors = {};
    Object.keys(administrator).forEach(function (key) {
        var value = administrator[key];
        errors = validationAdministrator(key, value, administrator, __assign({}, errors), t);
    });
    return errors;
};
export var validationAdministrator = function (fieldName, value, administrator, errors, t
// eslint-disable-next-line max-params
) {
    switch (fieldName) {
        case 'firstName':
            errors.firstName =
                value.trim().length < 2
                    ? "".concat(t('Register.Validation.MinLength'))
                    : undefined;
            break;
        case 'lastName':
            errors.lastName =
                value.trim().length < 2
                    ? "".concat(t('Register.Validation.MinLength'))
                    : undefined;
            break;
        case 'email':
            errors.email = !isValidEmail(value)
                ? "".concat(t('Register.Validation.Email'))
                : undefined;
            break;
        case 'password':
            errors.password = !value.trim()
                ? "".concat(t('Register.Validation.Required'))
                : !isValidLegacyPassword(value)
                    ? "".concat(t('Register.Validation.Password'))
                    : administrator.confirmPassword.length > 0 &&
                        administrator.confirmPassword !== value
                        ? "".concat(t('Register.Validation.ConfirmPassword'))
                        : undefined;
            errors.confirmPassword =
                administrator.confirmPassword.length > 0 &&
                    administrator.confirmPassword !== value
                    ? "".concat(t('Register.Validation.ConfirmPassword'))
                    : undefined;
            break;
        case 'confirmPassword':
            errors.confirmPassword = !value.trim()
                ? "".concat(t('Register.Validation.Required'))
                : administrator.password !== value
                    ? "".concat(t('Register.Validation.ConfirmPassword'))
                    : undefined;
            break;
    }
    return errors;
};
export var validationBillingInfoAll = function (billingInfo, countryCode, t) {
    var errors = {};
    Object.keys(billingInfo).forEach(function (key) {
        var value = billingInfo[key];
        errors = validationBillingInfo(key, value, countryCode, __assign({}, errors), t);
    });
    return errors;
};
export var validationBillingInfo = function (fieldName, value, countryCode, errors, t) {
    switch (fieldName) {
        case 'street':
            errors.street = !value.trim()
                ? "".concat(t('Register.Validation.Required'))
                : undefined;
            break;
        case 'areaCode':
            errors.areaCode = !isValidAreaCode(value, countryCode)
                ? "".concat(t('Register.Validation.PostalCode'))
                : undefined;
            break;
        case 'city':
            errors.city = !value.trim()
                ? "".concat(t('Register.Validation.Required'))
                : undefined;
            break;
        case 'invoiceReference':
            errors.invoiceReference = !value.trim()
                ? "".concat(t('Register.Validation.Required'))
                : undefined;
            break;
        case 'invoiceEmail':
            errors.invoiceEmail = !isValidEmail(value)
                ? "".concat(t('Register.Validation.Email'))
                : undefined;
            break;
    }
    return errors;
};
