import { jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { ApplicationContext } from '../../application';
import { config } from '../../config';
export var Version = function () {
    var application = useContext(ApplicationContext);
    if (!config.version) {
        return null;
    }
    return (_jsxs("div", { children: [application.title, " v", config.version] }));
};
Version.displayName = 'Version';
