var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { AlertWarning } from './Alert';
export var AlertErrors = function (_a) {
    var translationPath = _a.translationPath, error = _a.error, customValidationErrors = _a.customValidationErrors, _b = _a.includeErrorStatuses, includeErrorStatuses = _b === void 0 ? [] : _b, style = _a.style;
    var t = useTranslation().t;
    var apiError = error;
    var validationErrors = customValidationErrors !== null && customValidationErrors !== void 0 ? customValidationErrors : apiError === null || apiError === void 0 ? void 0 : apiError.validationErrors;
    if ((apiError === null || apiError === void 0 ? void 0 : apiError.name) === 'ValidationError' &&
        apiError.title &&
        validationErrors.length === 0) {
        validationErrors = [apiError.title];
    }
    return error ||
        (customValidationErrors && customValidationErrors.length > 0) ? (_jsxs(_Fragment, { children: [validationErrors && validationErrors.length > 0 && (_jsx(AlertWarning, __assign({ style: __assign({ marginTop: 10, width: '100%' }, style) }, { children: validationErrors.map(function (error, index) { return (_jsx("div", { children: translationPath ? t("".concat(translationPath, ".").concat(error)) : error }, index)); }) }))), error &&
                (!validationErrors || validationErrors.length === 0) &&
                includeErrorStatuses.includes(error.status) && (_jsx(AlertWarning, __assign({ style: __assign({ marginTop: 10, width: '100%' }, style) }, { children: translationPath
                    ? t("".concat(translationPath, ".").concat(error.name))
                    : error.name })))] })) : null;
};
AlertErrors.displayName = 'AlertErrors';
