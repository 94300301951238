var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useContext, useEffect, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonBadge } from 'react-icons/bs';
import { useImpersonateMutation } from '../../../api/authentication/authenticationApi';
import { CommonRoles } from '../../../models';
import { AuthenticationContext } from '../AuthenticationProvider';
export var Impersonate = function (_a) {
    var email = _a.email, style = _a.style;
    var t = useTranslation().t;
    var id = useId();
    var _b = useContext(AuthenticationContext), accessToken = _b.accessToken, refreshToken = _b.refreshToken;
    var _c = useImpersonateMutation(), impersonate = _c[0], data = _c[1].data;
    useEffect(function () {
        if (data) {
            accessToken.update(data.accessToken);
            refreshToken.update(data.refreshToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    if (!accessToken.user || !accessToken.isInRole(CommonRoles.GlobalAdmin)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt_impersonate_".concat(id), title: t('Impersonate.TooltipTitle'), text: t('Impersonate.Tooltip', { email: email }) }), _jsx(BadgeMini, __assign({ style: __assign({ marginLeft: 7 }, style), "data-tip": true, "data-for": "tt_impersonate_".concat(id), onClick: function () { return impersonate(email); } }, { children: _jsx(BsPersonBadge, {}) }))] }));
};
Impersonate.displayName = 'Impersonate';
