var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../AuthenticationProvider';
export var OrderCustomerHasAccess = function (_a) {
    var _b, _c;
    var productName = _a.productName;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    return (_jsxs("div", __assign({ style: { alignSelf: 'center' } }, { children: [_jsx(Heading2, { children: t("Order.".concat(productName, ".Title")) }), _jsx("p", { children: t("Order.".concat(productName, ".CustomerHasAccess.Title")) }), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: _jsxs(Trans, __assign({ i18nKey: "Order.".concat(productName, ".CustomerHasAccess.Text"), values: {
                        name: (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.currentCustomer.name,
                        supportEmail: config.support.email,
                    } }, { children: [_jsx("span", { children: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.currentCustomer.name }), _jsx("a", __assign({ href: "mailto: ".concat(config.support.email) }, { children: config.support.email }))] })) })), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: _jsx("a", __assign({ href: "/" }, { children: t('Order.BackLink') })) }))] })));
};
OrderCustomerHasAccess.displayName = 'OrderCustomerHasAccess';
