var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { LoaderOverlay } from '../loader';
import { CardBase } from './CardBase';
export var CardFocused = function (cardProps) {
    return (_jsx(Style, __assign({ width: cardProps.width, height: cardProps.height, style: cardProps.style }, { children: _jsx(CardBase, __assign({}, cardProps)) })));
};
CardFocused.displayName = 'CardFocused';
export var CardFocusedBody = function (_a) {
    var children = _a.children, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, style = _a.style, width = _a.width, height = _a.height;
    return (_jsxs(BodyStyle, __assign({ width: width, height: height, style: style }, { children: [isLoading && _jsx(LoaderOverlay, {}), _jsx("div", { children: children })] })));
};
CardFocusedBody.displayName = 'CardFocusedBody';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: fit-content;\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n"], ["\n  min-width: fit-content;\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n"])), function (props) { return props.width; }, function (props) { return props.height; }, function (_a) {
    var theme = _a.theme;
    return theme.size.viewMaxWidth;
});
var BodyStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  position: relative;\n"], ["\n  width: ", ";\n  height: ", ";\n  position: relative;\n"])), function (props) { return props.width; }, function (props) { return props.height; });
export var CardRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: space-between;\n\n  column-gap: 15px;\n  row-gap: 15px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: space-between;\n\n  column-gap: 15px;\n  row-gap: 15px;\n"])));
export var CardTile = function (_a) {
    var children = _a.children, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, style = _a.style;
    return (_jsxs(CardTileStyle, __assign({ style: style }, { children: [isLoading && _jsx(LoaderOverlay, {}), children] })));
};
var CardTileStyle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 0;\n\n  min-width: 250px;\n  padding: 10px;\n\n  //box-shadow: ", ";\n  background-color: ", ";\n  color: ", ";\n\n  border-radius: 5px;\n"], ["\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 0;\n\n  min-width: 250px;\n  padding: 10px;\n\n  //box-shadow: ", ";\n  background-color: ", ";\n  color: ", ";\n\n  border-radius: 5px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.light;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
