var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { BsList, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ApplicationContext } from '../../application';
import { Hamburger } from '../Hamburger';
import { EnvironmentBanner } from '../dev-tools';
export var Header = function (_a) {
    var isSidePanelShown = _a.isSidePanelShown, setIsSidePanelShown = _a.setIsSidePanelShown, children = _a.children;
    var application = useContext(ApplicationContext);
    var navigate = useNavigate();
    return (_jsxs(Style, { children: [setIsSidePanelShown && (_jsx(Hamburger, __assign({ onClick: function () { return setIsSidePanelShown(!isSidePanelShown); } }, { children: isSidePanelShown ? _jsx(BsX, {}) : _jsx(BsList, {}) }))), _jsxs(Logo, __assign({ onClick: function () { return navigate('/'); } }, { children: [application.title, _jsx(EnvironmentBanner, {})] })), children] }));
};
Header.displayName = 'Header';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 40px;\n\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n"], ["\n  width: 100%;\n  min-height: 40px;\n\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n"])));
var Logo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: auto;\n  margin-left: 5px;\n  cursor: pointer;\n\n  font: ", ";\n\n  color: ", ";\n"], ["\n  margin-right: auto;\n  margin-left: 5px;\n  cursor: pointer;\n\n  font: ", ";\n\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.title;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var templateObject_1, templateObject_2;
