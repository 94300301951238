var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { DevToolbar } from '@dimatech/shared/lib/components/dev-tools';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Header as SharedHeader, SwitchLocale, SwitchTheme, } from '@dimatech/shared/lib/components/workspace';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { initialsFromEmail } from '@dimatech/shared/lib/utils';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowRight, BsGear } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { appActions } from '../api/appSlice';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
import { SelectCustomer } from '../features/authentication/components/SelectCustomer';
import { MessagesBadge } from '../features/messenger';
export var Header = function (_a) {
    var _b, _c;
    var isSidePanelShown = _a.isSidePanelShown, setIsSidePanelShown = _a.setIsSidePanelShown, themes = _a.themes;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _d = useState(false), isVisible = _d[0], setIsVisible = _d[1];
    var _e = useContext(AuthenticationContext), accessToken = _e.accessToken, webToken = _e.webToken, logout = _e.logout;
    var initials = initialsFromEmail((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.email);
    return (_jsx(SharedHeader, __assign({ isSidePanelShown: isSidePanelShown, setIsSidePanelShown: setIsSidePanelShown }, { children: _jsxs(Style, { children: [_jsx(DevToolbar, { token: accessToken.token, userId: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id, customerId: accessToken.customerId, tokenExpires: accessToken.expires, webToken: webToken.token, webTokenExpires: webToken.expires }), _jsx(SwitchTheme, { dark: themes.dark.name, light: themes.light.name }), _jsx(SwitchLocale, {}), accessToken.user && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-user-profile", text: t('UserProfile.TooltipText') }), _jsx(TooltipContent, { id: "tt-user-logout", text: t('Logout.TooltipText') }), _jsx(TooltipContent, { place: "bottom", id: "tt-user-info", title: accessToken.user.email, text: _jsxs(_Fragment, { children: [_jsx("strong", { children: t('Profile.User.Account') }), _jsx("div", __assign({ style: { marginBottom: 10 } }, { children: accessToken.user.currentCustomer.name })), _jsx("strong", { children: t('Profile.User.Roles') }), _jsx("div", { children: accessToken.user.currentCustomer.roles
                                            .map(function (role) { return t("Roles.".concat(role)); })
                                            .join(', ') })] }) }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-user-profile", onClick: function () { return navigate('/user-profile'); } }, { children: _jsx(BsGear, {}) })), _jsx(MessagesBadge, { style: { marginRight: 10 } }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-user-logout", onClick: function () { return logout(); }, style: { marginLeft: 10 } }, { children: _jsx(BsBoxArrowRight, {}) })), _jsx(Badge, __assign({ onClick: function () { return setIsVisible(!isVisible); }, "data-tip": true, "data-for": "tt-user-info", style: { marginLeft: 20 } }, { children: initials })), _jsxs(User, __assign({ onClick: function () { return setIsVisible(!isVisible); } }, { children: [_jsx("div", __assign({ "data-tip": true, "data-for": "tt-user-info" }, { children: accessToken.user.email })), _jsx("div", { children: _jsx(SelectCustomer, { handleCustomerChange: function (customerId) {
                                            return dispatch(appActions.resetApplication(customerId));
                                        }, isVisible: isVisible, setIsVisible: setIsVisible }) })] }))] }))] }) })));
};
Header.displayName = 'Header';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n"])));
var User = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 10px;\n\n  > div:first-of-type {\n    color: ", ";\n\n    @media screen and (max-width: ", ") {\n      display: none;\n    }\n  }\n"], ["\n  margin-left: 10px;\n\n  > div:first-of-type {\n    color: ", ";\n\n    @media screen and (max-width: ", ") {\n      display: none;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, Breakpoints.small);
var templateObject_1, templateObject_2;
