import { CacheTagsEntity, featuresSurveyApi } from '../featuresSurveyApi';
export var entitySurveyApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get surveys for current entity
         */
        getEntitySurvey: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_a) {
                var entityId = _a.entityId, _customerId = _a._customerId, surveyInstanceId = _a.surveyInstanceId;
                return "entities/".concat(entityId, "/survey").concat(surveyInstanceId ? "?surveyInstanceId=".concat(surveyInstanceId) : '');
            },
            providesTags: [CacheTagsEntity.EntitySurvey],
        }),
    }); },
});
export var useGetEntitySurveyQuery = entitySurveyApi.useGetEntitySurveyQuery;
