var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo, useState } from 'react';
import { useSortableData } from '../../hooks';
import { SortDirection } from '../../models';
export var useUiSortablePaginator = function (_a) {
    var orderBy = _a.orderBy, _b = _a.orderDirection, orderDirection = _b === void 0 ? SortDirection.Asc : _b, _c = _a.page, page = _c === void 0 ? 1 : _c, _d = _a.pageSize, pageSize = _d === void 0 ? 25 : _d, data = _a.data;
    var _e = useState({
        orderBy: orderBy,
        orderDirection: orderDirection,
        page: page,
        pageSize: pageSize,
    }), paginator = _e[0], setPaginator = _e[1];
    var _f = useSortableData(data.records, {
        key: paginator.orderBy,
        direction: paginator.orderDirection,
    }), items = _f.items, sorter = _f.sorter;
    var setPage = function (page) { return setPaginator(__assign(__assign({}, paginator), { page: page })); };
    var pagedItems = useMemo(function () {
        var firstPageIndex = (paginator.page - 1) * paginator.pageSize;
        var lastPageIndex = firstPageIndex + paginator.pageSize;
        return items.slice(firstPageIndex, lastPageIndex);
    }, [paginator.page, items]);
    return { items: pagedItems, setPage: setPage, paginator: paginator, sorter: sorter };
};
