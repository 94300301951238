export enum DigitalAbilities {
  Efficiency = 'Efficiency',
  Innovation = 'Innovation',
  Balance = 'Balance',
}

export enum DigitalHeritages {
  Organisation = 'Organisation',
  Users = 'Users',
  Technology = 'Technology',
}

export type PrinciplesByCategory = {
  ability: Ability;
  heritage: Heritage;
};

export type PrinciplesByEntity = PrinciplesByCategory & {
  entityId: string;
  entityName: string;
  percentage: number;
  startDate: string;
  endDate: string;
};

export type Ability = SubCategory & {
  efficiency: SubCategory;
  innovation: SubCategory;
  balance: SubCategory;
};

export type Heritage = SubCategory & {
  organisation: SubCategory;
  users: SubCategory;
  technology: SubCategory;
};

export type SubCategory = {
  key?: string;
  percentageDontKnow?: number;
  percentage?: number;
  average?: number;
};

export type SurveyOverview = {
  principles: Principle[];
  categories: PrinciplesByCategory;
};

export type Principle = {
  principleId: string;
  average: number | null;
  percentageDontKnow: number;
  percentage: number | null;
  disagreement: number | null;
  maturityLevel: number | null;
  name?: string;
  entityName?: string;
  entityId?: string;
};

export type PrincipleOption = {
  principleId: string;
  optionId: string;
  count: number;
};

export type PrincipleOptionByPrinciple = {
  principleId: string;
  options: PrincipleOption[];
};

export type CategoryCustomer = {
  category: string;
  percentage: number;
  average: number;
  customerId: string;
  organization: string;
};

export type CategoryByCustomer = {
  customerId: string;
  organization: string;
  percentage: number;
  heritage?: CategoryCustomer;
  ability?: CategoryCustomer;
};

export type Overview = {
  principles: Principle[];
  categories: PrinciplesByCategory;
};

export type SubCategoryDefinition = {
  key: DigitalAbilities | DigitalHeritages;
  id: number;
  principles: number[];
};

export type Disagreement = {
  surveyInstanceId: string;
  disagreement: number;
};
