var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UserAgreementDikios } from '@dimatech/shared/lib/components/user-agreement';
import { config } from '@dimatech/shared/lib/config';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonRoles } from '../../../models';
import { AuthenticationContext } from '../AuthenticationProvider';
import { OrderCustomerHasAccess, OrderProduct } from './';
export var OrderDikios = function (_a) {
    var fromProductLink = _a.fromProductLink;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useState(false), customerHasAccess = _b[0], setCustomerHasAccess = _b[1];
    useEffect(function () {
        var customerHasDikiosAccess = accessToken.hasCustomerProductAccess(config.products.dikios.id);
        var userHasDikiosAccess = accessToken.isInRole(CommonRoles.GlobalAdmin) ||
            accessToken.hasUserProductAccess(config.products.dikios.id);
        if (customerHasDikiosAccess && userHasDikiosAccess) {
            window.location.replace(config.products.dikios.url);
        }
        else {
            setCustomerHasAccess(customerHasDikiosAccess);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken.token]);
    return (_jsxs(_Fragment, { children: [!customerHasAccess && (_jsx(OrderProduct, { productId: config.products.dikios.id, userAgreement: _jsx(UserAgreementDikios, {}), productName: "Dikios", orderedProductLink: _jsx("a", __assign({ href: config.products.dikios.url }, { children: t('Order.VerificationLinkTextDikios') })), fromProductLink: fromProductLink })), customerHasAccess && _jsx(OrderCustomerHasAccess, { productName: "Dikios" })] }));
};
OrderDikios.displayName = 'OrderDikios';
