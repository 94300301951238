import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { ProgressByEntity, ScheduleStatus, SortDirection } from 'models';
import { useTranslation } from 'react-i18next';
import { BsCalendarEvent, BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { formatAsNumber, formatDate } from 'utils';

export const StatusByEntity = ({
  progressByEntity,
  saveToPngElementId,
}: {
  progressByEntity: ProgressByEntity[];
  saveToPngElementId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const { items, sorter } = useSortableData(progressByEntity, {
    key: 'entityPath',
    direction: SortDirection.Asc,
  });

  return (
    <StyledTableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th sortKey="entityPath" sorter={sorter}>
              {t('Administrate.ViewStatus.StatusByEntity.Entity')}
            </Th>
            <Th
              style={{ width: 180 }}
              sortKey="allowIndividualMeasurementPeriods"
              sorter={sorter}
            >
              {t(
                'Administrate.ViewStatus.StatusByEntity.IndividualMeasurementStatus.Status'
              )}
            </Th>
            <Th style={{ width: 120 }} sortKey="status" sorter={sorter}>
              {t('Administrate.ViewStatus.StatusByEntity.Status.Status')}
            </Th>
            <ThRight style={{ width: 110 }} sortKey="daysLeft" sorter={sorter}>
              {t('Administrate.ViewStatus.StatusByEntity.DaysLeft')}
            </ThRight>
            <ThRight style={{ width: 100 }} sortKey="startDate" sorter={sorter}>
              {t('Administrate.ViewStatus.StatusByEntity.StartDate')}
            </ThRight>
            <ThRight style={{ width: 100 }} sortKey="endDate" sorter={sorter}>
              {t('Administrate.ViewStatus.StatusByEntity.EndDate')}
            </ThRight>
            <ThRight style={{ width: 100 }} sortKey="total" sorter={sorter}>
              {t('Administrate.ViewStatus.StatusByEntity.Respondents')}
            </ThRight>
            <ThCentered
              style={{ width: 150 }}
              sortKey="responseRate"
              sorter={sorter}
            >
              {t('Administrate.ViewStatus.StatusByEntity.ResponseRate')}
            </ThCentered>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <Tr key={index}>
              <Td data-value={item.entityPath}>
                <strong>{item.entityName}</strong>
                {item.entityPath !== item.entityName && (
                  <div className="size-xs" style={{ fontStyle: 'italic' }}>
                    {item.entityPath}
                  </div>
                )}
              </Td>
              <Td
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                <IndividualMeasurementStatus
                  allowIndividualMeasurementPeriods={
                    item.allowIndividualMeasurementPeriods
                  }
                />
              </Td>
              <Td
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                <Status status={item.status} />
              </Td>
              <TdRight>{formatAsNumber(item.daysLeft, null)}</TdRight>
              <TdRight>
                {item.startDate && formatDate(new Date(item.startDate), 'date')}
              </TdRight>
              <TdRight>
                {item.endDate && formatDate(new Date(item.endDate), 'date')}
              </TdRight>
              <TdRight>
                {formatAsNumber(item.totalRespondents, '0')}
                {'/'}
                {formatAsNumber(item.total - item.totalRespondents, '0')}
              </TdRight>
              <TdCentered data-value={item.responseRate}>
                {item.responseRate !== null &&
                  item.responseRate !== undefined && (
                    <Progress value={item.responseRate} singleValue={true} />
                  )}
              </TdCentered>
            </Tr>
          ))}
        </tbody>
      </Table>
    </StyledTableResponsiveContainer>
  );
};

StatusByEntity.displayName = 'StatusByEntity';

const StyledTableResponsiveContainer = styled(TableResponsiveContainer)`
  td {
    vertical-align: middle;
  }
`;

const Status = withTheme(
  ({
    theme,
    status,
  }: {
    theme: Theme;
    status: ScheduleStatus | null;
  }): JSX.Element => {
    const { t } = useTranslation();

    let color = theme.colors.lowPriority;
    let text = 'None';

    if (status === ScheduleStatus.Closed) {
      color = theme.colors.error;
      text = 'Closed';
    }

    if (status === ScheduleStatus.Started) {
      color = theme.colors.success;
      text = 'Started';
    }

    if (status === ScheduleStatus.NotStarted) {
      color = theme.colors.warning;
      text = 'NotStarted';
    }

    return (
      <>
        <BsCircleFill
          style={{ marginRight: 5, fontSize: theme.fonts.size.s }}
          color={color}
        />
        {t(`Administrate.ViewStatus.StatusByEntity.Status.${text}`)}
      </>
    );
  }
);

const IndividualMeasurementStatus = withTheme(
  ({
    theme,
    allowIndividualMeasurementPeriods,
  }: {
    theme: Theme;
    allowIndividualMeasurementPeriods: boolean;
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <>
        <BsCalendarEvent
          style={{ marginRight: 5, fontSize: theme.fonts.size.s }}
          color={
            allowIndividualMeasurementPeriods
              ? theme.colors.success
              : theme.colors.border
          }
        />
        {t(
          `Administrate.ViewStatus.StatusByEntity.IndividualMeasurementStatus.${
            allowIndividualMeasurementPeriods ? 'IsUsing' : 'IsNotUsing'
          }`
        )}
      </>
    );
  }
);
