var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsMoon, BsSun } from 'react-icons/bs';
import { ThemeContext } from '../../themes';
import { BadgeSmall } from '../Badge';
import { TooltipContent } from '../tooltip';
export var SwitchTheme = function (_a) {
    var dark = _a.dark, light = _a.light;
    var _b = useContext(ThemeContext), themeName = _b.themeName, switchTheme = _b.switchTheme;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-theme-switch", text: t('Profile.SwitchTheme.TooltipText') }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-theme-switch", onClick: function () { return switchTheme(themeName === dark ? light : dark); }, role: "button", tabIndex: 0, "aria-label": t('Profile.SwitchTheme.TooltipText') }, { children: themeName === dark ? _jsx(BsSun, {}) : _jsx(BsMoon, {}) }))] }));
};
SwitchTheme.displayName = 'SwitchTheme';
