import { CacheTags, dimiosApi } from 'api/dimiosApi';
import { Report } from 'models';

const reportApi = dimiosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get researcher historical reports
     */
    getResearcherHistoricalReports: build.query<Report[], string>({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: (_customerId) => `reports/historical`,
      providesTags: [CacheTags.EntityReport],
    }),
  }),
});

export const { useGetResearcherHistoricalReportsQuery } = reportApi;
