import { featuresCoreApi } from '../featuresCoreApi';
var systemApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get system version
         */
        getVersion: build.query({
            query: function () { return "system/version"; },
        }),
    }); },
});
export var useGetVersionQuery = systemApi.useGetVersionQuery, useLazyGetVersionQuery = systemApi.useLazyGetVersionQuery;
