var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { filterActions } from '../api/filter/filterSlice';
import { useGetSurveyInstances } from '../hooks/useGetSurveyInstances';
export var SelectSurveyPeriod = function (_a) {
    var instance = _a.instance, setInstance = _a.setInstance, _b = _a.isOnlyPeriodsWithDataShown, isOnlyPeriodsWithDataShown = _b === void 0 ? false : _b, _c = _a.isCurrentPeriodAlwaysShown, isCurrentPeriodAlwaysShown = _c === void 0 ? false : _c, _d = _a.isOnlyPeriodsFrom2022Shown, isOnlyPeriodsFrom2022Shown = _d === void 0 ? false : _d, style = _a.style;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _e = useState(), instances = _e[0], setInstances = _e[1];
    var _f = useGetSurveyInstances(false), instancesAll = _f.instances, isLoading = _f.isLoading, isFetching = _f.isFetching, isSuccess = _f.isSuccess;
    var getPreviousInstance = function (current, instances) {
        return instances === null || instances === void 0 ? void 0 : instances.find(function (item) {
            return item.id !== (current === null || current === void 0 ? void 0 : current.id) &&
                parseISO(item.endDate) < parseISO(current === null || current === void 0 ? void 0 : current.endDate);
        });
    };
    var handleSelectInstance = function (e) {
        var instanceId = e.currentTarget.value;
        var instance = instances === null || instances === void 0 ? void 0 : instances.find(function (item) { return item.id === instanceId; });
        var previousInstance = instance
            ? getPreviousInstance(instance, instances)
            : undefined;
        setInstance(instance, previousInstance);
    };
    useEffect(function () {
        var filtered = [];
        if (isOnlyPeriodsWithDataShown && isCurrentPeriodAlwaysShown) {
            filtered = instancesAll === null || instancesAll === void 0 ? void 0 : instancesAll.filter(function (instance) { return instance.hasData || instance.isCurrentInstance; });
        }
        else if (isOnlyPeriodsWithDataShown && isOnlyPeriodsFrom2022Shown) {
            filtered = instancesAll === null || instancesAll === void 0 ? void 0 : instancesAll.filter(function (instance) {
                return instance.hasData &&
                    instance.startDate &&
                    new Date(instance.startDate) > new Date(2022, 0);
            });
            if ((filtered === null || filtered === void 0 ? void 0 : filtered.length) === 0) {
                filtered = instancesAll === null || instancesAll === void 0 ? void 0 : instancesAll.filter(function (instance) { return instance.isCurrentInstance; });
            }
        }
        else if (isOnlyPeriodsWithDataShown) {
            filtered = instancesAll === null || instancesAll === void 0 ? void 0 : instancesAll.filter(function (instance) { return instance.hasData; });
        }
        else {
            filtered = instancesAll;
        }
        setInstances(filtered);
        // Select latest instance if no instances was already selected
        if (filtered && filtered.length > 0 && filtered[0].id) {
            if (!instance || !filtered.find(function (item) { return item.id === instance.id; })) {
                setInstance(filtered[0], getPreviousInstance(filtered[0], filtered));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instancesAll]);
    // Notify that instances has been loaded
    useEffect(function () {
        dispatch(filterActions.setFilterIsInstancesLoaded(!isFetching && !isLoading && isSuccess));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isFetching, isSuccess, accessToken.customerId]);
    if (isLoading) {
        return (_jsx("div", __assign({ style: { margin: 10 } }, { children: _jsx(LoaderSmall, {}) })));
    }
    return (_jsx(_Fragment, { children: instances && instances.length > 0 ? (_jsx(Select, __assign({ value: instance === null || instance === void 0 ? void 0 : instance.id, onChange: handleSelectInstance, style: style }, { children: instances === null || instances === void 0 ? void 0 : instances.map(function (instance) { return (_jsx("option", __assign({ value: instance.id }, { children: instance.name }), instance.id)); }) }))) : (_jsx(Select, __assign({ disabled: true }, { children: _jsx("option", { children: "-" }) }))) }));
};
SelectSurveyPeriod.displayName = 'SelectSurveyPeriod';
