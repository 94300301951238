import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { useSelectCustomerMutation } from '@dimatech/features-core/lib/api/customer/customerApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useAppDispatch } from 'hooks';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Used for navigation to a specific customer using url /customer/:customerId
// After customer has been set it will redirect to url specified in state (if available)
// I.e:
// navigate(`/customer/${customerId}`, {
//  state: { from: '/administrate/schedule' },
// })
//

export const Customer = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [selectCustomer] = useSelectCustomerMutation();
  const { refreshToken, accessToken } = useContext(AuthenticationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { customerId = '' } = useParams();

  useEffect(() => {
    if (customerId && customerId !== accessToken.customerId) {
      dispatch(appActions.resetApplication());

      if (!refreshToken) {
        return;
      }

      selectCustomer({
        customerId,
        refreshToken: refreshToken.get(),
      })
        .unwrap()
        .then((result) => {
          accessToken.update(result.accessToken);
          refreshToken.update(result.refreshToken);

          navigate((location.state as { from: string })?.from ?? '/');
        });
    } else {
      navigate((location.state as { from: string })?.from ?? '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, refreshToken]);

  return null;
};

Customer.displayName = 'Customer';
