var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../localization';
import { Language } from '../../models';
import { BadgeSmall } from '../Badge';
import { TooltipContent } from '../tooltip';
export var SwitchLocale = function () {
    var _a = useContext(LocalizationContext), locale = _a.locale, changeLocale = _a.changeLocale;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-locale-switch", text: t('Profile.SwitchLocale.TooltipText', {
                    toLocale: locale === Language.English
                        ? t('Profile.Languages.Swedish')
                        : t('Profile.Languages.English'),
                }) }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-locale-switch", style: { margin: '0 10px 0 10px' }, onClick: function () {
                    return changeLocale(locale === Language.English ? Language.Swedish : Language.English);
                }, role: "button", tabIndex: 0, "aria-label": t('Profile.SwitchLocale.TooltipText', {
                    toLocale: locale === Language.English
                        ? t('Profile.Languages.Swedish')
                        : t('Profile.Languages.English'),
                }) }, { children: locale === Language.English
                    ? t('Profile.Languages.SwedishShort')
                    : t('Profile.Languages.EnglishShort') }))] }));
};
SwitchLocale.displayName = 'SwitchLocale';
