import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Tooltip } from '@dimatech/shared/lib/components/tooltip';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { selectFilterByEntityType } from 'api/dimiosSlice';
import { config } from 'config';
import { useAppSelector } from 'hooks';
import { CategoryByCustomer, DataPlot, EntityType } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import styled, { withTheme } from 'styled-components';
import { Chroma, formatAsPercent, scaler } from 'utils';

// Documentation: https://www.react-simple-maps.io/

export const ResultPerType = withTheme(
  ({
    theme,
    categoryByCustomers,
  }: {
    theme: Theme;
    categoryByCustomers?: CategoryByCustomer[];
  }): JSX.Element => {
    const { t } = useTranslation();
    const filter = useAppSelector(selectFilterByEntityType);
    const [tooltip, setTooltip] = useState<DataPlot>();

    const isEntityTypeSupported =
      filter.entityType === EntityType.Region ||
      filter.entityType === EntityType.Municipality;

    if (filter.entityType && !isEntityTypeSupported) {
      return (
        <Style>
          <AlertInfo>
            {t('AllResult.ResultPerType.EntityTypeNotSupported')}
          </AlertInfo>
        </Style>
      );
    }

    const fileName =
      filter.entityType === EntityType.Region
        ? 'regions.json'
        : 'municipalities.json';

    const topoJson = `${config.products.dimios.url}/map/${fileName}`;

    const chroma: Chroma = {
      colorFrom: theme.colors.surface,
      colorTo: theme.colors.primary,
      start: Math.min(
        ...(categoryByCustomers?.map((category) => category.percentage) ?? [0])
      ),
      end: Math.max(
        ...(categoryByCustomers?.map((category) => category.percentage) ?? [1])
      ),
      min: 0.2,
    };

    const scale = scaler(chroma);

    return (
      <Style>
        <ComposableMap
          projection="geoMercator"
          style={{ height: '100%', width: '100%' }}
        >
          <ZoomableGroup
            zoom={17}
            center={[17, -297]}
            minZoom={15}
            maxZoom={45}
          >
            <Geographies geography={topoJson} data-tip data-for={`tt-map`}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const { name } = geo.properties;
                  const customer = categoryByCustomers?.find(
                    (category) => category.organization === name
                  );

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        setTooltip({
                          label: name,
                          x: Number(customer?.ability?.percentage ?? 0) * 100,
                          y: Number(customer?.heritage?.percentage ?? 0) * 100,
                          z: Number(customer?.percentage ?? 0) * 100,
                        });
                      }}
                      onMouseLeave={() => {
                        setTooltip(undefined);
                      }}
                      stroke={theme.colors.onSurface}
                      strokeWidth={0.04}
                      style={{
                        default: {
                          fill: customer?.percentage
                            ? scale(Number(customer?.percentage ?? 0)).hex()
                            : theme.colors.surface,
                          outline: 'none',
                        },
                        hover: {
                          fill: theme.colors.secondary,
                          outline: 'none',
                        },
                        pressed: {
                          fill: theme.colors.secondary,
                          outline: 'none',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>

        <Tooltip id="tt-map">
          {tooltip?.label && (
            <div className="tooltip-title">{tooltip.label}</div>
          )}

          <div>
            {`${t('CommonTerms.DigitalMaturity')} : ${formatAsPercent(
              tooltip?.z
            )}`}
          </div>

          <div>
            {`${t('CommonTerms.DigitalHeritage')} : ${formatAsPercent(
              tooltip?.y
            )}`}
          </div>

          <div>
            {`${t('CommonTerms.DigitalAbility')} : ${formatAsPercent(
              tooltip?.x
            )}`}
          </div>
        </Tooltip>
      </Style>
    );
  }
);

ResultPerType.displayName = 'ResultPerType';

const Style = styled.div`
  height: 700px;
  width: 400px;

  @media screen and (max-width: ${Breakpoints.small}) {
    width: 320px;
  }
`;
