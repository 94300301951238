import { HttpStatus } from '@dimatech/shared/lib/models';
import { nanoid } from 'nanoid';
import { appActions } from '../appSlice';
// This will intercept all dispatched actions
export var errorToUiMiddleware = function (_a) {
    var dispatch = _a.dispatch;
    return function (next) {
        return function (action) {
            var _a, _b, _c, _d, _e;
            var error = action.error ? (_a = action.payload) !== null && _a !== void 0 ? _a : action.error : undefined;
            if (((_b = action.type) === null || _b === void 0 ? void 0 : _b.endsWith('/rejected')) &&
                // 'ConditionError' is RTK Query's way of saying that the query is cached
                // and will not run again. This is intended behaviour so we can ignore it
                (error === null || error === void 0 ? void 0 : error.name) !== 'ConditionError' &&
                // Ignore Unauthorized and Forbidden here, we don't want to display these in the UI
                (error === null || error === void 0 ? void 0 : error.name) !== 'Unauthorized' &&
                (error === null || error === void 0 ? void 0 : error.name) !== 'Forbidden' &&
                (error === null || error === void 0 ? void 0 : error.status) !== HttpStatus.Unauthorized &&
                (error === null || error === void 0 ? void 0 : error.status) !== HttpStatus.Forbidden &&
                // Ignore 404 here
                (error === null || error === void 0 ? void 0 : error.name) !== 'NotFound' &&
                (error === null || error === void 0 ? void 0 : error.status) !== HttpStatus.NotFound &&
                // Ignore ValidationError here, they are handled where appropriate
                (error === null || error === void 0 ? void 0 : error.name) !== 'ValidationError' &&
                // Ignore PasswordMismatch here, they are handled where appropriate
                (error === null || error === void 0 ? void 0 : error.name) !== 'PasswordMismatch' &&
                // Ignore Rejected here, these are handled in api baseQuery
                (error === null || error === void 0 ? void 0 : error.message) !== 'Rejected') {
                // Send error to common state so it can be picked up by UI notification
                dispatch(appActions.setError({
                    // Give error a unique id
                    id: nanoid(),
                    // Create some generic error message from status and title
                    message: "".concat((_c = error.status) !== null && _c !== void 0 ? _c : error.name, " - ").concat((_e = (_d = error.message) !== null && _d !== void 0 ? _d : error.name) !== null && _e !== void 0 ? _e : 'Error'),
                    // TODO: Just add extra info here if dev env or user is global admin
                    extraInfo: JSON.stringify(error),
                }));
            }
            next(action);
        };
    };
};
