var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import styled from 'styled-components';
import { BadgeMini } from '../Badge';
export var Carousel = function (_a) {
    var style = _a.style, children = _a.children;
    var t = useTranslation().t;
    var _b = useState(0), currentIndex = _b[0], setCurrentIndex = _b[1];
    var _c = useState(Array.isArray(children) ? children.length : 1), length = _c[0], setLength = _c[1];
    var handleNext = function () {
        if (currentIndex < length - 1) {
            setCurrentIndex(function (prevState) { return prevState + 1; });
        }
    };
    var handlePrevious = function () {
        if (currentIndex > 0) {
            setCurrentIndex(function (prevState) { return prevState - 1; });
        }
    };
    useEffect(function () {
        setLength(Array.isArray(children) ? children.length : 1);
    }, [children]);
    return (_jsxs(ContainerStyle, __assign({ style: style }, { children: [_jsx(WrapperStyle, { children: _jsx(ContentWrapperStyle, { children: _jsx(ContentStyle, __assign({ style: { transform: "translateX(-".concat(currentIndex * 100, "%)") } }, { children: children })) }) }), _jsxs(NavigationStyle, { children: [_jsx("div", { children: currentIndex > 0 && (_jsxs("div", { children: [_jsx(BadgeMini, __assign({ "$inverted": true, onClick: handlePrevious }, { children: _jsx(BsArrowLeft, {}) })), _jsx("div", __assign({ onClick: handlePrevious }, { children: t('Common.Form.Previous') }))] })) }), _jsx("div", { children: currentIndex < length - 1 && (_jsxs("div", { children: [_jsx("div", __assign({ onClick: handleNext }, { children: t('Common.Form.Next') })), _jsx(BadgeMini, __assign({ "$inverted": true, onClick: handleNext }, { children: _jsx(BsArrowRight, {}) }))] })) })] })] })));
};
Carousel.displayName = 'Carousel';
var ContainerStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  border-radius: 7px;\n\n  background-color: ", ";\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  border-radius: 7px;\n\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
});
var WrapperStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var NavigationStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  border-radius: 4px;\n\n  background-color: ", ";\n  color: ", ";\n\n  > div > div {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  border-radius: 4px;\n\n  background-color: ", ";\n  color: ", ";\n\n  > div > div {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    cursor: pointer;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
});
var ContentWrapperStyle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
var ContentStyle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  transition: all 150ms linear;\n  overflow: none;\n"], ["\n  display: flex;\n  align-items: flex-start;\n  transition: all 150ms linear;\n  overflow: none;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
