var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { config } from '@dimatech/shared/lib/config';
import { axiosBaseQuery, } from '@dimatech/shared/lib/middleware/axiosBaseQuery';
import { HttpStatus } from '@dimatech/shared/lib/models';
import { Mutex } from 'async-mutex';
import axios from 'axios';
import { TokenName } from '../models';
import { getRefreshToken, removeAccessToken, removeRefreshToken, storeAccessToken, storeRefreshToken, } from '../models/token';
var mutex = new Mutex();
var refreshAuthTokenQuery = function (refreshToken) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(config.apiUrl.product.current).concat(config.apiUrl.authentication.refresh), { refreshToken: refreshToken })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); };
export var axiosBaseQueryWithReAuthentication = function (_a) {
    var _b = _a === void 0 ? { baseUrl: '' } : _a, baseUrl = _b.baseUrl;
    return function (args, api, extraOptions) { return __awaiter(void 0, void 0, void 0, function () {
        var result, refreshToken, authUrl, refreshUrl, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                // Wait until the mutex is available without locking it
                return [4 /*yield*/, mutex.waitForUnlock()];
                case 1:
                    // Wait until the mutex is available without locking it
                    _a.sent();
                    return [4 /*yield*/, axiosBaseQuery({ baseUrl: baseUrl })(args, api, extraOptions)];
                case 2:
                    result = _a.sent();
                    refreshToken = getRefreshToken(TokenName.Refresh);
                    authUrl = "".concat(config.apiUrl.authentication.login);
                    refreshUrl = "".concat(config.apiUrl.authentication.refresh);
                    error = result.error;
                    if (!(error &&
                        (error.status === HttpStatus.Unauthorized ||
                            error.status === HttpStatus.Forbidden) &&
                        args.url !== authUrl &&
                        args.url !== refreshUrl &&
                        refreshToken)) return [3 /*break*/, 7];
                    if (!!mutex.isLocked()) return [3 /*break*/, 4];
                    return [4 /*yield*/, mutex.runExclusive(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        // Get refresh token again
                                        refreshToken = getRefreshToken(TokenName.Refresh);
                                        // Refresh auth token using refresh token
                                        return [4 /*yield*/, refreshAuthTokenQuery(refreshToken)
                                                .then(function (auth) {
                                                storeAccessToken(TokenName.Access, auth.accessToken);
                                                storeRefreshToken(TokenName.Refresh, auth.refreshToken);
                                            })
                                                .catch(function () {
                                                // Force logout
                                                console.info('Invalid refresh token, quitting...');
                                                removeAccessToken(TokenName.Access);
                                                removeRefreshToken(TokenName.Refresh);
                                            })];
                                    case 1:
                                        // Refresh auth token using refresh token
                                        _a.sent();
                                        return [4 /*yield*/, axiosBaseQuery({ baseUrl: baseUrl })(args, api, extraOptions)];
                                    case 2:
                                        // Retry the initial query
                                        result = _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 4: 
                // Wait until the mutex is available without locking it
                return [4 /*yield*/, mutex.waitForUnlock()];
                case 5:
                    // Wait until the mutex is available without locking it
                    _a.sent();
                    return [4 /*yield*/, axiosBaseQuery({ baseUrl: baseUrl })(args, api, extraOptions)];
                case 6:
                    result = _a.sent();
                    _a.label = 7;
                case 7: return [2 /*return*/, result];
            }
        });
    }); };
};
