var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AlertToast } from '../Alert';
export var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 8px;\n\n  color: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  border-radius: 3px;\n\n  cursor: pointer;\n\n  &:disabled {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid\n      ", ";\n\n    cursor: default;\n  }\n"], ["\n  padding: 8px;\n\n  color: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  border-radius: 3px;\n\n  cursor: pointer;\n\n  &:disabled {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid\n      ", ";\n\n    cursor: default;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSecondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSecondaryDim;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryDim;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryDim;
});
Button.displayName = 'Button';
export var ButtonSecondary = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  background-color: transparent;\n  border: 1px solid\n    ", ";\n\n  &:disabled {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid\n      ", ";\n  }\n"], ["\n  color: ", ";\n  background-color: transparent;\n  border: 1px solid\n    ", ";\n\n  &:disabled {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid\n      ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryVariant;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSecondaryDim;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryDimVariant;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryDimVariant;
});
ButtonSecondary.displayName = 'ButtonSecondary';
export var ButtonLink = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  background-color: transparent;\n\n  border: none;\n  border-bottom: 1px solid\n    ", ";\n\n  cursor: pointer;\n\n  &:disabled {\n    color: ", ";\n    border-bottom: 1px solid\n      ", ";\n\n    cursor: default;\n  }\n"], ["\n  font-size: ", ";\n  color: ", ";\n  background-color: transparent;\n\n  border: none;\n  border-bottom: 1px solid\n    ", ";\n\n  cursor: pointer;\n\n  &:disabled {\n    color: ", ";\n    border-bottom: 1px solid\n      ", ";\n\n    cursor: default;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSecondaryDim;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryDim;
});
ButtonLink.displayName = 'ButtonLink';
export var Buttons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 10px;\n\n  margin-top: 20px;\n\n  button {\n    min-width: 100px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 10px;\n\n  margin-top: 20px;\n\n  button {\n    min-width: 100px;\n  }\n"])));
Buttons.displayName = 'Buttons';
export var ButtonFooter = function (_a) {
    var children = _a.children, style = _a.style;
    return (_jsx(ButtonFooterWithToastStyle, __assign({ style: style }, { children: _jsx("div", { children: children }) })));
};
export var ButtonFooterWithToast = function (_a) {
    var isSaved = _a.isSaved, setIsSaved = _a.setIsSaved, children = _a.children, style = _a.style;
    var t = useTranslation().t;
    return (_jsxs(ButtonFooterWithToastStyle, __assign({ style: style }, { children: [_jsx("div", { children: children }), _jsx(AlertToast, __assign({ style: { marginTop: 20 }, isSaved: isSaved, setIsSaved: setIsSaved }, { children: t('Common.Form.ChangesHasBeenSaved') }))] })));
};
ButtonFooterWithToast.displayName = 'ButtonFooterWithToast';
var ButtonFooterWithToastStyle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  > div {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n\n    > div:only-child {\n      margin-left: auto;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  > div {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n\n    > div:only-child {\n      margin-left: auto;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
