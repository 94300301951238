var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonFooter, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteSurveyAnonRespondentMutation } from '../../../api/entity/entitySurveyAnonRespondentApi';
import { entitySurveyAnonRespondentActions, selectIsDeletingAnonymousRespondents, } from '../../../api/entity/entitySurveyAnonRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
export var AnonymousRespondentsActionDelete = function (_a) {
    var entity = _a.entity, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var isDeletingRespondents = useSelector(selectIsDeletingAnonymousRespondents);
    var _b = useDeleteSurveyAnonRespondentMutation(), deleteSurveyAnonRespondent = _b[0], isSuccess = _b[1].isSuccess;
    var _c = useState(false), isDeleting = _c[0], setIsDeleting = _c[1];
    var handleDelete = function (e) {
        e.stopPropagation();
        if (isReadOnly) {
            return;
        }
        deleteSurveyAnonRespondent({
            entityId: entity.id,
        });
        dispatch(entitySurveyAnonRespondentActions.setIsDeletingAnonymousRespondents(false));
    };
    var handleConfirmDelete = function (e, show) {
        e.stopPropagation();
        dispatch(entitySurveyAnonRespondentActions.setIsDeletingAnonymousRespondents(show));
    };
    var handleMove = function (e) {
        e.stopPropagation();
        dispatch(entitySurveyAnonRespondentActions.setIsMovingAnonymousRespondents(true));
    };
    useEffect(function () {
        setIsDeleting(isDeletingRespondents);
    }, [isDeletingRespondents]);
    useEffect(function () {
        if (isSuccess) {
            handleRespondentMovedOrDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.AnonRespondent.Delete.ConfirmTitle'), handleKeyEnter: function (e) { return handleDelete(e); }, handleKeyEsc: function (e) { return handleConfirmDelete(e, false); } }, { children: [_jsx("div", { children: t('Administrate.AnonRespondent.Delete.ConfirmText') }), _jsx(AlertWarning, __assign({ style: { margin: '15px 0' } }, { children: t('Administrate.AnonRespondent.Delete.ConfirmWarning') })), _jsx("div", __assign({ className: "i size-s" }, { children: t('Administrate.AnonRespondent.Delete.ConfirmNote') })), _jsxs(ButtonFooter, { children: [_jsx(Buttons, { children: _jsx(ButtonSecondary, __assign({ type: "button", onClick: handleMove }, { children: t('Administrate.AnonRespondent.Move.TooltipTitle') })) }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleDelete(e); }, disabled: isReadOnly }, { children: t('Common.UI.Yes') }))] })] })] }))), _jsx(LinkWithTooltip, { style: { marginLeft: 10 }, isDisabled: isReadOnly || !filter.instance.isCurrentInstance, handleClick: function (e) {
                    return handleConfirmDelete(e, filter.instance.isCurrentInstance);
                }, title: t('Administrate.AnonRespondent.Delete.TooltipTitle'), tooltipTitle: t('Administrate.AnonRespondent.Delete.TooltipTitle'), tooltip: t('Administrate.AnonRespondent.Delete.Tooltip'), icon: _jsx(BsTrash, {}) })] }));
};
AnonymousRespondentsActionDelete.displayName =
    'AnonymousRespondentsActionDelete';
