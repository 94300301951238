var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { Button, ButtonSecondary, Buttons, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowsMove } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useMoveSurveyAnonRespondentMutation } from '../../../api/entity/entitySurveyAnonRespondentApi';
import { entitySurveyAnonRespondentActions, selectIsMovingAnonymousRespondents, } from '../../../api/entity/entitySurveyAnonRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
import { SelectEntityWithSurvey } from '../../../components/SelectEntityWithSurvey';
export var AnonymousRespondentsActionMove = function (_a) {
    var entity = _a.entity, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var isMovingRespondents = useSelector(selectIsMovingAnonymousRespondents);
    var _b = useMoveSurveyAnonRespondentMutation(), moveSurveyAnonRespondent = _b[0], isSuccess = _b[1].isSuccess;
    var _c = useState(false), isMoving = _c[0], setIsMoving = _c[1];
    var _d = useState(''), entityId = _d[0], setEntityId = _d[1];
    var handleMove = function (e) {
        e.stopPropagation();
        if (isReadOnly || !entityId) {
            return;
        }
        moveSurveyAnonRespondent({
            entityId: entity.id,
            toEntityId: entityId,
        });
        dispatch(entitySurveyAnonRespondentActions.setIsMovingAnonymousRespondents(false));
    };
    var handleConfirmMove = function (e, show) {
        e.stopPropagation();
        dispatch(entitySurveyAnonRespondentActions.setIsMovingAnonymousRespondents(show));
    };
    useEffect(function () {
        setIsMoving(isMovingRespondents);
    }, [isMovingRespondents]);
    useEffect(function () {
        if (isSuccess) {
            handleRespondentMovedOrDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    return (_jsxs(_Fragment, { children: [isMoving && (_jsxs(Modal, __assign({ title: t('Administrate.AnonRespondent.Move.Title'), handleKeyEnter: function (e) { return handleMove(e); }, handleKeyEsc: function (e) { return handleConfirmMove(e, false); } }, { children: [_jsx("div", { children: t('Administrate.AnonRespondent.Move.Text') }), _jsx(Label, { children: t('Administrate.AnonRespondent.Move.To') }), _jsx(SelectEntityWithSurvey, { autoFocus: true, entityId: entityId, setEntityId: setEntityId, surveyInstanceId: filter.instance.id, placeholder: t('Administrate.AnonRespondent.Move.SelectEntity') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmMove(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleMove(e); }, disabled: entity.id === entityId || !entityId || isReadOnly }, { children: t('Administrate.Respondent.Button.Move') }))] })] }))), _jsx(LinkWithTooltip, { isDisabled: isReadOnly || !filter.instance.isCurrentInstance, handleClick: function (e) {
                    return handleConfirmMove(e, filter.instance.isCurrentInstance);
                }, title: t('Administrate.AnonRespondent.Move.Title'), tooltipTitle: t('Administrate.AnonRespondent.Move.TooltipTitle'), tooltip: t('Administrate.AnonRespondent.Move.Tooltip'), icon: _jsx(BsArrowsMove, {}) })] }));
};
AnonymousRespondentsActionMove.displayName = 'AnonymousRespondentsActionMove';
