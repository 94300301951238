import { Gauge } from '@dimatech/features-core/lib/components/Gauge';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PrinciplesByCategory } from 'models';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Statistics = ({
  principlesByCategory,
  disagreement,
}: {
  principlesByCategory: PrinciplesByCategory;
  disagreement?: number;
}): JSX.Element => {
  const { t } = useTranslation();

  const isStandardDeviationEnabledFlagOn =
    useFlags()[flags.permanent.app.dimios.isStandardDeviationEnabled];

  const disagreementValue = disagreement ? disagreement * 100 : 0;

  return (
    <Style>
      <Gauge
        value={
          ((principlesByCategory.heritage.percentage ?? 0) +
            (principlesByCategory.ability.percentage ?? 0)) /
          2
        }
        defaultValue="-"
        name={t('CommonTerms.DigitalMaturity')}
        text={<Trans i18nKey="Overview.Statistics.DigitalMaturity.Help" />}
      />
      <Gauge
        value={principlesByCategory.heritage.percentage}
        defaultValue="-"
        name={t('CommonTerms.DigitalHeritage')}
        text={<Trans i18nKey="Overview.Statistics.DigitalHeritage.Help" />}
      />
      <Gauge
        value={principlesByCategory.ability.percentage}
        defaultValue="-"
        name={t('CommonTerms.DigitalAbility')}
        text={<Trans i18nKey="Overview.Statistics.DigitalAbility.Help" />}
      />
      {isStandardDeviationEnabledFlagOn && (
        <Gauge
          value={disagreementValue}
          defaultValue="-"
          name={t('CommonTerms.Disagreement')}
          text={<Trans i18nKey="Overview.Statistics.Disagreement.Help" />}
        />
      )}
    </Style>
  );
};

Statistics.displayName = 'Statistics';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  ul,
  ol {
    margin-left: 20px;
  }

  > div {
    margin-right: 15px;
  }
`;
