var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
export var Chart = function (_a) {
    var style = _a.style, children = _a.children;
    return (_jsx(Style, __assign({ style: style }, { children: children && (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "95%", debounce: 1 }, { children: children }))) })));
};
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 380px;\n  height: 355px;\n\n  font-size: ", ";\n\n  @media screen and (max-width: ", ") {\n    width: 315px !important;\n    height: 285px !important;\n  }\n"], ["\n  width: 380px;\n  height: 355px;\n\n  font-size: ", ";\n\n  @media screen and (max-width: ", ") {\n    width: 315px !important;\n    height: 285px !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xxs;
}, Breakpoints.small);
var templateObject_1;
