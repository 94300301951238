var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ViewRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  align-items: center;\n\n  max-width: ", ";\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  align-items: center;\n\n  max-width: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.size.viewMaxWidth;
});
ViewRow.displayName = 'ViewRow';
export var ViewRowBreak = styled.hr(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-basis: 100%;\n  height: 0;\n  margin: 10px 0;\n  border: 0;\n"], ["\n  flex-basis: 100%;\n  height: 0;\n  margin: 10px 0;\n  border: 0;\n"])));
ViewRowBreak.displayName = 'ViewRowBreak';
var templateObject_1, templateObject_2;
