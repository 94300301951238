var _a;
import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isMovingAnonymousRespondents: false,
    isDeletingAnonymousRespondents: false,
};
export var entitySurveyAnonRespondentReducer = (_a = createSlice({
    name: 'entity/survey/respondent/anon',
    initialState: initialState,
    reducers: {
        setIsMovingAnonymousRespondents: function (state, action) {
            state.isDeletingAnonymousRespondents = false;
            state.isMovingAnonymousRespondents = action.payload;
        },
        setIsDeletingAnonymousRespondents: function (state, action) {
            state.isDeletingAnonymousRespondents = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function () { return initialState; });
    },
}), _a.reducer), entitySurveyAnonRespondentActions = _a.actions;
export var selectIsMovingAnonymousRespondents = function (state) { return state.entitySurveyAnonRespondent.isMovingAnonymousRespondents; };
export var selectIsDeletingAnonymousRespondents = function (state) { return state.entitySurveyAnonRespondent.isDeletingAnonymousRespondents; };
