import { CacheTagsEntity, featuresSurveyApi } from '../featuresSurveyApi';
var entitySurveyScheduleApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Update survey message
         */
        updateSurveySchedule: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, startDate = _a.startDate, endDate = _a.endDate;
                return ({
                    url: "entities/".concat(entityId, "/survey/schedule"),
                    method: 'PUT',
                    body: {
                        entityId: entityId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                });
            },
            invalidatesTags: [
                CacheTagsEntity.EntitySurvey,
                CacheTagsEntity.ProgressByEntity,
                CacheTagsEntity.EntityWithSurvey,
            ],
        }),
        /**
         * Delete schedule
         */
        deleteSurveySchedule: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId;
                return ({
                    url: "entities/".concat(entityId, "/survey/schedule"),
                    method: 'DELETE',
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
        }),
    }); },
});
export var useUpdateSurveyScheduleMutation = entitySurveyScheduleApi.useUpdateSurveyScheduleMutation, useDeleteSurveyScheduleMutation = entitySurveyScheduleApi.useDeleteSurveyScheduleMutation;
