import { combineReducers } from 'redux';
import { reportHistoricalResearcherReducer } from './reportHistoricalResearcherSlice';
import { reportHistoricalReducer } from './reportHistoricalSlice';
import { reportOverviewReducer } from './reportOverviewSlice';

export const report = combineReducers({
  overview: reportOverviewReducer,
  historical: reportHistoricalReducer,
  historicalResearcher: reportHistoricalResearcherReducer,
});
