var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ImageCarouselItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n\n  width: 100%;\n  flex-shrink: 0;\n  flex-grow: 1;\n  border: 2px solid ", ";\n\n  img {\n    max-width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n\n  width: 100%;\n  flex-shrink: 0;\n  flex-grow: 1;\n  border: 2px solid ", ";\n\n  img {\n    max-width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
ImageCarouselItem.displayName = 'ImageCarouselItem';
export var ImageCarouselBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n\n  height: 100%;\n  margin: 10px 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n\n  height: 100%;\n  margin: 10px 0;\n"])));
ImageCarouselBody.displayName = 'ImageCarouselBody';
export var ImageCarouselFooter = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  padding: 10px;\n\n  font-size: ", ";\n  background-color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  padding: 10px;\n\n  font-size: ", ";\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
});
ImageCarouselFooter.displayName = 'ImageCarouselFooter';
var templateObject_1, templateObject_2, templateObject_3;
