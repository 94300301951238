var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Feature = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 100vh;\n  padding: 15px 10px 60px 10px;\n\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  align-items: flex-start;\n  align-content: flex-start;\n\n  color: ", ";\n  background-color: ", ";\n"], ["\n  width: 100%;\n  min-height: 100vh;\n  padding: 15px 10px 60px 10px;\n\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  align-items: flex-start;\n  align-content: flex-start;\n\n  color: ", ";\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
Feature.displayName = 'Feature';
var templateObject_1;
