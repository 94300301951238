var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HelpIcon, HelpIconLeft, } from '@dimatech/shared/lib/components/HelpIcon';
import { Checkbox, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerType } from '../../../models';
import { AuthenticationContext } from '../AuthenticationProvider';
export var OrderProductCustomerInfo = function (_a) {
    var _b, _c, _d, _e;
    var productId = _a.productId, isConsortiumLicense = _a.isConsortiumLicense, setIsConsortiumLicense = _a.setIsConsortiumLicense, isBaseVersion = _a.isBaseVersion, setIsBaseVersion = _a.setIsBaseVersion;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isBaseLicenseEnabledFlagOn = useFlags()[flags.permanent.app.pios.isBaseLicenseEnabled];
    return (_jsxs("section", { children: [_jsx(Heading3, { children: t('Order.Organisation.Title') }), _jsxs("div", { children: [_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "name" }, { children: t('Order.Organisation.Name') })), _jsx(Input, { type: "text", id: "name", name: "name", value: (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.currentCustomer.name, readOnly: true })] }), !accessToken.isInternational && (_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "name" }, { children: t('Order.Organisation.Type') })), _jsx(Input, { type: "text", id: "type", name: "type", value: t("Customer.Type.".concat((_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.currentCustomer.customerType)), readOnly: true })] }))] }), !accessToken.isInternational &&
                ((_d = accessToken.user) === null || _d === void 0 ? void 0 : _d.currentCustomer.customerType) !==
                    CustomerType.PubliclyOwnedOrganization &&
                ((_e = accessToken.user) === null || _e === void 0 ? void 0 : _e.currentCustomer.customerType) !== undefined && (_jsxs(_Fragment, { children: [_jsx(Checkbox, { checked: isConsortiumLicense, name: "isConsortiumLicense", id: "isConsortiumLicense", value: "1", onChange: function (e) { return setIsConsortiumLicense(e.currentTarget.checked); } }), _jsx(Label, __assign({ htmlFor: "isConsortiumLicense", style: { display: 'inline-block', margin: 10 } }, { children: t('Order.ConsortiumLicense') }))] })), isBaseLicenseEnabledFlagOn && productId === config.products.pios.id && (_jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Checkbox, { checked: isBaseVersion, name: "isBaseVersion", id: "isBaseVersion", onChange: function (e) {
                            setIsBaseVersion(e.currentTarget.checked);
                        } }), _jsxs(HelpIconLeft, { children: [_jsx(Label, __assign({ htmlFor: "isBaseVersion", style: { display: 'inline-block', margin: 10 } }, { children: t('Register.BaseVersion.Title') })), _jsx(HelpIcon, { isInverted: true, style: { marginLeft: 7 }, title: t('Register.BaseVersion.Title'), text: t('Register.BaseVersion.Text') })] })] })))] }));
};
OrderProductCustomerInfo.displayName = 'OrderProductCustomerInfo';
