var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink, Switch } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { config } from '@dimatech/shared/lib/config';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsClipboardPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGetEntitiesWithSurveyQuery, useUpdateAllowEntitySelectionForAnonymousUsersMutation, useUpdateIndividualMeasurementPeriodMutation, } from '../../api/entity/entityApi';
import { entityActions, selectSelectedEntity, } from '../../api/entity/entitySlice';
import { useGetEntitySurveyQuery } from '../../api/entity/entitySurveyApi';
import { selectFilter } from '../../api/filter/filterSlice';
import { SelectExtendedFilters } from '../../components/SelectExtendedFilters';
import { EntitiesTree } from '../administrate-organisation/components/EntitiesTree';
import { Schedule } from './components/Schedule';
import { ScheduleDisallowEntitySelectionForAnonymousUsersConfirm } from './components/ScheduleDisallowEntitySelectionForAnonymousUsersConfirm';
import { ScheduleIndividualScheduleConfirm } from './components/ScheduleIndividualScheduleConfirm';
/* eslint-disable max-lines-per-function */
export var AdministrateSchedule = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var enableAllowEntitySelectionForAnonymousUsers = _a.enableAllowEntitySelectionForAnonymousUsers;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var selectedEntity = useSelector(selectSelectedEntity);
    var updateIndividualMeasurementPeriod = useUpdateIndividualMeasurementPeriodMutation()[0];
    var updateAllowEntitySelectionForAnonymousUsers = useUpdateAllowEntitySelectionForAnonymousUsersMutation()[0];
    var _k = useState(false), allowIndividualMeasurementPeriods = _k[0], setAllowIndividualMeasurementPeriods = _k[1];
    var _l = useState(false), hasIndividualMeasurementPeriods = _l[0], setHasIndividualMeasurementPeriods = _l[1];
    var _m = useState(false), isShowingConfirmIndividualMeasurementPeriods = _m[0], setIsShowingConfirmIndividualMeasurementPeriods = _m[1];
    var _o = useState(false), isShowingConfirmDisallowEntitySelectionForAnonymousUsers = _o[0], setIsShowingConfirmDisallowEntitySelectionForAnonymousUsers = _o[1];
    var _p = useState(false), allowEntitySelectionForAnonymousUsers = _p[0], setAllowEntitySelectionForAnonymousUsers = _p[1];
    var _q = useState(), root = _q[0], setRoot = _q[1];
    var accessToken = useContext(AuthenticationContext).accessToken;
    var filter = useSelector(selectFilter);
    var entities = useGetEntitiesWithSurveyQuery(accessToken.customerId && ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) && filter.instance.id
        ? {
            _customerId: accessToken.customerId,
            _userId: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    var _r = useGetEntitySurveyQuery((selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) && accessToken.customerId && filter.instance.id
        ? {
            entityId: selectedEntity.id,
            _customerId: accessToken.customerId,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken), entitySurvey = _r.data, isFetching = _r.isFetching, isLoading = _r.isLoading;
    var canEdit = accessToken.isInRole(CommonRoles.CustomerAccountAdmin) ||
        accessToken.isInRole(CommonRoles.GlobalAdmin);
    var handleCheckIfConfirmIndividualMeasurementPeriods = function (value) {
        // Show confirm dialog if trying to turn off periods and any
        // child currently uses individual measurement periods
        if (hasIndividualMeasurementPeriods && !value) {
            setIsShowingConfirmIndividualMeasurementPeriods(true);
        }
        // do not allow respondents to select entity if individual
        // measurement periods are allowed
        else if (!allowIndividualMeasurementPeriods &&
            allowEntitySelectionForAnonymousUsers) {
            setIsShowingConfirmDisallowEntitySelectionForAnonymousUsers(value);
        }
        else {
            handleUpdateIndividualMeasurementPeriods(value);
        }
    };
    var handleConfirmIndividualMeasurementPeriods = function () {
        handleUpdateIndividualMeasurementPeriods(!allowIndividualMeasurementPeriods);
    };
    var handleConfirmDisallowEntitySelectionForAnonymousUsers = function () {
        handleUpdateIndividualMeasurementPeriods(true);
        handleUpdateAllowEntitySelectionForAnonymousUsers(false);
    };
    var handleUpdateIndividualMeasurementPeriods = function (value) {
        setAllowIndividualMeasurementPeriods(value);
        setIsShowingConfirmIndividualMeasurementPeriods(false);
        setIsShowingConfirmDisallowEntitySelectionForAnonymousUsers(false);
        if (root) {
            updateIndividualMeasurementPeriod({
                entityId: root.id,
                allowIndividualMeasurementPeriods: value,
            });
        }
    };
    var handleUpdateAllowEntitySelectionForAnonymousUsers = function (value) {
        setAllowEntitySelectionForAnonymousUsers(value);
        if (root) {
            updateAllowEntitySelectionForAnonymousUsers({
                entityId: root.id,
                allowEntitySelectionForAnonymousUsers: value,
            });
        }
    };
    useEffect(function () {
        var _a;
        if (entities && entities.length > 0) {
            var rootEntity = (_a = entities.find(function (entity) { return !entity.parentId; })) !== null && _a !== void 0 ? _a : entities[0];
            setRoot(rootEntity);
            var hasPeriod = entities.some(function (entity) { return entity.allowIndividualMeasurementPeriods; });
            setHasIndividualMeasurementPeriods(hasPeriod);
            setAllowIndividualMeasurementPeriods(!!(rootEntity === null || rootEntity === void 0 ? void 0 : rootEntity.allowIndividualMeasurementPeriods));
            setAllowEntitySelectionForAnonymousUsers(!!(rootEntity === null || rootEntity === void 0 ? void 0 : rootEntity.allowEntitySelectionForAnonymousUsers));
            if (!hasPeriod &&
                (!selectedEntity || !rootEntity.allowIndividualMeasurementPeriods)) {
                dispatch(entityActions.selectedEntity(rootEntity));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities, selectedEntity]);
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, __assign({ style: { justifyContent: 'space-between', paddingRight: 15 } }, { children: [_jsxs("div", __assign({ style: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                        } }, { children: [_jsx(Heading1, __assign({ style: { marginRight: 20 } }, { children: t('Administrate.Schedule.Title') })), _jsx(SelectExtendedFilters, { style: { marginRight: '20px' } }), canEdit && ((_d = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _d === void 0 ? void 0 : _d.currentCustomer.isApproved) && (_jsx(Switch, { label: t('Administrate.Schedule.IndividualMeasurementPeriods.Allow.Label'), tooltipTitle: t('Administrate.Schedule.IndividualMeasurementPeriods.Allow.TooltipTitle'), tooltipText: _jsx(Trans, { i18nKey: "Administrate.Schedule.IndividualMeasurementPeriods.Allow.Tooltip" }), setIsChecked: function (value) {
                                    return handleCheckIfConfirmIndividualMeasurementPeriods(value);
                                }, isChecked: allowIndividualMeasurementPeriods, isDisabled: !filter.instance.isCurrentInstance })), canEdit &&
                                enableAllowEntitySelectionForAnonymousUsers &&
                                ((_e = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _e === void 0 ? void 0 : _e.currentCustomer.isApproved) && (_jsx(Switch, { label: t('Administrate.Schedule.AllowEntitySelectionForAnonymousUsers.Label'), tooltipTitle: t('Administrate.Schedule.AllowEntitySelectionForAnonymousUsers.TooltipTitle'), tooltipText: _jsx(Trans, { i18nKey: "Administrate.Schedule.AllowEntitySelectionForAnonymousUsers.Tooltip" }), setIsChecked: function (value) {
                                    return handleUpdateAllowEntitySelectionForAnonymousUsers(value);
                                }, isChecked: allowEntitySelectionForAnonymousUsers, isDisabled: allowIndividualMeasurementPeriods ||
                                    !filter.instance.isCurrentInstance, style: { marginLeft: 20 } }))] })), isShowingConfirmIndividualMeasurementPeriods && (_jsx(ScheduleIndividualScheduleConfirm, { setIsShowingConfirm: setIsShowingConfirmIndividualMeasurementPeriods, handleConfirm: handleConfirmIndividualMeasurementPeriods })), isShowingConfirmDisallowEntitySelectionForAnonymousUsers && (_jsx(ScheduleDisallowEntitySelectionForAnonymousUsersConfirm, { setIsShowingConfirm: setIsShowingConfirmDisallowEntitySelectionForAnonymousUsers, handleConfirm: handleConfirmDisallowEntitySelectionForAnonymousUsers })), filter.instance.isCurrentInstance && (_jsxs("div", __assign({ style: { display: 'flex' } }, { children: [_jsx(TooltipContent, { id: "tt-preview", title: t('Administrate.Schedule.SurveyPreview.TooltipTitle'), text: t('Administrate.Schedule.SurveyPreview.Tooltip') }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-add", onClick: function () {
                                    var _a, _b;
                                    window.open("".concat(config.products.survey.url, "/preview/").concat((_a = config.survey) === null || _a === void 0 ? void 0 : _a.id, "/").concat(accessToken.customerId, "/").concat((_b = root === null || root === void 0 ? void 0 : root.id) !== null && _b !== void 0 ? _b : ''));
                                } }, { children: _jsx(BsClipboardPlus, {}) })), _jsx("a", __assign({ "data-tip": true, "data-for": "tt-preview", target: "blank", style: { marginLeft: 10 }, href: "".concat(config.products.survey.url, "/preview/").concat((_f = config.survey) === null || _f === void 0 ? void 0 : _f.id, "/").concat(accessToken.customerId, "/").concat((_g = root === null || root === void 0 ? void 0 : root.id) !== null && _g !== void 0 ? _g : '') }, { children: t('Administrate.Schedule.SurveyPreview.LinkText') }))] })))] })), _jsx(CustomerAccountInfo, {}), accessToken && !((_h = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _h === void 0 ? void 0 : _h.currentCustomer.isApproved) && (_jsx(Card, __assign({ width: "100%" }, { children: _jsx(AlertInfo, { children: t('Administrate.Schedule.NotApproved') }) }))), ((_j = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _j === void 0 ? void 0 : _j.currentCustomer.isApproved) && (_jsx(_Fragment, { children: _jsx(Card, __assign({ width: "100%" }, { children: _jsxs(CardBody, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Schedule.Text') })), !filter.instance.isCurrentInstance && (_jsx(AlertWarning, __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.ReadOnly') }))), (entities === null || entities === void 0 ? void 0 : entities.length) === 0 && !selectedEntity ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Entity.NoEntities') })), _jsx(ButtonLink, __assign({ type: "button", onClick: function () {
                                            navigate('/administrate/organisation');
                                        } }, { children: t('Administrate.Entity.NoEntitiesAddFirst') }))] })) : (_jsxs(Style, { children: [(hasIndividualMeasurementPeriods ||
                                        allowIndividualMeasurementPeriods) && (_jsx(EntitiesTree, { isStatusShown: true, isDraggable: false })), _jsxs("div", { children: [(isFetching || isLoading) && (_jsx(LoaderOverlay, { children: t('Common.UI.Loading') })), selectedEntity && entitySurvey && entities && (_jsx(Schedule, { allowIndividualMeasurementPeriods: allowIndividualMeasurementPeriods, entitySurvey: entitySurvey, entity: selectedEntity }))] })] }))] }) })) }))] }));
};
AdministrateSchedule.displayName = 'AdministrateSchedule';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div:first-of-type {\n    padding: 0 30px 30px 0;\n  }\n\n  > div:last-of-type {\n    flex-shrink: 1;\n    flex-grow: 6;\n    flex-basis: min-content;\n    padding: 0 30px 30px 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div:first-of-type {\n    padding: 0 30px 30px 0;\n  }\n\n  > div:last-of-type {\n    flex-shrink: 1;\n    flex-grow: 6;\n    flex-basis: min-content;\n    padding: 0 30px 30px 0;\n  }\n"])));
var templateObject_1;
