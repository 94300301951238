var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, Td, TdRight, Th, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { formatDate } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBuilding, BsGear, BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { CommonRoles } from '../../../models';
export var EntitiesListForUser = function (_a) {
    var _b, _c;
    var list = _a.list, role = _a.role, handleNavigateSearch = _a.handleNavigateSearch;
    var t = useTranslation().t;
    var _d = useState(''), roleNameShort = _d[0], setRoleNameShort = _d[1];
    useEffect(function () {
        if (role === CommonRoles.CustomerAdmin) {
            setRoleNameShort('Admin');
        }
        if (role === CommonRoles.Reader) {
            setRoleNameShort('Viewer');
        }
        if (role === CommonRoles.PiosReviewer) {
            setRoleNameShort('Reviewer');
        }
    }, [role]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ style: {
                padding: 20,
                paddingTop: 0,
            } }, { children: [_jsx("div", __assign({ style: { padding: 10 } }, { children: t("Administrate.Entity.".concat(roleNameShort, ".EntityText")) })), _jsxs(Table, __assign({ "$striped": true, style: { width: 'fit-content', minWidth: '40%', marginBottom: 30 } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, {}), _jsx(Th, { children: t('Administrate.Entity.Name') }), _jsx(Th, { children: t("Administrate.Entity.".concat(roleNameShort, ".Added")) }), _jsx(Th, { children: t("Administrate.Entity.".concat(roleNameShort, ".AddedBy")) }), _jsx(ThRight, {})] }) }), _jsx("tbody", { children: (_c = (_b = __spreadArray([], (list !== null && list !== void 0 ? list : []), true)) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) {
                                return a.name > b.name ? 1 : -1;
                            })) === null || _c === void 0 ? void 0 : _c.map(function (item, index) { return (_jsxs(Tr, { children: [_jsx(Td, __assign({ style: { width: 25 } }, { children: _jsx(BsBuilding, {}) })), _jsx(Td, { children: item.name }), _jsx(Td, { children: item.created &&
                                            item.updated &&
                                            new Date(item.updated) > new Date(item.created)
                                            ? formatDate(parseISO(item.updated))
                                            : item.created
                                                ? formatDate(parseISO(item.created))
                                                : '' }), _jsx(Td, { children: item.created &&
                                            item.updated &&
                                            item.updatedBy &&
                                            new Date(item.updated) > new Date(item.created)
                                            ? item.updatedBy
                                            : item.createdBy
                                                ? item.createdBy
                                                : 'support@dimatech.se' }), _jsx(TdRight, { children: _jsx(EditButtons, { id: item.id, handleNavigateSearch: handleNavigateSearch }) })] }, index)); }) })] }))] })) }));
};
EntitiesListForUser.displayName = 'EntitiesListForUser';
var EditButtons = function (_a) {
    var id = _a.id, handleNavigateSearch = _a.handleNavigateSearch;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var handleNavigateEntity = function (e) {
        e.stopPropagation();
        navigate('/administrate/organisation', {
            state: { id: id },
        });
    };
    return (_jsxs("div", __assign({ style: { display: 'flex', justifyContent: 'end' } }, { children: [_jsx(LinkWithTooltip, { tooltip: t('Administrate.Users.Navigate.OpenEntity'), icon: _jsx(BsGear, {}), handleClick: function (e) { return handleNavigateEntity(e); }, isInverted: true }), handleNavigateSearch && (_jsx(LinkWithTooltip, { tooltip: t('Administrate.Users.Navigate.OpenSearch'), icon: _jsx(BsSearch, {}), style: { marginLeft: 10 }, handleClick: function (e) { return handleNavigateSearch === null || handleNavigateSearch === void 0 ? void 0 : handleNavigateSearch(e, id); }, isInverted: true }))] })));
};
