import axios from 'axios';
import { TokenName } from '../models';
import { getAccessToken, getWebToken } from '../models/token';
// This will intercept all requests and responses done using axios
export var initializeAxiosRequestInterceptor = function () {
    axios.interceptors.request.use(function (config) {
        var token = getAccessToken(TokenName.Access);
        var webToken = getWebToken(TokenName.Web);
        if (config.headers) {
            // If token exists add it to the headers, else add the webToken
            if (token) {
                config.headers['Authorization'] = "Bearer ".concat(token);
            }
            else if (webToken) {
                config.headers['WebToken'] = webToken;
            }
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
};
