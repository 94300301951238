var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, Select } from '@dimatech/shared/lib/components/form';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';
import { BsArrowCounterclockwise, BsSearch } from 'react-icons/bs';
import { SelectEntitiesWithoutSurvey } from './SelectEntitiesWithoutSurvey';
import { UserFiltersStyle } from './UserFiltersStyle';
export var UserFilters = function (_a) {
    var _b, _c;
    var handleSearch = _a.handleSearch, entities = _a.entities, filter = _a.filter, setFilter = _a.setFilter, _d = _a.isEntityFilterShown, isEntityFilterShown = _d === void 0 ? true : _d, isRoleFilterShown = _a.isRoleFilterShown, roles = _a.roles;
    var t = useTranslation().t;
    var rolesOptions = roles === null || roles === void 0 ? void 0 : roles.map(function (value) { return ({
        label: t("Search.FilterRoles.".concat(value)),
        value: value,
    }); }).sort(function (a, b) { return a.label.localeCompare(b.label); });
    var handleChangeRoles = function (role) {
        setFilter(__assign(__assign({}, filter), { role: role, hasChanges: true }));
    };
    var handleChangeEmail = function (email) {
        setFilter(__assign(__assign({}, filter), { email: email, hasChanges: true }));
    };
    var handleChangeEntity = function (entityIds) {
        setFilter(__assign(__assign({}, filter), { entityIds: entityIds, hasChanges: true }));
    };
    var handleResetFilter = function () {
        setFilter({
            email: undefined,
            entityIds: undefined,
            hasChanges: false,
            role: (roles === null || roles === void 0 ? void 0 : roles.length) ? roles[0] : undefined,
        });
    };
    return (_jsxs(UserFiltersStyle, { children: [isRoleFilterShown && (_jsx(Select, __assign({ style: { width: 250, marginRight: 20 }, placeholder: "- ".concat(t('Common.UI.Select')), onChange: function (e) {
                    return handleChangeRoles(e.currentTarget.value);
                }, value: (_b = filter === null || filter === void 0 ? void 0 : filter.role) !== null && _b !== void 0 ? _b : '' }, { children: rolesOptions &&
                    rolesOptions.map(function (role) { return (_jsx("option", __assign({ value: role.value }, { children: role.label }), role.value)); }) }))), isEntityFilterShown && (_jsx("div", { children: _jsx(SelectEntitiesWithoutSurvey, { entityIds: filter === null || filter === void 0 ? void 0 : filter.entityIds, setEntityIds: handleChangeEntity, entities: entities }) })), _jsxs("div", { children: [_jsx(Input, { value: (_c = filter === null || filter === void 0 ? void 0 : filter.email) !== null && _c !== void 0 ? _c : '', placeholder: t('Search.EmailPlaceholder'), onChange: function (e) { return handleChangeEmail(e.currentTarget.value); } }), _jsx(BsSearch, {})] }), _jsxs("div", __assign({ style: { display: 'flex' } }, { children: [_jsx(Button, __assign({ type: "button", onClick: function () { return handleSearch(); } }, { children: t('Search.Search') })), _jsx("div", __assign({ style: { marginTop: 5, marginLeft: 7 } }, { children: _jsx(LinkWithTooltip, { isDisabled: !(filter === null || filter === void 0 ? void 0 : filter.hasChanges), isInverted: true, handleClick: handleResetFilter, tooltip: t('Search.ResetFilters'), icon: _jsx(BsArrowCounterclockwise, {}) }) }))] }))] }));
};
UserFilters.displayName = 'UserFilters';
