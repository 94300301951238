import { CacheTagsEntity, featuresSurveyApi } from '../featuresSurveyApi';
var entitySurveyAnonRespondentApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Move anon respondent
         */
        moveSurveyAnonRespondent: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, toEntityId = _a.toEntityId;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents/anon/move/").concat(toEntityId),
                    method: 'POST',
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
        }),
        /**
         * Delete anon respondent
         */
        deleteSurveyAnonRespondent: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents/anon"),
                    method: 'DELETE',
                });
            },
            invalidatesTags: [
                CacheTagsEntity.EntitySurvey,
                CacheTagsEntity.SurveyFailedMessages,
            ],
        }),
    }); },
});
export var useMoveSurveyAnonRespondentMutation = entitySurveyAnonRespondentApi.useMoveSurveyAnonRespondentMutation, useDeleteSurveyAnonRespondentMutation = entitySurveyAnonRespondentApi.useDeleteSurveyAnonRespondentMutation;
