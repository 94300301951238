import { Overview as OverviewFeature } from 'features/overview/Overview';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Overview = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Overview.Title')}>
      <OverviewFeature />
    </View>
  );
};

Overview.displayName = 'Overview';
