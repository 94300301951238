var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsMessageTemplate, featuresCoreApi } from '../featuresCoreApi';
var messageTemplateApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get message templates for current entity and locale
         */
        getEntityMessageTemplates: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_a) {
                var filter = _a.filter, _customerId = _a._customerId;
                return "messageTemplates?entityId=".concat(filter.entityId, "&locale=").concat(filter.locale);
            },
            providesTags: [CacheTagsMessageTemplate.EntityMessageTemplate],
        }),
        /**
         * Update message template
         */
        updateEntityMessageTemplate: build.mutation({
            query: function (template) { return ({
                url: "messageTemplates/updateOrCreate",
                method: 'PUT',
                body: __assign({}, template),
            }); },
            invalidatesTags: [CacheTagsMessageTemplate.EntityMessageTemplate],
        }),
        /**
         * Get message templates preview
         */
        getPreviewEntityMessageTemplate: build.query({
            query: function (_a) {
                var entityId = _a.entityId, locale = _a.locale, messageTemplateId = _a.messageTemplateId;
                return "messagetemplates/preview?entityId=".concat(entityId, "&locale=").concat(locale, "&messageTemplateId=").concat(messageTemplateId);
            },
            providesTags: [CacheTagsMessageTemplate.EntityMessageTemplate],
        }),
        /**
         * Send preview message template
         */
        sendPreviewEntityMessageTemplate: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, locale = _a.locale, messageTemplateId = _a.messageTemplateId, email = _a.email;
                return {
                    url: 'messagetemplates/send',
                    method: 'POST',
                    body: {
                        entityId: entityId,
                        locale: locale,
                        messageTemplateId: messageTemplateId,
                        email: email,
                    },
                };
            },
        }),
    }); },
});
export var useGetEntityMessageTemplatesQuery = messageTemplateApi.useGetEntityMessageTemplatesQuery, useUpdateEntityMessageTemplateMutation = messageTemplateApi.useUpdateEntityMessageTemplateMutation, useGetPreviewEntityMessageTemplateQuery = messageTemplateApi.useGetPreviewEntityMessageTemplateQuery, useSendPreviewEntityMessageTemplateMutation = messageTemplateApi.useSendPreviewEntityMessageTemplateMutation;
