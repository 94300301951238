var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Breakpoints } from '../../themes';
export var SidePanel = function (_a) {
    var children = _a.children, style = _a.style, _b = _a.isShown, isShown = _b === void 0 ? true : _b;
    return (_jsx(Style, __assign({ "$show": isShown, style: style }, { children: children })));
};
SidePanel.displayName = 'SidePanel';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px 10px;\n  height: 100%;\n  width: 170px;\n  min-width: 170px;\n\n  color: ", ";\n  background-color: ", ";\n\n  display: flex;\n  flex-direction: column;\n\n  @media screen and (max-width: ", ") {\n    display: ", ";\n  }\n"], ["\n  padding: 20px 10px;\n  height: 100%;\n  width: 170px;\n  min-width: 170px;\n\n  color: ", ";\n  background-color: ", ";\n\n  display: flex;\n  flex-direction: column;\n\n  @media screen and (max-width: ", ") {\n    display: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, Breakpoints.small, function (_a) {
    var $show = _a.$show;
    return ($show ? 'flex' : 'none');
});
var templateObject_1;
