import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { Theme } from '@dimatech/shared/lib/themes';
import { ChartTooltipSplit } from 'components/ChartTooltipSplit';
import { PrinciplesByEntity } from 'models';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Label,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const ChildResultCompared = withTheme(
  ({
    theme,
    principlesByEntity,
  }: {
    theme: Theme;
    principlesByEntity: PrinciplesByEntity[];
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <Chart>
        <ScatterChart>
          <CartesianGrid
            stroke={theme.colors.chart.gridStroke}
            strokeWidth={0.5}
          />

          <XAxis
            type="number"
            dataKey="heritage"
            name={t('CommonTerms.DigitalHeritage')}
            domain={[0, 100]}
            tickCount={5}
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0')}
            stroke={theme.colors.chart.axisStroke}
          >
            <Label
              value={t('CommonTerms.DigitalHeritage') as string}
              position="insideBottom"
              offset={-5}
              fill={theme.colors.onSurface}
            />
          </XAxis>

          <YAxis
            type="number"
            dataKey="ability"
            name={t('CommonTerms.DigitalAbility')}
            domain={[0, 100]}
            tickCount={5}
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0')}
            stroke={theme.colors.chart.axisStroke}
          >
            <Label
              value={t('CommonTerms.DigitalAbility') as string}
              offset={15}
              position="insideLeft"
              angle={-90}
              fill={theme.colors.onSurface}
            />
          </YAxis>

          {principlesByEntity.map((item, index) => {
            return (
              <Scatter
                key={index}
                data={[
                  {
                    heritage: item.heritage.percentage,
                    ability: item.ability.percentage,
                    label: item.entityName,
                  },
                ]}
                name={item.entityName}
                fill={
                  theme.colors.chart.colors[
                    index % theme.colors.chart.colors.length
                  ]
                }
                stroke={
                  theme.colors.chart.colors[
                    index % theme.colors.chart.colors.length
                  ]
                }
                opacity={0.8}
              />
            );
          })}

          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<ChartTooltipSplit />}
          />
          <ReferenceLine x={50} stroke={theme.colors.chart.axisStroke} />
          <ReferenceLine y={50} stroke={theme.colors.chart.axisStroke} />
        </ScatterChart>
      </Chart>
    );
  }
);

ChildResultCompared.displayName = 'ChildResultCompared';
