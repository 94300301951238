var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
import { SortDirection } from '../../models';
export var useSortablePaginator = function (_a) {
    var orderBy = _a.orderBy, _b = _a.orderDirection, orderDirection = _b === void 0 ? SortDirection.Asc : _b, _c = _a.page, page = _c === void 0 ? 1 : _c, _d = _a.pageSize, pageSize = _d === void 0 ? 25 : _d, handlePaginatorChange = _a.handlePaginatorChange;
    var _e = useState({
        orderBy: orderBy,
        orderDirection: orderDirection,
        page: page,
        pageSize: pageSize,
    }), paginator = _e[0], setPaginator = _e[1];
    var _f = useState({
        key: paginator.orderBy,
        direction: paginator.orderDirection,
    }), sortConfiguration = _f[0], setSortConfiguration = _f[1];
    var setPage = function (page) {
        handlePaginatorChange === null || handlePaginatorChange === void 0 ? void 0 : handlePaginatorChange(__assign(__assign({}, paginator), { page: page }));
        setPaginator(__assign(__assign({}, paginator), { page: page }));
    };
    var requestSort = function (orderBy) {
        var orderDirection = SortDirection.Asc;
        if (paginator.orderBy === orderBy &&
            paginator.orderDirection === SortDirection.Asc) {
            orderDirection = SortDirection.Desc;
        }
        setSortConfiguration(__assign(__assign({}, sortConfiguration), { key: orderBy, direction: orderDirection }));
        handlePaginatorChange === null || handlePaginatorChange === void 0 ? void 0 : handlePaginatorChange(__assign(__assign({}, paginator), { orderBy: orderBy, orderDirection: orderDirection }));
        setPaginator(__assign(__assign({}, paginator), { orderBy: orderBy, orderDirection: orderDirection }));
    };
    var sorter = {
        requestSort: requestSort,
        sortConfiguration: sortConfiguration,
    };
    return { setPage: setPage, paginator: paginator, sorter: sorter };
};
