import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { selectIsInstancesLoaded } from '@dimatech/features-survey/lib/api/filter/filterSlice';
import { SelectEntityWithSurvey } from '@dimatech/features-survey/lib/components/SelectEntityWithSurvey';
import { SelectSurveyPeriod } from '@dimatech/features-survey/lib/components/SelectSurveyPeriod';
import { Select } from '@dimatech/shared/lib/components/form';
import { Switch } from '@dimatech/shared/lib/components/form/Switch';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { dimiosActions, selectFilter } from 'api/dimiosSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EntityType, FilterableEntityType, SurveyInstance } from 'models';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export const SelectExtendedFilters = ({
  isEntityTypeFilterShown = false,
  isRespondentFilterShown = true,
  isPooFilterShown = true,
  isPeriodFilterShown = true,
  isOnlyPeriodsFrom2022Shown = false,
  isOnlyPeriodsWithDataShown = true,
  isCarelessFilterShown = true,
}: {
  isEntityTypeFilterShown?: boolean;
  isRespondentFilterShown?: boolean;
  isPooFilterShown?: boolean;
  isPeriodFilterShown?: boolean;
  isOnlyPeriodsFrom2022Shown?: boolean;
  isOnlyPeriodsWithDataShown?: boolean;
  isCarelessFilterShown?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);

  const filter = useAppSelector(selectFilter);
  const isInstancesLoaded = useSelector(selectIsInstancesLoaded);

  const isCarelessRespondentEnabledFlagOn =
    useFlags()[flags.permanent.app.dimios.isCarelessRespondentEnabled];

  useEffect(() => {
    if (
      isEntityTypeFilterShown &&
      accessToken.user?.currentCustomer.customerType
    ) {
      let entityType = accessToken.user.currentCustomer
        .customerType as FilterableEntityType;

      if (
        !(
          [
            EntityType.Municipality,
            EntityType.Government,
            EntityType.Region,
          ] as FilterableEntityType[]
        ).includes(entityType)
      ) {
        entityType = EntityType.Other;
      }

      handleChangeEntityType(entityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEntityTypeFilterShown, accessToken.customerId]);

  const handleChangeInstance = (
    instance: SurveyInstance,
    previousInstance?: SurveyInstance
  ) => {
    dispatch(dimiosActions.setFilterEntityId(''));
    dispatch(dimiosActions.setFilterInstance({ instance, previousInstance }));
  };

  const handleChangeEntity = (entityId: string) => {
    dispatch(dimiosActions.setFilterEntityId(entityId));
  };

  const handleChangeEntityType = (entityType: EntityType) => {
    dispatch(
      dimiosActions.setFilter({
        ...filter,
        entityType,
      })
    );
  };

  const handleChangeRespondents = (checked: boolean) => {
    dispatch(
      dimiosActions.setFilter({
        ...filter,
        includeOnlyInvolvedRespondents: checked,
      })
    );
  };

  const handleChangePoos = (checked: boolean) => {
    dispatch(
      dimiosActions.setFilter({
        ...filter,
        includePoos: checked,
      })
    );
  };

  const handleChangeCareless = (checked: boolean) => {
    dispatch(
      dimiosActions.setFilter({
        ...filter,
        carelessFilter: checked,
      })
    );
  };

  return (
    <Style>
      <div>
        <div>
          {isPeriodFilterShown && (
            <SelectSurveyPeriod
              instance={filter.instance}
              setInstance={handleChangeInstance}
              isOnlyPeriodsWithDataShown={isOnlyPeriodsWithDataShown}
              isOnlyPeriodsFrom2022Shown={isOnlyPeriodsFrom2022Shown}
            />
          )}

          {!isEntityTypeFilterShown && isInstancesLoaded && (
            <SelectEntityWithSurvey
              style={{ minWidth: 150, maxWidth: 'unset' }}
              entityId={filter.entityId}
              setEntityId={handleChangeEntity}
              surveyInstanceId={filter.instance?.id}
            />
          )}

          {isEntityTypeFilterShown && (
            <Select
              style={{ minWidth: 150 }}
              value={filter.entityType}
              onChange={(e) =>
                handleChangeEntityType(e.currentTarget.value as EntityType)
              }
            >
              {Object.values(FilterableEntityType).map((enumValue) => (
                <option key={enumValue} value={enumValue}>
                  {t(`CommonTerms.${enumValue}`)}
                </option>
              ))}
            </Select>
          )}
        </div>

        <div>
          {isRespondentFilterShown && (
            <Switch
              label={t('Filter.IncludeOnlyInvolvedRespondents.Label')}
              tooltipTitle={t(
                'Filter.IncludeOnlyInvolvedRespondents.TooltipTitle'
              )}
              tooltipText={
                <Trans i18nKey="Filter.IncludeOnlyInvolvedRespondents.Tooltip" />
              }
              setIsChecked={handleChangeRespondents}
              isChecked={filter.includeOnlyInvolvedRespondents}
            />
          )}
        </div>
        <div>
          {isPooFilterShown && (
            <Switch
              label={t('Filter.IncludePoos.Label')}
              tooltipTitle={t('Filter.IncludePoos.TooltipTitle')}
              tooltipText={<Trans i18nKey="Filter.IncludePoos.Tooltip" />}
              setIsChecked={handleChangePoos}
              isChecked={filter.includePoos}
            />
          )}
        </div>
        <div>
          {isCarelessFilterShown && isCarelessRespondentEnabledFlagOn && (
            <Switch
              label={t('Filter.ExcludeCarelessRespondents.Label')}
              tooltipTitle={t('Filter.ExcludeCarelessRespondents.TooltipTitle')}
              tooltipText={
                <Trans i18nKey="Filter.ExcludeCarelessRespondents.Tooltip" />
              }
              setIsChecked={handleChangeCareless}
              isChecked={filter.carelessFilter}
            />
          )}
        </div>
      </div>
    </Style>
  );
};

SelectExtendedFilters.displayName = 'SelectExtendedFilters';

const Style = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div:first-of-type {
      margin: 0;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 5px 0;
    }
  }
`;
