var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CacheTagsUserMessage, featuresCoreApi } from '../featuresCoreApi';
var userMessageApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get my messages
         * _userId is only used for RTK-Q to know that it should invalidate
         * the cache and re-fetch when the user is switching user
         */
        getMyMessages: build.query({
            query: function (_userId) { return 'messages/my'; },
            providesTags: [CacheTagsUserMessage.UserMessage],
            transformResponse: function (messages) {
                return __spreadArray([], messages, true).sort(function (a, b) {
                    return new Date(b.publishDate || '').getTime() -
                        new Date(a.publishDate || '').getTime();
                });
            },
        }),
        /**
         * Mark all messages as read
         */
        markAsRead: build.mutation({
            query: function (messageId) { return ({
                url: "messages/track/".concat(messageId !== null && messageId !== void 0 ? messageId : ''),
                method: 'PUT',
            }); },
            invalidatesTags: [CacheTagsUserMessage.UserMessage],
        }),
    }); },
});
export var useGetMyMessagesQuery = userMessageApi.useGetMyMessagesQuery, useMarkAsReadMutation = userMessageApi.useMarkAsReadMutation;
