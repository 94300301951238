var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { Button, ButtonSecondary, Buttons, Input, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
export var RespondentUpdate = withTheme(function (_a) {
    var email = _a.email, entityId = _a.entityId, handleUpdateRespondent = _a.handleUpdateRespondent, setIsUpdatingRespondent = _a.setIsUpdatingRespondent, theme = _a.theme;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var _b = useState(true), isValid = _b[0], setIsValid = _b[1];
    var _c = useState(''), newEmail = _c[0], setNewEmail = _c[1];
    var handleSave = function (e) {
        e.stopPropagation();
        if (!newEmail) {
            setIsValid(false);
            return;
        }
        handleUpdateRespondent(email, newEmail.trim(), entityId);
        setIsUpdatingRespondent(null);
    };
    return (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.Edit.Title'), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsUpdatingRespondent(null); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.Edit.Text') }), _jsx(Input, { autoFocus: true, type: "text", id: "respondent", name: "respondent", defaultValue: email, invalid: !isValid, onChange: function (e) { return setNewEmail(e.currentTarget.value); } }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsUpdatingRespondent(null); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave, disabled: isReadOnly }, { children: t('Common.Form.Save') }))] })] })));
});
RespondentUpdate.displayName = 'RespondentUpdate';
