import { Heading1, Heading4 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Theme } from '@dimatech/shared/lib/themes';
import { ViewHeader } from 'components/ViewHeader';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { View } from 'views';

export const NextStep = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View title={t('NextStep.Title')}>
      <ViewHeader>
        <Heading1>{t('NextStep.Title')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody>
          <Style>
            <div>{t('NextStep.Text')}</div>
            <div>
              <Heading4>{t('NextStep.Step1.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step1.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step2.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step2.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step3.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step3.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step4.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step4.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step5.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step5.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step6.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step6.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step7.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step7.Text" />
            </div>
            <div>
              <Heading4>{t('NextStep.Step8.Title')}</Heading4>
              <TransArray i18nKey="NextStep.Step8.Text" />
            </div>
            <div>{t('NextStep.Summary')}</div>
          </Style>
        </CardBody>
      </Card>
    </View>
  );
};

NextStep.displayName = 'NextStep';

const Style = styled.div`
  > div {
    margin-bottom: 20px;
    > h4 {
      color: ${({ theme }: { theme: Theme }) => theme.colors.primaryVariant};
      width: fit-content;
    }
  }
`;
