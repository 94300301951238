var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { ScrollToTop } from '../ScrollToTop';
export var View = function (_a) {
    var _b = _a.scrollToTop, scrollToTop = _b === void 0 ? true : _b, style = _a.style, children = _a.children;
    return (_jsxs(_Fragment, { children: [_jsx(ScrollToTop, { scrollToTop: scrollToTop }), _jsx(Style, __assign({ style: style }, { children: children }))] }));
};
export var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n"], ["\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n"])));
View.displayName = 'View';
var templateObject_1;
