import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext } from 'react';
import { useGetEntitiesWithSurveyQuery } from '../api/entity/entityApi';
export var useGetPreviousEntityId = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var instanceId = _a.instanceId, previousInstanceId = _a.previousInstanceId, entityId = _a.entityId, customerId = _a.customerId;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var entities = useGetEntitiesWithSurveyQuery(customerId && ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) && instanceId
        ? {
            _customerId: customerId,
            _userId: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id,
            surveyInstanceId: instanceId,
        }
        : skipToken).data;
    var previousEntities = useGetEntitiesWithSurveyQuery(customerId && ((_d = accessToken.user) === null || _d === void 0 ? void 0 : _d.id) && previousInstanceId
        ? {
            _customerId: customerId,
            _userId: (_e = accessToken.user) === null || _e === void 0 ? void 0 : _e.id,
            surveyInstanceId: previousInstanceId,
        }
        : skipToken).data;
    var entityOriginalId = (_f = entities === null || entities === void 0 ? void 0 : entities.find(function (entity) { return entity.id === entityId; })) === null || _f === void 0 ? void 0 : _f.originalId;
    var previousEntityId = (_g = previousEntities === null || previousEntities === void 0 ? void 0 : previousEntities.find(function (entity) { return entity.originalId === entityOriginalId; })) === null || _g === void 0 ? void 0 : _g.id;
    return { previousEntityId: previousEntityId };
};
