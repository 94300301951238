var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowInDown } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { useGetEntitySurveyQuery } from '../../../api/entity/entitySurveyApi';
import { selectFilter } from '../../../api/filter/filterSlice';
import { ScheduleStatusBanner } from '../../administrate-schedule/components/ScheduleStatusBanner';
import { AnonymousRespondents } from './AnonymousRespondents';
import { AnonymousRespondentsActions } from './AnonymousRespondentsActions';
import { RespondentsImport } from './RespondentsImport';
import { RespondentsList } from './RespondentsList';
export var Respondents = function (_a) {
    var root = _a.root, enableAllowEntitySelectionForAnonymousUsers = _a.enableAllowEntitySelectionForAnonymousUsers, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var filter = useSelector(selectFilter);
    var selectedEntity = useSelector(selectSelectedEntity);
    var _b = useState(), entity = _b[0], setEntity = _b[1];
    var _c = useState(null), status = _c[0], setStatus = _c[1];
    var _d = useState(false), isImporting = _d[0], setIsImporting = _d[1];
    var _e = useState(false), allowEntitySelectionForAnonymousUsers = _e[0], setAllowEntitySelectionForAnonymousUsers = _e[1];
    var _f = useState(''), entityIdImportRespondents = _f[0], setEntityIdImportRespondents = _f[1];
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _g = useGetEntitySurveyQuery(selectedEntity &&
        selectedEntity.id &&
        accessToken.customerId &&
        filter.instance.id
        ? {
            entityId: selectedEntity.id,
            _customerId: accessToken.customerId,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken), entitySurvey = _g.data, isEntitySurveyFetching = _g.isFetching;
    var itemsRef = useRef(null);
    var _h = useState(), isFetchingRespondents = _h[0], setIsFetchingRespondents = _h[1];
    useEffect(function () {
        var _a;
        if (!isFetchingRespondents) {
            if (itemsRef.current && (entity === null || entity === void 0 ? void 0 : entity.id)) {
                (_a = itemsRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [entity === null || entity === void 0 ? void 0 : entity.id, itemsRef, isFetchingRespondents]);
    useEffect(function () {
        setEntity(selectedEntity ? __assign({}, selectedEntity) : undefined);
    }, [selectedEntity]);
    useEffect(function () {
        setAllowEntitySelectionForAnonymousUsers(!!(root === null || root === void 0 ? void 0 : root.allowEntitySelectionForAnonymousUsers));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root]);
    return (_jsxs(_Fragment, { children: [_jsx(RespondentsAnchor, { ref: itemsRef }), entity && (_jsx(Heading2, __assign({ style: { marginBottom: 10 } }, { children: entity.name }))), entitySurvey &&
                !isEntitySurveyFetching &&
                (entity === null || entity === void 0 ? void 0 : entity.id) &&
                (entity === null || entity === void 0 ? void 0 : entity.id) !== entityIdImportRespondents && (_jsxs(_Fragment, { children: [_jsx(ScheduleStatusBanner, { startDate: entitySurvey.startDate, endDate: entitySurvey.endDate, status: status, setStatus: setStatus }), _jsx(AnonymousRespondents, { isFetching: isEntitySurveyFetching, entitySurvey: entitySurvey, root: root, allowEntitySelectionForAnonymousUsers: allowEntitySelectionForAnonymousUsers, entity: entity }), _jsx(AnonymousRespondentsActions, { entity: entity, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted })] })), (entity === null || entity === void 0 ? void 0 : entity.id) && !isEntitySurveyFetching && (_jsx(LinkWithTooltip, { handleClick: function () { return setIsImporting(filter.instance.isCurrentInstance); }, title: (entity === null || entity === void 0 ? void 0 : entity.id) === entityIdImportRespondents
                    ? t('Administrate.Respondent.Import.SelectNewFile')
                    : t('Administrate.Respondent.Import.Title'), tooltipTitle: t('Administrate.Respondent.Import.TooltipTitle'), tooltip: t('Administrate.Respondent.Import.Tooltip'), icon: _jsx(BsBoxArrowInDown, {}), isDisabled: !filter.instance.isCurrentInstance })), (entity === null || entity === void 0 ? void 0 : entity.id) && (entity === null || entity === void 0 ? void 0 : entity.id) !== entityIdImportRespondents && (_jsx(RespondentsList, { setIsFetchingRespondents: setIsFetchingRespondents, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted })), _jsx(RespondentsImport, { isImporting: isImporting, setIsImporting: setIsImporting, entityIdImportRespondents: entityIdImportRespondents, setEntityIdImportRespondents: setEntityIdImportRespondents })] }));
};
Respondents.displayName = ' Respondents';
var RespondentsAnchor = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: none;\n  @media screen and (max-width: ", ") {\n    display: block;\n  }\n"], ["\n  display: none;\n  @media screen and (max-width: ", ") {\n    display: block;\n  }\n"])), Breakpoints.small);
var templateObject_1;
