import { config } from '@dimatech/shared/lib/config';
import { getLocale } from '@dimatech/shared/lib/localization';
import { getTheme } from '@dimatech/shared/lib/themes';
import { useContext, useEffect } from 'react';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
import { CommonRoles } from '../models';
// This is used to identify current user with google analytics
export var useGoogleAnalytics = function () {
    var accessToken = useContext(AuthenticationContext).accessToken;
    useEffect(function () {
        if (accessToken.user) {
            gtag('config', config.googleAnalytics.key, {
                user_id: accessToken.user.id,
            });
            gtag('set', 'user_properties', {
                customer_id: accessToken.user.currentCustomer.id,
                customer_type: accessToken.user.currentCustomer.customerType,
                user_locale: getLocale(),
                user_theme: getTheme(),
                user_is_globaladmin: accessToken.isInRole(CommonRoles.GlobalAdmin),
                user_is_researcher: accessToken.isInRole(CommonRoles.Researcher),
                user_is_demouser: accessToken.isInRole(CommonRoles.DemoUser),
                user_is_custadmin: accessToken.isInRole(CommonRoles.CustomerAdmin),
                user_is_custaccountadmin: accessToken.isInRole(CommonRoles.CustomerAccountAdmin),
            });
        }
    }, [accessToken]);
};
