import { ThemeGuide } from '@dimatech/shared/lib/components/theme-guide';
import { Theme } from '@dimatech/shared/lib/themes';
import { withTheme } from 'styled-components';
import { View } from 'views';

export const StyleGuide = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    return (
      <View title="Style guide">
        <ThemeGuide theme={theme} />
      </View>
    );
  }
);

StyleGuide.displayName = 'StyleGuide';
