import { CommonRoles } from '../models';
export var isDemoCustomer = function (accessToken) {
    var _a;
    return ((_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.currentCustomer.customerType) === 'Demo';
};
/**
 * Returns true if the user is NOT in an admin role and therefore should only
 * be able to see admin functionality in read only mode (as long as isAdminAvailable is true)
 * @param accessToken
 * @returns
 */
export var isAdminReadOnly = function (accessToken) {
    return !accessToken.isInRole([
        CommonRoles.GlobalAdmin,
        CommonRoles.CustomerAccountAdmin,
        CommonRoles.CustomerAdmin,
    ]);
};
/**
 * Returns true if the user is in a role that can see admin functionality
 * @param accessToken
 * @returns
 */
export var isAdminAvailable = function (accessToken) {
    return accessToken.isInRole([
        CommonRoles.GlobalAdmin,
        CommonRoles.CustomerAccountAdmin,
        CommonRoles.CustomerAdmin,
        CommonRoles.DemoUser,
        CommonRoles.Researcher,
    ]);
};
