import { AccessToken } from '@dimatech/features-core/lib/models/token';
import { CommonRoles } from 'models';

/**
 * Returns true if the user is in a role that can administrate everything
 * for current customer, for now its just global admins and customer account admins
 */
export const isAccountAdmin = (accessToken: AccessToken) => {
  return accessToken.isInRole([
    CommonRoles.GlobalAdmin,
    CommonRoles.CustomerAccountAdmin,
  ]);
};
