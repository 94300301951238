import { ForgotPassword as ForgotPasswordComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('Password.Forgot.Title')}>
      <ForgotPasswordComponent />
    </ViewCentered>
  );
};

ForgotPassword.displayName = 'ForgotPassword';
