var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApplicationContext } from '@dimatech/shared/lib//application';
import { AlertWarningBanner } from '@dimatech/shared/lib/components/AlertBanner';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { config } from '@dimatech/shared/lib/config';
import { getCookie, storeCookie } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetVersionQuery } from '../api/system/systemApi';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
export var VersionCheck = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var application = useContext(ApplicationContext);
    var _a = useState(false), hasNewVersion = _a[0], setHasNewVersion = _a[1];
    var cookieName = "version-".concat(application.subdomain);
    var currentVersion = getCookie(cookieName);
    var version = useGetVersionQuery(!application.subdomain ? skipToken : undefined, {
        pollingInterval: config.versionCheck.pollInterval,
    }).data;
    var handleRefresh = function () {
        if (version) {
            storeCookie(cookieName, version);
            window.location.replace('/');
        }
    };
    var handleReadMore = function () {
        if (version) {
            navigate("/release-notes/v".concat(version));
        }
    };
    useEffect(function () {
        if (version) {
            if (currentVersion && version !== currentVersion) {
                setHasNewVersion(true);
            }
            else if (version) {
                storeCookie(cookieName, version);
            }
        }
    }, [currentVersion, version, cookieName]);
    if (!hasNewVersion || !application.subdomain || !version) {
        return null;
    }
    return (_jsx(AlertWarningBanner, { children: _jsxs("div", { children: [_jsx("div", { children: t("VersionCheck.Text", {
                        productName: application.title,
                        currentVersion: currentVersion,
                        newVersion: version,
                    }) }), _jsx("div", { children: _jsx(ButtonLink, __assign({ onClick: handleRefresh }, { children: t("VersionCheck.Refresh") })) }), (accessToken === null || accessToken === void 0 ? void 0 : accessToken.isLoggedIn) && (_jsx(ButtonLink, __assign({ onClick: handleReadMore }, { children: t("VersionCheck.ReadMore") })))] }) }));
};
VersionCheck.displayName = 'VersionCheck';
