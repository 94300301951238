import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeTiny } from '@dimatech/shared/lib/components/Badge';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { selectFilterByEntity } from 'api/dimiosSlice';
import { config } from 'config';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Principle, Question, SortDirection } from 'models';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { formatAsPercent, getSubCategoryDefinitions } from 'utils';
import { MaturityStaircase } from './MaturityStaircase';

/* eslint-disable max-lines-per-function */

export const AverageByOrganisationAndQuestion = withTheme(
  ({
    principles,
    principlesPreviousPeriod,
    principlesNationally,
    questions,
    saveToPngElementId,
    theme,
  }: {
    principles: Principle[];
    principlesPreviousPeriod?: Principle[];
    principlesNationally?: Principle[];
    questions: Question[];
    saveToPngElementId?: string;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const filterByEntity = useAppSelector(selectFilterByEntity);

    const [selectedPrinciple, setSelectedPrinciple] = useState<Principle>();

    const isStandardDeviationEnabledFlagOn =
      useFlags()[flags.permanent.app.dimios.isStandardDeviationEnabled];
    const isMaturityStaircaseEnabledFlagOn =
      useFlags()[flags.permanent.app.dimios.isMaturityStaircaseEnabled];

    const subCategoryDefinitions = getSubCategoryDefinitions();

    const mapped = principles.map((principle) => {
      const nationally = principlesNationally?.find(
        (national) => principle.principleId === national.principleId
      );

      const question = questions.find(
        (question) => question.id.toString() === principle.principleId
      );

      const previousPeriod = principlesPreviousPeriod?.find(
        (previous) => principle.principleId === previous.principleId
      );

      return {
        ...principle,
        name: t(
          `Survey.${config.survey?.id}.Question.${principle.principleId}.Principle`
        ),
        maturityLevel:
          principle.percentage && principle.percentage > 0
            ? Math.ceil(principle.percentage / 0.25)
            : null,
        previousPeriodPercent: previousPeriod?.percentage,
        nationallyPercent: nationally?.percentage,
        nationallyPercentDontKnow: nationally?.percentageDontKnow,
        nationallyDisagreement: nationally?.disagreement,
        sortOrder: question?.sortOrder ?? 0,
        subCategoryKey: subCategoryDefinitions.find((category) =>
          category.principles.includes(parseInt(principle.principleId))
        )?.key,
      };
    });

    const { items, sorter } = useSortableData(mapped, {
      key: 'sortOrder',
      direction: SortDirection.Asc,
    });

    const handleSelect = (item: Principle) => {
      setSelectedPrinciple(
        selectedPrinciple?.name === item.name ? undefined : item
      );
    };

    return (
      <>
        {isMaturityStaircaseEnabledFlagOn && (
          <div style={{ maxWidth: theme.size.viewMaxWidth, marginBottom: 20 }}>
            {t('QuestionAnalyze.AverageByOrganisationAndQuestion.Text')}
          </div>
        )}

        <TableResponsiveContainer>
          <Table id={saveToPngElementId}>
            <thead>
              <tr>
                <Th sortKey="name" sorter={sorter}>
                  {t(
                    'QuestionAnalyze.AverageByOrganisationAndQuestion.Principle'
                  )}
                </Th>
                <Th sortKey="subCategoryKey" sorter={sorter}>
                  {t(
                    'QuestionAnalyze.AverageByOrganisationAndQuestion.Subcategory'
                  )}
                </Th>

                <ThCentered
                  style={{ width: 150 }}
                  sortKey="percentage"
                  sorter={sorter}
                >
                  {t(
                    'QuestionAnalyze.AverageByOrganisationAndQuestion.Percent'
                  )}
                </ThCentered>

                {principlesPreviousPeriod &&
                  filterByEntity.previousInstance &&
                  filterByEntity.entityId && (
                    <ThCentered
                      style={{ width: 150 }}
                      sortKey="previousPeriodPercent"
                      sorter={sorter}
                    >
                      {t(
                        'QuestionAnalyze.AverageByOrganisationAndQuestion.PercentPreviousPeriod',
                        { period: filterByEntity.previousInstance.name }
                      )}
                    </ThCentered>
                  )}

                <ThRight
                  style={{ width: 100 }}
                  sortKey="nationallyPercent"
                  sorter={sorter}
                >
                  {t(
                    `QuestionAnalyze.AverageByOrganisationAndQuestion.${
                      accessToken.isInternational
                        ? 'SwedishPercent'
                        : 'NationallyPercent'
                    }`
                  )}
                </ThRight>

                <ThRight
                  style={{ width: 100 }}
                  sortKey="percentageDontKnow"
                  sorter={sorter}
                >
                  {t(
                    'QuestionAnalyze.AverageByOrganisationAndQuestion.PercentDontKnow'
                  )}
                </ThRight>

                <ThRight
                  style={{ width: 100 }}
                  sortKey="nationallyPercentDontKnow"
                  sorter={sorter}
                >
                  {t(
                    `QuestionAnalyze.AverageByOrganisationAndQuestion.${
                      accessToken.isInternational
                        ? 'SwedishPercentDontKnow'
                        : 'NationallyPercentDontKnow'
                    }`
                  )}
                </ThRight>

                {isStandardDeviationEnabledFlagOn && (
                  <>
                    <ThRight
                      style={{ width: 100 }}
                      sortKey="disagreement"
                      sorter={sorter}
                    >
                      {t(
                        `QuestionAnalyze.AverageByOrganisationAndQuestion.Disagreement`
                      )}
                    </ThRight>

                    <ThRight
                      style={{ width: 100 }}
                      sortKey="nationallyDisagreement"
                      sorter={sorter}
                    >
                      {t(
                        `QuestionAnalyze.AverageByOrganisationAndQuestion.${
                          accessToken.isInternational
                            ? 'SwedishDisagreement'
                            : 'NationallyDisagreement'
                        }`
                      )}
                    </ThRight>
                  </>
                )}

                {isMaturityStaircaseEnabledFlagOn && (
                  <ThRight
                    style={{ width: 100 }}
                    sortKey="maturityLevel"
                    sorter={sorter}
                  >
                    {t(`MaturityStaircase.Level.Title`)}
                  </ThRight>
                )}
              </tr>
            </thead>

            <tbody>
              {items.map((item) => {
                return (
                  <Fragment key={item.principleId}>
                    <Tr
                      isHoverable={isMaturityStaircaseEnabledFlagOn}
                      onSelect={
                        isMaturityStaircaseEnabledFlagOn
                          ? () => handleSelect(item)
                          : undefined
                      }
                      isSelected={item.name === selectedPrinciple?.name}
                    >
                      <Td>{item.name}</Td>
                      <Td data-value={item.subCategoryKey}>
                        {t(`CommonTerms.${item.subCategoryKey}`)}
                      </Td>

                      <TdCentered data-value={(item.percentage ?? 0) * 100}>
                        <Progress
                          color={
                            item.name === selectedPrinciple?.name
                              ? theme.colors.secondary
                              : undefined
                          }
                          formatter={formatAsPercent}
                          value={(item.percentage ?? 0) * 100}
                          singleValue={true}
                        />
                      </TdCentered>

                      {principlesPreviousPeriod &&
                        filterByEntity.previousInstance &&
                        filterByEntity.entityId && (
                          <TdCentered
                            data-value={(item.previousPeriodPercent ?? 0) * 100}
                          >
                            <Progress
                              color={
                                item.name === selectedPrinciple?.name
                                  ? theme.colors.secondary
                                  : undefined
                              }
                              formatter={formatAsPercent}
                              value={(item.previousPeriodPercent ?? 0) * 100}
                              singleValue={true}
                            />
                          </TdCentered>
                        )}

                      <TdRight data-value={(item.nationallyPercent ?? 0) * 100}>
                        {formatAsPercent((item.nationallyPercent ?? 0) * 100)}
                      </TdRight>

                      <TdRight data-value={item.percentageDontKnow * 100}>
                        {formatAsPercent(item.percentageDontKnow * 100)}
                      </TdRight>

                      <TdRight
                        data-value={(item.nationallyPercentDontKnow ?? 0) * 100}
                      >
                        {formatAsPercent(
                          (item.nationallyPercentDontKnow ?? 0) * 100
                        )}
                      </TdRight>

                      {isStandardDeviationEnabledFlagOn && (
                        <>
                          <TdRight data-value={(item.disagreement ?? 0) * 100}>
                            {formatAsPercent((item.disagreement ?? 0) * 100)}
                          </TdRight>

                          <TdRight
                            data-value={
                              (item.nationallyDisagreement ?? 0) * 100
                            }
                          >
                            {formatAsPercent(
                              (item.nationallyDisagreement ?? 0) * 100
                            )}
                          </TdRight>
                        </>
                      )}

                      {isMaturityStaircaseEnabledFlagOn && (
                        <TdRight data-value={item.maturityLevel}>
                          <MaturityStaircaseLevelStyle>
                            <BadgeTiny>{item.maturityLevel}</BadgeTiny>
                          </MaturityStaircaseLevelStyle>
                        </TdRight>
                      )}
                    </Tr>

                    {isMaturityStaircaseEnabledFlagOn &&
                      item.name === selectedPrinciple?.name && (
                        <Tr>
                          <Td
                            colSpan={10}
                            style={{
                              padding: '0 20px 50px 20px',
                              backgroundColor: theme.colors.background,
                            }}
                          >
                            <div
                              style={{
                                padding: 20,
                                backgroundColor: theme.colors.surface,
                              }}
                            >
                              <MaturityStaircase principle={item} />
                            </div>
                          </Td>
                        </Tr>
                      )}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      </>
    );
  }
);

AverageByOrganisationAndQuestion.displayName =
  'AverageByOrganisationAndQuestion';

const MaturityStaircaseLevelStyle = styled.div`
  display: flex;
  justify-content: center;

  > div {
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
  }
`;
