var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Buttons, ButtonSecondary, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchUser } from '../../../components/SearchUser';
export var AccountAdminAdd = function (_a) {
    var handleAddAdmin = _a.handleAddAdmin, setIsAdding = _a.setIsAdding;
    var t = useTranslation().t;
    var _b = useState(null), user = _b[0], setUser = _b[1];
    var handleSave = function (e) {
        e.stopPropagation();
        if (!(user === null || user === void 0 ? void 0 : user.email)) {
            return;
        }
        handleAddAdmin(user);
        setIsAdding(false);
    };
    return (_jsxs(Modal, __assign({ title: t('Administrate.Account.Admin.Add.Title'), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsAdding(false); } }, { children: [_jsx("div", { children: t('Administrate.Account.Admin.Add.Text') }), _jsx(Label, __assign({ htmlFor: "respondent" }, { children: t('Administrate.Account.Admin.Add.Email') })), _jsx(SearchUser, { user: user, setUser: setUser }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsAdding(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave }, { children: t('Common.Form.Save') }))] })] })));
};
AccountAdminAdd.displayName = 'AccountAdminAdd';
