var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertInfo, AlertLowPrio, AlertSuccess, } from '@dimatech/shared/lib/components/Alert';
import { ScheduleStatus } from '@dimatech/shared/lib/models';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { datesToScheduleStatus } from '../../../utils';
export var ScheduleStatusBanner = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, status = _a.status, setStatus = _a.setStatus;
    var t = useTranslation().t;
    useEffect(function () {
        setStatus(datesToScheduleStatus(startDate, endDate));
    }, [startDate, endDate, setStatus]);
    return (_jsxs(Style, { children: [status === null && (_jsx(AlertLowPrio, { children: t("Administrate.Schedule.Status.None") })), status === ScheduleStatus.NotStarted && (_jsx(AlertInfo, { children: t("Administrate.Schedule.Status.".concat(status)) })), status === ScheduleStatus.Started && (_jsx(AlertSuccess, { children: t("Administrate.Schedule.Status.".concat(status)) })), status === ScheduleStatus.Closed && (_jsx(AlertInfo, { children: t("Administrate.Schedule.Status.".concat(status)) }))] }));
};
ScheduleStatusBanner.displayName = 'ScheduleStatusBanner';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var templateObject_1;
