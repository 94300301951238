var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import { Button, ButtonFooter, ButtonSecondary, Buttons, DateTimePicker, Input, Label, } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { config } from '@dimatech/shared/lib/config';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { formatDate } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAddMessageMutation, useAddRecipientMutation, useDeleteRecipientMutation, useUpdateMessageMutation, } from '../../../api/message/messageApi';
import { messageActions, selectRecipientsToAdd, selectRecipientsToDelete, selectSelectedMessage, } from '../../../api/message/messageSlice';
export var MessageProperties = function () {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var userMessage = useSelector(selectSelectedMessage);
    var _e = useState(false), isSent = _e[0], setIsSent = _e[1];
    var _f = useUpdateMessageMutation(), updateMessage = _f[0], updatePosting = _f[1].isLoading;
    var _g = useAddMessageMutation(), addMessage = _g[0], addPosting = _g[1].isLoading;
    var recipientsToAdd = useSelector(selectRecipientsToAdd);
    var recipientsToDelete = useSelector(selectRecipientsToDelete);
    var hasChanges = useSelector(messageActions.setHasChanges);
    var _h = useAddRecipientMutation(), addRecipient = _h[0], addRecipientPosting = _h[1].isLoading;
    var _j = useDeleteRecipientMutation(), deleteRecipient = _j[0], deleteRecipientPosting = _j[1].isLoading;
    useEffect(function () {
        dispatch(messageActions.setIsRecipientsAdding(addRecipientPosting));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addRecipientPosting]);
    useEffect(function () {
        dispatch(messageActions.setIsRecipientsDeleting(deleteRecipientPosting));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteRecipientPosting]);
    var isPosting = updatePosting ||
        addPosting ||
        addRecipientPosting ||
        deleteRecipientPosting;
    var _k = useState(), message = _k[0], setMessage = _k[1];
    var _l = useState(true), isValid = _l[0], setIsValid = _l[1];
    var _m = useState(true), isTextValid = _m[0], setIsTextValid = _m[1];
    var handleCancel = function () {
        navigate("/administrate/messages");
        dispatch(messageActions.setRecipientsToAdd([]));
        dispatch(messageActions.setRecipientsToDelete([]));
    };
    var handleSave = function () {
        setIsValid(true);
        setIsTextValid(true);
        var limitInBytes = config.ui.textEditor.maxBytes;
        var textBytes = new TextEncoder().encode(message === null || message === void 0 ? void 0 : message.text);
        if (!message) {
            return;
        }
        if (!message.title || !message.text || message.publishDate === undefined) {
            setIsValid(false);
            return;
        }
        if (textBytes.length > limitInBytes) {
            setIsTextValid(false);
            return;
        }
        if (!message.id) {
            var userIds = recipientsToAdd.map(function (r) { return r.userId; });
            addMessage({ message: message, userIds: userIds })
                .unwrap()
                .then(function (result) {
                dispatch(messageActions.setHasChanges(false));
                dispatch(messageActions.setRecipientsToAdd([]));
                dispatch(messageActions.setRecipientsToDelete([]));
                navigate("/administrate/message/".concat(result.id));
            });
            return;
        }
        updateMessage({
            message: message,
        })
            .unwrap()
            .then(function () {
            deleteRecipients(message.id);
            addRecipients(message.id);
            dispatch(messageActions.setHasChanges(false));
        });
    };
    var deleteRecipients = function (userMessageId) {
        var userIds = recipientsToDelete.map(function (r) { return r.userId; });
        if (userIds.length > 0) {
            deleteRecipient({
                userMessageId: userMessageId,
                userIds: userIds,
            })
                .unwrap()
                .then(function () {
                dispatch(messageActions.setRecipientsToDelete([]));
            });
        }
    };
    var addRecipients = function (userMessageId) {
        var userIds = recipientsToAdd.map(function (r) { return r.userId; });
        if (userIds.length > 0) {
            addRecipient({
                userMessageId: userMessageId,
                userIds: userIds,
            })
                .unwrap()
                .then(function () {
                dispatch(messageActions.setRecipientsToAdd([]));
            });
        }
    };
    useEffect(function () {
        setMessage(userMessage);
        setIsSent(!!((userMessage === null || userMessage === void 0 ? void 0 : userMessage.id) &&
            userMessage.publishDate &&
            parseISO(userMessage.publishDate) < new Date()));
        setIsValid(true);
        setIsTextValid(true);
    }, [userMessage]);
    if (isPosting) {
        return (_jsx("div", __assign({ style: { margin: 10 } }, { children: _jsx(Loader, { children: t('Common.UI.Loading') }) })));
    }
    return (_jsx(_Fragment, { children: _jsxs(Style, { children: [_jsxs("div", __assign({ style: { marginBottom: 20 } }, { children: [isSent && (_jsx(AlertInfo, { children: t("Administrate.Messages.Message.PublishDate.TextInfo", {
                                date: formatDate(parseISO(message === null || message === void 0 ? void 0 : message.publishDate)),
                            }) })), !isSent && (_jsxs(_Fragment, { children: [_jsx(Label, __assign({ htmlFor: "publishDate" }, { children: t('Administrate.Messages.Message.PublishDate.Text') })), _jsx(DateTimePicker, { name: "publishDate", date: (_a = message === null || message === void 0 ? void 0 : message.publishDate) !== null && _a !== void 0 ? _a : '', setDate: function (date) {
                                        dispatch(messageActions.setHasChanges(true));
                                        setMessage(__assign(__assign({}, message), { publishDate: date
                                                ? date.toISOString()
                                                : new Date().toISOString() }));
                                    }, isValid: !(isValid === false && (message === null || message === void 0 ? void 0 : message.publishDate) === undefined), placeholderText: t('Administrate.Messages.Message.PublishDate.Title'), min: new Date() })] }))] })), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "title" }, { children: t('Administrate.Messages.Message.Title') })), _jsx(Input, { type: "text", id: "title", name: "title", style: { marginBottom: 20 }, value: (_b = message === null || message === void 0 ? void 0 : message.title) !== null && _b !== void 0 ? _b : '', invalid: !isValid && !(message === null || message === void 0 ? void 0 : message.title), onChange: function (e) {
                                dispatch(messageActions.setHasChanges(true));
                                setMessage(__assign(__assign({}, message), { title: e.currentTarget.value }));
                            } }), !isTextValid && (_jsx(AlertWarning, __assign({ style: { marginTop: 10 } }, { children: t("TextEditor.ValidationError.LimitExceeded") }))), _jsx(Label, __assign({ htmlFor: "body" }, { children: t('Administrate.Messages.Message.Text') })), _jsx(TextEditor, { value: message === null || message === void 0 ? void 0 : message.text, setValue: function (val) {
                                dispatch(messageActions.setHasChanges(true));
                                setMessage(__assign(__assign({}, message), { text: val ? val : '' }));
                            }, messageId: (_d = (_c = message === null || message === void 0 ? void 0 : message.id) !== null && _c !== void 0 ? _c : message === null || message === void 0 ? void 0 : message.uid) !== null && _d !== void 0 ? _d : '', style: { maxWidth: 800 }, allowLinks: true, invalid: !isValid && !(message === null || message === void 0 ? void 0 : message.text), validateHtmlIsEmpty: true })] }), _jsx(ButtonFooter, __assign({ style: { marginBottom: '50px', padding: '0 20px' } }, { children: _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ onClick: handleSave, type: "button", disabled: isPosting || !hasChanges }, { children: t('Common.Form.Save') }))] }) }))] }) }));
};
MessageProperties.displayName = 'MessageProperties';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-basis: 40%;\n  flex-grow: 1;\n\n  @media screen and (max-width: ", ") {\n    order: 2;\n  }\n"], ["\n  flex-basis: 40%;\n  flex-grow: 1;\n\n  @media screen and (max-width: ", ") {\n    order: 2;\n  }\n"])), Breakpoints.medium);
var templateObject_1;
