var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
export var Navigation = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 20px;\n\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  margin-bottom: 20px;\n\n  display: flex;\n  flex-direction: column;\n"])));
Navigation.displayName = 'Navigation';
export var NavigationItem = function (_a) {
    var children = _a.children, $selected = _a.$selected, $disabled = _a.$disabled, requireFlag = _a.requireFlag;
    var featureFlags = useFlags();
    var isFlagOn = !requireFlag || featureFlags[requireFlag];
    if (!isFlagOn) {
        return null;
    }
    return (_jsx(NavigationItemStyle, __assign({ "$selected": $selected, "$disabled": $disabled }, { children: children })));
};
export var NavigationItemStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 5px 10px 0 5px;\n  margin-bottom: 5px;\n\n  display: flex;\n  align-items: center;\n  vertical-align: middle;\n\n  &:hover {\n    > svg {\n      color: ", ";\n    }\n  }\n\n  svg {\n    flex-shrink: 0;\n    font-size: ", ";\n    color: ", ";\n  }\n\n  svg,\n  a {\n    vertical-align: middle;\n  }\n\n  a {\n    margin-left: 10px;\n    border-bottom: none;\n    pointer-events: ", ";\n    color: ", ";\n  }\n"], ["\n  padding: 5px 10px 0 5px;\n  margin-bottom: 5px;\n\n  display: flex;\n  align-items: center;\n  vertical-align: middle;\n\n  &:hover {\n    > svg {\n      color: ", ";\n    }\n  }\n\n  svg {\n    flex-shrink: 0;\n    font-size: ", ";\n    color: ", ";\n  }\n\n  svg,\n  a {\n    vertical-align: middle;\n  }\n\n  a {\n    margin-left: 10px;\n    border-bottom: none;\n    pointer-events: ", ";\n    color: ", ";\n  }\n"])), function (props) {
    return props.$disabled ? 'none' : props.theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xl;
}, function (props) {
    return props.$disabled
        ? props.theme.colors.dim
        : props.$selected
            ? props.theme.colors.primary
            : 'unset';
}, function (props) { return (props.$disabled ? 'none' : 'auto'); }, function (props) {
    return props.$disabled
        ? props.theme.colors.dim
        : props.$selected
            ? props.theme.colors.primary
            : 'unset';
});
NavigationItem.displayName = 'NavigationItem';
export var NavigationHeader = styled(NavigationItemStyle)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: bold;\n\n  &:hover {\n    > svg {\n      color: unset;\n    }\n  }\n"], ["\n  font-size: ", ";\n  font-weight: bold;\n\n  &:hover {\n    > svg {\n      color: unset;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.l;
});
NavigationHeader.displayName = 'NavigationHeader';
export var NavigationSeparator = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 10px 0;\n"], ["\n  margin: 10px 0;\n"])));
NavigationSeparator.displayName = 'NavigationSeparator';
export var NavigationSeparatorSlim = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 5px 0;\n"], ["\n  margin: 5px 0;\n"])));
NavigationSeparatorSlim.displayName = 'NavigationSeparatorSlim';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
