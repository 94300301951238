var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertError, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonFooterWithToast, ButtonLink, Checkbox, Label, } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Heading2, Heading3 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { CountryCode } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useOrderProductMutation } from '../../../api/account/accountApi';
import { useGetBillingInfoQuery } from '../../../api/customer/customerApi';
import { useGetLicenseTypeQuery } from '../../../api/license/licenseApi';
import { AuthenticationContext } from '../AuthenticationProvider';
import { validationBillingInfoAll } from '../validation';
import { LicenseType, OrderProductCustomerInfo, RegisterBillingAddress, } from './';
export var OrderProduct = function (_a) {
    var _b, _c, _d;
    var productId = _a.productId, userAgreement = _a.userAgreement, productName = _a.productName, orderedProductLink = _a.orderedProductLink, fromProductLink = _a.fromProductLink;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _e = useState(false), isAcceptTermsChecked = _e[0], setIsAcceptTermsChecked = _e[1];
    var _f = useState(true), isValid = _f[0], setIsValid = _f[1];
    var _g = useState(false), isShowingAgreement = _g[0], setIsShowingAgreement = _g[1];
    var _h = useState(false), isChangesSaved = _h[0], setIsChangesSaved = _h[1];
    var _j = useState({}), validation = _j[0], setValidation = _j[1];
    var _k = useState(), billingInfo = _k[0], setBillingInfo = _k[1];
    var _l = useState(false), isConsortiumLicense = _l[0], setIsConsortiumLicense = _l[1];
    var _m = useState(false), isBaseVersion = _m[0], setIsBaseVersion = _m[1];
    var _o = useOrderProductMutation(), orderProduct = _o[0], _p = _o[1], isSuccess = _p.isSuccess, isLoading = _p.isLoading, error = _p.error;
    var _q = useGetLicenseTypeQuery({
        productId: productId,
        isConsortiumLicense: isConsortiumLicense,
        isBaseVersion: isBaseVersion,
    }), licenseType = _q.data, isLoadingLicenseType = _q.isLoading, isFetchingLicenseType = _q.isFetching, errorLicenseType = _q.error;
    var _r = useGetBillingInfoQuery((_b = accessToken.customerId) !== null && _b !== void 0 ? _b : skipToken), billing = _r.data, isFetching = _r.isFetching, requestId = _r.requestId;
    var handleFieldChange = function () {
        setIsValid(true);
    };
    var handleSubmit = function (e) {
        var _a, _b;
        e.preventDefault();
        setIsValid(true);
        if (!billingInfo || !licenseType) {
            return;
        }
        var billingInfoErrors = validationBillingInfoAll(billingInfo, (_b = (_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.currentCustomer.countryCode) !== null && _b !== void 0 ? _b : CountryCode.SE, t);
        var isBillingInfoValid = !Object.keys(billingInfoErrors).some(function (key) { return billingInfoErrors[key] !== undefined; });
        setValidation(billingInfoErrors);
        if (!isBillingInfoValid) {
            setIsValid(false);
            return;
        }
        if (isAcceptTermsChecked) {
            orderProduct({
                city: billingInfo.city,
                street: billingInfo.street,
                areaCode: billingInfo.areaCode,
                invoiceReference: billingInfo.invoiceReference,
                invoiceEmail: billingInfo.invoiceEmail,
                productId: productId,
                isConsortiumLicense: isConsortiumLicense,
                isBaseVersion: isBaseVersion,
            });
        }
    };
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (billing) {
            setBillingInfo({
                street: (_a = billing.street) !== null && _a !== void 0 ? _a : '',
                areaCode: (_b = billing.areaCode) !== null && _b !== void 0 ? _b : '',
                city: (_c = billing.city) !== null && _c !== void 0 ? _c : '',
                invoiceReference: (_d = billing.invoiceReference) !== null && _d !== void 0 ? _d : '',
                invoiceEmail: (_e = billing.invoiceEmail) !== null && _e !== void 0 ? _e : '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billing, requestId]);
    if (isFetching) {
        return _jsx(LoaderSmall, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(_Fragment, { children: [isShowingAgreement && (_jsxs(Modal, __assign({ handleKeyEsc: function () { return setIsShowingAgreement(false); }, style: { width: '90%', maxWidth: 700 }, title: t('UserAgreement.Title') }, { children: [_jsx("div", __assign({ style: { overflowY: 'scroll', height: '80%', maxHeight: 700 } }, { children: userAgreement })), _jsx(OrderProductButtonsStyle, { children: _jsx(Button, __assign({ style: { minWidth: 100 }, type: "button", onClick: function () { return setIsShowingAgreement(false); } }, { children: t('Common.UI.Ok') })) })] }))), isSuccess && (_jsxs("div", __assign({ style: { alignSelf: 'center' } }, { children: [_jsx(Heading2, { children: t('Order.Verification') }), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: orderedProductLink })), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: fromProductLink }))] }))), !isSuccess && (_jsxs(_Fragment, { children: [_jsx(Heading2, { children: t("Order.".concat(productName, ".Title")) }), _jsxs(OrderProductStyle, __assign({ onSubmit: handleSubmit }, { children: [_jsx("div", { children: _jsx(Trans, { i18nKey: t("Order.".concat(productName, ".Text")) }) }), productId === config.products.pios.id && (_jsxs(_Fragment, { children: [_jsx(Heading3, { children: t('Order.Offer') }), _jsx(Trans, { i18nKey: 'Order.OfferText' })] })), _jsx(OrderProductCustomerInfo, { productId: productId, isConsortiumLicense: isConsortiumLicense, setIsConsortiumLicense: setIsConsortiumLicense, isBaseVersion: isBaseVersion, setIsBaseVersion: setIsBaseVersion }), !accessToken.isInternational && (_jsxs(_Fragment, { children: [(isLoadingLicenseType || isFetchingLicenseType) && (_jsx(LoaderSmall, {})), !isLoadingLicenseType &&
                                        !isFetchingLicenseType &&
                                        licenseType && _jsx(LicenseType, { licenseType: licenseType }), errorLicenseType && (_jsx(AlertWarning, { children: t('Order.LicenseError') }))] })), billingInfo && (_jsx(RegisterBillingAddress, { countryCode: (_d = (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.currentCustomer.countryCode) !== null && _d !== void 0 ? _d : CountryCode.SE, billing: billingInfo, setBilling: setBillingInfo, validation: validation, setValidation: setValidation, handleFieldChange: handleFieldChange })), _jsxs("section", { children: [_jsx(Heading3, { children: t('Order.WhatNow.Title') }), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: t('Order.WhatNow.Text') }))] }), error && (_jsx(AlertError, __assign({ style: { marginTop: 10 } }, { children: t("Order.HasErrors") }))), _jsxs(OrderProductButtonsStyle, { children: [_jsxs("div", { children: [_jsx(Checkbox, { name: "acceptTerms", id: "acceptTerms", checked: isAcceptTermsChecked, onChange: function (e) {
                                                    return setIsAcceptTermsChecked(e.currentTarget.checked);
                                                } }), _jsxs(Label, __assign({ htmlFor: "acceptTerms", style: { display: 'inline-block', margin: 10 } }, { children: [t('Order.AcceptTerms'), _jsx(ButtonLink, __assign({ onClick: function (e) {
                                                            e.preventDefault();
                                                            setIsShowingAgreement(true);
                                                        } }, { children: t('Register.AcceptTermsLink') }))] }))] }), !isValid && (_jsx(AlertWarning, __assign({ style: { marginTop: 10 } }, { children: t("Register.Validation.HasErrors") }))), _jsx(ButtonFooterWithToast, __assign({ isSaved: isChangesSaved, setIsSaved: setIsChangesSaved }, { children: _jsx(Button, __assign({ type: "submit", style: { width: 200 }, disabled: isLoading || !isAcceptTermsChecked }, { children: isLoading ? t('Order.Submitting') : t('Order.Submit') })) }))] })] })), _jsx(OrderProductBackLinkStyle, { children: _jsx(Link, __assign({ to: "/" }, { children: t('Order.BackLink') })) })] }))] }));
};
OrderProduct.displayName = 'OrderProduct';
export var OrderProductStyle = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 30px;\n\n  label > span {\n    color: ", ";\n  }\n\n  > section {\n    margin-top: 40px;\n\n    > h3 {\n      border-bottom: 1px solid\n        ", ";\n    }\n\n    > div {\n      width: 100%;\n      display: flex;\n      align-items: flex-end;\n\n      > div {\n        width: 50%;\n      }\n\n      > div:first-of-type {\n        width: 50%;\n        padding-right: 20px;\n      }\n    }\n  }\n\n  > h3 {\n    margin-top: 30px;\n    color: ", ";\n  }\n"], ["\n  margin-bottom: 30px;\n\n  label > span {\n    color: ", ";\n  }\n\n  > section {\n    margin-top: 40px;\n\n    > h3 {\n      border-bottom: 1px solid\n        ", ";\n    }\n\n    > div {\n      width: 100%;\n      display: flex;\n      align-items: flex-end;\n\n      > div {\n        width: 50%;\n      }\n\n      > div:first-of-type {\n        width: 50%;\n        padding-right: 20px;\n      }\n    }\n  }\n\n  > h3 {\n    margin-top: 30px;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.error;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryVariant;
});
export var OrderProductButtonsStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  button,\n  a {\n    margin-top: 20px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  button,\n  a {\n    margin-top: 20px;\n  }\n"])));
export var OrderProductBackLinkStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 15px;\n  padding-bottom: 30px;\n\n  font-size: ", ";\n"], ["\n  margin-top: 15px;\n  padding-bottom: 30px;\n\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
});
var templateObject_1, templateObject_2, templateObject_3;
