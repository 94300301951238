var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { BsArrowLeft } from 'react-icons/bs';
import { BadgeSmall } from './Badge';
import { TooltipContent } from './tooltip';
export var BackButton = function (_a) {
    var handleBack = _a.handleBack, _b = _a.isInverted, isInverted = _b === void 0 ? true : _b, style = _a.style;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-navigate-back", text: t('Common.UI.Back') }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-navigate-back", onClick: handleBack, "$inverted": isInverted, style: __assign({ marginRight: 15 }, style) }, { children: _jsx(BsArrowLeft, {}) }))] }));
};
BackButton.displayName = 'BackButton';
