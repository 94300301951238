import { Button, Buttons } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { CategoryByCustomer, SortDirection } from 'models';
import { useTranslation } from 'react-i18next';

export const CategoryRangeDetails = ({
  title,
  showDetailsFor,
  setShowDetailsFor,
}: {
  title: string;
  showDetailsFor: CategoryByCustomer & { similar: CategoryByCustomer[] };
  setShowDetailsFor: (
    item?: CategoryByCustomer & { similar: CategoryByCustomer[] }
  ) => void;
}) => {
  const { t } = useTranslation();

  const { items, sorter } = useSortableData(showDetailsFor.similar, {
    key: 'name',
    direction: SortDirection.Desc,
  });

  return (
    <Modal
      title={title}
      handleKeyEnter={() => setShowDetailsFor(undefined)}
      handleKeyEsc={() => setShowDetailsFor(undefined)}
      style={{
        minWidth: 300,
        width: '90%',
      }}
    >
      <TableResponsiveContainer
        style={{
          overflowY: 'auto',
          maxHeight: 400,
        }}
      >
        <Table>
          <thead>
            <Tr>
              <Th sortKey="organization" sorter={sorter}>
                {t('CommonTerms.Organisation')}
              </Th>
              <ThCentered
                sortKey="percentage"
                sorter={sorter}
                sortPredicate={(a, b) => {
                  return {
                    a: a.percentage,
                    b: b.percentage,
                  };
                }}
              >
                {t('CommonTerms.DigitalMaturity')}
              </ThCentered>
              <ThCentered
                sortKey="heritage"
                sorter={sorter}
                sortPredicate={(a, b) => {
                  return {
                    a: a.heritage?.percentage,
                    b: b.heritage?.percentage,
                  };
                }}
              >
                {t('CommonTerms.DigitalHeritage')}
              </ThCentered>
              <ThCentered
                sortKey="ability"
                sorter={sorter}
                sortPredicate={(a, b) => {
                  return {
                    a: a.ability?.percentage,
                    b: b.ability?.percentage,
                  };
                }}
              >
                {t('CommonTerms.DigitalAbility')}
              </ThCentered>
            </Tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <Tr key={index}>
                <Td>{item.organization}</Td>
                <TdCentered data-value={item.percentage}>
                  {formatAsPercent((item.percentage ?? 0) * 100)}
                </TdCentered>
                <TdCentered data-value={item.heritage?.percentage}>
                  {formatAsPercent((item.heritage?.percentage ?? 0) * 100)}
                </TdCentered>
                <TdCentered data-value={item.ability?.percentage}>
                  {formatAsPercent((item.ability?.percentage ?? 0) * 100)}
                </TdCentered>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableResponsiveContainer>

      <Buttons>
        <Button type="button" onClick={() => setShowDetailsFor(undefined)}>
          {t('Common.UI.Ok')}
        </Button>
      </Buttons>
    </Modal>
  );
};

CategoryRangeDetails.displayName = 'CategoryRangeDetails';
