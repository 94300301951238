var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { messageActions } from '../../../api/message/messageSlice';
import { selectSearchFilter, selectSearchIsFetching, selectSearchResult, selectSelectedUsers, userActions, } from '../../../api/user/userSlice';
import { UserFilters } from '../../../components/UserFilters';
import { RecipientsSearchResult } from './RecipientsSearchResult';
export var RecipientsAdd = function (_a) {
    var handleAddRecipients = _a.handleAddRecipients, searchableRoles = _a.searchableRoles, _b = _a.isEntityFilterShown, isEntityFilterShown = _b === void 0 ? true : _b, handleSearch = _a.handleSearch, initialPaginator = _a.initialPaginator;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var selectedUsers = useSelector(selectSelectedUsers);
    var searchResult = useSelector(selectSearchResult);
    var searchFilter = useSelector(selectSearchFilter);
    var searchIsFetching = useSelector(selectSearchIsFetching);
    var _c = useState([]), users = _c[0], setUsers = _c[1];
    var handleSave = function (e) {
        e.stopPropagation();
        handleAddRecipients(users);
    };
    useEffect(function () {
        if (selectedUsers) {
            var users_1 = selectedUsers.map(function (user) {
                var _a;
                return ({
                    userId: (_a = user.id) !== null && _a !== void 0 ? _a : user.uid,
                    email: user.email,
                });
            });
            setUsers(users_1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUsers]);
    return (_jsxs(Modal, __assign({ handleKeyEsc: function () {
            return dispatch(messageActions.setIsAddingRecipientsShown(false));
        }, title: t('Administrate.Messages.Message.Recipient.Add.Title'), style: { width: '90%', maxWidth: 1100 } }, { children: [_jsxs(Style, { children: [_jsx("div", { children: _jsx(UserFilters, { handleSearch: handleSearch, filter: searchFilter, setFilter: function (filter) { return dispatch(userActions.searchFilter(filter)); }, isEntityFilterShown: isEntityFilterShown, isRoleFilterShown: true, roles: searchableRoles }) }), searchIsFetching && _jsx(Loader, { children: t('Common.UI.Loading') }), !searchIsFetching && (_jsx(RecipientsSearchResult, { data: searchResult, handlePaginatorChange: handleSearch, initialPaginator: initialPaginator }))] }), _jsxs(Buttons, __assign({ style: { margin: '10px' } }, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () {
                            return dispatch(messageActions.setIsAddingRecipientsShown(false));
                        } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave, disabled: users.length === 0 }, { children: t('Administrate.Messages.Message.Recipient.Add.Add') }))] }))] })));
};
RecipientsAdd.displayName = 'RecipientsAdd';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div:first-of-type {\n    padding: 0 10px 10px 0;\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n\n    > input {\n      width: 180px;\n      padding-right: 0px;\n    }\n\n    > button {\n      min-width: 60px;\n    }\n  }\n"], ["\n  > div:first-of-type {\n    padding: 0 10px 10px 0;\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n\n    > input {\n      width: 180px;\n      padding-right: 0px;\n    }\n\n    > button {\n      min-width: 60px;\n    }\n  }\n"])));
var templateObject_1;
