var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerLineOfBusiness, } from '@dimatech/features-core/lib/models';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Tree } from '@dimatech/shared/lib/components/tree';
import { getAllChildren, getTreePathToItem } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBriefcase, BsBuilding, BsBuildings, BsCalendarEvent, } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { useGetEntitiesWithSurveyQuery, useMoveEntityWithSurveyMutation, } from '../../../api/entity/entityApi';
import { entityActions, selectSelectedEntity, } from '../../../api/entity/entitySlice';
import { selectFilter } from '../../../api/filter/filterSlice';
var getEntityIconByType = function (hasChildren, type) {
    return type && Object.keys(CustomerLineOfBusiness).includes(type) ? (_jsx(BsBriefcase, {})) : hasChildren ? (_jsx(BsBuildings, {})) : (_jsx(BsBuilding, {}));
};
export var EntitiesTree = withTheme(function (_a) {
    var _b, _c;
    var handleEntitySelect = _a.handleEntitySelect, theme = _a.theme, isStatusShown = _a.isStatusShown, selectedEntityId = _a.selectedEntityId, setSelectedEntityId = _a.setSelectedEntityId, isDraggable = _a.isDraggable;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var selectedEntity = useSelector(selectSelectedEntity);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _d = useState(), tree = _d[0], setTree = _d[1];
    var _e = useMoveEntityWithSurveyMutation(), moveEntity = _e[0], moveErrors = _e[1].error;
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var data = useGetEntitiesWithSurveyQuery(accessToken.customerId && ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) && filter.instance.id
        ? {
            _customerId: accessToken.customerId,
            _userId: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    var handleSelect = function (item) {
        var _a, _b, _c;
        var entity = data === null || data === void 0 ? void 0 : data.find(function (entity) { return entity.id === item.id; });
        dispatch(entityActions.selectedEntity(__assign(__assign({}, entity), { icon: null, items: null, childCount: (_b = (_a = item.items) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0, anyChildHasIndividualMeasurementPeriod: (_c = item.items) === null || _c === void 0 ? void 0 : _c.some(function (item) { var _a; return !!((_a = item.props) === null || _a === void 0 ? void 0 : _a.allowIndividualMeasurementPeriods); }) })));
        handleEntitySelect === null || handleEntitySelect === void 0 ? void 0 : handleEntitySelect();
    };
    var handleDrop = function (id, parentId) {
        var draggedEntity = data === null || data === void 0 ? void 0 : data.find(function (item) { return item.id === id; });
        var hasChildren = data ? getAllChildren(data, [id]) : [];
        if (isReadOnly ||
            !filter.instance.isCurrentInstance ||
            id === parentId ||
            (draggedEntity === null || draggedEntity === void 0 ? void 0 : draggedEntity.parentId) === parentId ||
            hasChildren.length >= 1) {
            return;
        }
        moveEntity({ entityId: id, parentId: parentId });
    };
    var mapToItems = function (entities, parentIds, openItems, depth) {
        if (depth === void 0) { depth = 0; }
        var items = entities
            .filter(function (entity) { return parentIds.includes(entity.parentId); })
            .map(function (entity) {
            var children = mapToItems(entities, [entity.id || null], openItems, depth + 1);
            return {
                content: (_jsxs(_Fragment, { children: [entity.name, entity.parentId &&
                            isStatusShown &&
                            entity.allowIndividualMeasurementPeriods && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-ind-per", text: t('Administrate.Schedule.IndividualMeasurementPeriods.Use.Uses') }), _jsx(BsCalendarEvent, { "data-tip": true, "data-for": "tt-ind-per", style: {
                                        marginLeft: 5,
                                        marginBottom: -2,
                                        color: theme.colors.success,
                                        fontSize: theme.fonts.size.base,
                                    } })] }))] })),
                id: entity.id,
                name: entity.name,
                items: children,
                icon: getEntityIconByType(children.length > 0, entity.type),
                props: {
                    respondents: entity.respondents,
                    allowIndividualMeasurementPeriods: entity.allowIndividualMeasurementPeriods,
                },
                isOpen: depth < 1 || (openItems === null || openItems === void 0 ? void 0 : openItems.includes(entity.id)),
            };
        });
        return items;
    };
    useEffect(function () {
        if (data) {
            var visibleEntities_1 = data.filter(function (entity) { return !entity.isReadOnly; });
            var orphans = visibleEntities_1
                .filter(function (entity) {
                return !visibleEntities_1.some(function (child) { return entity.parentId === child.id; });
            })
                .map(function (orphan) { return orphan.parentId; });
            var entity = selectedEntityId
                ? data === null || data === void 0 ? void 0 : data.find(function (entity) { return entity.id === selectedEntityId; })
                : // Select root if no other entity is selected
                    selectedEntity !== null && selectedEntity !== void 0 ? selectedEntity : data === null || data === void 0 ? void 0 : data.find(function (entity) { return !entity.parentId; });
            var openItems = (entity === null || entity === void 0 ? void 0 : entity.parentId)
                ? getTreePathToItem(visibleEntities_1, entity === null || entity === void 0 ? void 0 : entity.parentId)
                : undefined;
            setTree(mapToItems(visibleEntities_1, orphans, openItems));
            if (entity) {
                var children = visibleEntities_1
                    ? getAllChildren(visibleEntities_1, [entity.id])
                    : [];
                dispatch(entityActions.selectedEntity(__assign(__assign({}, entity), { childCount: children.length })));
                setSelectedEntityId === null || setSelectedEntityId === void 0 ? void 0 : setSelectedEntityId('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedEntityId]);
    return (_jsxs(_Fragment, { children: [(!tree || tree.length === 0) && !selectedEntity && (_jsx("div", { children: t('Administrate.Entity.NoEntities') })), _jsx(AlertErrors, { error: moveErrors, style: { padding: 10 }, translationPath: "Administrate.Entity.ValidationError" }), tree && tree.length > 0 && (_jsx(Tree, { items: tree, selected: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id, highlightItems: !(selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) && (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.parentId)
                    ? [selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.parentId]
                    : [], onSelect: handleSelect, onDrop: handleDrop, isDraggable: isDraggable }))] }));
});
EntitiesTree.displayName = 'EntitiesTree';
