var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommonRoles } from '../../models';
import { CacheTagsUser, featuresCoreApi } from '../featuresCoreApi';
var userApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get admins
         */
        getCustomerAdmins: build.query({
            query: function (_a) {
                var filter = _a.filter, _customerId = _a._customerId;
                return "user/list?filter.role=Write".concat(usersFilterToQuery(filter));
            },
            providesTags: [CacheTagsUser.CustomerAdmin],
        }),
        /**
         * Get users
         */
        getUsers: build.query({
            query: function (_a) {
                var filter = _a.filter, _customerId = _a._customerId;
                return "user/list?1=1".concat(usersFilterWithRoleToQuery(filter));
            },
            providesTags: [CacheTagsUser.UserSearchWithRole],
        }),
        /**
         * Get account admins
         */
        getAccountAdmins: build.query({
            query: function (_customerId) {
                return "user/search?filter.role=CustomerAccountAdmin&pageNumber=1&pageSize=1000&orderBy.propertyName=email&orderBy.direction=Ascending";
            },
            providesTags: [CacheTagsUser.CustomerAccountAdmin],
            transformResponse: function (_a) {
                var records = _a.records;
                return records;
            },
        }),
        /**
         * Get viewers
         */
        getViewers: build.query({
            query: function (_a) {
                var filter = _a.filter, _customerId = _a._customerId;
                return "user/list?filter.role=Read".concat(usersFilterToQuery(filter));
            },
            providesTags: [CacheTagsUser.Viewer],
        }),
        /**
         * Add customer admin
         */
        createOrUpdateCustomerAdmin: build.mutation({
            query: function (_a) {
                var user = _a.user;
                return ({
                    url: "user",
                    method: 'POST',
                    body: __assign(__assign({}, user), { isAdmin: true }),
                });
            },
            invalidatesTags: [CacheTagsUser.CustomerAdmin],
        }),
        /**
         * Delete customer admin
         */
        deleteCustomerAdmin: build.mutation({
            query: function (_a) {
                var user = _a.user;
                return ({
                    url: "user",
                    method: 'DELETE',
                    body: __assign(__assign({}, user), { isAdmin: true }),
                });
            },
            invalidatesTags: [CacheTagsUser.CustomerAdmin],
        }),
        /**
         * Add account admin
         */
        createOrUpdateAccountAdmin: build.mutation({
            query: function (_a) {
                var user = _a.user;
                return ({
                    url: "user",
                    method: 'POST',
                    body: __assign(__assign({}, user), { isAccountAdmin: true }),
                });
            },
            invalidatesTags: [CacheTagsUser.CustomerAccountAdmin],
        }),
        /**
         * Delete account admin
         */
        deleteAccountAdmin: build.mutation({
            query: function (_a) {
                var user = _a.user;
                return ({
                    url: "user",
                    method: 'DELETE',
                    body: __assign(__assign({}, user), { isAccountAdmin: true }),
                });
            },
            invalidatesTags: [CacheTagsUser.CustomerAccountAdmin],
        }),
        /**
         * Add viewer
         */
        createOrUpdateViewer: build.mutation({
            query: function (_a) {
                var user = _a.user;
                return ({
                    url: "user",
                    method: 'POST',
                    body: __assign(__assign({}, user), { isReader: true }),
                });
            },
            invalidatesTags: [CacheTagsUser.Viewer],
        }),
        /**
         * Delete viewer
         */
        deleteViewer: build.mutation({
            query: function (_a) {
                var user = _a.user;
                return ({
                    url: "user",
                    method: 'DELETE',
                    body: __assign(__assign({}, user), { isReader: true }),
                });
            },
            invalidatesTags: [CacheTagsUser.Viewer],
        }),
        /**
         * Remove viewer
         */
        removeViewer: build.mutation({
            query: function (email) { return ({
                url: "user/remove",
                method: 'DELETE',
                body: { email: email, role: CommonRoles.Reader },
            }); },
            invalidatesTags: [CacheTagsUser.Viewer],
        }),
        /**
         * Remove admin
         */
        removeCustomerAdmin: build.mutation({
            query: function (email) { return ({
                url: "user/remove",
                method: 'DELETE',
                body: { email: email, role: CommonRoles.CustomerAdmin },
            }); },
            invalidatesTags: [CacheTagsUser.CustomerAdmin],
        }),
        /**
         * Search users
         */
        search: build.query({
            query: function (_a) {
                var search = _a.search;
                var filters = "".concat(search.role ? "&filter.role=".concat(search.role) : '').concat(search.email ? "&filter.email=".concat(search.email) : '').concat(search.userId ? "&filter.id=".concat(search.userId) : '').concat(search.name ? "&filter.name=".concat(search.name) : '').concat(search.excludeRoles
                    ? "&filter.excludeRoles=".concat(search.excludeRoles.join(','))
                    : '');
                return "user/search?pageNumber=".concat(search.paginator.page, "&pageSize=").concat(search.paginator.pageSize, "&orderBy.propertyName=").concat(search.paginator.orderBy, "&orderBy.direction=").concat(search.paginator.orderDirection).concat(filters);
            },
            providesTags: [CacheTagsUser.UserSearch],
            transformResponse: function (_a) {
                var records = _a.records;
                return records;
            },
        }),
    }); },
});
export var useSearchQuery = userApi.useSearchQuery, useLazyGetUsersQuery = userApi.useLazyGetUsersQuery, useGetAccountAdminsQuery = userApi.useGetAccountAdminsQuery, useCreateOrUpdateCustomerAdminMutation = userApi.useCreateOrUpdateCustomerAdminMutation, useDeleteCustomerAdminMutation = userApi.useDeleteCustomerAdminMutation, useCreateOrUpdateAccountAdminMutation = userApi.useCreateOrUpdateAccountAdminMutation, useDeleteAccountAdminMutation = userApi.useDeleteAccountAdminMutation, useCreateOrUpdateViewerMutation = userApi.useCreateOrUpdateViewerMutation, useDeleteViewerMutation = userApi.useDeleteViewerMutation, useGetViewersQuery = userApi.useGetViewersQuery, useRemoveViewerMutation = userApi.useRemoveViewerMutation, useGetCustomerAdminsQuery = userApi.useGetCustomerAdminsQuery, useRemoveCustomerAdminMutation = userApi.useRemoveCustomerAdminMutation, useLazyGetViewersQuery = userApi.useLazyGetViewersQuery, useLazyGetCustomerAdminsQuery = userApi.useLazyGetCustomerAdminsQuery;
export var usersFilterToQuery = function (filter) {
    return "".concat(filter.paginator
        ? "&orderBy.direction=".concat(filter.paginator.orderDirection, "&orderBy.propertyName=").concat(filter.paginator.orderBy, "&pageSize=").concat(filter.paginator.pageSize, "&pageNumber=").concat(filter.paginator.page)
        : '').concat(filter.email ? "&filter.email=".concat(filter.email) : '').concat(filter.entityIds && filter.entityIds.length > 0
        ? filter.entityIds.map(function (entity) { return "&filter.entityIds=".concat(entity); }).join('')
        : '');
};
var usersFilterWithRoleToQuery = function (filter) {
    return "".concat(usersFilterToQuery(filter), "&filter.role=").concat(filter.role);
};
