var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export var AlertWarningBanner = function (_a) {
    var linkText = _a.linkText, children = _a.children;
    return (_jsxs(StyleWarning, { children: [children, linkText && _jsx("a", __assign({ href: "hej" }, { children: linkText }))] }));
};
AlertWarningBanner.displayName = 'AlertWarningBanner';
export var AlertInfoBanner = function (_a) {
    var linkText = _a.linkText, children = _a.children;
    return (_jsxs(StyleInfo, { children: [children, linkText && _jsx("a", __assign({ href: "hej" }, { children: linkText }))] }));
};
AlertInfoBanner.displayName = 'AlertInfoBanner';
export var AlertErrorBanner = function (_a) {
    var linkText = _a.linkText, children = _a.children;
    return (_jsxs(StyleError, { children: [children, linkText && _jsx("a", __assign({ href: "hej" }, { children: linkText }))] }));
};
AlertInfoBanner.displayName = 'AlertInfoBanner';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  line-height: 22px;\n\n  width: 100%;\n  padding: 10px 30px;\n"], ["\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  line-height: 22px;\n\n  width: 100%;\n  padding: 10px 30px;\n"])));
var StyleWarning = styled(Style)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.warning;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onWarning;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onWarning;
});
var StyleInfo = styled(Style)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.info;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onInfo;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onInfo;
});
var StyleError = styled(Style)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.error;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onError;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onError;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
