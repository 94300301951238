var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonSecondary, Buttons, Input, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { isValidEmail } from '@dimatech/shared/lib/utils';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
export var RespondentAdd = withTheme(function (_a) {
    var handleAddRespondent = _a.handleAddRespondent, setIsAdding = _a.setIsAdding, theme = _a.theme;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var selectedEntity = useSelector(selectSelectedEntity);
    var _b = useState(true), isValid = _b[0], setIsValid = _b[1];
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var handleSave = function (e) {
        e.stopPropagation();
        if (isReadOnly) {
            return;
        }
        if (!isValidEmail(email)) {
            setIsValid(false);
            return;
        }
        handleAddRespondent(email.trim());
        setIsAdding(false);
    };
    if (!selectedEntity) {
        return null;
    }
    return (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.Add.Title'), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsAdding(false); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.Add.Text') }), !isValid && (_jsx(AlertWarning, __assign({ style: { marginTop: 10, width: '100%' } }, { children: t('Administrate.Respondent.ValidationError.Respondents') }))), _jsx(Label, __assign({ htmlFor: "respondent" }, { children: t('Administrate.Respondent.Add.Email') })), _jsx(Input, { autoFocus: true, type: "text", id: "respondent", name: "respondent", value: email, invalid: !isValid, onChange: function (e) { return setEmail(e.currentTarget.value); } }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsAdding(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave, disabled: isReadOnly }, { children: t('Common.Form.Save') }))] })] })));
});
RespondentAdd.displayName = 'RespondentAdd';
