var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CacheTagsCustomer, featuresCoreApi } from '../featuresCoreApi';
var customerApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get organizations by type
         */
        getOrganizationsByType: build.query({
            query: function (_a) {
                var type = _a.type, _b = _a.includeArea, includeArea = _b === void 0 ? false : _b;
                return "account/organizations/".concat(type, "?includeArea=").concat(!!includeArea);
            },
            transformResponse: function (result) {
                return result.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
            },
        }),
        /**
         * Select customer
         */
        selectCustomer: build.mutation({
            query: function (_a) {
                var customerId = _a.customerId, refreshToken = _a.refreshToken;
                return {
                    url: "user/selectCustomer",
                    method: 'POST',
                    body: {
                        customerId: customerId,
                        refreshToken: refreshToken,
                    },
                };
            },
        }),
        /**
         * Get customer for logged on user
         * _userId is only used for RTK-Q to know that it should invalidate
         * the cache and re-fetch when the user is switched (ie logging out)
         */
        getMyCustomers: build.query({
            query: function (_userId) { return 'user/customers'; },
            transformResponse: function (customers) {
                return __spreadArray([], customers, true).sort(function (customerA, customerB) {
                    return customerA.name < customerB.name ? -1 : 1;
                });
            },
        }),
        /**
         * Get billing info for current customer
         * _customerId is only used for RTK-Q to know that it should invalidate
         * the cache and re-fetch when the user is switching customer
         */
        getBillingInfo: build.query({
            query: function (_customerId) { return 'customer/billingInfo'; },
            providesTags: [CacheTagsCustomer.BillingInfo],
        }),
        /**
         * Update billing info for current customer
         */
        updateBillingInfo: build.mutation({
            query: function (billingInfo) { return ({
                url: "customer/billingInfo",
                method: 'PUT',
                body: billingInfo,
            }); },
            invalidatesTags: [CacheTagsCustomer.BillingInfo],
        }),
        /**
         * Check if customer is registered in product
         */
        getCustomerIsRegisteredInProduct: build.query({
            query: function (_a) {
                var productId = _a.productId, _customerId = _a._customerId;
                return "customer/access?productId=".concat(productId);
            },
            providesTags: [CacheTagsCustomer.IsRegisteredInProduct],
        }),
        /**
         * Update locale for current customer
         */
        updateLocale: build.mutation({
            query: function (locale) { return ({
                url: "customer/locale/".concat(locale),
                method: 'PUT',
            }); },
            invalidatesTags: [CacheTagsCustomer.Locale],
        }),
        /**
         * Upload customer logo
         */
        uploadLogo: build.mutation({
            query: function (_a) {
                var data = _a.data, customerId = _a.customerId;
                return ({
                    url: "customer/".concat(customerId ? "".concat(customerId, "/") : '', "logo"),
                    method: 'POST',
                    body: data,
                });
            },
        }),
    }); },
});
export var useGetMyCustomersQuery = customerApi.useGetMyCustomersQuery, useGetOrganizationsByTypeQuery = customerApi.useGetOrganizationsByTypeQuery, useGetBillingInfoQuery = customerApi.useGetBillingInfoQuery, useUpdateBillingInfoMutation = customerApi.useUpdateBillingInfoMutation, useGetCustomerIsRegisteredInProductQuery = customerApi.useGetCustomerIsRegisteredInProductQuery, useSelectCustomerMutation = customerApi.useSelectCustomerMutation, useUpdateLocaleMutation = customerApi.useUpdateLocaleMutation, useUploadLogoMutation = customerApi.useUploadLogoMutation;
