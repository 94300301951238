var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { HelpIcon, HelpIconLeft, } from '@dimatech/shared/lib/components/HelpIcon';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Table, TableResponsiveContainer, Td, TdRight, Th, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { TransArray } from '@dimatech/shared/lib/localization';
import { SortDirection } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { parseISO } from 'date-fns';
import i18next from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsPersonBadge, BsPersonPlus } from 'react-icons/bs';
import { useCreateOrUpdateAccountAdminMutation, useGetAccountAdminsQuery, } from '../../../api/user/userApi';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { AccountAdminAdd } from './AccountAdminAdd';
import { AccountAdminsEditButtons } from './AccountAdminsEditButtons';
export var AccountAdmins = function () {
    var _a;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useCreateOrUpdateAccountAdminMutation(), createOrUpdate = _b[0], createError = _b[1].error;
    var _c = useGetAccountAdminsQuery((_a = accessToken.customerId) !== null && _a !== void 0 ? _a : skipToken), data = _c.data, isFetching = _c.isFetching;
    var _d = useSortableData(data !== null && data !== void 0 ? data : [], {
        key: 'email',
        direction: SortDirection.Asc,
    }), items = _d.items, sorter = _d.sorter;
    var _e = useState(false), isAdding = _e[0], setIsAdding = _e[1];
    var _f = useState(), error = _f[0], setError = _f[1];
    var handleAddAdmin = function (user) {
        setError(undefined);
        createOrUpdate({
            user: {
                email: user.email,
                isAccountAdmin: true,
            },
        });
    };
    useEffect(function () {
        setError(undefined);
    }, []);
    useEffect(function () {
        setError(createError);
    }, [createError]);
    if (isFetching) {
        return (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: _jsx(Loader, { children: t('Common.UI.Loading') }) })));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Heading3, { children: t('UserRoles.CustomerAccountAdmin.Title') }), _jsxs(HelpIconLeft, __assign({ style: { marginTop: 10, marginBottom: 10 } }, { children: [_jsx(Trans, { i18nKey: "UserRoles.CustomerAccountAdmin.Text" }), i18next.exists('UserRoles.CustomerAccountAdmin.Help') && (_jsx(HelpIcon, { style: { marginLeft: 7 }, title: t('UserRoles.CustomerAccountAdmin.Title'), text: _jsx(TransArray, { i18nKey: "UserRoles.CustomerAccountAdmin.Help" }) }))] })), _jsx(LinkWithTooltip, { isPrimary: true, handleClick: function () { return setIsAdding(true); }, title: t('Administrate.Account.Admin.Add.Title'), tooltipTitle: t('Administrate.Account.Admin.Add.TooltipTitle'), tooltip: t('Administrate.Account.Admin.Add.Tooltip'), icon: _jsx(BsPersonPlus, {}), style: { marginTop: 20, marginBottom: 10 } }), _jsx(AlertErrors, { error: error, translationPath: "Administrate.Account.Admin.ValidationError" }), (data === null || data === void 0 ? void 0 : data.length) === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('Administrate.Account.Admin.NoAdmins') }))), isAdding && (_jsx(AccountAdminAdd, { setIsAdding: setIsAdding, handleAddAdmin: handleAddAdmin })), data && data.length > 0 && (_jsx(TableResponsiveContainer, { children: _jsxs(Table, __assign({ style: { marginTop: 15, marginBottom: 10 } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, {}), _jsx(Th, __assign({ sortKey: "email", sorter: sorter }, { children: t('Administrate.Account.Admin.Email') })), _jsx(ThRight, __assign({ sortKey: "lastLoginDate", sorter: sorter }, { children: t('Administrate.Account.Admin.LastLogin') })), _jsx(Th, {})] }) }), _jsx("tbody", { children: items === null || items === void 0 ? void 0 : items.map(function (item, index) {
                                var _a;
                                return (_jsxs(Tr, { children: [_jsx(Td, __assign({ style: { width: 25 } }, { children: _jsx(BsPersonBadge, {}) })), _jsx(Td, { children: item.email }), _jsx(TdRight, __assign({ style: {
                                                whiteSpace: 'nowrap',
                                            } }, { children: item.lastLoginDate
                                                ? formatDate(parseISO(item.lastLoginDate))
                                                : '-' })), _jsx(TdRight, { children: item.email !== ((_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.email) && (_jsx("div", __assign({ style: {
                                                    verticalAlign: 'middle',
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                } }, { children: _jsx(AccountAdminsEditButtons, { user: item, setError: setError }) }))) })] }, index));
                            }) })] })) }))] }));
};
AccountAdmins.displayName = 'AccountAdmins';
