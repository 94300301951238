var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
export var usePagination = function (_a) {
    var totalCount = _a.totalCount, pageSize = _a.pageSize, _b = _a.siblingCount, siblingCount = _b === void 0 ? 1 : _b, currentPage = _a.currentPage;
    var paginationRange = useMemo(function () {
        var totalPageCount = Math.ceil(totalCount / pageSize);
        var totalPageNumbers = siblingCount + 5;
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }
        var leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        var rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
        var shouldShowLeftDots = leftSiblingIndex > 2;
        var shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
        var firstPageIndex = 1;
        var lastPageIndex = totalPageCount;
        if (!shouldShowLeftDots && shouldShowRightDots) {
            var leftItemCount = 3 + 2 * siblingCount;
            var leftRange = range(1, leftItemCount);
            return __spreadArray(__spreadArray([], leftRange, true), [Dots, totalPageCount], false);
        }
        if (shouldShowLeftDots && !shouldShowRightDots) {
            var rightItemCount = 3 + 2 * siblingCount;
            var rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return __spreadArray([firstPageIndex, Dots], rightRange, true);
        }
        if (shouldShowLeftDots && shouldShowRightDots) {
            var middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return __spreadArray(__spreadArray([firstPageIndex, Dots], middleRange, true), [Dots, lastPageIndex], false);
        }
        return range(1, totalPageCount);
    }, [totalCount, pageSize, siblingCount, currentPage]);
    return paginationRange;
};
export var Dots = '...';
var range = function (start, end) {
    var length = end - start + 1;
    return Array.from({ length: length }, function (_, index) { return index + start; });
};
