var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { formatAsNumber, formatDate } from '@dimatech/shared/lib/utils';
import { useTranslation } from 'react-i18next';
export var StatisticsMessagesChartTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, years = _a.years;
    var t = useTranslation().t;
    if (active && payload && payload.length > 0) {
        var item_1 = payload[0].payload;
        return (_jsxs(TooltipStyle, { children: [_jsx("div", __assign({ className: "tooltip-title" }, { children: formatDate(new Date(0, +item_1.month - 1), 'MMMM') })), years.map(function (year) { return (_jsx("div", { children: t('GlobalAdministrate.Overview.Statistics.Messages.SentMessages.CountByYear', {
                        year: year,
                        messages: formatAsNumber(item_1["count".concat(year)]),
                    }) }, "count".concat(year))); }), years.map(function (year) { return (_jsx("div", { children: t('GlobalAdministrate.Overview.Statistics.Messages.ActiveCustomers.CountByYear', {
                        year: year,
                        customers: formatAsNumber(item_1["activeCustomers".concat(year)]),
                    }) }, "activeCustomers".concat(year))); })] }));
    }
    return null;
};
StatisticsMessagesChartTooltip.displayName = 'StatisticsMessagesChartTooltip';
