var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function */
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Button, ButtonFooter, ButtonLink, ButtonSecondary, Buttons, Label, Radio, } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Table, Td, TdRight, Th, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { HttpStatus, SurveyResponseStatus } from '@dimatech/shared/lib/models';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { useAddSurveyRespondentMutation, useDeleteSurveyRespondentMutation, } from '../../../api/entity/entitySurveyRespondentApi';
import { ImportRespondentStatus } from '../../../models';
export var RespondentsImportList = withTheme(function (_a) {
    var dataImportRespondents = _a.dataImportRespondents, entityIdImportRespondents = _a.entityIdImportRespondents, setEntityIdImportRespondents = _a.setEntityIdImportRespondents, theme = _a.theme;
    var t = useTranslation().t;
    var _b = useState(false), isDeletingWithResponses = _b[0], setIsDeletingWithResponses = _b[1];
    var _c = useState(false), isImporting = _c[0], setIsImporting = _c[1];
    var _d = useState(true), shouldDataBeKept = _d[0], setShouldDataBeKept = _d[1];
    var _e = useState(false), isAllChecked = _e[0], setIsAllChecked = _e[1];
    var _f = useState([]), respondents = _f[0], setRespondents = _f[1];
    var _g = useState([]), invalidRespondents = _g[0], setInvalidRespondents = _g[1];
    var _h = useAddSurveyRespondentMutation(), addSurveyRespondent = _h[0], _j = _h[1], addIsLoading = _j.isLoading, addError = _j.error;
    var _k = useDeleteSurveyRespondentMutation(), deleteSurveyRespondent = _k[0], _l = _k[1], deleteIsLoading = _l.isLoading, deleteError = _l.error;
    var selectedEntity = useSelector(selectSelectedEntity);
    var error = addError !== null && addError !== void 0 ? addError : deleteError;
    var handleChange = function (e, email) {
        var newRespondents = __spreadArray([], respondents, true);
        var index = newRespondents.findIndex(function (respondent) { return respondent.email === email; });
        newRespondents[index] = __assign(__assign({}, newRespondents[index]), { include: e.target.checked });
        setRespondents(newRespondents);
    };
    var handleSelectAll = function (e) {
        var checked = !isAllChecked;
        setIsAllChecked(checked);
        setRespondents(respondents.map(function (resp) { return (__assign(__assign({}, resp), { include: checked })); }));
    };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var includedAdd, includedDelete;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    includedAdd = respondents
                        .filter(function (respondent) {
                        return !!respondent.email &&
                            respondent.include &&
                            respondent.status !== ImportRespondentStatus.NotInSource &&
                            respondent.status !== ImportRespondentStatus.Invalid;
                    })
                        .map(function (respondent) { return respondent.email; });
                    includedDelete = respondents
                        .filter(function (respondent) {
                        return respondent.include &&
                            respondent.status === ImportRespondentStatus.NotInSource;
                    })
                        .map(function (respondent) { return respondent.email; });
                    if (!(selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id)) return [3 /*break*/, 3];
                    return [4 /*yield*/, addSurveyRespondent({
                            entityId: selectedEntity.id,
                            respondent: includedAdd,
                            skipExisting: false,
                        }).unwrap()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, deleteSurveyRespondent({
                            entityId: selectedEntity.id,
                            respondents: includedDelete,
                            keepData: shouldDataBeKept,
                        }).unwrap()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    setEntityIdImportRespondents('');
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        // Filter out duplicate emails and already existing
        var unique = dataImportRespondents.filter(function (resp, index, self) {
            return !!resp.email &&
                index ===
                    self.findIndex(function (t) { return t.email.toLowerCase() === resp.email.toLowerCase(); }) &&
                resp.status !== ImportRespondentStatus.Exists;
        });
        setRespondents(__spreadArray([], unique, true).map(function (resp) { return (__assign(__assign({}, resp), { include: resp.status === ImportRespondentStatus.New })); }));
        setInvalidRespondents(dataImportRespondents.filter(function (resp) {
            return resp.status === ImportRespondentStatus.Invalid && !!resp.email;
        }));
    }, [dataImportRespondents]);
    useEffect(function () {
        var isDeletingWithResponses = respondents.some(function (respondent) {
            return respondent.include &&
                respondent.status === ImportRespondentStatus.NotInSource &&
                respondent.surveyResponseStatus === SurveyResponseStatus.Finished;
        });
        setIsDeletingWithResponses(isDeletingWithResponses);
    }, [respondents]);
    if (addIsLoading || deleteIsLoading) {
        return _jsx(LoaderOverlay, {});
    }
    return selectedEntity ? (_jsx(_Fragment, { children: (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) === entityIdImportRespondents && (_jsxs(_Fragment, { children: [respondents.length === 0 && (_jsx(AlertWarning, __assign({ style: { marginTop: 20, marginBottom: 10 } }, { children: t("Administrate.Respondent.Import.ValidationError.Empty") }))), respondents.length > 0 && (_jsx("div", __assign({ style: { marginTop: 20, marginBottom: 30 } }, { children: _jsx(Trans, { i18nKey: "Administrate.Respondent.Import.Help" }) }))), !error && invalidRespondents.length > 0 && (_jsx(AlertWarning, __assign({ style: { marginBottom: 30 } }, { children: t("Administrate.Respondent.Import.ValidationError.InvalidRespondents", {
                        count: invalidRespondents.length,
                    }) }))), _jsx(AlertErrors, { style: { marginBottom: 30 }, error: error, includeErrorStatuses: [HttpStatus.BadRequest] }), isImporting && (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.Import.ConfirmTitle'), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsImporting(false); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.Import.ConfirmText') }), isDeletingWithResponses && (_jsxs("div", __assign({ style: { marginTop: 15 } }, { children: [_jsx("div", { children: t('Administrate.Respondent.Import.Delete.ShouldDataBeKept.Title') }), _jsxs("div", { children: [_jsxs(Label, __assign({ htmlFor: "keepData" }, { children: [_jsx(Radio, { onChange: function (e) {
                                                        setShouldDataBeKept(e.target.checked);
                                                    }, id: "keepData", name: "shouldDataBeKept", value: 1, checked: shouldDataBeKept, style: { marginRight: 7 } }), t('Administrate.Respondent.Import.Delete.ShouldDataBeKept.KeepData')] })), _jsxs(Label, __assign({ htmlFor: "doNotKeepData" }, { children: [_jsx(Radio, { onChange: function (e) {
                                                        setShouldDataBeKept(!e.target.checked);
                                                    }, id: "doNotKeepData", name: "shouldDataBeKept", value: 1, checked: !shouldDataBeKept, style: { marginRight: 7 } }), t('Administrate.Respondent.Import.Delete.ShouldDataBeKept.DoNotKeepData')] }))] }), _jsx("div", __assign({ className: "i size-s", style: { marginTop: 15 } }, { children: t('Administrate.Respondent.Delete.ConfirmNote') }))] }))), _jsx(ButtonFooter, { children: _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsImporting(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave }, { children: t('Common.UI.Yes') }))] }) })] }))), respondents.length > 0 && (_jsxs(_Fragment, { children: [_jsx(ButtonLink, __assign({ onClick: function (e) { return handleSelectAll(e); } }, { children: isAllChecked
                                ? t("Administrate.Respondent.DeselectAll.Title")
                                : t("Administrate.Respondent.SelectAll.Title") })), _jsxs(Table, __assign({ style: { marginTop: 10, width: '100%' } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, { style: { width: 25 } }), _jsx(Th, { children: t("Administrate.Respondent.Respondent") }), _jsx(Th, { children: t("Administrate.Respondent.Import.ExistsOn") }), _jsx(ThRight, { children: t("Administrate.Respondent.Import.Suggestion") })] }) }), _jsx("tbody", { children: respondents.map(function (respondent, index) {
                                        var _a;
                                        return (_jsxs(Tr, { children: [_jsx(Td, { children: respondent.status !==
                                                        ImportRespondentStatus.Invalid && (_jsx("input", { type: "checkbox", onChange: function (e) {
                                                            return handleChange(e, respondent.email);
                                                        }, id: index + "_".concat(respondent.email), name: respondent.email, value: respondent.email, checked: !!respondent.include })) }), _jsxs(Td, { children: [!respondent.isDeleted && (_jsx("label", __assign({ htmlFor: index + "_".concat(respondent.email) }, { children: respondent.email }))), respondent.isDeleted && (_jsx("div", __assign({ className: "i", style: { color: theme.colors.dim } }, { children: t('Administrate.Respondent.DeletedRespondent') })))] }), _jsx(Td, { children: (_a = respondent.existsOn) !== null && _a !== void 0 ? _a : '-' }), _jsxs(TdRight, __assign({ style: {
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        borderTop: 'none',
                                                    } }, { children: [t("Administrate.Respondent.Import.Status.".concat(respondent.status), {
                                                            existsOn: respondent.existsOn,
                                                        }), _jsx(BsCircleFill, { style: {
                                                                marginLeft: 5,
                                                                color: importStatusToColor(respondent.status, theme),
                                                            } })] }))] }, index));
                                    }) })] }))] })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () {
                                setIsImporting(false);
                                setEntityIdImportRespondents('');
                            } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function () { return setIsImporting(true); }, disabled: respondents.filter(function (resp) { return resp.include; }).length === 0 ||
                                addIsLoading ||
                                deleteIsLoading }, { children: t('Administrate.Respondent.Button.SaveRespondents') }))] })] })) })) : null;
});
RespondentsImportList.displayName = 'RespondentsImportList';
var importStatusToColor = function (status, theme) {
    if (status === ImportRespondentStatus.Invalid) {
        return theme.colors.error;
    }
    if (status === ImportRespondentStatus.NotInSource) {
        return theme.colors.warning;
    }
    if (status === ImportRespondentStatus.ExistsOnAnotherEntity) {
        return theme.colors.info;
    }
    return theme.colors.success;
};
