var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel, BsSave } from 'react-icons/bs';
import styled from 'styled-components';
import { saveHtmlToPng, saveTableAsExcel } from '../../utils';
import { BadgeMini } from '../Badge';
import { HelpIcon } from '../HelpIcon';
import { TooltipContent } from '../tooltip';
export var CardBase = function (_a) {
    var title = _a.title, children = _a.children, canBeSavedAsPng = _a.canBeSavedAsPng, canBeSavedAsCsv = _a.canBeSavedAsCsv, helpText = _a.helpText, helpTextTitle = _a.helpTextTitle, titleStyle = _a.titleStyle, saveToPngElementId = _a.saveToPngElementId, innerStyle = _a.innerStyle;
    var id = useId();
    return (_jsxs(_Fragment, { children: [title && (_jsxs(CardHeader, { children: [_jsx("div", __assign({ style: titleStyle }, { children: title })), _jsx(CardToolbar, { title: title, canBeSavedAsCsv: canBeSavedAsCsv, canBeSavedAsPng: canBeSavedAsPng, helpText: helpText, helpTextTitle: helpTextTitle, saveToPngElementId: saveToPngElementId !== null && saveToPngElementId !== void 0 ? saveToPngElementId : id })] })), !title && (_jsx(CardToolbar, { title: title, canBeSavedAsCsv: canBeSavedAsCsv, canBeSavedAsPng: canBeSavedAsPng, helpText: helpText, helpTextTitle: helpTextTitle, saveToPngElementId: saveToPngElementId !== null && saveToPngElementId !== void 0 ? saveToPngElementId : id })), _jsx("div", __assign({ style: innerStyle, id: id }, { children: children }))] }));
};
export var CardStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n\n  margin: 5px 10px 15px 10px;\n\n  box-shadow: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-radius: 3px;\n\n  > div {\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n\n  margin: 5px 10px 15px 10px;\n\n  box-shadow: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-radius: 3px;\n\n  > div {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return props.width; }, function (props) { return props.height; }, function (_a) {
    var theme = _a.theme;
    return theme.size.viewMaxWidth;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
});
export var CardFocusedStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n"])), function (props) { return props.width; }, function (props) { return props.height; }, function (_a) {
    var theme = _a.theme;
    return theme.size.viewMaxWidth;
});
export var CardHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  padding: 10px 0 10px 10px;\n\n  > div:first-of-type {\n    font-weight: bold;\n  }\n\n  > div:last-of-type {\n    display: flex;\n    align-items: flex-end;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  padding: 10px 0 10px 10px;\n\n  > div:first-of-type {\n    font-weight: bold;\n  }\n\n  > div:last-of-type {\n    display: flex;\n    align-items: flex-end;\n  }\n"])));
export var CardToolbar = function (_a) {
    var _b;
    var title = _a.title, canBeSavedAsPng = _a.canBeSavedAsPng, canBeSavedAsCsv = _a.canBeSavedAsCsv, helpText = _a.helpText, helpTextTitle = _a.helpTextTitle, saveToPngElementId = _a.saveToPngElementId;
    var t = useTranslation().t;
    var id = useId();
    var displayTitle = (_b = helpTextTitle !== null && helpTextTitle !== void 0 ? helpTextTitle : title) !== null && _b !== void 0 ? _b : id;
    return (_jsxs(CardToolbarStyle, { children: [_jsx(HelpIcon, { title: displayTitle, text: helpText }), canBeSavedAsPng && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt_save_".concat(id), title: t('Common.Export.SaveAsPng'), text: t('Common.Export.SaveElementAsPng', { title: displayTitle }) }), _jsx(BadgeMini, __assign({ onClick: function () {
                            return saveHtmlToPng(saveToPngElementId !== null && saveToPngElementId !== void 0 ? saveToPngElementId : id, "".concat(displayTitle, ".png"));
                        }, "data-tip": true, "data-for": "tt_save_".concat(id) }, { children: _jsx(BsSave, {}) }))] })), canBeSavedAsCsv && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt_save_csv_".concat(id), title: t('Common.Export.SaveAsCsv'), text: t('Common.Export.SaveElementAsCsv', { title: displayTitle }) }), _jsx(BadgeMini, __assign({ onClick: function () {
                            return saveTableAsExcel(saveToPngElementId !== null && saveToPngElementId !== void 0 ? saveToPngElementId : id, displayTitle);
                        }, "data-tip": true, "data-for": "tt_save_csv_".concat(id) }, { children: _jsx(BsFileExcel, {}) }))] }))] }));
};
var CardToolbarStyle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  gap: 5px;\n  margin-right: 10px;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  gap: 5px;\n  margin-right: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
