var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import { HelpIcon, HelpIconLeft, } from '@dimatech/shared/lib/components/HelpIcon';
import { Input } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { QrCode } from '@dimatech/shared/lib/components/qr-code';
import { LinkWithTooltip, TooltipContent, } from '@dimatech/shared/lib/components/tooltip';
import { config } from '@dimatech/shared/lib/config';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { ScheduleStatus } from '@dimatech/shared/lib/models';
import { saveHtmlToPng } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsQrCode, BsSave } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGetEntitySurveyQuery } from '../../../api/entity/entitySurveyApi';
import { selectFilter } from '../../../api/filter/filterSlice';
import { datesToScheduleStatus } from '../../../utils';
export var AnonymousRespondents = function (_a) {
    var _b, _c, _d;
    var entitySurvey = _a.entitySurvey, entity = _a.entity, root = _a.root, isFetching = _a.isFetching, allowEntitySelectionForAnonymousUsers = _a.allowEntitySelectionForAnonymousUsers;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var _e = useState(null), status = _e[0], setStatus = _e[1];
    var _f = useState(), token = _f[0], setToken = _f[1];
    var _g = useState(false), isQrCodeShown = _g[0], setIsQrCodeShown = _g[1];
    var isQrCodesEnabledFlagOn = useFlags()[flags.permanent.shared.isQrCodesEnabled];
    var rootEntitySurvey = useGetEntitySurveyQuery(allowEntitySelectionForAnonymousUsers &&
        root &&
        root.id &&
        accessToken.customerId
        ? {
            entityId: root.id,
            _customerId: accessToken.customerId,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    useEffect(function () {
        var _a;
        if (entitySurvey) {
            setStatus(datesToScheduleStatus(entitySurvey.startDate, entitySurvey.endDate));
        }
        setToken((_a = rootEntitySurvey === null || rootEntitySurvey === void 0 ? void 0 : rootEntitySurvey.anonToken) !== null && _a !== void 0 ? _a : entitySurvey === null || entitySurvey === void 0 ? void 0 : entitySurvey.anonToken);
    }, [entitySurvey, rootEntitySurvey]);
    if (isFetching) {
        return (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: _jsx(Loader, { children: t('Common.UI.Loading') }) })));
    }
    return entitySurvey ? (_jsxs(Style, { children: [_jsxs(HelpIconLeft, { children: [t("Administrate.Entity.Survey.Text"), _jsx(HelpIcon, { title: t('Administrate.Entity.Survey.Text'), text: t('Administrate.Entity.Survey.Help'), style: { marginLeft: 10 }, isInverted: true })] }), status !== ScheduleStatus.Started && (_jsx(AlertInfo, __assign({ style: {
                    marginTop: 10,
                    marginBottom: 10,
                    whiteSpace: 'break-spaces',
                } }, { children: t("Administrate.Entity.Survey.TextNotStarted") }))), _jsxs("div", { children: [_jsxs("div", __assign({ style: {
                            marginTop: 0,
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                        } }, { children: [_jsx(Input, { style: { maxWidth: 400 }, value: "".concat(config.products.survey.url, "/").concat(isReadOnly
                                    ? "preview/".concat((_b = config.survey) === null || _b === void 0 ? void 0 : _b.id, "/").concat(accessToken.customerId)
                                    : "go/".concat(token)), readOnly: true }), _jsx(TooltipContent, { id: "tt-manual-invitation-link-copy", title: t('Administrate.Entity.Survey.CopyLink.TooltipTitle'), text: t('Administrate.Entity.Survey.CopyLink.Tooltip') }), _jsx(CopyToClipboard, { style: { marginLeft: 10 }, value: "".concat(config.products.survey.url, "/").concat(isReadOnly
                                    ? "preview/".concat((_c = config.survey) === null || _c === void 0 ? void 0 : _c.id, "/").concat(accessToken.customerId)
                                    : "go/".concat(token)), text: t('Administrate.Entity.Survey.CopyLink.Text'), tooltipId: "tt-manual-invitation-link-copy", isDisabled: !filter.instance.isCurrentInstance }), isQrCodesEnabledFlagOn && (_jsx(LinkWithTooltip, { style: { marginLeft: 10 }, title: !isQrCodeShown
                                    ? t('Administrate.Entity.Survey.ShowQrCode')
                                    : t('Administrate.Entity.Survey.HideQrCode'), tooltipTitle: t('Administrate.Entity.Survey.QrCode'), tooltip: !isQrCodeShown
                                    ? t('Administrate.Entity.Survey.ShowQrCode')
                                    : t('Administrate.Entity.Survey.HideQrCode'), handleClick: function () { return setIsQrCodeShown(!isQrCodeShown); }, disabledTimerInMs: 1, icon: _jsx(BsQrCode, {}), isDisabled: !filter.instance.isCurrentInstance }))] })), isQrCodesEnabledFlagOn && isQrCodeShown && (_jsxs("div", __assign({ style: { display: 'flex', gap: 10, marginTop: 10 } }, { children: [_jsx(QrCode, { id: "qr-code-".concat(entitySurvey.id), value: "".concat(config.products.survey.url, "/").concat(isReadOnly ? "preview/".concat((_d = config.survey) === null || _d === void 0 ? void 0 : _d.id) : "go/".concat(token)) }), _jsx(TooltipContent, { id: "tt_qr-code-".concat(entitySurvey.id), text: t('Administrate.Entity.Survey.SaveQrCodeAsPng') }), _jsx(BadgeMini, __assign({ onClick: function () {
                                    return saveHtmlToPng("qr-code-".concat(entitySurvey.id), "".concat(entity.name.toLowerCase(), "-qr.png"));
                                }, "data-tip": true, "data-for": "tt_qr-code-".concat(entitySurvey.id) }, { children: _jsx(BsSave, {}) }))] })))] })] })) : null;
};
AnonymousRespondents.displayName = 'AnonymousRespondents';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 20px;\n  margin-bottom: 10px;\n\n  padding: 10px;\n\n  background-color: ", ";\n"], ["\n  margin-top: 20px;\n  margin-bottom: 10px;\n\n  padding: 10px;\n\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
var templateObject_1;
