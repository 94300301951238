import { Breakpoints } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { PrincipleOptionByPrinciple, Question } from 'models';
import { useTranslation } from 'react-i18next';
import {
  BsArrowBarUp,
  BsClockHistory,
  BsDiagram3,
  BsGear,
  BsLightbulb,
  BsPeople,
} from 'react-icons/bs';
import styled from 'styled-components';
import { OurResultByQuestionAndOption } from './OurResultByQuestionAndOption';

export const OurResultBySubcategories = ({
  principlesByOption,
  questions,
}: {
  principlesByOption: PrincipleOptionByPrinciple[];
  questions: Question[];
}): JSX.Element => {
  const { t } = useTranslation();

  const efficiency = principlesByOption.filter((item) =>
    config.dimios.categories.digitalAbility.efficiency.principles.includes(
      parseInt(item.principleId)
    )
  );
  const innovation = principlesByOption.filter((item) =>
    config.dimios.categories.digitalAbility.innovation.principles.includes(
      parseInt(item.principleId)
    )
  );
  const balance = principlesByOption.filter((item) =>
    config.dimios.categories.digitalAbility.balance.principles.includes(
      parseInt(item.principleId)
    )
  );
  const organisation = principlesByOption.filter((item) =>
    config.dimios.categories.digitalHeritage.organisation.principles.includes(
      parseInt(item.principleId)
    )
  );
  const users = principlesByOption.filter((item) =>
    config.dimios.categories.digitalHeritage.users.principles.includes(
      parseInt(item.principleId)
    )
  );
  const technology = principlesByOption.filter((item) =>
    config.dimios.categories.digitalHeritage.technology.principles.includes(
      parseInt(item.principleId)
    )
  );

  return (
    <Style>
      <div>
        <div>
          <BsClockHistory />
          {t('CommonTerms.Efficiency')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={efficiency}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsLightbulb />
          {t('CommonTerms.Innovation')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={innovation}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsArrowBarUp />
          {t('CommonTerms.Balance')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={balance}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsDiagram3 />
          {t('CommonTerms.Organisation')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={organisation}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsPeople />
          {t('CommonTerms.Users')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={users}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsGear />
          {t('CommonTerms.Technology')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={technology}
          questions={questions}
          showLegend={true}
        />
      </div>
    </Style>
  );
};

OurResultBySubcategories.displayName = 'OurResultBySubcategories';

const Style = styled.div`
  padding-bottom: 10px;

  > div {
    display: flex;
    align-items: flex-start;

    > div:first-child {
      width: 100px;

      svg {
        margin-right: 10px;
      }
    }

    > div:last-child {
      flex: 1;
    }
  }

  > div:not(:last-child) {
    margin-bottom: -10px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    padding-bottom: 30px;
    > div {
      display: block;

      > div:first-child {
        width: auto;
      }
    }
  }
`;
