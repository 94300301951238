import {
  Table,
  TableResponsiveContainer,
  Th,
} from '@dimatech/shared/lib/components/table';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { config } from 'config';
import { Principle } from 'models';
import { Trans, useTranslation } from 'react-i18next';
import { MaturityStaircaseRow } from './MaturityStaircaseRow';

export const MaturityStaircase = ({
  principle,
}: {
  principle: Principle;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Heading4 style={{ marginBottom: 5, width: '50%' }}>
        {t(`MaturityStaircase.Title`, {
          principle: principle.name,
        })}
      </Heading4>

      <div style={{ marginBottom: 20, maxWidth: 1000 }}>
        <Trans
          i18nKey={`MaturityStaircase.Question.${principle.principleId}.Description`}
        />
      </div>

      <div style={{ marginBottom: 20, maxWidth: 1000 }}>
        <div className="b">{t(`MaturityStaircase.RelatedQuestion`)}</div>
        {t(
          `Survey.${config.survey?.id}.Question.${principle.principleId}.Label`
        )}
      </div>

      <TableResponsiveContainer>
        <Table>
          <thead>
            <tr>
              <Th style={{ width: 50 }}>{t(`MaturityStaircase.Level.Name`)}</Th>
              <Th style={{ maxWidth: 600 }}>
                {t(`MaturityStaircase.Description`)}
              </Th>
              <Th style={{ width: 200 }}>{t(`MaturityStaircase.Risks`)}</Th>
              <Th style={{ width: 250 }}>
                {t(`MaturityStaircase.Recommendation`)}
              </Th>
            </tr>
          </thead>
          <tbody>
            <MaturityStaircaseRow
              principleId={principle.principleId}
              level={1}
              isHighlighted={principle.maturityLevel === 1}
            />
            <MaturityStaircaseRow
              principleId={principle.principleId}
              level={2}
              isHighlighted={principle.maturityLevel === 2}
            />
            <MaturityStaircaseRow
              principleId={principle.principleId}
              level={3}
              isHighlighted={principle.maturityLevel === 3}
            />
            <MaturityStaircaseRow
              principleId={principle.principleId}
              level={4}
              isHighlighted={principle.maturityLevel === 4}
            />
          </tbody>
        </Table>
      </TableResponsiveContainer>
    </>
  );
};

MaturityStaircase.displayName = 'MaturityStaircase';
