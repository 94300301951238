import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdministrateStory,
  AdministrateStoryFilter,
  SortDirection,
} from 'models';

import { RootState } from 'store';

type State = {
  filter: AdministrateStoryFilter;
  selectedStory?: AdministrateStory;
  selectedStories?: AdministrateStory[];
};

const initialState: State = {
  filter: {
    includeOnlyInvolvedRespondents: false,
    paginator: {
      page: 1,
      pageSize: 50,
      orderBy: 'created',
      orderDirection: SortDirection.Asc,
    },
  },
};

export const { reducer: storyReducer, actions: storyActions } = createSlice({
  name: 'story',
  initialState,
  reducers: {
    selectStory: (
      state,
      action: PayloadAction<AdministrateStory | undefined>
    ) => {
      state.selectedStory = action.payload;
    },
    selectStories: (
      state,
      action: PayloadAction<AdministrateStory[] | undefined>
    ) => {
      state.selectedStories = action.payload;
    },
    setFilter: (state, action: PayloadAction<AdministrateStoryFilter>) => {
      state.filter = action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: {},
});

export const selectSelectedStory = (
  state: RootState
): AdministrateStory | undefined => state.story.selectedStory;

export const selectSelectedStories = (
  state: RootState
): AdministrateStory[] | undefined => state.story.selectedStories;

export const selectFilter = (state: RootState): AdministrateStoryFilter =>
  state.story.filter;
