var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useTranslation } from 'react-i18next';
import { RespondentsFailedMessagesList } from './components/RespondentsFailedMessagesList';
export var RespondentsFailedMessages = function (_a) {
    var handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(ViewHeader, __assign({ style: { justifyContent: 'space-between', paddingRight: 15 } }, { children: _jsx(Heading1, { children: t('Administrate.Respondent.FailedMessages.Title') }) })), _jsx(CustomerAccountInfo, {}), _jsx(Card, __assign({ width: "100%", innerStyle: { display: 'table', width: '100%' } }, { children: _jsxs(CardBody, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Respondent.FailedMessages.Text') })), _jsx(RespondentsFailedMessagesList, { handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted })] }) }))] }));
};
RespondentsFailedMessages.displayName = 'RespondentsFailedMessages';
