var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonFooterWithToast, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { CountryCode } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetBillingInfoQuery, useUpdateBillingInfoMutation, } from '../../../api/customer/customerApi';
import { validationBillingInfoAll, } from '../../authentication';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { RegisterBillingAddress } from '../../authentication/components';
export var BillingInfo = function () {
    var _a, _b, _c;
    var t = useTranslation().t;
    var _d = useState(), billing = _d[0], setBilling = _d[1];
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _e = useState(false), isChangesSaved = _e[0], setIsChangesSaved = _e[1];
    var _f = useState({}), validation = _f[0], setValidation = _f[1];
    var _g = useGetBillingInfoQuery((_a = accessToken.customerId) !== null && _a !== void 0 ? _a : skipToken), billingInfo = _g.data, isFetching = _g.isFetching, requestId = _g.requestId, refetch = _g.refetch;
    var _h = useUpdateBillingInfoMutation(), update = _h[0], error = _h[1].error;
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (billingInfo) {
            setBilling({
                street: (_a = billingInfo.street) !== null && _a !== void 0 ? _a : '',
                areaCode: (_b = billingInfo.areaCode) !== null && _b !== void 0 ? _b : '',
                city: (_c = billingInfo.city) !== null && _c !== void 0 ? _c : '',
                invoiceReference: (_d = billingInfo.invoiceReference) !== null && _d !== void 0 ? _d : '',
                invoiceEmail: (_e = billingInfo.invoiceEmail) !== null && _e !== void 0 ? _e : '',
            });
        }
    }, [billingInfo, requestId]);
    var handleSubmit = function (e) {
        var _a, _b;
        e.preventDefault();
        if (!billing) {
            return;
        }
        var billingInfoErrors = validationBillingInfoAll(billing, (_b = (_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.currentCustomer.countryCode) !== null && _b !== void 0 ? _b : CountryCode.SE, t);
        var isBillingInfoValid = !Object.keys(billingInfoErrors).some(function (key) { return billingInfoErrors[key] !== undefined; });
        setValidation(billingInfoErrors);
        if (!isBillingInfoValid) {
            return;
        }
        update(billing)
            .unwrap()
            .then(function () { return setIsChangesSaved(true); });
    };
    var handleCancel = function () {
        refetch();
    };
    if (isFetching) {
        return _jsx(LoaderSmall, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(Style, __assign({ onSubmit: handleSubmit }, { children: [error && (_jsx(AlertError, __assign({ style: { marginTop: 10 } }, { children: error.message }))), billing && (_jsxs(_Fragment, { children: [_jsx(RegisterBillingAddress, { countryCode: (_c = (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.currentCustomer.countryCode) !== null && _c !== void 0 ? _c : CountryCode.SE, billing: billing, setBilling: setBilling, validation: validation, setValidation: setValidation }), _jsx(ButtonFooterWithToast, __assign({ isSaved: isChangesSaved, setIsSaved: setIsChangesSaved }, { children: _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "submit" }, { children: t('Common.Form.Save') }))] }) }))] }))] })));
};
BillingInfo.displayName = 'BillingInfo';
var Style = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > section {\n    > div {\n      width: 100%;\n      display: flex;\n      align-items: flex-end;\n\n      > div {\n        width: 50%;\n      }\n\n      > div:first-of-type {\n        padding-right: 20px;\n      }\n    }\n  }\n"], ["\n  > section {\n    > div {\n      width: 100%;\n      display: flex;\n      align-items: flex-end;\n\n      > div {\n        width: 50%;\n      }\n\n      > div:first-of-type {\n        padding-right: 20px;\n      }\n    }\n  }\n"])));
var templateObject_1;
