import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { Theme } from '@dimatech/shared/lib/themes';
import { ChartTooltipSplit } from 'components/ChartTooltipSplit';
import { PrinciplesByEntity } from 'models';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const ChildResult = withTheme(
  ({
    theme,
    principlesByEntity,
  }: {
    theme: Theme;
    principlesByEntity: PrinciplesByEntity[];
  }): JSX.Element => {
    const { t } = useTranslation();

    const data = principlesByEntity.map((entity) => ({
      value: entity.percentage,
      label: entity.entityName,
    }));

    return (
      <Chart style={{ width: 500 }}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 70,
            bottom: 5,
          }}
        >
          <CartesianGrid
            stroke={theme.colors.chart.gridStroke}
            strokeWidth={0.5}
          />

          <XAxis
            type="number"
            domain={[0, 100]}
            tickLine={false}
            stroke={theme.colors.chart.axisStroke}
            tickFormatter={(value: string) => formatAsPercent(value, '0')}
          >
            <Label
              value={t('CommonTerms.DigitalMaturity') as string}
              position="insideBottom"
              offset={-5}
              fill={theme.colors.onSurface}
            />
          </XAxis>

          <YAxis
            tickLine={false}
            stroke={theme.colors.chart.axisStroke}
            dataKey="label"
            type="category"
            tick={({ x, y, payload }) => {
              const ellipsizedValue =
                payload.value.length > 23
                  ? payload.value.substring(0, 20) + '...'
                  : payload.value;
              return (
                <Text
                  fill={theme.colors.onSurface}
                  dy={-2}
                  x={x}
                  y={y}
                  textAnchor="end"
                  verticalAnchor="start"
                >
                  {ellipsizedValue}
                </Text>
              );
            }}
          />

          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<ChartTooltipSplit />}
          />

          <Bar dataKey="value" name={t('CommonTerms.DigitalMaturity')}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  theme.colors.chart.colors[
                    index % theme.colors.chart.colors.length
                  ]
                }
                stroke={
                  theme.colors.chart.colors[
                    index % theme.colors.chart.colors.length
                  ]
                }
              />
            ))}
          </Bar>
        </BarChart>
      </Chart>
    );
  }
);

ChildResult.displayName = 'ChildResult';
