var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency } from '../../utils';
export var HeatmapTooltip = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var item = _a.item, config = _a.config;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [config.tooltip.title && (_jsxs("div", __assign({ className: "tooltip-title" }, { children: [config.tooltip.title, item.items && ": ".concat(item.items.length)] }))), config.tooltip.labelX && (_jsxs("div", { children: [config.tooltip.labelX, ": ", (_c = (_b = config.tooltip).formatterX) === null || _c === void 0 ? void 0 : _c.call(_b, item.x)] })), config.tooltip.labelY && (_jsxs("div", { children: [config.tooltip.labelY, ": ", (_e = (_d = config.tooltip).formatterY) === null || _e === void 0 ? void 0 : _e.call(_d, item.y)] })), config.tooltip.labelZ && (_jsxs("div", { children: [config.tooltip.labelZ, ": ", (_g = (_f = config.tooltip).formatterZ) === null || _g === void 0 ? void 0 : _g.call(_f, item.z)] })), config.tooltip.showDetails && (_jsxs("div", { children: [item.items &&
                        item.items.slice(0, 4).map(function (data, index) {
                            var _a, _b, _c, _d;
                            return (_jsxs(Fragment, { children: [_jsx("div", __assign({ className: "tooltip-subtitle" }, { children: data.label })), data.info && _jsx("div", __assign({ className: "tooltip-info" }, { children: data.info })), config.tooltip.labelZ && (_jsxs("div", { children: [config.tooltip.labelZ, ": ", formatAsCurrency(data.z)] })), config.tooltip.showDetailsX && (_jsxs("div", { children: [config.tooltip.labelX, ":", ' ', (_b = (_a = config.tooltip).formatterDetailsX) === null || _b === void 0 ? void 0 : _b.call(_a, data.x)] })), config.tooltip.showDetailsY && (_jsxs("div", { children: [config.tooltip.labelY, ":", ' ', (_d = (_c = config.tooltip).formatterDetailsY) === null || _d === void 0 ? void 0 : _d.call(_c, data.y)] }))] }, index));
                        }), item.items && item.items.length > 4 && (_jsx("div", __assign({ style: { paddingTop: 15 } }, { children: t('Common.UI.AndXMore', { count: item.items.length - 4 }) })))] }))] }));
};
HeatmapTooltip.displayName = 'HeatmapTooltip';
