var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetFailedMessagesQuery } from '../../../api/survey/surveyApi';
export var RespondentsFailedMessagesBanner = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _a = useGetFailedMessagesQuery(accessToken.customerId
        ? {
            _customerId: accessToken.customerId,
            paginator: {
                page: 1,
                pageSize: 25,
                orderBy: 'email',
                orderDirection: SortDirection.Asc,
            },
        }
        : skipToken), data = _a.data, isFetching = _a.isFetching;
    var handleDisplayFailedMessages = function () {
        navigate('/administrate/respondents-failed-messages');
    };
    return !isFetching && data && data.totalRecords > 0 ? (_jsx(AlertError, __assign({ style: { marginBottom: 20 } }, { children: _jsx(ButtonLink, __assign({ onClick: handleDisplayFailedMessages }, { children: t('Administrate.Respondent.FailedMessages.BannerText', {
                count: data.totalRecords,
            }) })) }))) : null;
};
RespondentsFailedMessagesBanner.displayName = 'RespondentsFailedMessagesBanner';
