var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Pagination, useSortablePaginator, } from '@dimatech/shared/lib/components/paginator';
import { Table, TableResponsiveContainer, Td, TdRight, Th, Tr, } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { SortDirection, } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory } from 'react-icons/bs';
import { useUpdateSurveyRespondentMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { useLazyGetFailedMessagesQuery } from '../../../api/survey/surveyApi';
import { RespondentActions } from './RespondentActions';
import { RespondentCopyLinkButton } from './RespondentCopyLinkButton';
import { RespondentMessageHistory } from './RespondentMessageHistory';
import { RespondentMessageStatus } from './RespondentMessageStatus';
import { RespondentSurveyStatus } from './RespondentSurveyStatus';
import { RespondentUpdate } from './RespondentUpdate';
export var RespondentsFailedMessagesList = function (_a) {
    var handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var customerId = accessToken.customerId;
    var _b = useUpdateSurveyRespondentMutation(), updateRespondent = _b[0], updateError = _b[1].error;
    var _c = useState(null), isShowingMessagesForRespondent = _c[0], setIsShowingMessagesForRespondent = _c[1];
    var _d = useState(null), isUpdatingRespondent = _d[0], setIsUpdatingRespondent = _d[1];
    var _e = useState(), error = _e[0], setError = _e[1];
    var _f = useState(), existsError = _f[0], setExistsErrors = _f[1];
    var _g = useState([]), respondents = _g[0], setRespondents = _g[1];
    var initialPaginator = {
        page: 1,
        pageSize: 50,
        orderBy: 'email',
        orderDirection: SortDirection.Asc,
    };
    var _h = useLazyGetFailedMessagesQuery(), getFailedMessages = _h[0], _j = _h[1], data = _j.data, isFetching = _j.isFetching;
    var handleGetFailedMessages = function (paginator) {
        if (paginator === void 0) { paginator = initialPaginator; }
        if (accessToken.customerId) {
            getFailedMessages({
                paginator: paginator,
                _customerId: accessToken.customerId,
            }, true);
        }
    };
    var _k = useSortablePaginator(__assign(__assign({}, initialPaginator), { handlePaginatorChange: handleGetFailedMessages })), setPage = _k.setPage, paginator = _k.paginator, sorter = _k.sorter;
    useEffect(function () {
        handleGetFailedMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);
    useEffect(function () {
        var _a;
        setRespondents((_a = data === null || data === void 0 ? void 0 : data.records) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    var handleUpdateRespondent = function (email, newEmail, entityId) {
        setError(undefined);
        setExistsErrors(undefined);
        updateRespondent({
            entityId: entityId,
            email: email,
            newEmail: newEmail,
        });
    };
    if (isFetching) {
        return (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: _jsx(Loader, { children: t('Common.UI.Loading') }) })));
    }
    return (_jsxs(_Fragment, { children: [_jsx(AlertErrors, { error: error !== null && error !== void 0 ? error : updateError, translationPath: "Administrate.Respondent.ValidationError" }), existsError && (_jsx(AlertWarning, __assign({ style: { marginTop: 10, width: '100%' } }, { children: existsError }))), respondents.length === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('Administrate.Respondent.FailedMessages.NoFailedMessages') }))), isUpdatingRespondent && (_jsx(RespondentUpdate, { email: isUpdatingRespondent.email, entityId: isUpdatingRespondent.entityId, setIsUpdatingRespondent: setIsUpdatingRespondent, handleUpdateRespondent: handleUpdateRespondent })), isShowingMessagesForRespondent && (_jsx(RespondentMessageHistory, { entityName: isShowingMessagesForRespondent.entityName, respondent: isShowingMessagesForRespondent, setIsShowingMessagesForRespondent: function (responent) {
                    return setIsShowingMessagesForRespondent(responent ? isShowingMessagesForRespondent : null);
                } })), _jsx(TooltipContent, { id: "tt-message-history", title: t('Administrate.Respondent.Messages.Title'), text: t('Administrate.Respondent.Messages.Tooltip') }), _jsx(TooltipContent, { id: "tt-link-copy", title: t('Administrate.Respondent.CopyLink.TooltipTitle'), text: t('Administrate.Respondent.CopyLink.Tooltip') }), _jsx(Pagination, { currentPage: paginator.page, totalCount: respondents.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } }), respondents && respondents.length > 0 && (_jsx(TableResponsiveContainer, { children: _jsxs(Table, __assign({ style: { marginTop: 10 } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, __assign({ sortKey: "email", sorter: sorter }, { children: t('Administrate.Respondent.Respondent') })), _jsx(Th, __assign({ sortKey: "entityName", sorter: sorter }, { children: t('Administrate.Respondent.Entity') })), _jsx(Th, __assign({ sortKey: "status", sorter: sorter }, { children: t('Administrate.Respondent.SurveyStatus.Title') })), _jsx(Th, { children: t('Administrate.Respondent.Messages.Title') }), _jsx(Th, {}), _jsx(Th, {})] }) }), _jsx("tbody", { children: respondents.map(function (respondent, index) {
                                var _a;
                                var latestMessage = (_a = __spreadArray([], respondent.messages, true).sort(function (a, b) {
                                    return a.sentDate > b.sentDate ? -1 : 1;
                                })) === null || _a === void 0 ? void 0 : _a[0];
                                return (_jsxs(Tr, { children: [_jsx(Td, __assign({ style: {
                                                whiteSpace: 'nowrap',
                                            } }, { children: respondent.email })), _jsx(Td, __assign({ style: {
                                                whiteSpace: 'nowrap',
                                            } }, { children: respondent.entityName })), _jsx(Td, __assign({ style: {
                                                whiteSpace: 'nowrap',
                                            } }, { children: _jsx(RespondentSurveyStatus, { status: respondent.status }) })), _jsx(Td, { children: latestMessage && (_jsxs("div", __assign({ style: {
                                                    verticalAlign: 'middle',
                                                    display: 'flex',
                                                    alignItems: 'baseline',
                                                    whiteSpace: 'nowrap',
                                                } }, { children: [_jsx(RespondentMessageStatus, { status: latestMessage.status, error: latestMessage.error }), _jsx("span", __assign({ style: { marginRight: 10, marginLeft: 10 } }, { children: latestMessage.sentDate &&
                                                            formatDate(new Date(latestMessage.sentDate)) })), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-message-history", onClick: function () {
                                                            return setIsShowingMessagesForRespondent(respondent);
                                                        } }, { children: _jsx(BsClockHistory, {}) }))] }))) }), _jsx(TdRight, __assign({ style: { width: 150, whiteSpace: 'nowrap' } }, { children: _jsx(RespondentCopyLinkButton, { respondent: respondent }) })), _jsx(TdRight, __assign({ style: {
                                                verticalAlign: 'middle',
                                                display: 'flex',
                                                justifyContent: 'end',
                                            } }, { children: _jsx(RespondentActions, { isReadOnly: isReadOnly, respondent: respondent, selectedEntityId: respondent.entityId, setIsUpdatingRespondent: setIsUpdatingRespondent, setError: setError, isMoveEnabled: false, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted }) }))] }, index));
                            }) })] })) })), _jsx(Pagination, { currentPage: paginator.page, totalCount: respondents.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } })] }));
};
RespondentsFailedMessagesList.displayName = 'RespondentsFailedMessagesList';
