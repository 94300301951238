import chroma from 'chroma-js';
// Documentation: https://gka.github.io/chroma.js/#chroma-scale
export var scaler = function (config) {
    var _a, _b;
    return chroma
        .scale([config.colorFrom, config.colorTo])
        .mode((_a = config.mode) !== null && _a !== void 0 ? _a : 'lrgb') // or 'lab'
        .padding([(_b = config.min) !== null && _b !== void 0 ? _b : 0, 0])
        .domain([config.start, config.end]);
};
export var rgbToHex = function (values) {
    return chroma(values).hex();
};
export var hexToRgb = function (color) {
    return chroma(color).rgb();
};
/**
 * Takes any string and creates a unique color from it
 * that is always the same for the same string
 * @param str
 * @returns
 */
export var stringToColor = function (str) {
    var hash = 0;
    str.split('').forEach(function (char) {
        // eslint-disable-next-line no-bitwise
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    var color = '#';
    for (var i = 0; i < 3; i++) {
        // eslint-disable-next-line no-bitwise
        var value = (hash >> (i * 8)) & 0xff;
        color += value.toString(16).padStart(2, '0');
    }
    return color;
};
