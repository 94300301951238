import { GlobalAdministrateReports as GlobalAdministrateReportsFeature } from 'features/global-administrate-reports';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const GlobalAdministrateReports = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('GlobalAdministrate.Reports.Title')}>
      <GlobalAdministrateReportsFeature />
    </View>
  );
};

GlobalAdministrateReports.displayName = 'GlobalAdministrateReports';
