var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { formatDate } from '@dimatech/shared/lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowUpCircle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { appActions } from '../../../api/appSlice';
export var ReleaseNote = function (_a) {
    var meta = _a.meta, children = _a.children;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(appActions.appendReleaseNotesVersion(meta.version));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta.version]);
    return (_jsxs(Style, __assign({ id: meta.version, isPlanned: !meta.releaseDate || meta.releaseDate > new Date() }, { children: [_jsx(Heading2, { children: meta.version }), _jsxs("div", { children: [meta.releaseDate &&
                        meta.releaseDate <= new Date() &&
                        formatDate(meta.releaseDate, 'date'), !meta.releaseDate && _jsx("i", { children: t('ReleaseNotes.Planned') }), meta.releaseDate && meta.releaseDate > new Date() && (_jsx("i", { children: t('ReleaseNotes.Upcoming', {
                            releaseDate: formatDate(meta.releaseDate, 'date'),
                        }) }))] }), _jsx("div", { children: children }), _jsx("div", { children: _jsx(LinkWithTooltip, { handleClick: function () {
                        window.scrollTo(0, 0);
                    }, icon: _jsx(BsArrowUpCircle, {}), title: t('ReleaseNotes.ScrollToTop'), isInverted: true, disabledTimerInMs: 1 }) })] })));
};
ReleaseNote.displayName = 'ReleaseNote';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px;\n  padding-bottom: 30px;\n  margin-bottom: 20px;\n\n  border-bottom: 1px solid ", ";\n\n  > div {\n    margin-top: 10px;\n  }\n\n  > div:first-of-type {\n    margin-top: 0;\n  }\n\n  > div:last-of-type {\n    margin-top: 20px;\n  }\n\n  img {\n    display: block;\n    max-width: 500px;\n    margin: 10px;\n  }\n\n  ", "\n"], ["\n  padding: 10px;\n  padding-bottom: 30px;\n  margin-bottom: 20px;\n\n  border-bottom: 1px solid ", ";\n\n  > div {\n    margin-top: 10px;\n  }\n\n  > div:first-of-type {\n    margin-top: 0;\n  }\n\n  > div:last-of-type {\n    margin-top: 20px;\n  }\n\n  img {\n    display: block;\n    max-width: 500px;\n    margin: 10px;\n  }\n\n  ", "\n"])), function (props) { return props.theme.colors.primary; }, function (props) {
    return props.isPlanned &&
        "\n    padding: 20px;\n    color: ".concat(props.theme.colors.onBackground, ";\n    background-color: ").concat(props.theme.colors.background, ";\n    ");
});
var templateObject_1;
