import { GlobalAdministrateOverview as GlobalAdministrateOverviewFeature } from '@dimatech/features-survey/lib/features/global-administrate-overview';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const GlobalAdministrateOverview = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('GlobalAdministrate.Overview.Title')}>
      <GlobalAdministrateOverviewFeature />
    </View>
  );
};

GlobalAdministrateOverview.displayName = 'GlobalAdministrateOverview';
