import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { config } from '@dimatech/shared/lib/config';
import {
  useApproveStoryMutation,
  useDisapproveStoryMutation,
  useUpdateStoryMutation,
} from 'api/stories/storiesApi';

import { selectSelectedStory, storyActions } from 'api/stories/storySlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AdministrateStory } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const StoryEdit = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedStory = useAppSelector(selectSelectedStory);

  const [updateStory, { isLoading: updatePosting }] = useUpdateStoryMutation();

  const [hasChanges, setHasChanges] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [story, setStory] = useState<AdministrateStory>();
  const [isValid, setIsValid] = useState(true);
  const [isTextValid, setIsTextValid] = useState(true);

  const [approve] = useApproveStoryMutation();
  const [disapprove] = useDisapproveStoryMutation();

  const handleCancel = () => {
    dispatch(storyActions.selectStory());
  };

  const handleSave = () => {
    setIsValid(true);
    setIsTextValid(true);

    const limitInBytes = config.ui.textEditor.maxBytes;
    const textBytes = new TextEncoder().encode(story?.value);

    if (!story?.value) {
      setIsValid(false);
      return;
    }

    if (textBytes.length > limitInBytes) {
      setIsTextValid(false);
      return;
    }

    if (story) {
      updateStory(story)
        .unwrap()
        .then((story) => {
          dispatch(storyActions.selectStory(story));
        });
    }

    setHasChanges(false);

    dispatch(storyActions.selectStory());
  };

  const handleApprove = () => {
    if (!story) {
      return;
    }
    approve([story.id])
      .unwrap()
      .then(() => {
        dispatch(storyActions.selectStory({ ...story, approved: true }));
      });
    dispatch(storyActions.selectStory());
  };

  const handleDisapprove = () => {
    if (!story) {
      return;
    }
    disapprove([story.id]).then(() => {
      dispatch(storyActions.selectStory({ ...story, approved: false }));
    });
    dispatch(storyActions.selectStory());
  };

  useEffect(() => {
    setStory(selectedStory);
    setIsValid(true);
    setIsTextValid(true);
  }, [selectedStory]);

  return (
    <Tr style={{ height: 55 }}>
      <Td colSpan={7} style={{ padding: 0 }}>
        {story && (
          <>
            {!isTextValid && (
              <AlertWarning style={{ marginTop: 10 }}>
                {t(`TextEditor.ValidationError.LimitExceeded`)}
              </AlertWarning>
            )}

            <TextEditorStyle>
              <TextEditor
                value={story.value ?? ''}
                setValue={(val) => {
                  setHasChanges(true);
                  setStory({ ...story, value: val ? val : '' });
                }}
                messageId={story?.id}
                allowLinks={false}
                invalid={!isValid && !story?.value}
                validateHtmlIsEmpty={true}
              />
            </TextEditorStyle>

            <ButtonFooterWithToast
              isSaved={isChangesSaved}
              setIsSaved={setIsChangesSaved}
              style={{ marginBottom: '50px', padding: '0 20px' }}
            >
              <Buttons style={{ justifyContent: 'flex-start' }}>
                {!story.approved && (
                  <Button
                    type="button"
                    onClick={handleApprove}
                    style={{ marginLeft: 0 }}
                  >
                    {t('Administrate.Stories.Buttons.Approve')}
                  </Button>
                )}

                {story.approved && (
                  <ButtonSecondary
                    type="button"
                    onClick={handleDisapprove}
                    style={{ marginLeft: 0 }}
                  >
                    {t('Administrate.Stories.Buttons.Disapprove')}
                  </ButtonSecondary>
                )}
              </Buttons>

              <Buttons>
                <ButtonSecondary type="button" onClick={handleCancel}>
                  {t('Common.Form.Cancel')}
                </ButtonSecondary>
                <Button
                  onClick={handleSave}
                  type="button"
                  disabled={updatePosting || !hasChanges}
                >
                  {t('Common.Form.Save')}
                </Button>
              </Buttons>
            </ButtonFooterWithToast>
          </>
        )}
      </Td>
    </Tr>
  );
};

StoryEdit.displayName = 'StoryEdit';

const TextEditorStyle = styled.div`
  > div {
    padding: 20px;
  }
`;
