var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, Td, Tr } from '@dimatech/shared/lib/components/table';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { useGetEntityMessageTemplatesQuery } from '../../../api/message-template/messageTemplateApi';
import { messageTemplateActions, selectSelectedMessageTemplate, } from '../../../api/message-template/messageTemplateSlice';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
export var MessageTemplatesList = function () {
    var t = useTranslation().t;
    var selectedEntity = useSelector(selectSelectedEntity);
    var selectedMessageTemplate = useSelector(selectSelectedMessageTemplate);
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var messageTemplates = useGetEntityMessageTemplatesQuery((selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) && accessToken.customerId
        ? {
            filter: { entityId: selectedEntity.id, locale: 'se' },
            _customerId: accessToken.customerId,
        }
        : skipToken).data;
    var handleSelect = function (messageTemplate) {
        dispatch(messageTemplateActions.selectedMessageTemplate(messageTemplate));
    };
    useEffect(function () {
        dispatch(messageTemplateActions.selectedMessageTemplate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntity]);
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, __assign({ style: { marginBottom: 10 } }, { children: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.name })), selectedEntity && (messageTemplates === null || messageTemplates === void 0 ? void 0 : messageTemplates.length) === 0 && (_jsx(_Fragment, { children: _jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.MessageTemplate.NoMessageTemplates', {
                        name: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.name,
                    }) })) })), (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) && messageTemplates && (messageTemplates === null || messageTemplates === void 0 ? void 0 : messageTemplates.length) > 0 && (_jsx(Table, { children: _jsx("tbody", { children: messageTemplates === null || messageTemplates === void 0 ? void 0 : messageTemplates.map(function (messageTemplate, index) { return (_jsxs(Tr, __assign({ onSelect: function () { return handleSelect(messageTemplate); }, isSelected: messageTemplate.messageTemplateId ===
                            (selectedMessageTemplate === null || selectedMessageTemplate === void 0 ? void 0 : selectedMessageTemplate.messageTemplateId) }, { children: [_jsx(Td, __assign({ style: { width: 25 } }, { children: _jsx(BsFillEnvelopeFill, {}) })), _jsx(Td, { children: t("Administrate.MessageTemplate.MessageTemplateType.".concat(messageTemplate.messageTemplateType)) })] }), index)); }) }) }))] }));
};
MessageTemplatesList.displayName = 'MessageTemplateList';
