import { BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { Td, TdCentered, Tr } from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import { Trans } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

export const MaturityStaircaseRow = withTheme(
  ({
    principleId,
    level,
    isHighlighted,
    theme,
  }: {
    principleId: string;
    level: number;
    isHighlighted: boolean;
    theme: Theme;
  }) => {
    return (
      <RowStyle>
        <TdCentered>
          <BadgeSmall
            style={{
              cursor: 'default',
              ...(isHighlighted
                ? {
                    color: theme.colors.onPrimary,
                    backgroundColor: theme.colors.primary,
                  }
                : undefined),
            }}
          >
            {level}
          </BadgeSmall>
        </TdCentered>
        <Td style={{ maxWidth: 600 }}>
          <Trans
            i18nKey={`MaturityStaircase.Question.${principleId}.${level}.Description`}
          />
        </Td>
        <Td>
          <Trans
            i18nKey={`MaturityStaircase.Question.${principleId}.${level}.Risks`}
          />
        </Td>
        <Td>
          <Trans
            i18nKey={`MaturityStaircase.Question.${principleId}.${level}.Recommendation`}
          />
        </Td>
      </RowStyle>
    );
  }
);

MaturityStaircaseRow.displayName = 'MaturityStaircaseRow';

const RowStyle = styled(Tr)`
  td > p:first-of-type {
    margin-top: 0;
  }
`;
