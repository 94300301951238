var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsMessage, featuresCoreApi } from '../featuresCoreApi';
var messageApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get user messages
         */
        getUserMessages: build.query({
            query: function (_customerId) { return "messages"; },
            providesTags: [CacheTagsMessage.Message],
            transformResponse: function (result) {
                return result.sort(function (a, b) { var _a, _b; return ((_a = a.publishDate) !== null && _a !== void 0 ? _a : '') > ((_b = b.publishDate) !== null && _b !== void 0 ? _b : '') ? -1 : 1; });
            },
        }),
        /**
         * Get user message recipients
         */
        getRecipients: build.query({
            query: function (_a) {
                var userMessageId = _a.userMessageId;
                return "messages/".concat(userMessageId, "/recipients");
            },
            providesTags: [CacheTagsMessage.MessageRecipients],
            transformResponse: function (result) {
                return result.sort(function (a, b) { var _a, _b; return ((_a = a.email) !== null && _a !== void 0 ? _a : '') > ((_b = b.email) !== null && _b !== void 0 ? _b : '') ? 1 : -1; });
            },
        }),
        /**
         * Create new message
         */
        addMessage: build.mutation({
            query: function (_a) {
                var message = _a.message, userIds = _a.userIds;
                return ({
                    url: "messages",
                    method: 'POST',
                    body: {
                        title: message.title,
                        text: message.text,
                        publishDate: message.publishDate,
                        userIds: userIds,
                    },
                });
            },
            invalidatesTags: [CacheTagsMessage.Message],
            transformResponse: function (result, _meta, arg) {
                return __assign(__assign({}, arg.message), result);
            },
        }),
        /**
         * Update message
         */
        updateMessage: build.mutation({
            query: function (_a) {
                var message = _a.message;
                return ({
                    url: "messages/".concat(message.id),
                    method: 'PUT',
                    body: __assign({}, message),
                });
            },
            invalidatesTags: [CacheTagsMessage.Message],
            transformResponse: function (_result, _meta, arg) {
                return __assign({}, arg.message);
            },
        }),
        /**
         * Delete message
         */
        deleteMessage: build.mutation({
            query: function (_a) {
                var userMessageId = _a.userMessageId;
                return ({
                    url: "messages/".concat(userMessageId),
                    method: 'DELETE',
                });
            },
            invalidatesTags: [CacheTagsMessage.Message],
        }),
        /**
         * Add recipients to message
         */
        addRecipient: build.mutation({
            query: function (_a) {
                var userMessageId = _a.userMessageId, userIds = _a.userIds;
                return ({
                    url: "messages/".concat(userMessageId, "/recipients"),
                    method: 'PUT',
                    body: {
                        userIds: userIds,
                    },
                });
            },
            invalidatesTags: [CacheTagsMessage.MessageRecipients],
        }),
        /**
         * Delete recipient
         */
        deleteRecipient: build.mutation({
            query: function (_a) {
                var userMessageId = _a.userMessageId, userIds = _a.userIds;
                return ({
                    url: "messages/".concat(userMessageId, "/recipients"),
                    method: 'DELETE',
                    body: {
                        userIds: userIds,
                    },
                });
            },
            invalidatesTags: [CacheTagsMessage.MessageRecipients],
        }),
    }); },
});
export var useGetUserMessagesQuery = messageApi.useGetUserMessagesQuery, useGetRecipientsQuery = messageApi.useGetRecipientsQuery, useLazyGetUserMessagesQuery = messageApi.useLazyGetUserMessagesQuery, useUpdateMessageMutation = messageApi.useUpdateMessageMutation, useAddMessageMutation = messageApi.useAddMessageMutation, useAddRecipientMutation = messageApi.useAddRecipientMutation, useDeleteRecipientMutation = messageApi.useDeleteRecipientMutation, useDeleteMessageMutation = messageApi.useDeleteMessageMutation;
