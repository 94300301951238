import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SurveyResponseStatus } from '@dimatech/shared/lib/models';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { withTheme } from 'styled-components';
export var RespondentSurveyStatus = withTheme(function (_a) {
    var theme = _a.theme, status = _a.status;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(BsCircleFill, { style: { marginRight: 5, fontSize: theme.fonts.size.s }, color: status === SurveyResponseStatus.Finished
                    ? theme.colors.success
                    : status === SurveyResponseStatus.Opened
                        ? theme.colors.warning
                        : theme.colors.error }), t("Administrate.Respondent.SurveyStatus.".concat(status === SurveyResponseStatus.Finished
                ? 'Completed'
                : status === SurveyResponseStatus.Opened
                    ? 'Opened'
                    : 'NotCompleted'))] }));
});
RespondentSurveyStatus.displayName = 'RespondentSurveyStatus';
