var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../AuthenticationProvider';
export var UserInfo = function () {
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    if (!accessToken.user) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, __assign({ style: { marginBottom: 15 } }, { children: t('UserInfo.Title') })), _jsx("div", { children: accessToken.user.email }), _jsx("div", __assign({ style: { marginBottom: 15 } }, { children: accessToken.user.currentCustomer.name })), _jsx("strong", { children: t('UserInfo.Roles') }), _jsx("div", { children: accessToken.user.currentCustomer.roles
                    .map(function (role) { return t("Roles.".concat(role)); })
                    .join(', ') })] }));
};
UserInfo.displayName = 'UserInfo';
