var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonLink, DatePicker } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3, Heading4 } from '@dimatech/shared/lib/components/typography';
import { formatAsNumber, formatDate, groupBy, toTitleCase, } from '@dimatech/shared/lib/utils';
import { add, formatISO, isPast, parseISO, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsAlarm, BsEnvelope } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useGetPlanningQuery } from '../../../api/account/accountApi';
import { MessageType } from '../../../models';
export var Planning = withTheme(function (_a) {
    var theme = _a.theme;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _b = useState(), items = _b[0], setItems = _b[1];
    var _c = useState(formatISO(sub(new Date(), { days: 2 }), {
        representation: 'date',
    })), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(formatISO(add(new Date(), { days: 30 }), {
        representation: 'date',
    })), endDate = _d[0], setEndDate = _d[1];
    var _e = useGetPlanningQuery({
        startDate: startDate,
        endDate: endDate,
    }), data = _e.data, isFetching = _e.isFetching;
    useEffect(function () {
        if (!data) {
            return;
        }
        var groupedResult = groupBy(data, function (message) {
            return formatISO(parseISO(message.date), { representation: 'date' });
        });
        var plannedMessages = Object.keys(groupedResult)
            .sort(function (a, b) { return (a > b ? 1 : -1); })
            .map(function (key) {
            var groupedMessages = groupBy(groupedResult[key].items, function (message) { return message.customerId; });
            var customers = Object.keys(groupedMessages).map(function (key) {
                var items = groupedMessages[key].items.sort(function (a, b) {
                    return a.entityName > b.entityName ? 1 : -1;
                });
                return {
                    customerId: key,
                    customerName: items[0].customerName,
                    items: items,
                    count: items.reduce(function (value, item) { return value + item.count; }, 0),
                };
            });
            return {
                date: parseISO(key),
                customers: customers,
                count: customers.reduce(function (value, item) { return value + item.count; }, 0),
            };
        });
        setItems(plannedMessages);
    }, [data]);
    return (_jsxs(_Fragment, { children: [_jsxs(HeaderStyle, { children: [_jsx(Heading3, { children: t('GlobalAdministrate.Overview.Planning.Title') }), _jsx(DatePicker, { name: "startDate", date: startDate, setDate: function (date) {
                            if (date) {
                                setStartDate(formatISO(date, {
                                    representation: 'date',
                                }));
                            }
                        } }), _jsx(DatePicker, { name: "endDate", date: endDate, setDate: function (date) {
                            if (date) {
                                setEndDate(formatISO(date, {
                                    representation: 'date',
                                }));
                            }
                        } })] }), isFetching && _jsx(LoaderSmall, { children: t('Common.UI.Loading') }), !isFetching && (items === null || items === void 0 ? void 0 : items.length) === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('GlobalAdministrate.Overview.Planning.NoItems') }))), _jsx(Style, { children: !isFetching &&
                    (items === null || items === void 0 ? void 0 : items.map(function (item, index) { return (_jsxs("div", __assign({ style: {
                            color: isPast(item.date)
                                ? theme.colors.dim
                                : theme.colors.onBackground,
                        } }, { children: [_jsxs(Heading4, __assign({ style: {
                                    borderBottomColor: isPast(item.date)
                                        ? theme.colors.dim
                                        : 'unset',
                                } }, { children: [toTitleCase(formatDate(item.date, 'PPPP')), ' - ', _jsx("span", __assign({ style: {
                                            color: isPast(item.date)
                                                ? theme.colors.dim
                                                : theme.colors.secondary,
                                        } }, { children: t('GlobalAdministrate.Overview.Planning.NoOfRespondents', {
                                            noOfRespondents: formatAsNumber(item.count),
                                        }) }))] })), _jsx(TooltipContent, { id: "tt-invitation", text: t('Administrate.MessageTemplate.MessageTemplateType.SurveyInvitation') }), _jsx(TooltipContent, { id: "tt-reminder", text: t('Administrate.MessageTemplate.MessageTemplateType.SurveyReminder') }), item.customers.map(function (customer, customerIndex) {
                                var _a;
                                return (_jsxs(CustomerStyle, { children: [_jsx(ButtonLink, __assign({ style: {
                                                color: isPast(item.date) ? theme.colors.dim : 'unset',
                                            }, onClick: function (e) {
                                                return navigate("/customer/".concat(customer.customerId), {
                                                    state: { from: '/administrate/schedule' },
                                                });
                                            } }, { children: (_a = customer.customerName) !== null && _a !== void 0 ? _a : 'Missing Customer Name' })), customer.items.map(function (message, messageIndex) { return (_jsx(Table, { children: _jsx("tbody", { children: _jsxs(Tr, { children: [_jsxs(Td, { children: [message.messageType === MessageType.Reminder ? (_jsx(BsAlarm, { "data-tip": true, "data-for": "tt-reminder" })) : (_jsx(BsEnvelope, { "data-tip": true, "data-for": "tt-invitation" })), formatDate(parseISO(message.date), 'time')] }), _jsx(Td, __assign({ style: { width: 250 } }, { children: message.entityName })), _jsx(TdRight, { children: t('GlobalAdministrate.Overview.Planning.NoOfRespondents', {
                                                                noOfRespondents: formatAsNumber(message.count),
                                                            }) })] }) }) }, "".concat(message.entityId).concat(customerIndex).concat(messageIndex))); })] }, "".concat(customer.customerId).concat(customerIndex)));
                            })] }), index)); })) })] }));
});
Planning.displayName = 'Planning';
var HeaderStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  h3 {\n    margin-right: 20px;\n  }\n\n  > div {\n    margin-right: 20px;\n\n    input {\n      width: 90px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  h3 {\n    margin-right: 20px;\n  }\n\n  > div {\n    margin-right: 20px;\n\n    input {\n      width: 90px;\n    }\n  }\n"])));
var Style = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  h4 {\n    margin-top: 20px;\n    padding: 3px;\n\n    > svg {\n      margin-right: 10px;\n    }\n\n    border-bottom: 1px solid\n      ", ";\n  }\n"], ["\n  h4 {\n    margin-top: 20px;\n    padding: 3px;\n\n    > svg {\n      margin-right: 10px;\n    }\n\n    border-bottom: 1px solid\n      ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
});
var CustomerStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 20px;\n\n  table {\n    width: fit-content;\n  }\n\n  > button {\n    font-weight: bold;\n    margin: 10px 0 5px 5px;\n  }\n\n  tr {\n    td {\n      margin: 2px 5px;\n      width: 250px;\n      border: none;\n    }\n\n    td:first-of-type {\n      width: 80px;\n\n      > svg {\n        font-size: ", ";\n        margin-right: 10px;\n      }\n    }\n\n    td:last-of-type {\n      width: 150px;\n    }\n  }\n"], ["\n  margin-bottom: 20px;\n\n  table {\n    width: fit-content;\n  }\n\n  > button {\n    font-weight: bold;\n    margin: 10px 0 5px 5px;\n  }\n\n  tr {\n    td {\n      margin: 2px 5px;\n      width: 250px;\n      border: none;\n    }\n\n    td:first-of-type {\n      width: 80px;\n\n      > svg {\n        font-size: ", ";\n        margin-right: 10px;\n      }\n    }\n\n    td:last-of-type {\n      width: 150px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.l;
});
var templateObject_1, templateObject_2, templateObject_3;
