import { useGetMyCustomersQuery } from '@dimatech/features-core/lib/api/customer/customerApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectCustomer = ({
  customerId,
  setCustomerId,
  style,
  autoFocus,
  name,
  placeholder,
}: {
  customerId?: string;
  setCustomerId: (customerId: string) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
  name?: string;
  placeholder?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const { data, isLoading } = useGetMyCustomersQuery(
    accessToken.user?.id ?? skipToken
  );

  if (isLoading) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      <Select
        name={name}
        autoFocus={!!autoFocus}
        value={customerId ?? ''}
        onChange={(e) => setCustomerId(e.currentTarget.value)}
        style={style}
      >
        <option value="">
          {placeholder ? placeholder : t('Filter.AllCustomers')}
        </option>
        {data?.map((customer) => (
          <option key={customer.id} value={customer.id}>
            {customer.name}
          </option>
        ))}
      </Select>
    </>
  );
};

SelectCustomer.displayName = 'SelectCustomer';
