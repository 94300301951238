import { Stories as StoriesFeature } from 'features/stories';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Stories = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Stories.Title')}>
      <StoriesFeature />
    </View>
  );
};

Stories.displayName = 'Stories';
