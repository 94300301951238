var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Group array by predicate. Payload can be used to store extra data besides grouping key
 * @param array
 * @param predicate
 * @param payloadPredicate
 * @returns
 */
export var groupBy = function (array, predicate, payloadPredicate) {
    return array.reduce(function (acc, value) {
        var key = predicate(value);
        var payload = payloadPredicate && payloadPredicate(value);
        var item = (acc[key] || (acc[key] = {
            items: [],
            payload: payload,
        }));
        (item.items || (item.items = [])).push(value);
        return acc;
    }, {});
};
/**
 * Returns true if the array has duplicates
 * @param array
 * @param predicate
 * @returns
 */
export var hasDuplicates = function (array, predicate) {
    return array.some(function (a, index) {
        return array.findIndex(function (b) { return predicate(a, b); }) !== index;
    });
};
/**
 * Randomly shuffles an array
 * @param array
 */
export var shuffle = function (array) {
    var _a;
    var shuffled = __spreadArray([], array, true);
    var currentIndex = shuffled.length;
    while (currentIndex != 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        _a = [
            shuffled[randomIndex],
            shuffled[currentIndex],
        ], shuffled[currentIndex] = _a[0], shuffled[randomIndex] = _a[1];
    }
    return shuffled;
};
