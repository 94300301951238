var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody, ViewHeader, } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useGetUserMessagesQuery } from '../../api/message/messageApi';
import { AuthenticationContext } from '../authentication/AuthenticationProvider';
import { MessageList } from './components/MessageList';
export var AdministrateMessages = function () {
    var _a;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var navigate = useNavigate();
    var _b = useGetUserMessagesQuery((_a = accessToken.customerId) !== null && _a !== void 0 ? _a : skipToken), messages = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching, isError = _b.isError;
    var handleCreate = function () {
        navigate("/administrate/message/new");
    };
    if (isFetching) {
        return (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: _jsx(Loader, { children: t('Common.UI.Loading') }) })));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, { children: [_jsx(Heading1, { children: t('Administrate.Messages.Title') }), _jsxs("div", { children: [_jsx(TooltipContent, { id: "tt-message-add", title: t('Administrate.Messages.Add.TooltipTitle'), text: t('Administrate.Messages.Add.Tooltip') }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-message-add", onClick: handleCreate, "$inverted": true }, { children: _jsx(BsPlus, {}) }))] })] }), _jsx(Card, { children: _jsx(CardBody, __assign({ isLoading: isLoading || isFetching }, { children: _jsx(MessageList, { messages: messages, isError: isError }) })) })] }));
};
AdministrateMessages.displayName = 'AdministrateMessages';
