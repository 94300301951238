import { CacheTags, dimiosApi } from 'api/dimiosApi';
import {
  AdministrateStory,
  AdministrateStoryFilter,
  PaginatedResult,
} from 'models';

const storiesApi = dimiosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get administrate stories
     */
    getStoriesForAdministration: build.query<
      PaginatedResult<AdministrateStory>,
      {
        filter: AdministrateStoryFilter;
        _customerId: string;
        _userId: string;
      }
    >({
      query: ({ filter, _customerId, _userId }) =>
        `stories${filterToQuery(filter)}`,
      transformResponse: (result: PaginatedResult<AdministrateStory>) => {
        return result;
      },
      providesTags: [CacheTags.Story],
    }),

    /**
     * Update administrate story
     */
    updateStory: build.mutation({
      query: (story: AdministrateStory) => ({
        url: `stories/${story.id}`,
        method: 'PUT',
        body: {
          answer: story.value,
        },
      }),
      invalidatesTags: [CacheTags.Story],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     * Approve administrate story
     */
    approveStory: build.mutation({
      query: (storyIds: string[]) => ({
        url: `stories/approve`,
        method: 'PUT',
        body: { storyIds },
      }),
      invalidatesTags: [CacheTags.Story],
    }),

    /**
     * Disapprove administrate story
     */
    disapproveStory: build.mutation({
      query: (storyIds: string[]) => ({
        url: `stories/ban`,
        method: 'PUT',
        body: { storyIds },
      }),
      invalidatesTags: [CacheTags.Story],
    }),
  }),
});

export const {
  useGetStoriesForAdministrationQuery,
  useLazyGetStoriesForAdministrationQuery,
  useUpdateStoryMutation,
  useApproveStoryMutation,
  useDisapproveStoryMutation,
} = storiesApi;

const filterToQuery = (filter: AdministrateStoryFilter): string =>
  `?pageNumber=${filter.paginator.page}&pageSize=${
    filter.paginator.pageSize
  }&orderBy.propertyName=${filter.paginator.orderBy}&orderBy.direction=${
    filter.paginator.orderDirection
  }${filter.customerId ? `&filter.customerId=${filter.customerId}` : ''}${
    filter.categoryId ? `&filter.categoryId=${filter.categoryId}` : ''
  }${
    filter.entityIds && filter.entityIds.length > 0
      ? filter.entityIds
          .map((entityId) => `&filter.entityIds=${entityId}`)
          .join('')
      : ''
  }${
    filter.approved === true
      ? `&filter.approved=true`
      : filter.approved === false
      ? `&filter.approved=false`
      : ''
  }${
    filter.instance ? `&filter.surveyInstanceId=${filter.instance.id}` : ''
  }${`&filter.respondentFilter=${filter.includeOnlyInvolvedRespondents}`}`;
