import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircleFill } from 'react-icons/bs';
import { withTheme } from 'styled-components';
export var MessageStatus = withTheme(function (_a) {
    var publishDate = _a.publishDate, theme = _a.theme;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-status-sent", title: t('Administrate.Messages.Status.Sent.Name'), text: t('Administrate.Messages.Status.Sent.Text') }), _jsx(TooltipContent, { id: "tt-status-not-sent", title: t('Administrate.Messages.Status.NotSent.Name'), text: t('Administrate.Messages.Status.NotSent.Text') }), publishDate && parseISO(publishDate) < new Date() && (_jsx(BsCheckCircleFill, { "data-tip": true, "data-for": "tt-status-sent", style: {
                    marginRight: 8,
                    color: theme.colors.success,
                } })), publishDate && parseISO(publishDate) > new Date() && (_jsx(BsCircleFill, { "data-tip": true, "data-for": "tt-status-not-sent", style: {
                    marginRight: 8,
                    color: theme.colors.lowPriority,
                } }))] }));
});
MessageStatus.displayName = 'MessageStatus';
