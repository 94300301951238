var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Card } from '@dimatech/shared/lib/components/workspace';
import { config } from '@dimatech/shared/lib/config';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../AuthenticationProvider';
export var CustomerAccountInfo = function () {
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    return !(accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) ? null : (_jsx(_Fragment, { children: !accessToken.user.currentCustomer.isApproved && (_jsx(Card, { children: _jsxs(AlertInfo, { children: [t('CommonTerms.NotApproved'), ' ', _jsx("a", __assign({ href: "mailto: ".concat(config.support.email) }, { children: config.support.email }))] }) })) }));
};
CustomerAccountInfo.displayName = 'CustomerAccountInfo';
