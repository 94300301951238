/* 
We allow this here since this is the entry point of 
getting all settings from the .env files
*/
/* eslint-disable no-process-env */
import {
  config as sharedConfig,
  Config as SharedConfig,
} from '@dimatech/shared/lib/config';
import {
  DigitalAbilities,
  DigitalHeritages,
  SubCategoryDefinition,
} from 'models';

// NOTE: Add settings here that are unique for this app, shared settings should be added to shared config

export const config: Config = {
  ...sharedConfig,
  authentication: {
    ...sharedConfig.authentication,
    // Do not allow users with only CommonUser role
    allowCommonUserInPrivateRoutes: false,
  },
  dimios: {
    categories: {
      digitalAbility: {
        key: 'Ability',
        efficiency: {
          key: DigitalAbilities.Efficiency,
          id: 280024,
          principles: [280017, 280018, 280019, 280020, 280021, 280022, 280023],
        },
        innovation: {
          key: DigitalAbilities.Innovation,
          id: 280033,
          principles: [280027, 280028, 280029, 280030, 280031, 280032],
        },
        balance: {
          key: DigitalAbilities.Balance,
          id: 280041,
          principles: [280036, 280037, 280038, 280039, 280040],
        },
      },
      digitalHeritage: {
        key: 'Heritage',
        organisation: {
          key: DigitalHeritages.Organisation,
          id: 280004,
          principles: [280002, 280003],
        },
        users: {
          key: DigitalHeritages.Users,
          id: 280008,
          principles: [280006, 280007],
        },
        technology: {
          key: DigitalHeritages.Technology,
          id: 280014,
          principles: [280010, 280011, 280012, 280013],
        },
      },
    },
    principalOptions: [280001, 280002, 280003, 280004, 280005, 280006, 280007],
  },
};

type Config = SharedConfig & {
  dimios: {
    categories: {
      digitalAbility: {
        key: string;
        efficiency: SubCategoryDefinition;
        innovation: SubCategoryDefinition;
        balance: SubCategoryDefinition;
      };
      digitalHeritage: {
        key: string;
        organisation: SubCategoryDefinition;
        users: SubCategoryDefinition;
        technology: SubCategoryDefinition;
      };
    };
    principalOptions: number[];
  };
};
