var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Attribution } from './Attribution';
import { Version } from './Version';
export var SidePanelFooter = function () {
    return (_jsxs(Style, { children: [_jsx(Attribution, {}), _jsx(Version, {})] }));
};
SidePanelFooter.displayName = 'SidePanelFooter';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  margin-left: 10px;\n\n  /* position: fixed;\n  left: 25px;\n  bottom: 10px; */\n"], ["\n  margin-bottom: 20px;\n  margin-left: 10px;\n\n  /* position: fixed;\n  left: 25px;\n  bottom: 10px; */\n"])));
var templateObject_1;
