var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CompositeDecorator, ContentState, convertFromHTML, Editor, EditorState, Modifier, RichUtils, } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { createRef, useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { BsLink, BsList, BsTypeBold, BsTypeItalic } from 'react-icons/bs';
import styled, { css } from 'styled-components';
import { Breakpoints } from '../themes';
import { Button, Buttons, ButtonSecondary, Input, Label } from './form';
import { Modal } from './modal';
export var TextEditor = function (_a) {
    var messageId = _a.messageId, value = _a.value, setValue = _a.setValue, _b = _a.allowLinks, allowLinks = _b === void 0 ? true : _b, _c = _a.linkPrefix, linkPrefix = _c === void 0 ? 'https://' : _c, _d = _a.validateHtmlIsEmpty, validateHtmlIsEmpty = _d === void 0 ? false : _d, style = _a.style, _e = _a.invalid, invalid = _e === void 0 ? false : _e;
    var t = useTranslation().t;
    var _f = useState(linkPrefix), link = _f[0], setLink = _f[1];
    var _g = useState(''), linkDisplayText = _g[0], setLinkDisplayText = _g[1];
    var _h = useState(false), isAddLinkShown = _h[0], setIsAddLinkShown = _h[1];
    var editor = createRef();
    var _j = useState(EditorState.createEmpty()), editorState = _j[0], setEditorState = _j[1];
    var selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    var selectedText = currentContentBlock.getText().slice(start, end);
    useEffect(function () {
        var blocks = convertFromHTML(value || '');
        var content = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap);
        var decorator = linkDecorator;
        var initialEditorState = EditorState.createWithContent(content, decorator);
        setEditorState(initialEditorState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageId]);
    useEffect(function () {
        setLinkDisplayText(selectedText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedText]);
    var toggleBlockType = function (blockType) {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };
    var toggleInlineStyle = function (inlineStyle) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };
    var handleChange = function (editorState) {
        setEditorState(editorState);
        handleBlur();
    };
    var handleBlur = function () {
        var html = stateToHTML(editorState.getCurrentContent());
        if (validateHtmlIsEmpty) {
            var text = editorState.getCurrentContent().getPlainText();
            if (text.trim().length === 0) {
                html = undefined;
            }
        }
        setValue(html);
    };
    var handleKeyCommand = function (command) {
        var newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };
    var generateLink = function () {
        var decorator = linkDecorator;
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selectionState, null));
            setLinkDisplayText('');
            setIsAddLinkShown(false);
            return;
        }
        var currentContent = editorState.getCurrentContent();
        currentContent.createEntity('LINK', 'MUTABLE', {
            url: link,
            target: '_blank',
        });
        var entityKey = currentContent.getLastCreatedEntityKey();
        var textWithEntity = Modifier.replaceText(currentContent, selectionState, linkDisplayText, editorState.getCurrentInlineStyle(), entityKey);
        var newState = EditorState.createWithContent(textWithEntity, decorator);
        var html = stateToHTML(newState.getCurrentContent());
        setValue(html);
        setEditorState(newState);
        setIsAddLinkShown(false);
        setLink(linkPrefix);
        setLinkDisplayText('');
    };
    var showUrl = function () {
        var startKey = selectionState.getStartKey();
        var blockWithLinkAtBeginning = currentContent.getBlockForKey(startKey);
        var linkKey = blockWithLinkAtBeginning.getEntityAt(start);
        if (linkKey) {
            var linkInstance = currentContent.getEntity(linkKey);
            var linkText = linkInstance.getData().url;
            setLink(linkText);
        }
        else {
            setLink(linkPrefix);
        }
    };
    return (_jsxs(RootStyle, __assign({ style: style }, { children: [_jsx(StyleControls, { editorState: editorState, onToggleBlockType: toggleBlockType, onToggleInlineStyle: toggleInlineStyle, setIsAddLinkShown: setIsAddLinkShown, setEditorState: setEditorState, allowLinks: allowLinks, showUrl: showUrl }), _jsx(EditorStyle, __assign({ onClick: function () { var _a; return (_a = editor.current) === null || _a === void 0 ? void 0 : _a.focus(); }, invalid: invalid }, { children: _jsx(Editor, { stripPastedStyles: true, onChange: handleChange, onBlur: handleBlur, handleKeyCommand: handleKeyCommand, editorState: editorState, ref: editor }) })), isAddLinkShown && (_jsxs(Modal, __assign({ title: t('TextEditor.Title.Link'), handleKeyEnter: generateLink, handleKeyEsc: function () { return setIsAddLinkShown(false); } }, { children: [_jsx(Label, __assign({ htmlFor: "link" }, { children: t('TextEditor.Title.Url') })), _jsx(Input, { autoFocus: true, type: "url", id: "link", name: "link", placeholder: "https://...", value: link, onChange: function (e) { return setLink(e.currentTarget.value); } }), _jsx(Label, __assign({ htmlFor: "text" }, { children: t('TextEditor.Title.Text') })), _jsx(Input, { type: "text", id: "text", name: "text", defaultValue: linkDisplayText, onChange: function (e) { return setLinkDisplayText(e.currentTarget.value); } }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsAddLinkShown(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function () {
                                    var _a;
                                    (_a = editor.current) === null || _a === void 0 ? void 0 : _a.focus();
                                    generateLink();
                                } }, { children: t('Common.Form.Save') }))] })] })))] })));
};
TextEditor.displayName = 'TextEditor';
var Link = function (props) {
    if (props.entityKey !== undefined) {
        var url_1 = props.contentState.getEntity(props.entityKey).getData().url;
        var openLinkInNewTab = function (e) {
            e.preventDefault();
            window.open(url_1, '_blank');
        };
        return (_jsx("a", __assign({ rel: "noopener noreferrer", href: url_1, onClick: openLinkInNewTab }, { children: props.children })));
    }
};
var linkDecorator = new CompositeDecorator([
    {
        strategy: function (contentBlock, callback, contentState) {
            contentBlock.findEntityRanges(function (character) {
                var entityKey = character.getEntity();
                return (entityKey !== null &&
                    contentState.getEntity(entityKey).getType() === 'LINK');
            }, callback);
        },
        component: Link,
    },
]);
var EditorStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 150px;\n  width: 100%;\n  margin-top: 20px;\n  padding: 10px;\n  overflow-y: auto;\n\n  color: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-bottom: 1px solid\n    ", ";\n  cursor: text;\n\n  .public-DraftStyleDefault-ul {\n    margin-left: 15px;\n  }\n"], ["\n  height: 150px;\n  width: 100%;\n  margin-top: 20px;\n  padding: 10px;\n  overflow-y: auto;\n\n  color: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-bottom: 1px solid\n    ", ";\n  cursor: text;\n\n  .public-DraftStyleDefault-ul {\n    margin-left: 15px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (props) {
    return props.invalid ? props.theme.colors.error : props.theme.colors.border;
});
var RootStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 10px;\n\n  color: ", ";\n\n  @media screen and (max-width: ", ") {\n    padding: 36px 16px 0;\n  }\n"], ["\n  padding-top: 10px;\n\n  color: ", ";\n\n  @media screen and (max-width: ", ") {\n    padding: 36px 16px 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, Breakpoints.small);
var StyleControls = function (_a) {
    var editorState = _a.editorState, onToggleBlockType = _a.onToggleBlockType, onToggleInlineStyle = _a.onToggleInlineStyle, setIsAddLinkShown = _a.setIsAddLinkShown, setEditorState = _a.setEditorState, allowLinks = _a.allowLinks, showUrl = _a.showUrl;
    var t = useTranslation().t;
    var styles = editorState.getCurrentInlineStyle();
    var selection = editorState.getSelection();
    var blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    var handleToggleBlockType = function (event, blockType) {
        event.preventDefault();
        onToggleBlockType(blockType);
    };
    var handleToggleInlineStyle = function (event, inlineStyle) {
        event.preventDefault();
        onToggleInlineStyle(inlineStyle);
    };
    var handleAddLink = function (event, blockType) {
        event.preventDefault();
        if (!allowLinks) {
            return;
        }
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
        setIsAddLinkShown(true);
        showUrl();
    };
    return (_jsxs(ControlsStyle, { children: [_jsx(ControlStyle, __assign({ title: t('TextEditor.Bold'), "$active": styles.has('BOLD'), onMouseDown: function (e) { return handleToggleInlineStyle(e, 'BOLD'); } }, { children: _jsx(BsTypeBold, {}) })), _jsx(ControlStyle, __assign({ title: t('TextEditor.Italic'), "$active": styles.has('ITALIC'), onMouseDown: function (e) { return handleToggleInlineStyle(e, 'ITALIC'); } }, { children: _jsx(BsTypeItalic, {}) })), _jsxs(ControlStyle, __assign({ title: t('TextEditor.List'), "$active": 'unordered-list-item' === blockType, onMouseDown: function (e) { return handleToggleBlockType(e, 'unordered-list-item'); } }, { children: [_jsx(BsList, {}), _jsx("span", { children: t('TextEditor.List') })] })), allowLinks && (_jsx(ControlStyle, __assign({ title: t('TextEditor.Link'), "$active": 'link' === blockType, disabled: editorState.getSelection().isCollapsed(), onMouseDown: function (e) { return handleAddLink(e, 'link'); } }, { children: _jsx(BsLink, {}) })))] }));
};
var ControlsStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n  user-select: none;\n"], ["\n  margin: 0;\n  user-select: none;\n"])));
var ControlStyle = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-block;\n  margin-right: 10px;\n  padding: 5px 10px;\n  height: 32px;\n\n  border: 1px solid ", ";\n  border-radius: 3px;\n\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n\n  &:disabled {\n    border: 1px solid ", ";\n    background-color: ", ";\n    color: ", ";\n    cursor: auto;\n  }\n\n  svg {\n    vertical-align: text-top;\n  }\n\n  span {\n    margin-left: 5px;\n  }\n\n  ", "\n"], ["\n  display: inline-block;\n  margin-right: 10px;\n  padding: 5px 10px;\n  height: 32px;\n\n  border: 1px solid ", ";\n  border-radius: 3px;\n\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n\n  &:disabled {\n    border: 1px solid ", ";\n    background-color: ", ";\n    color: ", ";\n    cursor: auto;\n  }\n\n  svg {\n    vertical-align: text-top;\n  }\n\n  span {\n    margin-left: 5px;\n  }\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (props) {
    if (props.$active) {
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        border: 1px solid\n          ", ";\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        border: 1px solid\n          ", ";\n        background-color: ", ";\n        color: ", ";\n      "])), function (_a) {
            var theme = _a.theme;
            return theme.colors.secondary;
        }, function (_a) {
            var theme = _a.theme;
            return theme.colors.secondary;
        }, function (_a) {
            var theme = _a.theme;
            return theme.colors.onSecondary;
        });
    }
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
