var _a;
import { createSlice } from '@reduxjs/toolkit';
import { appActions } from '../appSlice';
var initialState = {};
export var entityReducer = (_a = createSlice({
    name: 'entity',
    initialState: initialState,
    reducers: {
        selectedEntity: function (state, action) {
            state.selectedEntity = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function (state) {
            state.selectedEntity = initialState.selectedEntity;
        });
    },
}), _a.reducer), entityActions = _a.actions;
export var selectSelectedEntity = function (state) { return state.entity.selectedEntity; };
