import { jsx as _jsx } from "react/jsx-runtime";
import { BsFile, BsFileExcel, BsFileImage, BsFilePdf, BsFilePpt, BsFileWord, } from 'react-icons/bs';
export var FileIcon = function (_a) {
    var _b;
    var fileName = _a.fileName, style = _a.style;
    var parts = fileName.split('.');
    var extension = (parts.length === 1 || (parts.length === 2 && parts[0] === '')
        ? undefined
        : (_b = parts.pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || 'unknown';
    var getIcon = function () {
        if (['doc', 'docx', 'dotx'].includes(extension)) {
            return _jsx(BsFileWord, { style: style });
        }
        if (['xls', 'xlsm', 'xlsx', 'xltx', 'csv'].includes(extension)) {
            return _jsx(BsFileExcel, { style: style });
        }
        if (['ppt', 'pot', 'potm', 'potx', 'pptx'].includes(extension)) {
            return _jsx(BsFilePpt, { style: style });
        }
        if (['pdf'].includes(extension)) {
            return _jsx(BsFilePdf, { style: style });
        }
        if (['png', 'jpg', 'jpeg', 'gif', 'tif'].includes(extension)) {
            return _jsx(BsFileImage, { style: style });
        }
        if (['unknown', 'txt'].includes(extension)) {
            return _jsx(BsFileImage, { style: style });
        }
        return _jsx(BsFile, { style: style });
    };
    return getIcon();
};
FileIcon.displayName = 'FileIcon';
