import { base, Color, Theme, ThemeType } from '@dimatech/shared/lib/themes';

// Light theme, only overrides necessary properties
export const light: Theme = {
  ...base,
  name: 'Dimios Light',
  type: ThemeType.Light,

  colors: {
    ...base.colors,

    primaryVariant: Color.DimiosBlue,

    secondary: Color.DarkTurquoise,
    secondaryVariant: Color.SeaGreen,
    onSecondary: Color.White,

    chart: {
      ...base.colors.chart,

      primary: Color.DimiosBlue,
      primaryHighlight: Color.DimiosBlueDark,

      secondary: Color.SandyBrown,
      secondaryHighlight: Color.KojiOrangeLight,
    },
  },
};
