import { verifyConfiguration } from '@dimatech/shared/lib/config';
// Uncomment this to mock date
// import { mockDate } from 'utils';
import { config } from 'config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from 'store';
import App from './App';
import './localization';
import reportWebVitals from './reportWebVitals';

(async () => {
  // Set to date time that should be tested
  // mockDate(new Date(2019, 0, 1));

  verifyConfiguration();

  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarkly.key,
    options: {
      bootstrap: 'localStorage',
      diagnosticOptOut: true,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <LaunchDarklyProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </LaunchDarklyProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
