import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Theme } from '@dimatech/shared/lib/themes';
import { ChartTooltipSplit } from 'components/ChartTooltipSplit';
import { PrinciplesByCategory } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const OurResultComparedTo = withTheme(
  ({
    theme,
    principlesByCategory,
    principlesNationallyByCategory,
  }: {
    theme: Theme;
    principlesByCategory: PrinciplesByCategory;
    principlesNationallyByCategory?: PrinciplesByCategory;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const nationalAverageLabel = accessToken.isInternational
      ? t('CommonTerms.SwedishAverage')
      : t('CommonTerms.NationalAverage');

    const keys = [
      { key: t('CommonTerms.OurResult') },
      { key: nationalAverageLabel },
    ];

    const [indicators, setIndicators] = useState(
      keys.reduce((a: Record<string, boolean>, { key }) => {
        a[key] = false;
        return a;
      }, {})
    );

    const handleSwitch = (e: string) => {
      setIndicators({
        ...indicators,
        [e]: !indicators[e],
      });
    };

    return (
      <Chart>
        <ScatterChart style={{ height: '105%' }}>
          <CartesianGrid
            stroke={theme.colors.chart.gridStroke}
            strokeWidth={0.5}
          />

          <XAxis
            type="number"
            dataKey="heritage"
            name={t('CommonTerms.DigitalHeritage')}
            domain={[0, 100]}
            tickCount={5}
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0')}
            stroke={theme.colors.chart.axisStroke}
          >
            <Label
              value={t('CommonTerms.DigitalHeritage') as string}
              position="insideBottom"
              offset={-5}
              fill={theme.colors.onSurface}
            />
          </XAxis>

          <YAxis
            type="number"
            dataKey="ability"
            name={t('CommonTerms.DigitalAbility')}
            domain={[0, 100]}
            tickCount={5}
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0')}
            stroke={theme.colors.chart.axisStroke}
          >
            <Label
              value={t('CommonTerms.DigitalAbility') as string}
              offset={15}
              position="insideLeft"
              angle={-90}
              fill={theme.colors.onSurface}
            />
          </YAxis>

          <Scatter
            data={[
              {
                heritage: principlesByCategory.heritage.percentage,
                ability: principlesByCategory.ability.percentage,
                label: t('CommonTerms.OurResult'),
              },
            ]}
            name={t('CommonTerms.OurResult')}
            stroke={theme.colors.chart.primary}
            fill={theme.colors.chart.primary}
            opacity={0.8}
            hide={indicators[t('CommonTerms.OurResult')] === true}
          />

          {principlesNationallyByCategory && (
            <Scatter
              data={[
                {
                  heritage: principlesNationallyByCategory.heritage.percentage,
                  ability: principlesNationallyByCategory.ability.percentage,
                  label: nationalAverageLabel,
                },
              ]}
              name={nationalAverageLabel}
              stroke={theme.colors.chart.secondary}
              fill={theme.colors.chart.secondary}
              opacity={0.8}
              hide={indicators[nationalAverageLabel] === true}
            />
          )}

          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<ChartTooltipSplit />}
          />

          <ReferenceLine x={50} stroke={theme.colors.chart.axisStroke} />
          <ReferenceLine y={50} stroke={theme.colors.chart.axisStroke} />
          <Legend
            verticalAlign="bottom"
            height={10}
            iconType="circle"
            onClick={(e) => handleSwitch(e.value)}
            formatter={(value: string) => (
              <span style={{ cursor: 'pointer' }}>{value}</span>
            )}
          />
        </ScatterChart>
      </Chart>
    );
  }
);

OurResultComparedTo.displayName = 'OurResultComparedTo';
