import { config } from 'config';
import {
  CategoryByCustomer,
  CategoryCustomer,
  DigitalAbilities,
  DigitalHeritages,
  Principle,
  PrincipleOption,
  PrincipleOptionByPrinciple,
  PrinciplesByCategory,
  PrinciplesByEntity,
  StoriesByCategory,
  Story,
  SubCategory,
  SubCategoryDefinition,
} from 'models';
import { groupBy } from 'utils';

// Used for calculations
type Category = SubCategory & {
  key: string;
  categories?: Category[];
  totalPercentage: number;
  totalAverage: number;
  totalPrinciples: number;
  totalPrinciplesHasValue: number;
  totalPercentageDontKnow: number;
};

/**
 * Map stories to StoriesByCategory
 * @param stories
 * @returns
 */
export const mapToStoriesByCategory = (
  stories: Story[]
): StoriesByCategory[] => {
  const groupedResult = groupBy(stories, (story) => story.categoryId);

  const storiesByCategory = Object.keys(groupedResult).map((key) => {
    return {
      categoryId: key,
      stories: groupedResult[key].items,
    } as StoriesByCategory;
  });

  return storiesByCategory;
};

/**
 * Map principles to CategoryByCustomer and calculates averages
 * @param principles
 * @returns
 */
export const mapToCategoryByCustomer = (
  categoryCustomers: CategoryCustomer[]
): CategoryByCustomer[] => {
  const categoryByCustomers = categoryCustomers
    .filter(
      (category) =>
        category.category === config.dimios.categories.digitalAbility.key
    )
    .map((category) => {
      const heritage = categoryCustomers.find(
        (heritageCategory) =>
          heritageCategory.category ===
            config.dimios.categories.digitalHeritage.key &&
          heritageCategory.customerId === category.customerId
      );

      return {
        customerId: category.customerId,
        organization: category.organization,
        heritage,
        ability: category,
        percentage: ((heritage?.percentage ?? 0) + category.percentage) / 2,
      };
    });

  return categoryByCustomers;
};

/**
 * Map principles to PrincipleOptionByPrinciple and calculates averages
 * @param principles
 * @returns
 */
export const mapToPrincipleOptionByPrinciple = (
  principles: PrincipleOption[]
): PrincipleOptionByPrinciple[] => {
  const groupedResult = groupBy(
    principles,
    (principle) => principle.principleId
  );

  const principleOptionByPrinciple = Object.keys(groupedResult).map((key) => {
    return {
      principleId: key,
      options: groupedResult[key].items,
    } as PrincipleOptionByPrinciple;
  });

  return principleOptionByPrinciple;
};

/**
 * Map principles to PrinciplesByEntity and calculates averages
 * @param principles
 * @returns
 */
export const mapToPrinciplesByEntity = (
  principles: Principle[]
): PrinciplesByEntity[] => {
  const groupedResult = groupBy(
    principles,
    (principle) => principle.entityId as string,
    (principle) => ({ entityName: principle.entityName })
  );

  const principlesByEntity = Object.keys(groupedResult).map((key) => {
    const categories = mapToPrinciplesByCategory(groupedResult[key].items);

    return {
      entityId: key,
      entityName: groupedResult[key].payload?.entityName,
      ability: categories.ability,
      heritage: categories.heritage,
      percentage:
        ((categories.heritage?.percentage ?? 0) +
          (categories.ability?.percentage ?? 0)) /
        2,
    } as PrinciplesByEntity;
  });

  return principlesByEntity;
};

/**
 * Map principles to PrinciplesByCategory and calculates averages
 * @param principles
 * @returns
 */
export const mapToPrinciplesByCategory = (
  principles: Principle[]
): PrinciplesByCategory => {
  const abilityPrinciples = config.dimios.categories.digitalAbility;
  const heritagePrinciples = config.dimios.categories.digitalHeritage;

  const { ability, heritage } = principles.reduce(
    ({ ability, heritage }, principle) => {
      // Sum percentage for all sub categories in Digital Ability
      Object.values(DigitalAbilities).forEach((enumValue) => {
        const key = enumValue.toLowerCase() as keyof typeof abilityPrinciples;

        // Check if principle is included in Digital Ability
        if (
          (abilityPrinciples[key] as SubCategoryDefinition).principles.includes(
            parseFloat(principle.principleId)
          )
        ) {
          // It is, summarize values
          summarizeCategory(enumValue, principle, ability);
        }
      });

      // Sum percentage for all sub categories in Digital Heritage
      Object.values(DigitalHeritages).forEach((enumValue) => {
        const key = enumValue.toLowerCase() as keyof typeof heritagePrinciples;

        // Check if principle is included in Digital Heritage
        if (
          (
            heritagePrinciples[key] as SubCategoryDefinition
          ).principles.includes(parseFloat(principle.principleId))
        ) {
          // It is, summarize values
          summarizeCategory(enumValue, principle, heritage);
        }
      });

      return {
        ability,
        heritage,
      };
    },
    {
      ability: {} as Category,
      heritage: {} as Category,
    }
  );

  // Calc new percentages in Digital Ability
  ability.categories?.forEach((subCategory) =>
    calculateSubCategory(subCategory)
  );
  calculateCategory(ability);

  // Calc new percentages in Digital Heritage
  heritage.categories?.forEach((subCategory) =>
    calculateSubCategory(subCategory)
  );
  calculateCategory(heritage);

  // Map to PrinciplesByCategory
  const principlesByCategory = mapCategoriesToPrinciplesByCategory(
    ability,
    heritage
  );

  return principlesByCategory;
};

/**
 * Summarize category and subcategory
 * @param category
 */
const summarizeCategory = (
  enumValue: string,
  principle: Principle,
  category: Category
) => {
  if (!category.categories) {
    category.categories = [];
  }

  // Check if sub category exists, else create it
  let subCategory = category.categories.find((s) => s.key === enumValue);

  if (!subCategory) {
    subCategory = {
      key: enumValue,
      percentage: 0,
      totalPercentage: 0,
      totalAverage: 0,
      totalPrinciples: 0,
      totalPercentageDontKnow: 0,
      totalPrinciplesHasValue: 0,
    };

    category.categories.push(subCategory);
  }

  // Add values
  if (principle.percentage) {
    if (principle.average) {
      subCategory.totalAverage += principle.average;
    }

    subCategory.totalPrinciplesHasValue++;
    subCategory.totalPercentage += principle.percentage;
  }

  category.totalPrinciples = (category.totalPrinciples ?? 0) + 1;
  category.totalPercentageDontKnow =
    (category.totalPercentageDontKnow ?? 0) + principle.percentageDontKnow;

  subCategory.totalPrinciples++;
  subCategory.totalPercentageDontKnow += principle.percentageDontKnow;
};

/**
 * Calculates averages
 * @param category
 */
const calculateCategory = (category: Category): void => {
  const sum =
    category.categories?.reduce(
      (value, cat) => (cat.percentage ?? 0) + value,
      0
    ) ?? 0;

  const sumAverage =
    category.categories?.reduce(
      (value, cat) => (cat.average ?? 0) + value,
      0
    ) ?? 0;

  const length =
    category.categories?.reduce(
      (value, cat) => (cat.percentage ? 1 : 0) + value,
      0
    ) ?? 0;

  category.percentage = sum / length;
  category.average = sumAverage / length;
  category.percentageDontKnow =
    (category.totalPercentageDontKnow / category.totalPrinciples) * 100;
};

/**
 * Calculates averages
 * @param category
 */
const calculateSubCategory = (category: Category): void => {
  if (category.totalPrinciplesHasValue > 0) {
    category.average = category.totalAverage / category.totalPrinciplesHasValue;
    category.percentage =
      (category.totalPercentage / category.totalPrinciplesHasValue) * 100;
  }

  category.percentageDontKnow =
    (category.totalPercentageDontKnow / category.totalPrinciples) * 100;
};

/**
 * Maps categories to PrinciplesByCategory
 * @param ability
 * @param heritage
 * @returns
 */
const mapCategoriesToPrinciplesByCategory = (
  ability: Category,
  heritage: Category
): PrinciplesByCategory => {
  const toSubCategory = (category: Category | undefined): SubCategory => ({
    key: category?.key,
    percentage: category?.percentage,
    average: category?.average,
    percentageDontKnow: category?.percentageDontKnow,
  });

  const balance = toSubCategory(
    ability.categories?.find(
      (subCategory) => subCategory.key === DigitalAbilities.Balance
    )
  );

  const efficiency = toSubCategory(
    ability.categories?.find(
      (subCategory) => subCategory.key === DigitalAbilities.Efficiency
    )
  );

  const innovation = toSubCategory(
    ability.categories?.find(
      (subCategory) => subCategory.key === DigitalAbilities.Innovation
    )
  );

  const organisation = toSubCategory(
    heritage.categories?.find(
      (subCategory) => subCategory.key === DigitalHeritages.Organisation
    )
  );

  const users = toSubCategory(
    heritage.categories?.find(
      (subCategory) => subCategory.key === DigitalHeritages.Users
    )
  );

  const technology = toSubCategory(
    heritage.categories?.find(
      (subCategory) => subCategory.key === DigitalHeritages.Technology
    )
  );

  const principlesByCategory: PrinciplesByCategory = {
    ability: {
      percentage: ability.percentage,
      average: ability.average,
      percentageDontKnow: ability.percentageDontKnow,
      balance,
      efficiency,
      innovation,
    },
    heritage: {
      percentage: heritage.percentage,
      average: heritage.average,
      percentageDontKnow: heritage.percentageDontKnow,
      organisation,
      users,
      technology,
    },
  };

  return principlesByCategory;
};

export const getSubCategoryDefinitions = (): SubCategoryDefinition[] => [
  { ...config.dimios.categories.digitalHeritage.users },
  { ...config.dimios.categories.digitalAbility.balance },
  { ...config.dimios.categories.digitalAbility.efficiency },
  { ...config.dimios.categories.digitalAbility.innovation },
  { ...config.dimios.categories.digitalHeritage.organisation },
  { ...config.dimios.categories.digitalHeritage.technology },
];
