import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ApplicationContext } from '@dimatech/shared/lib/application';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { useLazyGetStoriesForAdministrationQuery } from 'api/stories/storiesApi';
import { selectFilter } from 'api/stories/storySlice';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import {
  AdministrateStoryFilter,
  CommonRoles,
  Paginator,
  SortDirection,
} from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StoriesList } from './components/StoriesList';
import { StoryFilters } from './components/StoryFilters';

export const AdministrateStories = (): JSX.Element | null => {
  const { t } = useTranslation();
  const application = useContext(ApplicationContext);
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const isCustomerAccountAdmin = accessToken.isInRole(
    CommonRoles.CustomerAccountAdmin
  );

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 50,
    orderBy: 'created',
    orderDirection: SortDirection.Desc,
  };

  const [getStories, { data: stories, isLoading, isFetching }] =
    useLazyGetStoriesForAdministrationQuery();

  const handleGetStories = (
    paginator: Paginator,
    filter: AdministrateStoryFilter
  ) => {
    accessToken.customerId &&
      accessToken.user?.id &&
      getStories(
        {
          filter: {
            customerId: filter.customerId,
            instance: filter.instance,
            entityIds: filter.entityIds,
            categoryId: filter.categoryId,
            approved: filter.approved,
            includeOnlyInvolvedRespondents:
              filter.includeOnlyInvolvedRespondents,
            paginator,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        },
        true
      );
  };

  useEffect(() => {
    handleGetStories(initialPaginator, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Stories.Title')}</Heading1>
        <StoryFilters
          isEntityFilterShown={isCustomerAccountAdmin}
          isCustomerFilterShown={!isCustomerAccountAdmin}
        />
      </ViewHeader>

      <Card width="100%">
        <CardBody>
          {t('Administrate.Stories.Text', {
            productName: application.title,
          })}
        </CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {stories?.totalRecords === 0 && (
            <Card width="100%">
              <AlertInfo>{t('Administrate.Stories.NoData')}</AlertInfo>
            </Card>
          )}
          {stories && stories?.totalRecords > 0 && (
            <Card width="100%">
              <CardBody isLoading={isFetching}>
                <StoriesList
                  dataStories={stories?.records}
                  handleGetStories={handleGetStories}
                  totalRecords={stories?.totalRecords}
                  initialPaginator={initialPaginator}
                />
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  );
};

AdministrateStories.displayName = 'AdministrateStories';
