var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { Button, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useConfirmEmailMutation } from '../../../api/authentication/authenticationApi';
export var ConfirmEmail = function () {
    var t = useTranslation().t;
    var location = useLocation();
    var state = location.state;
    var _a = useConfirmEmailMutation(), confirm = _a[0], _b = _a[1], error = _b.error, isLoading = _b.isLoading, isSuccess = _b.isSuccess;
    var handleSubmit = function (e) {
        e.preventDefault();
        confirm(state.messageToken);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, { children: t('ConfirmEmail.Title') }), error && (_jsx(Error, { children: error ? t("Errors.".concat(error.name)) : t('Errors.Generic') })), !isSuccess && (_jsxs(_Fragment, { children: [_jsx(Text, { children: _jsx(Trans, { i18nKey: "ConfirmEmail.Text" }) }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "email" }, { children: t('ConfirmEmail.Email') })), _jsx(Input, { type: "email", id: "email", name: "email", defaultValue: state.email, readOnly: true })] }), _jsx(Button, __assign({ onClick: handleSubmit, style: { width: '100%', marginTop: 10 }, disabled: isLoading }, { children: isLoading
                            ? t('ConfirmEmail.Submitting')
                            : t('ConfirmEmail.Submit') }))] })), isSuccess && (_jsx(Text, { children: _jsx(Trans, { i18nKey: "ConfirmEmail.Verification" }) }))] }));
};
ConfirmEmail.displayName = 'ConfirmEmail';
var Text = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var Error = styled(AlertError)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var templateObject_1, templateObject_2;
