var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertLowPrio } from '@dimatech/shared/lib/components/Alert';
import { Button, Buttons, ButtonSecondary, Checkbox, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchUser } from '../../../components/SearchUser';
import { SelectEntity } from '../../../components/SelectEntity';
import { CommonRoles } from '../../../models';
export var UserAdd = function (_a) {
    var handleAddUser = _a.handleAddUser, setIsAdding = _a.setIsAdding, role = _a.role, includeChildEntities = _a.includeChildEntities, setIncludeChildEntities = _a.setIncludeChildEntities, _b = _a.isEntityFilterShown, isEntityFilterShown = _b === void 0 ? true : _b, isLoading = _a.isLoading, entities = _a.entities;
    var t = useTranslation().t;
    var _c = useState(null), user = _c[0], setUser = _c[1];
    var _d = useState(''), entityId = _d[0], setEntityId = _d[1];
    var _e = useState(''), path = _e[0], setPath = _e[1];
    var handleSave = function (e) {
        e.stopPropagation();
        if (!(user === null || user === void 0 ? void 0 : user.email) || (isEntityFilterShown && !entityId)) {
            return;
        }
        handleAddUser === null || handleAddUser === void 0 ? void 0 : handleAddUser(__assign({}, user), entityId);
        setIsAdding(false);
    };
    var handleCancel = function () {
        setIsAdding(false);
        setIncludeChildEntities === null || setIncludeChildEntities === void 0 ? void 0 : setIncludeChildEntities(true);
    };
    useEffect(function () {
        if (role === CommonRoles.CustomerAdmin) {
            setPath("Administrate.Entity.Admin");
        }
        if (role === CommonRoles.Reader) {
            setPath("Administrate.Entity.Viewer");
        }
        if (role === CommonRoles.PiosReviewer) {
            setPath("Administrate.Entity.Reviewer");
        }
        if (role === CommonRoles.PiosProjectManager) {
            setPath("Project.Manager");
        }
        if (role === CommonRoles.PiosEffectRealizationResponsible) {
            setPath("Project.EffectRealisationResponsible");
        }
        if (role === CommonRoles.PiosOrderingParty) {
            setPath("Project.OrderingParty");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);
    return (_jsxs(Modal, __assign({ title: t("".concat(path, ".Add.Title")), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsAdding(false); } }, { children: [_jsx("div", { children: t("".concat(path, ".Add.Text")) }), _jsx(AlertLowPrio, __assign({ style: { margin: '10px 0' } }, { children: t("Administrate.Users.EmailNotification") })), _jsx(SearchUser, { user: user, setUser: setUser }), isEntityFilterShown && (_jsxs(_Fragment, { children: [_jsx(SelectEntity, { entityId: entityId, setEntityId: setEntityId, isLoading: isLoading, data: entities }), _jsx("div", { children: _jsxs(Label, __assign({ htmlFor: "includeChildEntities" }, { children: [_jsx(Checkbox, { id: "includeChildEntities", name: "includeChildEntities", style: { marginRight: 10 }, checked: includeChildEntities !== null && includeChildEntities !== void 0 ? includeChildEntities : true, onChange: function (e) {
                                        return setIncludeChildEntities === null || setIncludeChildEntities === void 0 ? void 0 : setIncludeChildEntities(e.currentTarget.checked);
                                    } }), t("".concat(path, ".Add.IncludeChildEntities"))] })) })] })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave }, { children: t('Common.Form.Save') }))] })] })));
};
UserAdd.displayName = 'UserAdd';
