var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDeleteViewerMutation } from '@dimatech/features-core/lib/api/user/userApi';
import { Button, ButtonSecondary, Buttons, Checkbox, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { selectFilter } from '../../../api/filter/filterSlice';
export var EntityViewersActionDelete = function (_a) {
    var user = _a.user, setError = _a.setError, isReadOnly = _a.isReadOnly;
    var t = useTranslation().t;
    var filter = useSelector(selectFilter);
    var deleteViewer = useDeleteViewerMutation()[0];
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var selectedEntity = useSelector(selectSelectedEntity);
    var _c = useState(true), includeChildEntities = _c[0], setIncludeChildEntities = _c[1];
    var handleConfirmDelete = function (e) {
        e.stopPropagation();
        if (isReadOnly) {
            return;
        }
        deleteViewer({
            user: {
                email: user.email,
                isReader: true,
                entityId: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id,
                includeChildEntities: includeChildEntities,
            },
        });
        setIsDeleting(false);
        setIncludeChildEntities(true);
    };
    var handleDelete = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        setIsDeleting(show);
    };
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Entity.Viewer.Delete.ConfirmTitle'), handleKeyEnter: function (e) { return handleConfirmDelete(e); }, handleKeyEsc: function (e) { return handleDelete(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Entity.Viewer.Delete.ConfirmText') }), (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.childCount) !== undefined &&
                        selectedEntity.childCount > 0 && (_jsx("div", { children: _jsxs(Label, __assign({ htmlFor: "includeChildEntities" }, { children: [_jsx(Checkbox, { id: "includeChildEntities", name: "includeChildEntities", style: { marginRight: 10 }, checked: includeChildEntities !== null && includeChildEntities !== void 0 ? includeChildEntities : true, onChange: function (e) {
                                        return setIncludeChildEntities(e.currentTarget.checked);
                                    } }), t('Administrate.Entity.Viewer.Delete.IncludeChildEntities')] })) })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e); }, disabled: isReadOnly }, { children: t('Common.UI.Yes') }))] })] }))), _jsx(LinkWithTooltip, { style: { marginLeft: 7 }, isDisabled: !filter.instance.isCurrentInstance, handleClick: function (e) { return handleDelete(e, filter.instance.isCurrentInstance); }, tooltipTitle: t('Administrate.Entity.Viewer.Delete.TooltipTitle'), tooltip: t('Administrate.Entity.Viewer.Delete.Tooltip'), icon: _jsx(BsTrash, {}) })] }));
};
EntityViewersActionDelete.displayName = 'EntityViewersActionDelete';
