import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  ChangePassword,
  UserInfo,
} from '@dimatech/features-core/lib/features/authentication/components';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const UserProfile = (): JSX.Element | null => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);

  if (!accessToken.user) {
    return null;
  }

  return (
    <View title={t('UserProfile.Title')}>
      <Card style={{ minWidth: 350 }}>
        <CardBody>
          <UserInfo />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <ChangePassword />
        </CardBody>
      </Card>
    </View>
  );
};

UserProfile.displayName = 'UserProfile';
