var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeSmall, BadgeTiny } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { config } from '@dimatech/shared/lib/config';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChat, BsChatText } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { appActions, selectIsUserMessageShown, selectUnreadMessageCount, } from '../../api/appSlice';
import { useGetMyMessagesQuery } from '../../api/user/userMessageApi';
import { AuthenticationContext } from '../authentication/AuthenticationProvider';
export var MessagesBadge = function (_a) {
    var _b;
    var style = _a.style;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var unreadMessageCount = useSelector(selectUnreadMessageCount);
    var isUserMessageShown = useSelector(selectIsUserMessageShown);
    var isUserMessagesEnabledFlagOn = useFlags()[flags.permanent.shared.isUserMessagesEnabled];
    var _c = useState(0), unreadMessages = _c[0], setUnreadMessages = _c[1];
    var data = useGetMyMessagesQuery(isUserMessagesEnabledFlagOn && ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id)
        ? accessToken.user.id
        : skipToken, {
        pollingInterval: config.messenger.pollInterval,
    }).data;
    var handleSetIsUserMessageShown = function (isUserMessageShown) {
        dispatch(appActions.setIsUserMessageShown(isUserMessageShown));
    };
    useEffect(function () {
        var _a;
        var unread = (_a = data === null || data === void 0 ? void 0 : data.filter(function (m) { return !m.read; })) !== null && _a !== void 0 ? _a : [];
        setUnreadMessages(unread.length);
        if (unread.length !== unreadMessageCount) {
            dispatch(appActions.setUnreadMessageCount(unread.length));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, unreadMessageCount]);
    if (!isUserMessagesEnabledFlagOn) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-user-messages", text: t('Messenger.UnreadCount', {
                    count: unreadMessages,
                }) }), _jsxs(Style, __assign({ "data-tip": true, "data-for": "tt-user-messages", style: __assign({}, style) }, { children: [unreadMessages > 0 && (_jsx(UnreadCountStyle, __assign({ onClick: function () { return handleSetIsUserMessageShown(!isUserMessageShown); } }, { children: unreadMessages }))), _jsxs(BadgeStyle, __assign({ onClick: function () { return handleSetIsUserMessageShown(!isUserMessageShown); }, style: { marginLeft: 10 } }, { children: [unreadMessages === 0 && _jsx(BsChat, {}), unreadMessages > 0 && _jsx(BsChatText, {})] }))] }))] }));
};
MessagesBadge.displayName = 'MessagesBadge';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 30px;\n  height: 30px;\n"], ["\n  position: relative;\n  width: 30px;\n  height: 30px;\n"])));
var BadgeStyle = styled(BadgeSmall)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
var UnreadCountStyle = styled(BadgeTiny)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 20px;\n  left: 30px;\n\n  font-size: ", ";\n\n  z-index: 100;\n  position: relative;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  position: absolute;\n  top: 20px;\n  left: 30px;\n\n  font-size: ", ";\n\n  z-index: 100;\n  position: relative;\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xs;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onPrimary;
});
var templateObject_1, templateObject_2, templateObject_3;
