import { featuresCoreApi } from '@dimatech/features-core/lib/api/featuresCoreApi';
import { errorToUiMiddleware } from '@dimatech/features-core/lib/api/middleware/errorToUi';
import { featuresSurveyApi } from '@dimatech/features-survey/lib/api/featuresSurveyApi';
import { errorTrackingMiddleware } from '@dimatech/shared/lib/middleware/errorTracking';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { dimiosApi } from 'api/dimiosApi';
import { rootReducer } from 'rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dimiosApi.middleware,
      featuresCoreApi.middleware,
      featuresSurveyApi.middleware,
      errorTrackingMiddleware,
      errorToUiMiddleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
