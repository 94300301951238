import { HttpStatus } from '../models';
/**
 *
 * Structure of an error message
 * {
 *     "type": "RecordNotFoundException",
 *     "title": "Entity with id '6287420d761bfe7be6840268' does not exist.",
 *     "status": 404,
 *     "detail": null,
 *     "instance": null
 * }
 *
 * Type could be null or one of
 * ManakinException - general exception
 * RecordNotFoundException
 * RecordAlreadyExistsException
 *
 * Validation exception structure
 * {
 *   "type": "ManakinValidationException",
 *   "title": "Send date is invalid",
 *   "errors": {
 *     "SendDate": [
 *       "InvalidValue"
 *     ]
 *   }
 * }
 *
 * Possible validation errors:
 * InvalidValue
 * Duplicate
 * Unsupported
 * MissingValue
 * NotMatching
 * TokenExpired
 *
 * Status codes used in backend are:
 * NotFound = 404
 * Conflict = 409 -- Thrown when some name is taken
 * BadRequest = 400
 * Forbidden = 403
 * Unauthorized = 401
 * InternalServerError = 500
 */
export var mapAxiosBaseQueryError = function (error, response) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (Object.hasOwn(error, 'validationErrors')) {
        // We have already parsed this error, return as is
        return error;
    }
    var status = (_a = response === null || response === void 0 ? void 0 : response.status) !== null && _a !== void 0 ? _a : HttpStatus.ServiceUnavailable;
    var name = HttpStatus[status];
    // If error is of type DimatechException, use the title
    // as name instead of HttpStatus
    if (((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.type) === 'DimatechException') {
        name = ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.title) || name;
    }
    var info = "".concat(((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.type) ? "".concat(response.data.type) : "".concat(name)).concat(((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.title) ? " - ".concat(response.data.title) : '');
    var apiError = {
        name: name,
        status: status,
        message: error.message,
        info: info,
        title: ((_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.title) || name,
        validationErrors: [],
    };
    // TODO: Clean this up, do we really need ResponseError?
    if (response && status === HttpStatus.BadRequest) {
        if ((_g = response.data) === null || _g === void 0 ? void 0 : _g.errors) {
            apiError.name = 'ValidationError';
            apiError.validationErrors = checkValidationErrors(response);
        }
        else {
            apiError.name =
                (response === null || response === void 0 ? void 0 : response.title) ||
                    ((_h = response === null || response === void 0 ? void 0 : response.data) === null || _h === void 0 ? void 0 : _h.title) ||
                    apiError.name;
        }
    }
    if (response && status === HttpStatus.Conflict) {
        apiError.name = 'ValidationError';
        apiError.validationErrors = [
            (response === null || response === void 0 ? void 0 : response.title) ||
                ((_j = response === null || response === void 0 ? void 0 : response.data) === null || _j === void 0 ? void 0 : _j.title) ||
                HttpStatus[status],
        ];
    }
    return apiError;
};
var checkValidationErrors = function (response) {
    var errors = response.data.errors;
    // TODO: Return key and value as soon as backend returns meaningful values everywhere
    // return errors
    //   ? Object.keys(errors).map((key: string) => `${key}:${errors[key]}`)
    //   : [];
    return errors ? Object.keys(errors).map(function (key) { return key; }) : [];
};
