export var ImportRespondentStatus;
(function (ImportRespondentStatus) {
    ImportRespondentStatus["New"] = "New";
    ImportRespondentStatus["Exists"] = "Exists";
    ImportRespondentStatus["ExistsOnAnotherEntity"] = "ExistsOnAnotherEntity";
    ImportRespondentStatus["NotInSource"] = "NotInSource";
    ImportRespondentStatus["Invalid"] = "Invalid";
})(ImportRespondentStatus || (ImportRespondentStatus = {}));
export var MessageType;
(function (MessageType) {
    MessageType["Invitation"] = "Invitation";
    MessageType["Reminder"] = "Reminder";
})(MessageType || (MessageType = {}));
