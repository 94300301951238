var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label, Select } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';
import { CustomerLineOfBusiness, CustomerType, } from '../../../models';
export var RegisterOrganisationType = function (_a) {
    var customer = _a.customer, handleChange = _a.handleChange, handleBlur = _a.handleBlur, validation = _a.validation;
    var t = useTranslation().t;
    return (_jsxs("div", { children: [_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "type" }, { children: t('Organisation.Type') })), _jsxs(Select, __assign({ name: "type", id: "type", value: customer.type, onChange: handleChange, invalid: !!(validation === null || validation === void 0 ? void 0 : validation.type), "data-tip": true, "data-for": "tt-type", onBlur: function (e) { return handleBlur(e); } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('Organisation.SelectType') })), Object.values(CustomerType)
                                .filter(function (type) { return type !== CustomerType.Demo; })
                                .map(function (value) { return (_jsx("option", __assign({ value: value }, { children: t("Customer.Type.".concat(value)) }), value)); })] })), _jsx(TooltipContent, { id: "tt-type", text: validation === null || validation === void 0 ? void 0 : validation.type })] }), customer.type === CustomerType.PubliclyOwnedOrganization && (_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "name" }, { children: t('Organisation.LineOfBusiness') })), _jsxs(Select, __assign({ name: "lineOfBusiness", id: "lineOfBusiness", value: customer.lineOfBusiness, onChange: handleChange, invalid: !!(validation === null || validation === void 0 ? void 0 : validation.lineOfBusiness), "data-tip": true, "data-for": "tt-lineOfBusiness", onBlur: function (e) { return handleBlur(e); } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('Organisation.SelectLineOfBusiness') })), Object.values(CustomerLineOfBusiness).map(function (value) { return (_jsx("option", __assign({ value: value }, { children: t("Customer.LineOfBusiness.".concat(value)) }), value)); })] })), _jsx(TooltipContent, { id: "tt-lineOfBusiness", text: validation === null || validation === void 0 ? void 0 : validation.lineOfBusiness })] }))] }));
};
RegisterOrganisationType.displayName = 'RegisterOrganisationType';
