import { CacheTagsEntity, featuresSurveyApi } from '../featuresSurveyApi';
var entitySurveyRespondentApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Add survey respondent
         */
        addSurveyRespondent: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, respondent = _a.respondent, skipExisting = _a.skipExisting;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents"),
                    method: 'POST',
                    body: { respondents: respondent, skipExisting: skipExisting },
                    timeout: 60000,
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
            transformResponse: function (result, _meta) {
                return {
                    result: result,
                };
            },
        }),
        /**
         * Update survey respondent
         */
        updateSurveyRespondent: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, email = _a.email, newEmail = _a.newEmail;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents"),
                    method: 'PUT',
                    body: {
                        email: email,
                        newEmail: newEmail,
                    },
                });
            },
            invalidatesTags: [
                CacheTagsEntity.EntitySurvey,
                CacheTagsEntity.SurveyFailedMessages,
            ],
        }),
        /**
         * Delete survey respondent
         */
        deleteSurveyRespondent: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, respondents = _a.respondents, keepData = _a.keepData;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents"),
                    method: 'DELETE',
                    body: { respondents: respondents, keepData: keepData },
                    timeout: 60000,
                });
            },
            invalidatesTags: [
                CacheTagsEntity.EntitySurvey,
                CacheTagsEntity.SurveyFailedMessages,
            ],
        }),
        /**
         * Move survey respondent
         */
        moveSurveyRespondent: build.mutation({
            query: function (_a) {
                var respondents = _a.respondents, entityId = _a.entityId, toEntityId = _a.toEntityId;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents/move/").concat(toEntityId),
                    method: 'POST',
                    body: { respondents: respondents },
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
        }),
        /**
         * Import survey respondent
         */
        importSurveyRespondent: build.mutation({
            query: function (_a) {
                var data = _a.data, entityId = _a.entityId;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents/import/preview"),
                    method: 'POST',
                    body: data,
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
            transformResponse: function (result, _meta, arg) {
                return {
                    result: result,
                    id: arg.entityId,
                };
            },
        }),
        /**
         * Generate responses for respondents
         */
        generateResponsesForRespondents: build.mutation({
            query: function (_a) {
                var respondents = _a.respondents, entityId = _a.entityId;
                return ({
                    url: "entities/".concat(entityId, "/survey/respondents/fake"),
                    method: 'POST',
                    body: { respondents: respondents },
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
        }),
    }); },
});
export var useAddSurveyRespondentMutation = entitySurveyRespondentApi.useAddSurveyRespondentMutation, useDeleteSurveyRespondentMutation = entitySurveyRespondentApi.useDeleteSurveyRespondentMutation, useUpdateSurveyRespondentMutation = entitySurveyRespondentApi.useUpdateSurveyRespondentMutation, useMoveSurveyRespondentMutation = entitySurveyRespondentApi.useMoveSurveyRespondentMutation, useImportSurveyRespondentMutation = entitySurveyRespondentApi.useImportSurveyRespondentMutation, useGenerateResponsesForRespondentsMutation = entitySurveyRespondentApi.useGenerateResponsesForRespondentsMutation;
