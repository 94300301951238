var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonLink, Checkbox, Label, } from '@dimatech/shared/lib/components/form';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { appActions, selectIsUserMessageShown } from '../../api/appSlice';
import { useGetMyMessagesQuery, useMarkAsReadMutation, } from '../../api/user/userMessageApi';
import { AuthenticationContext } from '../authentication/AuthenticationProvider';
import { Message } from './Message';
export var Messages = function (_a) {
    var _b, _c;
    var style = _a.style;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isUserMessageShown = useSelector(selectIsUserMessageShown);
    var markAsRead = useMarkAsReadMutation()[0];
    var data = useGetMyMessagesQuery((_c = (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : skipToken).data;
    var _d = useState([]), messages = _d[0], setMessages = _d[1];
    var _e = useState(), selectedMessage = _e[0], setSelectedMessage = _e[1];
    var _f = useState(true), showUnreadOnly = _f[0], setShowUnreadOnly = _f[1];
    var _g = useState(false), hasUnreadMessages = _g[0], setHasUnreadMessages = _g[1];
    var isUserMessagesEnabledFlagOn = useFlags()[flags.permanent.shared.isUserMessagesEnabled];
    var markAllAsRead = function () {
        markAsRead(undefined);
    };
    var handleSelectMessage = function (message) {
        markAsRead(message.id);
        setSelectedMessage(message);
    };
    var handleHideMessages = function () {
        dispatch(appActions.setIsUserMessageShown(false));
    };
    useEffect(function () {
        var _a;
        var unread = (_a = data === null || data === void 0 ? void 0 : data.filter(function (m) { return !m.read; })) !== null && _a !== void 0 ? _a : [];
        var filtered = showUnreadOnly ? unread : data;
        setHasUnreadMessages(unread.length > 0);
        setMessages(filtered !== null && filtered !== void 0 ? filtered : []);
    }, [showUnreadOnly, data]);
    if (!isUserMessagesEnabledFlagOn || !isUserMessageShown) {
        return null;
    }
    return (_jsxs(Style, __assign({ style: style }, { children: [_jsx("div", { children: !selectedMessage && (_jsxs(_Fragment, { children: [_jsxs(Label, __assign({ htmlFor: "showUnreadOnly" }, { children: [_jsx(Checkbox, { onChange: function () { return setShowUnreadOnly(!showUnreadOnly); }, id: "showUnreadOnly", name: "showUnreadOnly", style: { marginRight: 5 }, value: "1", checked: showUnreadOnly }), t('Messenger.ViewUnreadMessagesOnly')] })), hasUnreadMessages && (_jsx(ButtonLink, __assign({ onClick: function () { return markAllAsRead(); } }, { children: t('Messenger.MarkAllAsRead') })))] })) }), !selectedMessage && data && data.length === 0 && (_jsx("div", __assign({ className: "i" }, { children: t('Messenger.NoMessages') }))), !selectedMessage && data && data.length > 0 && messages.length === 0 && (_jsx("div", __assign({ className: "i" }, { children: t('Messenger.NoUnreadMessages') }))), selectedMessage && (_jsx(Message, { message: selectedMessage, isSelected: true })), !selectedMessage &&
                (messages === null || messages === void 0 ? void 0 : messages.map(function (message) { return (_jsx(Message, { message: message, handleSelect: function () { return handleSelectMessage(message); } }, message.id)); })), _jsxs("div", { children: [selectedMessage && (_jsx(ButtonLink, __assign({ style: { margin: '10px 0' }, onClick: function () { return setSelectedMessage(undefined); } }, { children: t('Common.UI.Back') }))), _jsx(ButtonLink, __assign({ style: { margin: '10px 0' }, onClick: handleHideMessages }, { children: t('Common.UI.Close') }))] })] })));
};
Messages.displayName = 'Messages';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 1000;\n  position: absolute;\n  right: 0;\n  padding: 10px;\n  width: 380px;\n  min-width: 380px;\n  height: 100%;\n  overflow-y: auto;\n\n  color: ", ";\n  background-color: ", ";\n  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1), -3px 3px 3px rgba(0, 0, 0, 0.15);\n\n  display: flex;\n  flex-direction: column;\n\n  button {\n    width: fit-content;\n  }\n\n  > div:first-of-type,\n  > div:last-of-type {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n\n    label {\n      margin-top: 0;\n    }\n  }\n"], ["\n  z-index: 1000;\n  position: absolute;\n  right: 0;\n  padding: 10px;\n  width: 380px;\n  min-width: 380px;\n  height: 100%;\n  overflow-y: auto;\n\n  color: ", ";\n  background-color: ", ";\n  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1), -3px 3px 3px rgba(0, 0, 0, 0.15);\n\n  display: flex;\n  flex-direction: column;\n\n  button {\n    width: fit-content;\n  }\n\n  > div:first-of-type,\n  > div:last-of-type {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n\n    label {\n      margin-top: 0;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
var templateObject_1;
