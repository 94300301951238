var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Workspace = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n\n  color: ", ";\n  background-color: ", ";\n"], ["\n  width: 100%;\n\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n\n  color: ", ";\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
});
Workspace.displayName = 'Workspace';
var templateObject_1;
