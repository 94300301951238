var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Pagination, useUiSortablePaginator, } from '@dimatech/shared/lib/components/paginator';
import { Table, TableResponsiveContainer, Td, TdRight, Th, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { SortDirection } from '@dimatech/shared/lib/models';
import { extractTextFromHTML, formatDate, shorten, } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { messageActions } from '../../../api/message/messageSlice';
import { MessageListEditButtons } from './MessageListEditButtons';
import { MessageStatus } from './MessageStatus';
export var MessageList = function (_a) {
    var messages = _a.messages, isError = _a.isError;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _b = useUiSortablePaginator({
        orderBy: 'publishDate',
        orderDirection: SortDirection.Desc,
        pageSize: 25,
        data: {
            totalRecords: (messages !== null && messages !== void 0 ? messages : []).length,
            records: messages !== null && messages !== void 0 ? messages : [],
        },
    }), sortedMessages = _b.items, setPage = _b.setPage, paginator = _b.paginator, sorter = _b.sorter;
    var handleSelect = function (userMessageId) {
        navigate("/administrate/message/".concat(userMessageId));
        dispatch(messageActions.setRecipientsToAdd([]));
        dispatch(messageActions.setRecipientsToDelete([]));
    };
    var handleCreate = function () {
        navigate("/administrate/message/new");
        dispatch(messageActions.setRecipientsToAdd([]));
        dispatch(messageActions.setRecipientsToDelete([]));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Messages.Text') })), _jsx(LinkWithTooltip, { isPrimary: true, handleClick: handleCreate, title: t('Administrate.Messages.Add.TooltipTitle'), tooltipTitle: t('Administrate.Messages.Add.TooltipTitle'), tooltip: t('Administrate.Messages.Add.Tooltip'), icon: _jsx(BsPlus, {}), style: { marginBottom: 20 } }), ((messages && (messages === null || messages === void 0 ? void 0 : messages.length) === 0) || isError) && (_jsx("div", { children: t('Administrate.Messages.NoMatches') })), messages && messages.length > 0 && !isError && (_jsxs(_Fragment, { children: [_jsx(Pagination, { style: { marginBottom: 20 }, currentPage: paginator.page, totalCount: messages.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } }), _jsx(TableResponsiveContainer, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(ThRight, __assign({ sortKey: "publishDate", sorter: sorter, style: { width: '1%' } }, { children: t('Administrate.Messages.Message.PublishDate.Title') })), _jsx(Th, __assign({ sortKey: "title", sorter: sorter }, { children: t('Administrate.Messages.Message.Title') })), _jsx(Th, __assign({ sortKey: "text", sorter: sorter }, { children: t('Administrate.Messages.Message.Text') })), _jsx(ThRight, { style: { width: 35 } })] }) }), _jsx("tbody", { children: sortedMessages.map(function (message, index) {
                                        var _a;
                                        return (_jsxs(Tr, __assign({ isHoverable: true, onSelect: function () { return handleSelect(message.id); } }, { children: [_jsxs(TdRight, __assign({ style: { whiteSpace: 'nowrap' } }, { children: [_jsx(MessageStatus, { publishDate: message.publishDate }), message.publishDate
                                                            ? formatDate(parseISO(message.publishDate))
                                                            : '-'] })), _jsx(Td, { children: message.title }), _jsx(Td, { children: shorten(extractTextFromHTML((_a = message.text) !== null && _a !== void 0 ? _a : ''), 300) }), _jsx(TdRight, { children: _jsx(MessageListEditButtons, { message: message }) })] }), index));
                                    }) })] }) }), _jsx(Pagination, { style: { marginBottom: 20 }, currentPage: paginator.page, totalCount: messages.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } })] }))] }));
};
MessageList.displayName = 'MessageList';
