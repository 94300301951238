var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { config } from '@dimatech/shared/lib/config';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQueryWithReAuthentication } from '../middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '../middleware/axiosRequestInterceptor';
// Initialize an empty Dimios api service that we'll inject endpoints into
export var CacheTagsLicense;
(function (CacheTagsLicense) {
    CacheTagsLicense["Type"] = "LicenseType";
})(CacheTagsLicense || (CacheTagsLicense = {}));
export var CacheTagsUserMessage;
(function (CacheTagsUserMessage) {
    CacheTagsUserMessage["UserMessage"] = "UserMessage";
})(CacheTagsUserMessage || (CacheTagsUserMessage = {}));
export var CacheTagsUser;
(function (CacheTagsUser) {
    CacheTagsUser["UserSearch"] = "UserSearch";
    CacheTagsUser["CustomerAdmin"] = "CustomerAdmin";
    CacheTagsUser["CustomerAccountAdmin"] = "CustomerAccountAdmin";
    CacheTagsUser["Viewer"] = "Viewer";
    CacheTagsUser["UserSearchWithRole"] = "UserSearchWithRole";
})(CacheTagsUser || (CacheTagsUser = {}));
export var CacheTagsEntity;
(function (CacheTagsEntity) {
    CacheTagsEntity["Entity"] = "Entity";
})(CacheTagsEntity || (CacheTagsEntity = {}));
export var CacheTagsTag;
(function (CacheTagsTag) {
    CacheTagsTag["Tag"] = "Tag";
})(CacheTagsTag || (CacheTagsTag = {}));
export var CacheTagsCustomer;
(function (CacheTagsCustomer) {
    CacheTagsCustomer["ApiKey"] = "ApiKey";
    CacheTagsCustomer["BillingInfo"] = "CustomerBillingInfo";
    CacheTagsCustomer["CustomerAdmin"] = "CustomerAdmin";
    CacheTagsCustomer["CustomerAccountAdmin"] = "CustomerAccountAdmin";
    CacheTagsCustomer["IsRegisteredInProduct"] = "CustomerIsRegisteredInProduct";
    CacheTagsCustomer["Locale"] = "Locale";
})(CacheTagsCustomer || (CacheTagsCustomer = {}));
export var CacheTagsMessage;
(function (CacheTagsMessage) {
    CacheTagsMessage["Message"] = "Message";
    CacheTagsMessage["MessageRecipients"] = "MessageRecipients";
})(CacheTagsMessage || (CacheTagsMessage = {}));
export var CacheTagsFeatureFlag;
(function (CacheTagsFeatureFlag) {
    CacheTagsFeatureFlag["FeatureFlag"] = "FeatureFlag";
})(CacheTagsFeatureFlag || (CacheTagsFeatureFlag = {}));
export var CacheTagsMessageTemplate;
(function (CacheTagsMessageTemplate) {
    CacheTagsMessageTemplate["CommonMessageTemplate"] = "CommonMessageTemplate";
    CacheTagsMessageTemplate["EntityMessageTemplate"] = "EntityMessageTemplate";
})(CacheTagsMessageTemplate || (CacheTagsMessageTemplate = {}));
var cacheTags = [
    Object.values(CacheTagsEntity),
    Object.values(CacheTagsUser),
    Object.values(CacheTagsUserMessage),
    Object.values(CacheTagsCustomer),
    Object.values(CacheTagsMessage),
    Object.values(CacheTagsFeatureFlag),
    Object.values(CacheTagsLicense),
].join(',');
initializeAxiosConfig(__assign({}, config.axiosDefaults));
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();
// Api
export var featuresCoreApi = createApi({
    reducerPath: 'featuresCoreApi',
    keepUnusedDataFor: 3600,
    tagTypes: [cacheTags],
    baseQuery: axiosBaseQueryWithReAuthentication({
        baseUrl: config.apiUrl.product.current,
    }),
    endpoints: function () { return ({}); },
});
