import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PrinciplesByEntity, SortDirection } from 'models';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsPercent } from 'utils';

export const ChildResultPerSubCategory = ({
  principlesByEntity,
  saveToPngElementId,
}: {
  principlesByEntity: PrinciplesByEntity[];
  saveToPngElementId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const showDevDebugInfo = useFlags()[flags.permanent.shared.showDevDebugInfo];

  const formatter = (value: string | number | undefined) =>
    formatAsPercent(value, '-') as string;

  const { items, sorter } = useSortableData(principlesByEntity, {
    key: 'name',
    direction: SortDirection.Asc,
  });

  return (
    <TableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th sortKey="entityName" sorter={sorter}>
              {t('CommonTerms.Entity')}
            </Th>

            <ThRight
              sortKey="efficiency"
              sorter={sorter}
              sortPredicate={({ ability: a }, { ability: b }) => {
                return {
                  a: a.efficiency.percentage,
                  b: b.efficiency.percentage,
                };
              }}
            >
              {t('CommonTerms.Efficiency')}
            </ThRight>

            <ThRight
              sortKey="innovation"
              sorter={sorter}
              sortPredicate={({ ability: a }, { ability: b }) => {
                return {
                  a: a.innovation.percentage,
                  b: b.innovation.percentage,
                };
              }}
            >
              {t('CommonTerms.Innovation')}
            </ThRight>

            <ThRight
              sortKey="balance"
              sorter={sorter}
              sortPredicate={({ ability: a }, { ability: b }) => {
                return {
                  a: a.balance.percentage,
                  b: b.balance.percentage,
                };
              }}
            >
              {t('CommonTerms.Balance')}
            </ThRight>

            <ThRight
              sortKey="organisation"
              sorter={sorter}
              sortPredicate={({ heritage: a }, { heritage: b }) => {
                return {
                  a: a.organisation.percentage,
                  b: b.organisation.percentage,
                };
              }}
            >
              {t('CommonTerms.Organisation')}
            </ThRight>

            <ThRight
              sortKey="users"
              sorter={sorter}
              sortPredicate={({ heritage: a }, { heritage: b }) => {
                return {
                  a: a.users.percentage,
                  b: b.users.percentage,
                };
              }}
            >
              {t('CommonTerms.Users')}
            </ThRight>

            <ThRight
              sortKey="technology"
              sorter={sorter}
              sortPredicate={({ heritage: a }, { heritage: b }) => {
                return {
                  a: a.technology.percentage,
                  b: b.technology.percentage,
                };
              }}
            >
              {t('CommonTerms.Technology')}
            </ThRight>
            <ThCentered
              style={{ width: 150 }}
              sortKey="digitalMaturity"
              sorter={sorter}
              sortPredicate={({ percentage: a }, { percentage: b }) => {
                return { a, b };
              }}
            >
              {t('CommonTerms.DigitalMaturity')}
            </ThCentered>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <Fragment key={index}>
              <Tr>
                <Td>{item.entityName}</Td>
                <TdRight data-value={item.ability.efficiency.percentage}>
                  {formatter(item.ability.efficiency.percentage)}
                </TdRight>
                <TdRight data-value={item.ability.innovation.percentage}>
                  {formatter(item.ability.innovation.percentage)}
                </TdRight>
                <TdRight data-value={item.ability.balance.percentage}>
                  {formatter(item.ability.balance.percentage)}
                </TdRight>
                <TdRight data-value={item.heritage.organisation.percentage}>
                  {formatter(item.heritage.organisation.percentage)}
                </TdRight>
                <TdRight data-value={item.heritage.users.percentage}>
                  {formatter(item.heritage.users.percentage)}
                </TdRight>
                <TdRight data-value={item.heritage.technology.percentage}>
                  {formatter(item.heritage.technology.percentage)}
                </TdRight>
                <TdCentered
                  data-value={
                    ((item.ability.percentage ?? 0) +
                      (item.heritage.percentage ?? 0)) /
                    2
                  }
                >
                  <Progress
                    formatter={formatter}
                    singleValue={true}
                    value={
                      ((item.ability.percentage ?? 0) +
                        (item.heritage.percentage ?? 0)) /
                      2
                    }
                  />
                </TdCentered>
              </Tr>

              {showDevDebugInfo && (
                <Tr className="debug-info">
                  <Td>{item.entityName}</Td>
                  <TdRight>
                    {item.ability.efficiency.average?.toString()}
                  </TdRight>
                  <TdRight>
                    {item.ability.innovation.average?.toString()}
                  </TdRight>
                  <TdRight>{item.ability.balance.average?.toString()}</TdRight>
                  <TdRight>
                    {item.heritage.organisation.average?.toString()}
                  </TdRight>
                  <TdRight>{item.heritage.users.average?.toString()}</TdRight>
                  <TdRight>
                    {item.heritage.technology.average?.toString()}
                  </TdRight>
                  <TdCentered>
                    {(
                      ((item.ability.average ?? 0) +
                        (item.heritage.average ?? 0)) /
                      2
                    )?.toString()}
                  </TdCentered>
                </Tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

ChildResultPerSubCategory.displayName = 'ChildResultPerSubCategory';
