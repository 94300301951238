var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { BsX } from 'react-icons/bs';
import { formatDate } from '../../utils';
import { BadgeMini } from '../Badge';
import { Heading4 } from '../typography';
import { ToastStyle } from './ToastStyle';
export var ToastError = function (_a) {
    var _b;
    var error = _a.error, style = _a.style, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var date = new Date();
    var utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes());
    return (_jsxs(ToastStyle, __assign({ style: style }, { children: [_jsxs("div", { children: [_jsxs(Heading4, { children: [(_b = error.title) !== null && _b !== void 0 ? _b : t('Common.Error.Title'), error.count > 1 &&
                                " - ".concat(t('Common.Error.Count', { count: error.count }))] }), _jsx(BadgeMini, __assign({ onClick: function () {
                            handleClose(error);
                        }, "$inverted": true }, { children: _jsx(BsX, {}) }))] }), _jsx("div", { children: error.message }), error.extraInfo && _jsx("div", __assign({ className: "error-extra" }, { children: error.extraInfo })), _jsxs("div", __assign({ className: "size-s", style: { marginTop: 10 } }, { children: ["UTC: ", formatDate(utc)] }))] })));
};
ToastError.displayName = 'ToastError';
