import { mergeJson } from '@dimatech/shared/lib/utils';
import administrate_account_en from './lang/administrate-account/en.json';
import administrate_account_sv from './lang/administrate-account/sv.json';
import administrate_message_template_en from './lang/administrate-message-template/en.json';
import administrate_message_template_sv from './lang/administrate-message-template/sv.json';
import administrate_organisation_en from './lang/administrate-organisation/en.json';
import administrate_organisation_sv from './lang/administrate-organisation/sv.json';
import administrate_respondents_en from './lang/administrate-respondents/en.json';
import administrate_respondents_sv from './lang/administrate-respondents/sv.json';
import administrate_schedule_en from './lang/administrate-schedule/en.json';
import administrate_schedule_sv from './lang/administrate-schedule/sv.json';
import administrate_stories_en from './lang/administrate-stories/en.json';
import administrate_stories_sv from './lang/administrate-stories/sv.json';
import administrate_view_status_en from './lang/administrate-view-status/en.json';
import administrate_view_status_sv from './lang/administrate-view-status/sv.json';
import global_administrate_overview_en from './lang/global-administrate-overview/en.json';
import global_administrate_overview_sv from './lang/global-administrate-overview/sv.json';
import global_administrate_reports_en from './lang/global-administrate-reports/en.json';
import global_administrate_reports_sv from './lang/global-administrate-reports/sv.json';
import roles_en from './lang/roles/en.json';
import roles_sv from './lang/roles/sv.json';
import default_en from './lang/en.json';
import default_sv from './lang/sv.json';
export var features_survey_en = mergeJson(default_en, [
    administrate_account_en,
    administrate_message_template_en,
    administrate_organisation_en,
    administrate_respondents_en,
    administrate_schedule_en,
    administrate_stories_en,
    administrate_view_status_en,
    global_administrate_overview_en,
    global_administrate_reports_en,
    roles_en,
]);
export var features_survey_sv = mergeJson(default_sv, [
    administrate_account_sv,
    administrate_message_template_sv,
    administrate_organisation_sv,
    administrate_respondents_sv,
    administrate_schedule_sv,
    administrate_stories_sv,
    administrate_view_status_sv,
    global_administrate_overview_sv,
    global_administrate_reports_sv,
    roles_sv,
]);
