var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, ButtonFooter, ButtonSecondary, Buttons, Label, Radio, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { SurveyResponseStatus } from '@dimatech/shared/lib/models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteSurveyRespondentMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { entitySurveyRespondentActions, selectDeletingRespondent, } from '../../../api/entity/entitySurveyRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
export var RespondentActionDelete = function (_a) {
    var respondent = _a.respondent, selectedEntityId = _a.selectedEntityId, setError = _a.setError, isReadOnly = _a.isReadOnly, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var deletingRespondent = useSelector(selectDeletingRespondent);
    var _b = useDeleteSurveyRespondentMutation(), deleteRespondent = _b[0], isSuccess = _b[1].isSuccess;
    var _c = useState(false), isDeleting = _c[0], setIsDeleting = _c[1];
    var _d = useState(true), shouldDataBeKept = _d[0], setShouldDataBeKept = _d[1];
    var handleConfirmDelete = function (e) {
        e.stopPropagation();
        if (isReadOnly || !deletingRespondent) {
            return;
        }
        deleteRespondent({
            entityId: selectedEntityId,
            respondents: [deletingRespondent],
            keepData: respondent.status === SurveyResponseStatus.Finished && shouldDataBeKept,
        });
        dispatch(entitySurveyRespondentActions.setDeletingRespondent());
    };
    var handleDelete = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setDeletingRespondent(show ? respondent.email : undefined));
    };
    var handleMove = function (e) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setMovingRespondent(respondent.email));
    };
    useEffect(function () {
        setIsDeleting(!!deletingRespondent && deletingRespondent === respondent.email);
    }, [deletingRespondent, respondent]);
    useEffect(function () {
        if (isSuccess) {
            handleRespondentMovedOrDeleted === null || handleRespondentMovedOrDeleted === void 0 ? void 0 : handleRespondentMovedOrDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.Delete.ConfirmTitle'), handleKeyEnter: handleConfirmDelete, handleKeyEsc: function (e) { return handleDelete(e, false); } }, { children: [!respondent.isDeleted && (_jsx("div", { children: t('Administrate.Respondent.Delete.ConfirmText') })), respondent.isDeleted && (_jsx(AlertWarning, { children: t('Administrate.Respondent.Delete.ConfirmWarningPermanent') })), respondent.status === SurveyResponseStatus.Finished &&
                        !respondent.isDeleted && (_jsxs("div", __assign({ style: { marginTop: 15 } }, { children: [_jsx("div", { children: t('Administrate.Respondent.Delete.ShouldDataBeKept.Title') }), _jsxs("div", { children: [_jsxs(Label, __assign({ htmlFor: "keepData" }, { children: [_jsx(Radio, { onChange: function (e) {
                                                    setShouldDataBeKept(e.target.checked);
                                                }, id: "keepData", name: "shouldDataBeKept", value: 1, checked: shouldDataBeKept, style: { marginRight: 7 } }), t('Administrate.Respondent.Delete.ShouldDataBeKept.KeepData')] })), _jsxs(Label, __assign({ htmlFor: "doNotKeepData" }, { children: [_jsx(Radio, { onChange: function (e) {
                                                    setShouldDataBeKept(!e.target.checked);
                                                }, id: "doNotKeepData", name: "shouldDataBeKept", value: 1, checked: !shouldDataBeKept, style: { marginRight: 7 } }), t('Administrate.Respondent.Delete.ShouldDataBeKept.DoNotKeepData')] }))] })] }))), _jsx(AlertWarning, __assign({ style: { margin: '15px 0' } }, { children: t('Administrate.Respondent.Delete.ConfirmWarning') })), _jsx("div", __assign({ className: "i size-s" }, { children: t('Administrate.Respondent.Delete.ConfirmNote') })), _jsxs(ButtonFooter, { children: [_jsx(Buttons, { children: _jsx(ButtonSecondary, __assign({ type: "button", onClick: handleMove }, { children: t('Administrate.Respondent.Move.TooltipTitle') })) }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleConfirmDelete, disabled: isReadOnly }, { children: t('Common.UI.Yes') }))] })] })] }))), _jsx(TooltipContent, { id: "tt-respondent-delete", title: t('Administrate.Respondent.Delete.TooltipTitle'), text: t('Administrate.Respondent.Delete.Tooltip') }), _jsx(BadgeMini, __assign({ style: { margin: '0 0 0 3px' }, "data-tip": true, "data-for": "tt-respondent-delete", onClick: function (e) { return handleDelete(e, filter.instance.isCurrentInstance); }, disabled: !filter.instance.isCurrentInstance }, { children: _jsx(BsTrash, {}) }))] }));
};
RespondentActionDelete.displayName = 'RespondentActionDelete';
