var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CacheTagsEntity, featuresSurveyApi } from '../featuresSurveyApi';
import { entitySurveyApi } from './entitySurveyApi';
var entitySurveyMessageApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Add survey message
         */
        addSurveyMessage: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, sendDate = _a.sendDate, messageType = _a.messageType;
                return ({
                    url: "entities/".concat(entityId, "/survey/messages"),
                    method: 'POST',
                    body: {
                        sendDate: sendDate,
                        messageType: messageType,
                    },
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
            transformResponse: function (result, _meta, arg) {
                return {
                    messageId: result,
                    messageType: arg.messageType,
                    sendDate: arg.sendDate,
                    isDirty: false,
                };
            },
        }),
        /**
         * Update survey message
         */
        updateSurveyMessage: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, sendDate = _a.sendDate, messageId = _a.messageId;
                return ({
                    url: "entities/".concat(entityId, "/survey/messages"),
                    method: 'PUT',
                    body: {
                        messageId: messageId,
                        sendDate: sendDate,
                    },
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
        }),
        /**
         * Delete survey message
         */
        deleteSurveyMessage: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, messageId = _a.messageId;
                return ({
                    url: "entities/".concat(entityId, "/survey/messages/").concat(messageId),
                    method: 'DELETE',
                });
            },
            invalidatesTags: [CacheTagsEntity.EntitySurvey],
        }),
        /**
         * Update survey message
         */
        resendUserSurveyMessage: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, userId = _a.userId, messageId = _a.messageId;
                return ({
                    url: "entities/".concat(entityId, "/survey/messages/resend"),
                    method: 'PUT',
                    body: {
                        messageId: messageId,
                        userId: userId,
                    },
                });
            },
            onQueryStarted: function (_a, _b) {
                var userId = _a.userId, entityId = _a.entityId, _customerId = _a._customerId;
                var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled;
                return __awaiter(this, void 0, void 0, function () {
                    var createdMessage;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0: return [4 /*yield*/, queryFulfilled];
                            case 1:
                                createdMessage = (_c.sent()).data;
                                dispatch(entitySurveyApi.util.updateQueryData('getEntitySurvey', { entityId: entityId, _customerId: _customerId }, function (entitySurvey) {
                                    var _a;
                                    var respondent = (_a = entitySurvey.respondents) === null || _a === void 0 ? void 0 : _a.find(function (respondent) { return respondent.userId === userId; });
                                    if (respondent) {
                                        respondent.messages.push(createdMessage);
                                    }
                                }));
                                return [2 /*return*/];
                        }
                    });
                });
            },
        }),
    }); },
});
export var useAddSurveyMessageMutation = entitySurveyMessageApi.useAddSurveyMessageMutation, useDeleteSurveyMessageMutation = entitySurveyMessageApi.useDeleteSurveyMessageMutation, useUpdateSurveyMessageMutation = entitySurveyMessageApi.useUpdateSurveyMessageMutation, useResendUserSurveyMessageMutation = entitySurveyMessageApi.useResendUserSurveyMessageMutation;
