var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, Label } from '@dimatech/shared/lib/components/form';
import { useSortablePaginator } from '@dimatech/shared/lib/components/paginator';
import { Table, Td, TdRight, Th, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { formatDate } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckAll, BsX } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUsers, userActions } from '../../../api/user/userSlice';
export var RecipientsSearchResult = function (_a) {
    var data = _a.data, initialPaginator = _a.initialPaginator, handlePaginatorChange = _a.handlePaginatorChange;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var selectedUsers = useSelector(selectSelectedUsers);
    var _b = useState(), users = _b[0], setUsers = _b[1];
    var noOfSelected = selectedUsers ? selectedUsers.length : 0;
    var noOfTotal = data === null || data === void 0 ? void 0 : data.records.length;
    var isAllChecked = noOfSelected === noOfTotal;
    var sorter = useSortablePaginator(__assign(__assign({}, initialPaginator), { handlePaginatorChange: handlePaginatorChange })).sorter;
    var handleSelectAll = function (checked) {
        var newUsers = users === null || users === void 0 ? void 0 : users.map(function (user) { return (__assign(__assign({}, user), { isSelected: checked })); });
        setUsers(newUsers);
        dispatch(userActions.selectedUsers(newUsers === null || newUsers === void 0 ? void 0 : newUsers.filter(function (user) { return user.isSelected; })));
    };
    var handleSelect = function (e, userId) {
        var newUsers = __spreadArray([], (users !== null && users !== void 0 ? users : []), true);
        var index = newUsers.findIndex(function (user) { return user.id === userId || user.uid === userId; });
        newUsers[index] = __assign(__assign({}, newUsers[index]), { isSelected: e.target.checked });
        setUsers(newUsers);
        dispatch(userActions.selectedUsers(newUsers.filter(function (user) { return user.isSelected; })));
    };
    useEffect(function () {
        if (data) {
            setUsers(data === null || data === void 0 ? void 0 : data.records);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data === null || data === void 0 ? void 0 : data.records]);
    return users && users.length === 0 ? (_jsx("div", { children: t('Administrate.Messages.Message.Recipient.NoMatches') })) : (_jsxs(_Fragment, { children: [users && users.length > 0 && (_jsx(LinkWithTooltip, { style: { margin: '10px 20px 10px 0' }, handleClick: function () { return handleSelectAll(!isAllChecked); }, title: isAllChecked ? t("Common.UI.DeselectAll") : t("Common.UI.SelectAll"), icon: isAllChecked ? _jsx(BsX, {}) : _jsx(BsCheckAll, {}), disabledTimerInMs: 1 })), users && (_jsx("div", __assign({ style: { overflowY: 'scroll', height: '50%', maxHeight: 500 } }, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, { style: { width: 35 }, isExportIgnored: true }), _jsx(Th, __assign({ sortKey: "email", sorter: sorter }, { children: t('Administrate.Messages.Message.Recipient.Email') })), _jsx(ThRight, __assign({ sortKey: "lastLoginDate", sorter: sorter, isExportIgnored: true }, { children: t('Administrate.Messages.Message.Recipient.LastLogin') }))] }) }), _jsx("tbody", { children: users.map(function (item, index) {
                                var _a, _b, _c, _d;
                                return (_jsxs(Tr, { children: [_jsx(Td, __assign({ "data-export-ignore": true }, { children: _jsx(Checkbox, { onChange: function (e) {
                                                    var _a;
                                                    handleSelect(e, (_a = item.id) !== null && _a !== void 0 ? _a : item.uid);
                                                }, id: index + "_".concat((_a = item.id) !== null && _a !== void 0 ? _a : item.uid), name: (_b = item.id) !== null && _b !== void 0 ? _b : item.uid, value: (_c = item.id) !== null && _c !== void 0 ? _c : item.uid, checked: !!item.isSelected }) })), _jsx(Td, { children: _jsx(Label, __assign({ style: { margin: 0 }, htmlFor: index + "_".concat((_d = item.id) !== null && _d !== void 0 ? _d : item.uid) }, { children: item.email })) }), _jsx(TdRight, __assign({ "data-export-ignore": true, style: {
                                                whiteSpace: 'nowrap',
                                            } }, { children: _jsx(Label, __assign({ style: { margin: 0, justifyContent: 'end' }, htmlFor: index + "_".concat(item.id) }, { children: item.lastLoginDate
                                                    ? formatDate(parseISO(item.lastLoginDate))
                                                    : '-' })) }))] }, index));
                            }) })] }) })))] }));
};
RecipientsSearchResult.displayName = 'RecipientsSearchResult';
