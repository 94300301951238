var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { AlertSuccess, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { Switch } from '@dimatech/shared/lib/components/form';
import { ScheduleStatus } from '@dimatech/shared/lib/models';
import { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateIndividualMeasurementPeriodMutation } from '../../../api/entity/entityApi';
import { entityActions } from '../../../api/entity/entitySlice';
import { selectFilter } from '../../../api/filter/filterSlice';
import { ScheduleIndividualScheduleConfirm } from './ScheduleIndividualScheduleConfirm';
export var ScheduleIndividualSchedule = function (_a) {
    var entity = _a.entity, useIndividualMeasurementPeriods = _a.useIndividualMeasurementPeriods, setUseIndividualMeasurementPeriods = _a.setUseIndividualMeasurementPeriods, style = _a.style, status = _a.status, handleClearSchedule = _a.handleClearSchedule;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var updateIndividualMeasurementPeriod = useUpdateIndividualMeasurementPeriodMutation()[0];
    var _b = useState(false), hasIndividualMeasurementPeriods = _b[0], setHasIndividualMeasurementPeriods = _b[1];
    var _c = useState(false), isShowingConfirm = _c[0], setIsShowingConfirm = _c[1];
    var accessToken = useContext(AuthenticationContext).accessToken;
    var canEditIndividualMeasurementPeriods = accessToken.isInRole(CommonRoles.CustomerAccountAdmin) ||
        accessToken.isInRole(CommonRoles.GlobalAdmin);
    useEffect(function () {
        setUseIndividualMeasurementPeriods(!!entity.allowIndividualMeasurementPeriods);
        setHasIndividualMeasurementPeriods(!!entity.anyChildHasIndividualMeasurementPeriod);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity]);
    var handleCheckIfConfirm = function (value) {
        // Show confirm dialog if trying to turn off periods and any
        // child currently uses individual measurement periods
        if (hasIndividualMeasurementPeriods && !value) {
            setIsShowingConfirm(true);
        }
        else {
            handleUpdateIndividualMeasurementPeriods(value);
        }
    };
    var handleConfirm = function () {
        handleUpdateIndividualMeasurementPeriods(!useIndividualMeasurementPeriods);
    };
    var handleUpdateIndividualMeasurementPeriods = function (value) {
        var _a;
        setUseIndividualMeasurementPeriods(value);
        setIsShowingConfirm(false);
        if (entity === null || entity === void 0 ? void 0 : entity.parentId) {
            updateIndividualMeasurementPeriod({
                entityId: entity.id,
                allowIndividualMeasurementPeriods: value,
            })
                .unwrap()
                .then(function () {
                if (value === true && status === ScheduleStatus.NotStarted) {
                    handleClearSchedule();
                }
                dispatch(entityActions.selectedEntity(__assign(__assign({}, entity), { allowIndividualMeasurementPeriods: value })));
            });
            setHasIndividualMeasurementPeriods(((_a = entity === null || entity === void 0 ? void 0 : entity.childCount) !== null && _a !== void 0 ? _a : 0) > 0 && value);
        }
    };
    return (_jsxs(_Fragment, { children: [(entity === null || entity === void 0 ? void 0 : entity.parentId) && (_jsxs(_Fragment, { children: [!canEditIndividualMeasurementPeriods &&
                        useIndividualMeasurementPeriods && (_jsx(AlertSuccess, __assign({ style: __assign({}, style) }, { children: t('Administrate.Schedule.IndividualMeasurementPeriods.Use.Uses') }))), !canEditIndividualMeasurementPeriods &&
                        !useIndividualMeasurementPeriods && (_jsx(AlertWarning, __assign({ style: __assign({}, style) }, { children: t('Administrate.Schedule.IndividualMeasurementPeriods.Use.NotUsedInformation') }))), canEditIndividualMeasurementPeriods && (_jsx("div", __assign({ style: __assign({ margin: '20px 0 20px 10px' }, style) }, { children: _jsx(Switch, { label: t('Administrate.Schedule.IndividualMeasurementPeriods.Use.Label'), tooltipTitle: t('Administrate.Schedule.IndividualMeasurementPeriods.Use.TooltipTitle'), tooltipText: _jsx(Trans, { i18nKey: "Administrate.Schedule.IndividualMeasurementPeriods.Use.Tooltip" }), setIsChecked: function (value) { return handleCheckIfConfirm(value); }, isChecked: useIndividualMeasurementPeriods, isDisabled: !filter.instance.isCurrentInstance }) })))] })), isShowingConfirm && (_jsx(ScheduleIndividualScheduleConfirm, { setIsShowingConfirm: setIsShowingConfirm, handleConfirm: handleConfirm }))] }));
};
ScheduleIndividualSchedule.displayName = 'ScheduleIndividualScheduleBanner';
