import { usePolling } from '@dimatech/shared/lib//hooks';
import { config } from '@dimatech/shared/lib/config';
import { useEffect, useState } from 'react';
import { useRefreshAuthTokenMutation } from '../api/authentication/authenticationApi';
import { TokenName } from '../models';
import { AccessToken, RefreshToken, WebToken } from '../models/token';
export var useAuthentication = function () {
    var _a = useState(false), forceLogout = _a[0], setForceLogout = _a[1];
    var handleTokenUpdate = function () {
        setTokenUpdated(new Date().getTime());
    };
    var _b = useState(0), tokenUpdated = _b[0], setTokenUpdated = _b[1];
    var accessToken = new AccessToken(TokenName.Access, handleTokenUpdate);
    var refreshToken = new RefreshToken(TokenName.Refresh, handleTokenUpdate);
    var webToken = new WebToken(TokenName.Web, handleTokenUpdate);
    var refreshAuthToken = useRefreshAuthTokenMutation()[0];
    // Clears refresh token and auth token for current app
    var removeTokens = function () {
        accessToken.logout();
        refreshToken.logout();
        webToken.logout();
    };
    var logout = function () {
        removeTokens();
    };
    usePolling(function (resume) {
        refreshToken.refresh();
        webToken.refresh();
        if (!refreshToken.isValid() && !webToken.isValid()) {
            // Refresh token missing or expired, force logout
            console.info('Missing or expired refresh/web-token, forced logout');
            setForceLogout(true);
        }
        else if (refreshToken.isValid() &&
            // Only get new accessToken if there is less than the poll interval left (with some margin)
            // to make sure we get a new one before accessToken expires
            accessToken.expiresInSeconds() <=
                config.authentication.tokenPollInterval / 1000 + 30) {
            // Refresh using the refresh token
            console.info('Refreshing tokens...');
            refreshAuthToken({ refreshToken: refreshToken.token })
                .unwrap()
                .then(function (result) {
                accessToken.update(result.accessToken);
                refreshToken.update(result.refreshToken);
            })
                .catch(function () {
                // Ignore error, just force logout
                setForceLogout(true);
            });
        }
        resume();
    }, config.authentication.tokenPollInterval);
    useEffect(function () {
        // Force logout, this could potentially have side effects
        // so we need to call it from useEffect
        if (forceLogout) {
            setForceLogout(false);
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceLogout]);
    return {
        accessToken: accessToken,
        refreshToken: refreshToken,
        webToken: webToken,
        tokenUpdated: tokenUpdated,
        logout: logout,
    };
};
