var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerLineOfBusiness } from '@dimatech/features-core/lib/models';
import { isDemoCustomer } from '@dimatech/features-core/lib/utils';
import { Checkbox, Label, Select } from '@dimatech/shared/lib/components/form';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
export var EntityTypeInfo = function (_a) {
    var entity = _a.entity, isPoo = _a.isPoo, isValid = _a.isValid, setIsValid = _a.setIsValid, setIsPoo = _a.setIsPoo, setEntity = _a.setEntity, selectedEntityType = _a.selectedEntityType, setSelectedEntityType = _a.setSelectedEntityType;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isDemo = isDemoCustomer(accessToken);
    var isAdding = entity && !entity.id;
    var handleEntityTypeSelect = function (e) {
        setSelectedEntityType(e.currentTarget.value);
        setEntity(__assign(__assign({}, entity), { type: e.currentTarget.value }));
    };
    var handleIsPooChange = function (e) {
        setIsValid(true);
        setIsPoo(e.currentTarget.checked);
    };
    return (_jsxs("div", __assign({ style: { marginTop: 20 } }, { children: [isAdding && (_jsxs(_Fragment, { children: [_jsx(Checkbox, { checked: isPoo, name: "isPoo", id: "isPoo", value: "1", disabled: 
                        // Disable POO for demo customers since all entities need to be of type "Demo"
                        isDemo ||
                            !isAdding ||
                            (!!entity.type &&
                                Object.keys(CustomerLineOfBusiness).includes(entity.type)), onChange: function (e) { return handleIsPooChange(e); } }), _jsx(Label, __assign({ htmlFor: "isPoo", style: { display: 'inline-block', margin: 10 } }, { children: t('Administrate.Entity.Poo.Title') }))] })), isPoo && (_jsxs(_Fragment, { children: [!isAdding && (_jsxs(_Fragment, { children: [_jsx("div", { children: t('Administrate.Entity.Poo.Title') }), _jsx("div", { children: t('Administrate.Entity.Poo.LineOfBusiness', {
                                    type: t("Customer.LineOfBusiness.".concat(entity === null || entity === void 0 ? void 0 : entity.type)),
                                }) })] })), isAdding && (_jsxs("div", __assign({ style: { maxWidth: 400 } }, { children: [_jsx(Label, __assign({ htmlFor: "type", style: { marginTop: 0 } }, { children: t('Administrate.Entity.Poo.Text') })), _jsxs(Select, __assign({ name: "type", id: "type", defaultValue: selectedEntityType, onChange: function (e) { return handleEntityTypeSelect(e); }, invalid: !isValid && isPoo && selectedEntityType === '' }, { children: [_jsx("option", __assign({ value: "" }, { children: t('Administrate.Entity.Poo.SelectLineOfBusiness') })), Object.values(CustomerLineOfBusiness).map(function (value) { return (_jsx("option", __assign({ value: value }, { children: t("Customer.LineOfBusiness.".concat(value)) }), value)); })] }))] })))] }))] })));
};
EntityTypeInfo.displayName = 'EntityTypeInfo';
