var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Label } from '@dimatech/shared/lib/components/form';
import { DateTimePicker } from '@dimatech/shared/lib/components/form/DateTimePicker';
import { differenceInSeconds, parseISO } from 'date-fns';
import { useId } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCheck, BsTrash } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
export var ScheduleDate = withTheme(function (_a) {
    var date = _a.date, label = _a.label, handleDelete = _a.handleDelete, handleChange = _a.handleChange, allowEditAfterOccurrence = _a.allowEditAfterOccurrence, isDirty = _a.isDirty, theme = _a.theme, style = _a.style, isValid = _a.isValid, isReadOnly = _a.isReadOnly, min = _a.min, max = _a.max, defaultDateOnFocus = _a.defaultDateOnFocus, openToDate = _a.openToDate;
    var id = useId();
    // NOTE: Use isPast from date-fns instead?
    var hasOccurred = function (date) {
        return date ? differenceInSeconds(parseISO(date), new Date()) < 0 : false;
    };
    return (_jsxs(Style, __assign({ style: style }, { children: [_jsx(Label, __assign({ htmlFor: id }, { children: label })), _jsx(DateTimePicker, { defaultDateOnFocus: defaultDateOnFocus, name: id, date: date, setDate: function (date) {
                    handleChange(date);
                }, disabled: isReadOnly ||
                    (!allowEditAfterOccurrence && hasOccurred(date) && !isDirty), isValid: isValid, min: min, max: max, openToDate: openToDate }), _jsx(BsCheck, { style: {
                    fontSize: 20,
                    marginLeft: 15,
                    color: theme.colors.success,
                    visibility: hasOccurred(date) ? 'visible' : 'hidden',
                    display: !isReadOnly && handleDelete && !hasOccurred(date)
                        ? 'none'
                        : 'initial',
                } }), !isReadOnly && handleDelete && !hasOccurred(date) && (_jsx(BadgeMini, __assign({ style: { marginLeft: 10 }, onClick: handleDelete }, { children: _jsx(BsTrash, {}) })))] })));
});
ScheduleDate.displayName = 'ScheduleDate';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > label {\n    min-width: 100px;\n    padding-bottom: 3px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  > label {\n    min-width: 100px;\n    padding-bottom: 3px;\n  }\n"])));
var templateObject_1;
