import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { CommonRoles } from 'models';
import { formatDate, htmlDecode } from 'utils';

import { Checkbox } from '@dimatech/shared/lib/components/form';
import {
  useApproveStoryMutation,
  useDisapproveStoryMutation,
} from 'api/stories/storiesApi';
import {
  selectFilter,
  selectSelectedStories,
  selectSelectedStory,
  storyActions,
} from 'api/stories/storySlice';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AdministrateStory, AdministrateStoryFilter, Paginator } from 'models';
import { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsCheckAll,
  BsHandThumbsDown,
  BsHandThumbsUp,
  BsPencil,
  BsX,
} from 'react-icons/bs';
import styled from 'styled-components';
import { getSubCategoryDefinitions } from 'utils';
import { StoryEdit } from './StoryEdit';
import { StoryStatus } from './StoryStatus';

// eslint-disable-next-line max-lines-per-function
export const StoriesList = ({
  dataStories,
  handleGetStories,
  totalRecords,
  initialPaginator,
}: {
  dataStories?: AdministrateStory[];
  handleGetStories: (
    paginator: Paginator,
    filter: AdministrateStoryFilter
  ) => void;
  totalRecords: number | undefined;
  initialPaginator: Paginator;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const selectedStory = useAppSelector(selectSelectedStory);
  const selectedStories = useAppSelector(selectSelectedStories);
  const filter = useAppSelector(selectFilter);

  const subCategoryDefinitions = getSubCategoryDefinitions();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isCustomerAccountAdmin = accessToken.isInRole(
    CommonRoles.CustomerAccountAdmin
  );

  const [stories, setStories] = useState<AdministrateStory[]>([]);
  const [storiesForApproval, setStoriesForApproval] = useState<
    AdministrateStory[]
  >([]);
  const [storiesForReject, setStoriesForReject] = useState<AdministrateStory[]>(
    []
  );

  const [approve] = useApproveStoryMutation();
  const [disapprove] = useDisapproveStoryMutation();

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: (paginator) => handleGetStories(paginator, filter),
  });

  const noOfSelected = selectedStories ? selectedStories.length : 0;
  const noOfTotal = stories.length;
  const isAllChecked = noOfSelected === noOfTotal;

  const handleSelect = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const newStories = [...stories];
    const index = newStories.findIndex((story) => story.id === id);

    newStories[index] = {
      ...newStories[index],
      isSelected: e.target.checked,
    };

    setStories(newStories);
    dispatch(
      storyActions.selectStories(newStories.filter((story) => story.isSelected))
    );
  };

  const handleSelectAll = (checked: boolean) => {
    const newStories = stories.map((story) => ({
      ...story,
      isSelected: checked,
    }));

    setStories(newStories);

    dispatch(
      storyActions.selectStories(newStories.filter((story) => story.isSelected))
    );
  };

  const handleSelectOne = (story: AdministrateStory) => {
    dispatch(
      storyActions.selectStory(
        selectedStory?.id === story.id ? undefined : story
      )
    );
  };

  const handleApprove = () => {
    const storyIds: string[] = storiesForApproval.map((story) => story.id);
    approve(storyIds)
      .unwrap()
      .then(() => {
        if (selectedStory && storyIds.some((id) => id === selectedStory?.id)) {
          dispatch(
            storyActions.selectStory({ ...selectedStory, approved: true })
          );
        }
      });

    dispatch(storyActions.selectStories([]));
  };

  const handleDisapprove = () => {
    const storyIds = storiesForReject.map((story) => story.id);
    disapprove(storyIds)
      .unwrap()
      .then(() => {
        if (selectedStory && storyIds.some((id) => id === selectedStory?.id)) {
          dispatch(
            storyActions.selectStory({ ...selectedStory, approved: false })
          );
        }
      });
    dispatch(storyActions.selectStories([]));
  };

  useEffect(() => {
    setPage(1);
    dispatch(storyActions.selectStory());
    dispatch(storyActions.selectStories([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (dataStories) {
      setStories(dataStories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStories]);

  useEffect(() => {
    if (selectedStories) {
      const approved = selectedStories
        .filter((story) => story.approved === true)
        .map((story) => story);
      setStoriesForReject(approved);

      const notApproved = selectedStories
        .filter((story) => story.approved === false)
        .map((story) => story);
      setStoriesForApproval(notApproved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStories]);

  return (
    <>
      {totalRecords && totalRecords > 0 ? (
        <div
          style={{
            margin: '20px 0 10px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <LinkWithTooltip
            style={{ margin: '0 5px 0 0' }}
            handleClick={() => handleSelectAll(!isAllChecked)}
            title={
              isAllChecked
                ? t(`Common.UI.DeselectAll`)
                : t(`Common.UI.SelectAll`)
            }
            icon={isAllChecked ? <BsX /> : <BsCheckAll />}
            disabledTimerInMs={1}
          />

          {storiesForApproval.length > 0 && (
            <LinkWithTooltip
              style={{ margin: '0 5px' }}
              handleClick={() => handleApprove()}
              title={t(`Administrate.Stories.Edit.TitleApprove`, {
                count: storiesForApproval?.length,
              })}
              icon={<BsHandThumbsUp />}
              isDisabled={noOfSelected === 0}
              disabledTimerInMs={500}
            />
          )}
          {storiesForReject?.length > 0 && (
            <LinkWithTooltip
              style={{ margin: '0 5px' }}
              handleClick={() => handleDisapprove()}
              title={t(`Administrate.Stories.Edit.TitleDisapprove`, {
                count: storiesForReject?.length,
              })}
              icon={<BsHandThumbsDown />}
              isDisabled={noOfSelected === 0}
              disabledTimerInMs={500}
            />
          )}
        </div>
      ) : null}

      <Pagination
        currentPage={paginator.page}
        totalCount={totalRecords || 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
        style={{ marginBottom: 30 }}
      />

      {totalRecords && totalRecords > 0 ? (
        <TableResponsiveContainer>
          <Table>
            <thead>
              <tr>
                <Th style={{ width: 35 }} />
                <Th sortKey="approved" sorter={sorter}>
                  {t('Administrate.Stories.Status.Title')}
                </Th>
                <Th sortKey="value" sorter={sorter}>
                  {t('Administrate.Stories.Value')}
                </Th>
                <Th sortKey="categoryId" sorter={sorter}>
                  {t('Administrate.Stories.Category')}
                </Th>
                {!isCustomerAccountAdmin && (
                  <Th>{t('Administrate.Stories.Customer')}</Th>
                )}
                <ThRight sortKey="created" sorter={sorter}>
                  {t('Administrate.Stories.Created')}
                </ThRight>
                {(isGlobalAdmin || isResearcher || isCustomerAccountAdmin) && (
                  <ThRight style={{ width: 35 }} />
                )}
              </tr>
            </thead>

            <tbody>
              {stories?.map((story, index) => (
                <Fragment key={story.id}>
                  <Tr
                    isHoverable={true}
                    key={index}
                    isSelected={selectedStory?.id === story.id}
                    onSelect={(e) => {
                      if ((e.target as HTMLElement).nodeName === 'INPUT') {
                        return;
                      }
                      handleSelectOne(story);
                    }}
                  >
                    <Td>
                      <Checkbox
                        onChange={(e) => handleSelect(e, story.id)}
                        id={index + `_${story.id}`}
                        name={story.id}
                        value={story.id}
                        checked={!!story.isSelected}
                      />
                    </Td>
                    <Td style={{ whiteSpace: 'nowrap' }}>
                      <StoryStatus story={story} />
                    </Td>
                    <TdStyle
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(story.value) ?? '',
                      }}
                    />
                    <Td>
                      {t(
                        `CommonTerms.${
                          subCategoryDefinitions.find(
                            (category) =>
                              category.id.toString() === story.categoryId
                          )?.key
                        }`
                      )}
                    </Td>
                    {!isCustomerAccountAdmin && <Td>{story.customerName}</Td>}
                    <TdRight style={{ whiteSpace: 'nowrap' }}>
                      {story.created
                        ? formatDate(parseISO(story.created))
                        : '-'}
                    </TdRight>
                    {(isGlobalAdmin ||
                      isResearcher ||
                      isCustomerAccountAdmin) && (
                      <TdRight style={{ verticalAlign: 'middle' }}>
                        <EditButtons />
                      </TdRight>
                    )}
                  </Tr>

                  {selectedStory && selectedStory.id === story.id && (
                    <StoryEdit />
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      ) : undefined}

      <Pagination
        currentPage={paginator.page}
        totalCount={totalRecords || 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
        style={{ marginTop: 30 }}
      />
    </>
  );
};

StoriesList.displayName = 'StoriesList';

const TdStyle = styled(Td)`
  > p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const EditButtons = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent
        id="tt-story-edit"
        title={t('Administrate.Stories.Edit.TooltipTitle')}
        text={t('Administrate.Stories.Edit.Tooltip')}
      />

      <div
        style={{
          width: 30,
        }}
      >
        <BadgeMini data-tip data-for="tt-story-edit">
          <BsPencil />
        </BadgeMini>
      </div>
    </>
  );
};

EditButtons.displayName = 'EditButtons';
