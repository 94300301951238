var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ViewHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-left: 10px;\n  margin-bottom: 10px;\n  max-width: ", ";\n\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n\n  h1 {\n    margin-right: 10px;\n  }\n\n  > div {\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-end;\n  }\n"], ["\n  width: 100%;\n  margin-left: 10px;\n  margin-bottom: 10px;\n  max-width: ", ";\n\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n\n  h1 {\n    margin-right: 10px;\n  }\n\n  > div {\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-end;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.size.viewMaxWidth;
});
ViewHeader.displayName = 'ViewHeader';
var templateObject_1;
