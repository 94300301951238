export var ApiRoles;
(function (ApiRoles) {
    ApiRoles["Write"] = "Write";
    ApiRoles["Read"] = "Read";
    ApiRoles["Review"] = "Review";
})(ApiRoles || (ApiRoles = {}));
export var TokenName;
(function (TokenName) {
    TokenName["Access"] = "auth-accessToken";
    TokenName["Refresh"] = "auth-refreshToken";
    TokenName["Web"] = "auth-webToken";
})(TokenName || (TokenName = {}));
export var CommonRoles;
(function (CommonRoles) {
    CommonRoles["GlobalAdmin"] = "GlobalAdmin";
    CommonRoles["CommonUser"] = "CommonUser";
    CommonRoles["CustomerAdmin"] = "CustomerAdmin";
    CommonRoles["Researcher"] = "Researcher";
    CommonRoles["CustomerAccountAdmin"] = "CustomerAccountAdmin";
    CommonRoles["DemoUser"] = "DemoUser";
    CommonRoles["Reader"] = "Reader";
    CommonRoles["DiosRespondent"] = "DiosRespondent";
    CommonRoles["PortfolioOwner"] = "PortfolioOwner";
    CommonRoles["PiosReviewer"] = "PiosReviewer";
    CommonRoles["PiosProjectManager"] = "PiosProjectManager";
    CommonRoles["PiosEffectRealizationResponsible"] = "PiosEffectRealizationResponsible";
    CommonRoles["PiosOrderingParty"] = "PiosOrderingParty";
})(CommonRoles || (CommonRoles = {}));
export var MessageTokenAction;
(function (MessageTokenAction) {
    MessageTokenAction["Login"] = "Login";
    MessageTokenAction["Welcome"] = "Welcome";
})(MessageTokenAction || (MessageTokenAction = {}));
export var CustomerType;
(function (CustomerType) {
    CustomerType["Municipality"] = "Municipality";
    CustomerType["Region"] = "Region";
    CustomerType["Government"] = "Government";
    CustomerType["Other"] = "Other";
    CustomerType["PubliclyOwnedOrganization"] = "PubliclyOwnedOrganization";
    CustomerType["Demo"] = "Demo";
})(CustomerType || (CustomerType = {}));
export var CustomerLineOfBusiness;
(function (CustomerLineOfBusiness) {
    CustomerLineOfBusiness["RealEstate"] = "RealEstate";
    CustomerLineOfBusiness["HealthAndSocialCare"] = "HealthAndSocialCare";
    CustomerLineOfBusiness["Energy"] = "Energy";
    CustomerLineOfBusiness["Infrastructure"] = "Infrastructure";
    CustomerLineOfBusiness["OtherPoo"] = "OtherPoo";
})(CustomerLineOfBusiness || (CustomerLineOfBusiness = {}));
export var CustomerPrincipalOwnerType;
(function (CustomerPrincipalOwnerType) {
    CustomerPrincipalOwnerType["Municipality"] = "Municipality";
    CustomerPrincipalOwnerType["Region"] = "Region";
})(CustomerPrincipalOwnerType || (CustomerPrincipalOwnerType = {}));
