import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useGetSurveyInstancesQuery } from '../api/survey/surveyApi';
export var useGetSurveyInstances = function (hasData) {
    var _a;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useState(), instances = _b[0], setInstances = _b[1];
    var _c = useGetSurveyInstancesQuery(accessToken.customerId
        ? {
            _customerId: accessToken.customerId,
            _userId: (_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.id,
        }
        : skipToken), data = _c.data, isSuccess = _c.isSuccess, isLoading = _c.isLoading, isFetching = _c.isFetching;
    useEffect(function () {
        if (hasData && data) {
            var instancesWithData = data.filter(function (instance) { return instance.hasData === true; });
            setInstances(instancesWithData);
        }
        if (!hasData && data) {
            setInstances(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return { instances: instances, isSuccess: isSuccess, isLoading: isLoading, isFetching: isFetching };
};
