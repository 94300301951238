var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, Buttons, ButtonSecondary, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
export var ScheduleDisallowEntitySelectionForAnonymousUsersConfirm = function (_a) {
    var setIsShowingConfirm = _a.setIsShowingConfirm, handleConfirm = _a.handleConfirm;
    var t = useTranslation().t;
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ title: t('Administrate.Schedule.AllowEntitySelectionForAnonymousUsers.ConfirmTitle'), handleKeyEnter: function () { return handleConfirm(); }, handleKeyEsc: function () { return setIsShowingConfirm(false); } }, { children: [_jsx("div", { children: t('Administrate.Schedule.AllowEntitySelectionForAnonymousUsers.ConfirmText') }), _jsx(AlertWarning, __assign({ style: { marginBottom: 10, marginTop: 10 } }, { children: t('Administrate.Schedule.AllowEntitySelectionForAnonymousUsers.ConfirmWarning') })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsShowingConfirm(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function () { return handleConfirm(); } }, { children: t('Common.UI.Yes') }))] })] })) }));
};
ScheduleDisallowEntitySelectionForAnonymousUsersConfirm.displayName =
    'ScheduleDisallowEntitySelectionForAnonymousUsersConfirm';
