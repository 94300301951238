var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { axiosBaseQueryWithReAuthentication } from '@dimatech/features-core/lib/middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '@dimatech/features-core/lib/middleware/axiosRequestInterceptor';
import { config } from '@dimatech/shared/lib/config';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
// Initialize an empty Dimios api service that we'll inject endpoints into
export var CacheTagsEntity;
(function (CacheTagsEntity) {
    CacheTagsEntity["EntityWithSurvey"] = "EntityWithSurvey";
    CacheTagsEntity["EntitySurvey"] = "EntitySurvey";
    CacheTagsEntity["ProgressByEntity"] = "ProgressByEntity";
    CacheTagsEntity["EntityReport"] = "EntityReport";
    CacheTagsEntity["SurveyFailedMessages"] = "SurveyFailedMessages";
})(CacheTagsEntity || (CacheTagsEntity = {}));
export var CacheTagsSurvey;
(function (CacheTagsSurvey) {
    CacheTagsSurvey["SurveyInstances"] = "SurveyInstances";
})(CacheTagsSurvey || (CacheTagsSurvey = {}));
var cacheTags = [
    Object.values(CacheTagsEntity),
    Object.values(CacheTagsSurvey),
].join(',');
initializeAxiosConfig(__assign({}, config.axiosDefaults));
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();
// Api
export var featuresSurveyApi = createApi({
    reducerPath: 'featuresSurveyApi',
    keepUnusedDataFor: 3600,
    tagTypes: [cacheTags],
    baseQuery: axiosBaseQueryWithReAuthentication({
        baseUrl: config.apiUrl.product.current,
    }),
    endpoints: function () { return ({}); },
});
