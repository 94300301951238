import { CacheTags, dimiosApi } from 'api/dimiosApi';
import {
  Careless,
  CategoryByCustomer,
  CategoryCustomer,
  Disagreement,
  Principle,
  PrincipleOption,
  PrincipleOptionByPrinciple,
  PrinciplesByEntity,
  Progress,
  ProgressByEntity,
  StoriesByCategory,
  Story,
  SurveyFilter,
  SurveyOverview,
} from 'models';
import {
  datesToScheduleStatus,
  daysLeft,
  mapToCategoryByCustomer,
  mapToPrincipleOptionByPrinciple,
  mapToPrinciplesByCategory,
  mapToPrinciplesByEntity,
  mapToStoriesByCategory,
} from 'utils';

const statisticsApi = dimiosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get overview for current customer
     */
    getOverview: build.query<
      SurveyOverview,
      {
        filter: SurveyFilter;
        _customerId?: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        `overview${filterToQuery(filter)}`,
      providesTags: [CacheTags.Overview],
      transformResponse: (principles: Principle[]) => {
        // Store list of normalized PrincipleOptionByPrinciple in state
        return {
          principles,
          categories: mapToPrinciplesByCategory(principles),
        };
      },
    }),

    /**
     * Get careless overview for current customer
     */
    getCareless: build.query<
      Careless | undefined,
      {
        filter: SurveyFilter;
        _customerId?: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        `overview/careless${filterToQuery(filter)}`,
      providesTags: [CacheTags.Careless],
      transformResponse: (careless: Careless[]) => {
        // Only use first instance since we will always only have one
        return careless.length === 0 ? undefined : careless[0];
      },
    }),

    /**
     * Get progress of survey for current customer
     */
    getProgress: build.query<
      Progress,
      {
        filter: SurveyFilter;
        _customerId?: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        `overview/progress${filterToQuery(filter)}`,
      providesTags: [CacheTags.Progress],
    }),

    /**
     * Get progress of survey for current customer and entity
     */
    getProgressByEntity: build.query<
      ProgressByEntity[],
      {
        filter: SurveyFilter;
        _customerId: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        // cspell: disable-next-line
        `overview/progressbyentity${filterToQuery(filter)}`,
      keepUnusedDataFor: 60,
      transformResponse: (result: ProgressByEntity[]): ProgressByEntity[] => {
        // Store list of normalized PrincipleOptionByPrinciple in state
        const items = result.map((item) => {
          return {
            ...item,
            finished: item.finished ?? 0,
            daysLeft: daysLeft(item.startDate, item.endDate),
            status: datesToScheduleStatus(item.startDate, item.endDate),
            responseRate: item.finished
              ? (item.finished / item.total) * 100
              : 0,
          };
        });

        return items;
      },
      providesTags: [CacheTags.ProgressByEntity],
    }),

    /**
     * Get stories from survey for current customer
     */
    getStories: build.query<
      StoriesByCategory[],
      {
        filter: SurveyFilter;
        _customerId: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        `overview/stories${filterToQuery(filter)}`,
      transformResponse: (result: Story[]) => {
        // Store list of normalized StoriesByCategory in state
        return mapToStoriesByCategory(result);
      },
      providesTags: [CacheTags.Stories],
    }),

    /**
     * Get overview by option for current customer
     */
    getOverviewByOption: build.query<
      PrincipleOptionByPrinciple[],
      {
        filter: SurveyFilter;
        _customerId: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        // cspell: disable-next-line
        `overview/byoption${filterToQuery(filter)}`,
      transformResponse: (result: PrincipleOption[]) => {
        // Store list of normalized PrincipleOptionByPrinciple in state
        return mapToPrincipleOptionByPrinciple(result);
      },
      providesTags: [CacheTags.OverviewByOption],
    }),

    /**
     * Get overview by option for current customer
     */
    getOverviewByCustomer: build.query<
      CategoryByCustomer[],
      {
        filter: SurveyFilter;
        _customerId?: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        // cspell: disable-next-line
        `overview/bycustomer${filterToQuery(filter)}`,
      transformResponse: (result: CategoryCustomer[]) => {
        // Store list of normalized PrincipleOptionByPrinciple in state
        return mapToCategoryByCustomer(result);
      },
      providesTags: [CacheTags.OverviewByCustomer],
    }),

    /**
     * Get internal comparison of survey for current customer
     */
    getOverviewByEntity: build.query<
      PrinciplesByEntity[],
      {
        filter: SurveyFilter;
        _customerId: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        // cspell: disable-next-line
        `overview/byentity${filterToQuery(filter)}`,
      transformResponse: (result: Principle[]) => {
        // Store sorted list of normalized PrinciplesByEntity in state
        return mapToPrinciplesByEntity(result).sort((a, b) =>
          (a.entityName ?? '') < (b.entityName ?? '') ? -1 : 1
        );
      },
      providesTags: [CacheTags.OverviewByEntity],
    }),

    /**
     * Get disagreement
     */
    getDisagreement: build.query<
      Disagreement[],
      {
        filter: SurveyFilter;
        _customerId?: string | undefined;
        _userId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) =>
        `overview/disagreement${filterToQuery(filter)}`,
      providesTags: [CacheTags.Disagreement],
    }),
  }),
});

export const {
  useGetOverviewQuery,
  useGetStoriesQuery,
  useGetOverviewByEntityQuery,
  useGetOverviewByOptionQuery,
  useGetOverviewByCustomerQuery,
  useGetProgressQuery,
  useGetProgressByEntityQuery,
  useGetCarelessQuery,
  useGetDisagreementQuery,
} = statisticsApi;

const filterToQuery = (filter: SurveyFilter): string =>
  `?respondentFilter=${
    filter.includeOnlyInvolvedRespondents
  }&pooFilter=${!filter.includePoos}${
    filter.instance ? `&surveyInstanceId=${filter.instance.id}` : ''
  }${filter.entityId ? `&entityId=${filter.entityId}` : ''}${
    filter.entityType ? `&type=${filter.entityType}` : ''
  }${filter.allCustomers ? `&allCustomers=${filter.allCustomers}` : ''}${
    filter.carelessFilter ? `&carelessFilter=${filter.carelessFilter}` : ''
  }`;
