import { CacheTags, dimiosApi } from 'api/dimiosApi';
import { Report } from 'models';

const reportEntityApi = dimiosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get reports for current entity
     */
    getEntityReports: build.query<
      Report[],
      {
        entityId: string;
        _customerId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ entityId, _customerId }) => `entities/${entityId}/reports`,
      providesTags: [CacheTags.EntityReport],
    }),
  }),
});

export const { useGetEntityReportsQuery } = reportEntityApi;
