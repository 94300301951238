var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonSecondary, Buttons } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useTranslation } from 'react-i18next';
export var MessageTemplatePreviewModal = function (_a) {
    var setIsPreviewing = _a.setIsPreviewing, messageTemplate = _a.messageTemplate, data = _a.data;
    var t = useTranslation().t;
    var replaceTemplateTags = function () {
        if (data !== undefined &&
            (messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.title) !== undefined &&
            (messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.body) !== undefined &&
            (messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.signature) !== undefined) {
            return data === null || data === void 0 ? void 0 : data.replace('{{Title}}', messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.title).replace('{{Body}}', messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.body).replace('{{Signature}}', messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.signature);
        }
    };
    return (_jsxs(Modal, __assign({ handleKeyEnter: function () { return setIsPreviewing(false); }, title: t('Administrate.MessageTemplate.Preview.Title'), style: {
            width: '90%',
            maxWidth: '700px',
            height: '90%',
            maxHeight: '1000px',
        } }, { children: [_jsx("iframe", { title: t('Administrate.MessageTemplate.Preview.Title'), srcDoc: replaceTemplateTags(), src: "preview.htm", frameBorder: "0", style: {
                    width: '100%',
                    height: '100%',
                } }), _jsx(Buttons, { children: _jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsPreviewing(false); } }, { children: t('Common.UI.Ok') })) })] })));
};
