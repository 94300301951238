var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { ButtonSecondary, Buttons, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchUser } from '../../../components/SearchUser';
import { CommonRoles } from '../../../models';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
export var SendMessagePreviewModal = function (_a) {
    var _b;
    var setIsShowingSendMessagePreviewModal = _a.setIsShowingSendMessagePreviewModal, handleSend = _a.handleSend, hasUnsavedChanges = _a.hasUnsavedChanges;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isGlobalAdmin = accessToken.isInRole([CommonRoles.GlobalAdmin]);
    var _c = useState(null), user = _c[0], setUser = _c[1];
    return (_jsxs(Modal, __assign({ handleKeyEsc: function () { return setIsShowingSendMessagePreviewModal(false); }, title: t('Administrate.MessageTemplate.Send.Title') }, { children: [hasUnsavedChanges && (_jsx(AlertWarning, { children: t('Administrate.MessageTemplate.Send.UnsavedChangesWarning') })), !isGlobalAdmin &&
                t('Administrate.MessageTemplate.Send.ToEmail', {
                    email: (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.email,
                }), isGlobalAdmin && (_jsxs(_Fragment, { children: [_jsx(Label, __assign({ htmlFor: "respondent" }, { children: t('Administrate.MessageTemplate.Send.Email') })), _jsx(SearchUser, { user: user, setUser: setUser })] })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsShowingSendMessagePreviewModal(false); } }, { children: t('Common.Form.Cancel') })), _jsx(ButtonSecondary, __assign({ disabled: !user && isGlobalAdmin, type: "button", onClick: function () { return handleSend(user); } }, { children: t('Common.UI.Ok') }))] })] })));
};
