var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
export var ReleaseNoteSurvey = function (_a) {
    var children = _a.children;
    var t = useTranslation().t;
    return (_jsxs(Style, { children: [_jsx(Heading3, { children: t('ReleaseNotes.Survey') }), _jsx("div", { children: children })] }));
};
ReleaseNoteSurvey.displayName = 'ReleaseNoteSurvey';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 20px;\n\n  > h3 {\n    width: fit-content;\n  }\n"], ["\n  margin-bottom: 20px;\n\n  > h3 {\n    width: fit-content;\n  }\n"])));
var templateObject_1;
