var _a;
import { createSlice } from '@reduxjs/toolkit';
import { SearchAdministrateUsersRole } from 'models';
import { appActions } from '../appSlice';
var initialState = {
    searchFilter: {
        role: SearchAdministrateUsersRole.Write,
    },
};
export var userReducer = (_a = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        selectedUsers: function (state, action) {
            state.selectedUsers = action.payload;
        },
        searchResult: function (state, action) {
            state.searchResult = action.payload;
        },
        searchFilter: function (state, action) {
            state.searchFilter = action.payload;
        },
        searchIsLoading: function (state, action) {
            state.searchIsLoading = action.payload;
        },
        searchIsFetching: function (state, action) {
            state.searchIsFetching = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function () { return initialState; });
    },
}), _a.reducer), userActions = _a.actions;
export var selectSelectedUsers = function (state) { return state.user.selectedUsers; };
export var selectSearchResult = function (state) { return state.user.searchResult; };
export var selectSearchFilter = function (state) { return state.user.searchFilter; };
export var selectSearchIsFetching = function (state) {
    return state.user.searchIsLoading || state.user.searchIsFetching;
};
