var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Breakpoints } from '../themes';
export var Hamburger = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: none;\n  font-size: ", ";\n  margin-right: 10px;\n\n  cursor: pointer;\n\n  &:hover {\n    color: ", ";\n  }\n\n  @media screen and (max-width: ", ") {\n    display: block;\n  }\n"], ["\n  display: none;\n  font-size: ", ";\n  margin-right: 10px;\n\n  cursor: pointer;\n\n  &:hover {\n    color: ", ";\n  }\n\n  @media screen and (max-width: ", ") {\n    display: block;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xl;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, Breakpoints.small);
var templateObject_1;
