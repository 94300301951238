var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loader } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { useTranslation } from 'react-i18next';
import { BsPersonPlus } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { messageActions } from '../../../api/message/messageSlice';
export var Recipients = function (_a) {
    var recipients = _a.recipients, isLoading = _a.isLoading;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    if (isLoading) {
        return _jsx(Loader, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, { children: t('Administrate.Messages.Message.Recipient.NoOfRecipients', {
                    count: recipients.length,
                    read: recipients.filter(function (r) { return !!r.read; }).length,
                    percentage: formatAsPercent((recipients.filter(function (r) { return !!r.read; }).length / recipients.length) *
                        100, '0', 0),
                }) }), _jsx("div", __assign({ style: { display: 'flex', marginTop: 15, marginBottom: 0 } }, { children: _jsx(LinkWithTooltip, { handleClick: function () {
                        return dispatch(messageActions.setIsAddingRecipientsShown(true));
                    }, title: t('Administrate.Messages.Message.Recipient.Add.Title'), tooltipTitle: t('Administrate.Messages.Message.Recipient.Add.TooltipTitle'), tooltip: t('Administrate.Messages.Message.Recipient.Add.Tooltip'), isPrimary: true, icon: _jsx(BsPersonPlus, {}) }) }))] }));
};
Recipients.displayName = 'Recipients';
