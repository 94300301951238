var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { flags } from '../../feature-flags';
import { Tab, Tabs } from '../tab';
import { Table, Td, Tr } from '../table';
import { Card, CardBody } from '../workspace';
export var AllFeatureFlags = withTheme(function (_a) {
    var theme = _a.theme;
    var t = useTranslation().t;
    var allFlags = useFlags();
    var showDevTools = allFlags[flags.permanent.shared.showDevTools];
    var _b = useState(0), selectedTabIndex = _b[0], setSelectedTabIndex = _b[1];
    return showDevTools && allFlags ? (_jsxs("div", __assign({ style: { width: '100%' } }, { children: [_jsxs(Tabs, { children: [_jsx(Tab, __assign({ isSelected: selectedTabIndex === 0, handleSelect: function () { return setSelectedTabIndex(0); } }, { children: t('Account.Dikios.Name') })), _jsx(Tab, __assign({ isSelected: selectedTabIndex === 1, handleSelect: function () { return setSelectedTabIndex(1); } }, { children: t('Account.Dimios.Name') })), _jsx(Tab, __assign({ isSelected: selectedTabIndex === 2, handleSelect: function () { return setSelectedTabIndex(2); } }, { children: t('Account.Dios.Name') })), _jsx(Tab, __assign({ isSelected: selectedTabIndex === 3, handleSelect: function () { return setSelectedTabIndex(3); } }, { children: t('Account.Pios.Name') })), _jsx(Tab, __assign({ isSelected: selectedTabIndex === 4, handleSelect: function () { return setSelectedTabIndex(4); } }, { children: t('Common.UI.Test') }))] }), _jsx(Card, __assign({ width: "100%" }, { children: _jsx(CardBody, { children: _jsx(Style, __assign({ "$striped": true }, { children: _jsx("tbody", { children: Object.keys(allFlags)
                                .filter(function (key) {
                                if (selectedTabIndex === 0) {
                                    return key.startsWith('app-dikios');
                                }
                                if (selectedTabIndex === 1) {
                                    return key.startsWith('app-dimios');
                                }
                                if (selectedTabIndex === 2) {
                                    return key.startsWith('app-dios');
                                }
                                if (selectedTabIndex === 3) {
                                    return key.startsWith('app-pios');
                                }
                                if (selectedTabIndex === 4) {
                                    return key.startsWith('test');
                                }
                            })
                                .map(function (key) { return (_jsxs(Tr, __assign({ isHoverable: true }, { children: [_jsx(Td, { children: key }), _jsx(Td, { children: _jsx(BsCircleFill, { style: {
                                                color: allFlags[key]
                                                    ? theme.colors.success
                                                    : theme.colors.error,
                                            } }) })] }), key)); }) }) })) }) }))] }))) : null;
});
AllFeatureFlags.displayName = 'AllFeatureFlags';
var Style = styled(Table)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: fit-content;\n"], ["\n  width: fit-content;\n"])));
var templateObject_1;
