import { Select } from '@dimatech/shared/lib/components/form';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { getSubCategoryDefinitions } from 'utils';

export const SelectCategory = ({
  categoryId,
  setCategoryId,
  style,
  autoFocus,
  name,
  placeholder,
}: {
  categoryId?: string;
  setCategoryId: (categoryId: string) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
  name?: string;
  placeholder?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <Select
        name={name}
        autoFocus={!!autoFocus}
        value={categoryId ?? ''}
        onChange={(e) => setCategoryId(e.currentTarget.value)}
        style={style}
      >
        <option value="">
          {placeholder ? placeholder : t('Filter.AllCategory')}
        </option>
        {getSubCategoryDefinitions().map((category) => (
          <option key={category.id} value={category.id}>
            {t(`CommonTerms.${category.key}`)}
          </option>
        ))}
      </Select>
    </>
  );
};

SelectCategory.displayName = 'SelectCategory';
