var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { Button, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthenticateMutation, useRefreshAuthTokenMutation, } from '../../../api/authentication/authenticationApi';
import { AuthenticationContext } from '../AuthenticationProvider';
export var Login = function (_a) {
    var handleLoggedIn = _a.handleLoggedIn, _b = _a.isRestrictedLogin, isRestrictedLogin = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var location = useLocation();
    var _c = useContext(AuthenticationContext), accessToken = _c.accessToken, refreshToken = _c.refreshToken, webToken = _c.webToken, logout = _c.logout;
    var state = location.state;
    var _d = useAuthenticateMutation(), authenticate = _d[0], _e = _d[1], data = _e.data, error = _e.error, isLoading = _e.isLoading;
    var refreshAuthToken = useRefreshAuthTokenMutation()[0];
    var _f = useState({
        login: (state === null || state === void 0 ? void 0 : state.email) || '',
        password: '',
    }), login = _f[0], setLogin = _f[1];
    var handleSubmit = function (e) {
        e.preventDefault();
        if (!login.login || !login.password) {
            return;
        }
        authenticate({ authentication: login });
    };
    var handleChange = function (event) {
        var _a;
        var target = event.currentTarget;
        setLogin(__assign(__assign({}, login), (_a = {}, _a[target.name] = target.value.trim(), _a)));
    };
    useEffect(function () {
        // Check if refresh token is set and valid, then we are logged in
        if (refreshToken.isValid()) {
            // Check if token is set and is valid, then we are
            // authed within this app and can redirect
            if (accessToken.isValid()) {
                handleLoggedIn();
                return;
            }
            // If auth token is missing or invalid, request a
            // token using refresh token
            refreshAuthToken({ refreshToken: refreshToken.token })
                .unwrap()
                .then(function (result) {
                webToken.logout();
                accessToken.update(result.accessToken);
                refreshToken.update(result.refreshToken);
                handleLoggedIn();
            })
                .catch(function () {
                // Ignore error, just logout
                logout();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (data) {
            webToken.logout();
            accessToken.update(data.accessToken);
            refreshToken.update(data.refreshToken);
            handleLoggedIn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    useEffect(function () {
        if (error) {
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    return (_jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(Heading2, { children: t('Login.Title') }), error && _jsx(Error, { children: t("Login.ValidationError.".concat(error.name)) }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "login" }, { children: t('Login.Login') })), _jsx(Input, { type: "text", id: "login", name: "login", defaultValue: login.login, onChange: handleChange })] }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "password" }, { children: t('Login.Password') })), _jsx(Input, { type: "password", id: "password", name: "password", defaultValue: login.password, onChange: handleChange })] }), _jsx(Button, __assign({ onClick: handleSubmit, style: { width: '100%', marginTop: 10 }, disabled: isLoading }, { children: isLoading ? t('Login.Submitting') : t('Login.Submit') })), !isRestrictedLogin && (_jsxs(_Fragment, { children: [_jsx(LinkContainer, { children: _jsx(Link, __assign({ to: "/forgot-password" }, { children: t('Login.ForgotPasswordLink') })) }), _jsxs(LinkContainer, { children: [_jsx("div", { children: t('Cookies.Notice.Text') }), _jsx(Link, __assign({ to: "/cookies" }, { children: t('Cookies.Notice.ReadMore') }))] }), _jsx(LinkContainer, { children: _jsx(Link, __assign({ to: "/register" }, { children: t('Login.Register') })) })] }))] })));
};
Login.displayName = 'Login';
var LinkContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 15px;\n"], ["\n  margin-top: 15px;\n"])));
var Error = styled(AlertError)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var templateObject_1, templateObject_2;
