var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SortDirection } from '@dimatech/shared/lib/models';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { messageActions, selectIsAddingRecipientsShown, selectRecipientsToDelete, } from '../../api/message/messageSlice';
import { useLazyGetUsersQuery } from '../../api/user/userApi';
import { selectSearchFilter, userActions } from '../../api/user/userSlice';
import { AuthenticationContext } from '../authentication/AuthenticationProvider';
import { MessageEdit } from './components/MessageEdit';
import { RecipientsAdd } from './components/RecipientsAdd';
export var Message = function (_a) {
    var searchableRoles = _a.searchableRoles, _b = _a.isEntityFilterShown, isEntityFilterShown = _b === void 0 ? true : _b;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isAddingRecipientsShown = useSelector(selectIsAddingRecipientsShown);
    var recipientsToDelete = useSelector(selectRecipientsToDelete);
    var filter = useSelector(selectSearchFilter);
    var _c = useState([]), recipients = _c[0], setRecipients = _c[1];
    var navigate = useNavigate();
    var customerId = useState(accessToken.customerId)[0];
    var _d = useLazyGetUsersQuery(), getUsers = _d[0], _e = _d[1], data = _e.data, isLoading = _e.isLoading, isFetching = _e.isFetching;
    useEffect(function () {
        dispatch(userActions.searchIsLoading(isLoading));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    useEffect(function () {
        dispatch(userActions.searchIsFetching(isFetching));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);
    useEffect(function () {
        dispatch(userActions.searchResult(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    useEffect(function () {
        if (customerId !== accessToken.customerId) {
            navigate("/administrate/messages");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken.customerId]);
    var initialPaginator = {
        page: 1,
        pageSize: 10000,
        orderBy: 'email',
        orderDirection: SortDirection.Asc,
    };
    var handleSearch = function (paginator) {
        if (paginator === void 0) { paginator = initialPaginator; }
        if (accessToken.customerId) {
            getUsers({
                filter: __assign({ paginator: paginator }, filter),
                _customerId: accessToken.customerId,
            }, true);
        }
    };
    var handleAddRecipients = function (recipientsToAdd) {
        dispatch(messageActions.setHasChanges(true));
        dispatch(messageActions.setRecipientsToAdd(recipientsToAdd));
        dispatch(messageActions.setRecipientsToDelete(recipientsToDelete.filter(function (r) { return !recipientsToAdd.some(function (n) { return n.userId === r.userId; }); })));
    };
    var addRecipients = function (users) {
        var allRecipients = recipients.concat(users.filter(function (user) { return !recipients.some(function (r) { return r.userId === user.userId; }); }));
        setRecipients(allRecipients);
        handleAddRecipients(users);
        dispatch(messageActions.setIsAddingRecipientsShown(false));
        dispatch(userActions.selectedUsers());
    };
    return (_jsxs(_Fragment, { children: [_jsx(MessageEdit, { recipients: recipients, setRecipients: setRecipients }), isAddingRecipientsShown && (_jsx(RecipientsAdd, { handleAddRecipients: addRecipients, searchableRoles: searchableRoles, isEntityFilterShown: isEntityFilterShown, handleSearch: handleSearch, initialPaginator: initialPaginator }))] }));
};
Message.displayName = 'Message';
