var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { colorFromEmail, extractTextFromHTML, formatDate, htmlDecode, } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, withTheme } from 'styled-components';
import { UserMessageType } from '../../models';
import { AuthenticationContext } from '../authentication/AuthenticationProvider';
import { MessageTypeIcon } from './MessageTypeIcon';
export var Message = withTheme(function (_a) {
    var _b, _c, _d, _e, _f;
    var message = _a.message, isSelected = _a.isSelected, handleSelect = _a.handleSelect, theme = _a.theme;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var currentUserEmail = ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.email) || '';
    var decoded = (_d = htmlDecode((_c = message.text) !== null && _c !== void 0 ? _c : '', ['a'])) !== null && _d !== void 0 ? _d : '';
    var textLength = extractTextFromHTML(decoded).length;
    var maxTextLength = 300;
    var shortened = decoded.substring(0, maxTextLength);
    var html = isSelected
        ? decoded
        : "".concat((_e = htmlDecode(shortened, ['a'])) !== null && _e !== void 0 ? _e : '').concat(textLength > maxTextLength ? '...' : '');
    var emailColor = colorFromEmail({
        email: message.sender,
        currentUserEmail: currentUserEmail,
    });
    return (_jsxs(_Fragment, { children: [!isSelected && (_jsx(TooltipContent, { id: "tt-message-read-more", text: t('Messenger.ReadMore') })), _jsxs(Style, __assign({ isRead: isSelected || !!message.read, isSelected: !!isSelected, onClick: function () { return handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect(message); }, "data-tip": true, "data-for": "tt-message-read-more" }, { children: [_jsxs(MessageHeaderStyle, __assign({ isRead: !!message.read }, { children: [_jsx(MessageTypeIcon, { messageType: (_f = message.type) !== null && _f !== void 0 ? _f : UserMessageType.General, style: { color: emailColor || theme.colors.primary } }), _jsxs("div", { children: [_jsx("div", { children: message.publishDate
                                            ? formatDate(parseISO(message.publishDate))
                                            : '-' }), _jsx("div", { children: message.sender === null
                                            ? config.support.email
                                            : message.sender })] })] })), _jsx(Heading4, { children: message.title }), _jsx(MessageStyle, { dangerouslySetInnerHTML: {
                            __html: html,
                        } })] }))] }));
});
Message.displayName = 'Message';
var Style = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  padding: 10px;\n\n  background-color: ", ";\n  color: ", ";\n\n  ", "\n\n  ", "\n"], ["\n  margin-bottom: 10px;\n  padding: 10px;\n\n  background-color: ", ";\n  color: ", ";\n\n  ", "\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (props) {
    return !props.isRead && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border-left: 3px solid\n        ", ";\n    "], ["\n      border-left: 3px solid\n        ", ";\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.primary;
    });
}, function (props) {
    return !props.isSelected && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      cursor: pointer;\n    "], ["\n      cursor: pointer;\n    "])));
});
var MessageHeaderStyle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  gap: 10px;\n  margin-bottom: 5px;\n\n  h4 {\n    font: ", ";\n  }\n\n  svg {\n    min-width: 35px;\n    margin-top: 10px;\n    font-size: 2.4rem;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  gap: 10px;\n  margin-bottom: 5px;\n\n  h4 {\n    font: ", ";\n  }\n\n  svg {\n    min-width: 35px;\n    margin-top: 10px;\n    font-size: 2.4rem;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.base;
}, function (_a) {
    var theme = _a.theme, isRead = _a.isRead;
    return isRead ? theme.colors.onSurface : theme.colors.primary;
});
var MessageStyle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 10px;\n\n  > p {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n"], ["\n  margin-bottom: 10px;\n\n  > p {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
