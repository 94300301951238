var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsLightbulb } from 'react-icons/bs';
import styled from 'styled-components';
import { shuffle } from '../../utils';
import { Heading2 } from '../typography';
import { Carousel } from './Carousel';
import { CarouselBody, CarouselHeader, CarouselItem } from './CarouselItem';
export var PromoCarousel = function (_a) {
    var items = _a.items, _b = _a.randomise, randomise = _b === void 0 ? true : _b, style = _a.style;
    var t = useTranslation().t;
    var _c = useState(), tips = _c[0], setTips = _c[1];
    if (items.length === 0) {
        return null;
    }
    useEffect(function () {
        setTips(randomise ? shuffle(items) : items);
    }, [items]);
    return (_jsxs(PromoCarouselStyle, __assign({ style: style }, { children: [_jsxs(PromoCarouselHeaderStyle, { children: [_jsx("div", { children: _jsx(BsLightbulb, {}) }), t('PromoCarousel.Title')] }), _jsx(Carousel, { children: tips === null || tips === void 0 ? void 0 : tips.map(function (tip, index) { return (_jsx(CarouselItem, { children: _jsxs(_Fragment, { children: [tip.title && _jsx(CarouselHeader, { children: tip.title }), _jsx(CarouselBody, { children: tip.content })] }) }, index)); }) })] })));
};
PromoCarousel.displayName = 'PromoCarousel';
var PromoCarouselStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 5px;\n  padding: 20px 20px 30px 20px;\n  border-radius: 10px;\n\n  background-color: ", ";\n  color: ", ";\n\n  box-shadow: ", ";\n"], ["\n  margin-top: 5px;\n  padding: 20px 20px 30px 20px;\n  border-radius: 10px;\n\n  background-color: ", ";\n  color: ", ";\n\n  box-shadow: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSecondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
});
var PromoCarouselHeaderStyle = styled(Heading2)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 15px;\n\n  > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    padding: 5px;\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n\n    background-color: ", ";\n    color: ", ";\n\n    box-shadow: ", ";\n  }\n"], ["\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 15px;\n\n  > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    padding: 5px;\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n\n    background-color: ", ";\n    color: ", ";\n\n    box-shadow: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSecondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
});
var templateObject_1, templateObject_2;
