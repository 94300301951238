import { Welcome as WelcomeComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const Welcome = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('Welcome.Title')}>
      <WelcomeComponent />
    </ViewCentered>
  );
};

Welcome.displayName = 'Welcome';
