import { mergeJson } from '@dimatech/shared/lib/utils';
import administrate_account_en from './lang/administrate-account/en.json';
import administrate_account_sv from './lang/administrate-account/sv.json';
import administrate_authentication_en from './lang/administrate-authentication/en.json';
import administrate_authentication_sv from './lang/administrate-authentication/sv.json';
import administrate_feature_flag_en from './lang/administrate-feature-flag/en.json';
import administrate_feature_flag_sv from './lang/administrate-feature-flag/sv.json';
import administrate_message_template_en from './lang/administrate-message-template/en.json';
import administrate_message_template_sv from './lang/administrate-message-template/sv.json';
import administrate_messages_en from './lang/administrate-messages/en.json';
import administrate_messages_sv from './lang/administrate-messages/sv.json';
import administrate_organisation_en from './lang/administrate-organisation/en.json';
import administrate_organisation_sv from './lang/administrate-organisation/sv.json';
import administrate_tags_en from './lang/administrate-tags/en.json';
import administrate_tags_sv from './lang/administrate-tags/sv.json';
import administrate_user_en from './lang/administrate-user/en.json';
import administrate_user_sv from './lang/administrate-user/sv.json';
import messenger_en from './lang/messenger/en.json';
import messenger_sv from './lang/messenger/sv.json';
import default_en from './lang/en.json';
import default_sv from './lang/sv.json';
export var features_core_en = mergeJson(default_en, [
    administrate_account_en,
    administrate_feature_flag_en,
    administrate_messages_en,
    administrate_organisation_en,
    administrate_tags_en,
    administrate_user_en,
    messenger_en,
    administrate_authentication_en,
    administrate_message_template_en,
]);
export var features_core_sv = mergeJson(default_sv, [
    administrate_account_sv,
    administrate_feature_flag_sv,
    administrate_messages_sv,
    administrate_organisation_sv,
    administrate_tags_sv,
    administrate_user_sv,
    messenger_sv,
    administrate_authentication_sv,
    administrate_message_template_sv,
]);
