import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetResearcherHistoricalReportsQuery } from 'api/report/reportApi';
import { getHistoricalResearcher } from 'api/report/reportHistoricalResearcherSlice';
import { Report, Reports } from 'components/Reports';
import { useAppDispatch } from 'hooks';
import { Report as ReportModel } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const HistoricalReportList = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const { data, isLoading } = useGetResearcherHistoricalReportsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  return (
    <>
      <TooltipContent
        id="tt-export-historical-download"
        title={t('Reports.Download.TooltipTitle')}
        text={t('Reports.Download.Tooltip')}
      />

      <Reports>
        <Heading3 style={{ marginBottom: 10 }}>
          {t('GlobalAdministrate.Reports.Historical.Title')}
        </Heading3>

        {isLoading && (
          <div>
            <LoaderSmall />
          </div>
        )}

        {!isLoading &&
          data?.map((report, index) => {
            return <HistoricalReport key={index} report={report} />;
          })}
      </Reports>
    </>
  );
};

HistoricalReportList.displayName = 'HistoricalReportList';

const HistoricalReport = ({ report }: { report: ReportModel }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (report: ReportModel) => {
    setIsDownloading(true);

    dispatch(
      getHistoricalResearcher({
        reportId: report.id,
        fileName: report.fileName,
      })
    ).finally(() => setIsDownloading(false));
  };

  return (
    <Report
      report={report}
      isDownloading={isDownloading}
      handleDownload={handleDownload}
    />
  );
};
