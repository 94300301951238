var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { useDeleteAccountAdminMutation } from '../../../api/user/userApi';
import { Impersonate } from '../../authentication/components/Impersonate';
import { SendForgotPasswordEmail } from '../../authentication/components/SendForgotPasswordEmail';
export var AccountAdminsEditButtons = function (_a) {
    var user = _a.user, setError = _a.setError;
    var t = useTranslation().t;
    var deleteAccountAdmin = useDeleteAccountAdminMutation()[0];
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var handleConfirmDelete = function (e) {
        e.stopPropagation();
        deleteAccountAdmin({
            user: {
                email: user.email,
                isAccountAdmin: true,
            },
        });
        setIsDeleting(false);
    };
    var handleDelete = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        setIsDeleting(show);
    };
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Account.Admin.Delete.ConfirmTitle'), handleKeyEnter: function (e) { return handleConfirmDelete(e); }, handleKeyEsc: function (e) { return handleDelete(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Account.Admin.Delete.ConfirmText') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e); } }, { children: t('Common.UI.Yes') }))] })] }))), _jsx(Impersonate, { email: user.email }), _jsx(SendForgotPasswordEmail, { email: user.email }), _jsx(TooltipContent, { id: "tt-respondent-delete", title: t('Administrate.Account.Admin.Delete.TooltipTitle'), text: t('Administrate.Account.Admin.Delete.Tooltip') }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-respondent-delete", onClick: function (e) { return handleDelete(e, true); }, style: { marginLeft: 7 } }, { children: _jsx(BsTrash, {}) }))] }));
};
AccountAdminsEditButtons.displayName = 'AccountAdminsEditButtons';
