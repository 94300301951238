import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { Progress } from 'models';
import { useTranslation } from 'react-i18next';
import { BsEnvelope, BsHandThumbsUp, BsPeople, BsPerson } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { formatAsNumber, formatAsPercent } from 'utils';

export const StatisticsResponseRate = withTheme(
  ({
    theme,
    progress,
  }: {
    theme: Theme;
    progress: Progress | undefined;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    return (
      <Style>
        <Number
          icon={<BsHandThumbsUp />}
          color={theme.colors.primary}
          text={t('Overview.StatisticsResponseRate.ResponseRateRespondents')}
          formattedValue={formatAsPercent(
            ((progress?.finishedRespondents ?? 0) /
              (progress?.totalRespondents ?? 0)) *
              100
          )}
        />

        <Number
          icon={<BsEnvelope />}
          color={theme.colors.primary}
          text={t('Overview.StatisticsResponseRate.NumberOfAnswersRespondents')}
          formattedValue={formatAsNumber(progress?.finishedRespondents)}
        />

        <Number
          icon={<BsPeople />}
          color={theme.colors.primary}
          text={t('Overview.StatisticsResponseRate.NumberOfRespondents')}
          formattedValue={`${formatAsNumber(progress?.totalRespondents)}`}
        />

        <Number
          icon={<BsPerson />}
          color={theme.colors.primary}
          text={t('Overview.StatisticsResponseRate.NumberOfAnswersAnonymous')}
          formattedValue={formatAsNumber(progress?.finishedAnonRespondents)}
        />
      </Style>
    );
  }
);

StatisticsResponseRate.displayName = 'StatisticsResponseRate';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  > div {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
