var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { SearchBox } from '@dimatech/shared/lib/components/Search';
import { Checkbox } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { Pagination, useUiSortablePaginator, } from '@dimatech/shared/lib/components/paginator';
import { Table, TableResponsiveContainer, Td, TdRight, Th, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip, TooltipContent, } from '@dimatech/shared/lib/components/tooltip';
import { ScheduleStatus } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClipboardPlus, BsClockHistory } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { useGetEntitySurveyQuery } from '../../../api/entity/entitySurveyApi';
import { useAddSurveyRespondentMutation, useUpdateSurveyRespondentMutation, } from '../../../api/entity/entitySurveyRespondentApi';
import { entitySurveyRespondentActions, selectSelectedRespondents, } from '../../../api/entity/entitySurveyRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
import { datesToScheduleStatus } from '../../../utils';
import { RespondentActions } from './RespondentActions';
import { RespondentAdd } from './RespondentAdd';
import { RespondentCopyLinkButton } from './RespondentCopyLinkButton';
import { RespondentMessageHistory } from './RespondentMessageHistory';
import { RespondentMessageStatus } from './RespondentMessageStatus';
import { RespondentSurveyStatus } from './RespondentSurveyStatus';
import { RespondentUpdate } from './RespondentUpdate';
import { RespondentsSelectedActions } from './RespondentsSelectedActions';
// TODO: Fix, split code
/* eslint-disable max-lines-per-function */
export var RespondentsList = withTheme(function (_a) {
    var setIsFetchingRespondents = _a.setIsFetchingRespondents, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted, theme = _a.theme;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var selectedEntity = useSelector(selectSelectedEntity);
    var selectedRespondents = useSelector(selectSelectedRespondents);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var _b = useAddSurveyRespondentMutation(), addRespondent = _b[0], addError = _b[1].error;
    var _c = useUpdateSurveyRespondentMutation(), updateRespondent = _c[0], updateError = _c[1].error;
    var _d = useState(false), isAdding = _d[0], setIsAdding = _d[1];
    var _e = useState(''), search = _e[0], setSearch = _e[1];
    var _f = useState(null), isShowingMessagesForRespondent = _f[0], setIsShowingMessagesForRespondent = _f[1];
    var _g = useState(null), isUpdatingRespondent = _g[0], setIsUpdatingRespondent = _g[1];
    var _h = useState(), error = _h[0], setError = _h[1];
    var _j = useState(), existsError = _j[0], setExistsErrors = _j[1];
    var _k = useState([]), filtered = _k[0], setFiltered = _k[1];
    var _l = useState([]), respondents = _l[0], setRespondents = _l[1];
    var _m = useState(null), status = _m[0], setStatus = _m[1];
    var _o = useGetEntitySurveyQuery(selectedEntity &&
        selectedEntity.id &&
        accessToken.customerId &&
        filter.instance.id
        ? {
            entityId: selectedEntity.id,
            _customerId: accessToken.customerId,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken), entitySurvey = _o.data, isFetching = _o.isFetching, isLoading = _o.isLoading;
    var _p = useUiSortablePaginator({
        orderBy: 'email',
        pageSize: 50,
        data: {
            totalRecords: filtered.length,
            records: filtered,
        },
    }), items = _p.items, setPage = _p.setPage, paginator = _p.paginator, sorter = _p.sorter;
    var handleAddRespondent = function (email) {
        setError(undefined);
        setExistsErrors(undefined);
        if (!(selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id)) {
            return;
        }
        addRespondent({
            entityId: selectedEntity.id,
            respondent: [email],
            skipExisting: true,
        })
            .unwrap()
            .then(function (_a) {
            var _b;
            var result = _a.result;
            if (((_b = result === null || result === void 0 ? void 0 : result[0]) === null || _b === void 0 ? void 0 : _b.status) === 'Skipped') {
                setExistsErrors(t('Administrate.Respondent.ValidationError.Skipped', {
                    entityName: result[0].originalEntityName,
                }));
            }
        });
    };
    var handleUpdateRespondent = function (email, newEmail) {
        setError(undefined);
        setExistsErrors(undefined);
        if (!(selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id)) {
            return;
        }
        updateRespondent({
            entityId: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id,
            email: email,
            newEmail: newEmail,
        });
    };
    var handleSelect = function (e, email) {
        var newRespondents = __spreadArray([], selectedRespondents, true);
        var respondent = respondents.find(function (r) { return r.email === email; });
        var index = newRespondents.findIndex(function (respondent) { return respondent.email === email; });
        if (e.target.checked && respondent && index === -1) {
            newRespondents.push(__assign({}, respondent));
        }
        if (!e.target.checked && respondent && index !== -1) {
            newRespondents.splice(index, 1);
        }
        dispatch(entitySurveyRespondentActions.setSelectedRespondents(newRespondents));
    };
    useEffect(function () {
        var _a;
        setRespondents((_a = entitySurvey === null || entitySurvey === void 0 ? void 0 : entitySurvey.respondents) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entitySurvey]);
    useEffect(function () {
        var _a;
        setFiltered((_a = respondents.filter(function (respondent) {
            return respondent.email.toLowerCase().includes(search.toLowerCase());
        })) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, respondents]);
    useEffect(function () {
        setError(undefined);
        setExistsErrors(undefined);
        setPage(1);
        setSearch('');
        dispatch(entitySurveyRespondentActions.resetSelectedRespondents());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntity]);
    useEffect(function () {
        setIsFetchingRespondents(isFetching || isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading]);
    useEffect(function () {
        setError(addError !== null && addError !== void 0 ? addError : updateError);
    }, [addError, updateError]);
    useEffect(function () {
        if (entitySurvey) {
            setStatus(datesToScheduleStatus(entitySurvey.startDate, entitySurvey.endDate));
        }
    }, [entitySurvey]);
    if (isFetching || isLoading) {
        return (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: _jsx(LoaderOverlay, { children: t('Common.UI.Loading') }) })));
    }
    return (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) ? (_jsxs(_Fragment, { children: [_jsx(LinkWithTooltip, { style: { marginTop: 10, marginBottom: 10 }, handleClick: function () { return setIsAdding(filter.instance.isCurrentInstance); }, title: t('Administrate.Respondent.Add.Title'), tooltipTitle: t('Administrate.Respondent.Add.TooltipTitle'), tooltip: t('Administrate.Respondent.Add.Tooltip'), icon: _jsx(BsClipboardPlus, {}), isDisabled: !filter.instance.isCurrentInstance }), _jsx(AlertErrors, { error: error !== null && error !== void 0 ? error : updateError, translationPath: "Administrate.Respondent.ValidationError" }), existsError && (_jsx(AlertWarning, __assign({ style: { marginTop: 10, width: '100%' } }, { children: existsError }))), respondents.length === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('Administrate.Respondent.NoRespondents') }))), isAdding && (_jsx(RespondentAdd, { setIsAdding: setIsAdding, handleAddRespondent: handleAddRespondent })), isUpdatingRespondent && (_jsx(RespondentUpdate, { email: isUpdatingRespondent.email, entityId: isUpdatingRespondent.entityId, setIsUpdatingRespondent: setIsUpdatingRespondent, handleUpdateRespondent: handleUpdateRespondent })), isShowingMessagesForRespondent && (_jsx(RespondentMessageHistory, { entityName: selectedEntity.name, respondent: isShowingMessagesForRespondent, setIsShowingMessagesForRespondent: setIsShowingMessagesForRespondent })), _jsx(TooltipContent, { id: "tt-message-history", title: t('Administrate.Respondent.Messages.Title'), text: t('Administrate.Respondent.Messages.Tooltip') }), _jsx(TooltipContent, { id: "tt-link-copy", title: t('Administrate.Respondent.CopyLink.TooltipTitle'), text: t('Administrate.Respondent.CopyLink.Tooltip') }), respondents.length > 0 && (_jsxs("div", __assign({ style: {
                    margin: '20px 0 10px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                } }, { children: [_jsx(RespondentsSelectedActions, { isReadOnly: isReadOnly, filtered: filtered, selectedEntityId: selectedEntity.id, setError: setError, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted }), (entitySurvey === null || entitySurvey === void 0 ? void 0 : entitySurvey.respondents) !== undefined &&
                        (entitySurvey === null || entitySurvey === void 0 ? void 0 : entitySurvey.respondents.length) > 0 && (_jsx("div", __assign({ style: { display: 'flex', marginLeft: 'auto' } }, { children: _jsx(SearchBox, { name: search, setName: setSearch }) })))] }))), respondents.length > 0 && filtered.length === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('Administrate.Respondent.NoRespondentsMatchingFilter') }))), _jsx(Pagination, { currentPage: paginator.page, totalCount: filtered.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } }), items && items.length > 0 && (_jsx(TableResponsiveContainer, { children: _jsxs(Table, __assign({ style: { marginTop: 10 } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, { style: { width: 25 } }), _jsx(Th, __assign({ sortKey: "email", sorter: sorter }, { children: t('Administrate.Respondent.Respondent') })), _jsx(Th, __assign({ sortKey: "status", sorter: sorter, style: { width: 150 } }, { children: t('Administrate.Respondent.SurveyStatus.Title') })), _jsx(Th, __assign({ style: { width: 150 } }, { children: t('Administrate.Respondent.Messages.Title') })), status === ScheduleStatus.Started && (_jsx(Th, { style: { width: 150 } })), _jsx(Th, { style: { width: 110 } })] }) }), _jsx("tbody", { children: items.map(function (item, index) {
                                var _a;
                                var latestMessage = (_a = __spreadArray([], item.messages, true).sort(function (a, b) {
                                    return a.sentDate > b.sentDate ? -1 : 1;
                                })) === null || _a === void 0 ? void 0 : _a[0];
                                return (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(Checkbox, { onChange: function (e) { return handleSelect(e, item.email); }, id: index + "_".concat(item.email), name: item.email, value: item.email, checked: selectedRespondents.some(function (r) { return r.email === item.email; }) }) }), _jsxs(Td, __assign({ style: {
                                                whiteSpace: 'nowrap',
                                            } }, { children: [!item.isDeleted && (_jsx("label", __assign({ htmlFor: index + "_".concat(item.email) }, { children: item.email }))), item.isDeleted && (_jsx("div", __assign({ className: "i", style: { color: theme.colors.dim } }, { children: t('Administrate.Respondent.DeletedRespondent') })))] })), _jsx(Td, __assign({ style: {
                                                whiteSpace: 'nowrap',
                                                color: item.isDeleted ? theme.colors.dim : 'unset',
                                            } }, { children: _jsx(RespondentSurveyStatus, { status: item.status }) })), _jsx(Td, { children: latestMessage && (_jsxs("div", __assign({ style: {
                                                    verticalAlign: 'middle',
                                                    display: 'flex',
                                                    alignItems: 'baseline',
                                                    whiteSpace: 'nowrap',
                                                    color: item.isDeleted
                                                        ? theme.colors.dim
                                                        : 'unset',
                                                } }, { children: [_jsx(RespondentMessageStatus, { status: latestMessage.status }), _jsx("span", __assign({ style: { marginRight: 10, marginLeft: 10 } }, { children: latestMessage.sentDate &&
                                                            formatDate(new Date(latestMessage.sentDate)) })), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-message-history", onClick: function () {
                                                            return setIsShowingMessagesForRespondent(item);
                                                        } }, { children: _jsx(BsClockHistory, {}) }))] }))) }), status === ScheduleStatus.Started && (_jsx(Td, __assign({ style: { whiteSpace: 'nowrap' } }, { children: !item.isDeleted && (_jsx(RespondentCopyLinkButton, { respondent: item, isReadOnly: isReadOnly })) }))), _jsx(TdRight, __assign({ style: { verticalAlign: 'middle' } }, { children: _jsx("div", __assign({ style: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                } }, { children: _jsx(RespondentActions, { isReadOnly: isReadOnly, respondent: item, selectedEntityId: selectedEntity.id, setIsUpdatingRespondent: setIsUpdatingRespondent, setError: setError, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted }) })) }))] }, index));
                            }) })] })) })), _jsx(Pagination, { currentPage: paginator.page, totalCount: filtered.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } })] })) : null;
});
RespondentsList.displayName = 'RespondentsList';
