var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { config } from '@dimatech/shared/lib/config';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../features/authentication';
import { CommonRoles } from '../models';
export var PrivateRoute = function (_a) {
    var _b;
    var requireRole = _a.requireRole, requireFlag = _a.requireFlag, _c = _a.isWebTokenAllowed, isWebTokenAllowed = _c === void 0 ? false : _c, _d = _a.isBaseLicenseAllowed, isBaseLicenseAllowed = _d === void 0 ? false : _d, children = _a.children;
    var _e = useContext(AuthenticationContext), accessToken = _e.accessToken, webToken = _e.webToken, tokenUpdated = _e.tokenUpdated;
    var location = useLocation();
    var navigate = useNavigate();
    var featureFlags = useFlags();
    var ldContext = (_b = useLDClient()) === null || _b === void 0 ? void 0 : _b.getContext();
    var state = __assign({ from: "".concat(location.pathname).concat(location.search) }, location.state);
    useEffect(function () {
        if (isWebTokenAllowed && webToken.isValid()) {
            return;
        }
        // Do not allow users with only CommonUser role
        if (!config.authentication.allowCommonUserInPrivateRoutes &&
            accessToken.isValid() &&
            accessToken.roles.length === 1 &&
            accessToken.isInRole(CommonRoles.CommonUser)) {
            navigate('/unauthorized', {
                state: { from: '/' },
            });
        }
        if (!requireRole) {
            return;
        }
        if (!accessToken.isValid()) {
            navigate('/login', {
                state: __assign({}, state),
            });
            return;
        }
        if (!accessToken.isInRole(requireRole)) {
            navigate('/', {
                state: { from: '/' },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenUpdated, requireRole]);
    useEffect(function () {
        if (!isBaseLicenseAllowed &&
            accessToken.isBaseLicense &&
            location.pathname !== '/') {
            navigate('/');
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken.isBaseLicense, accessToken.customerId]);
    var isFlagOn = !requireFlag || featureFlags[requireFlag];
    if (!isFlagOn) {
        // If ldContext.anonymous is true, it means that the user is not
        // synced with LD yet (and all flags will have default state) so
        // in this case we dont want to redirect the user, just return
        // null and wait for sync to complete
        return (ldContext === null || ldContext === void 0 ? void 0 : ldContext.anonymous) ? null : _jsx(Navigate, { to: "/" });
    }
    if (!accessToken.isValid() && !(isWebTokenAllowed && webToken.isValid())) {
        return _jsx(Navigate, { to: "/login", state: __assign({}, state) });
    }
    if (!isBaseLicenseAllowed &&
        accessToken.isBaseLicense &&
        // Always allow access for global admins
        !accessToken.isInRole(CommonRoles.GlobalAdmin) &&
        // Always allow access to root to prevent infinite loops
        location.pathname !== '/') {
        return _jsx(Navigate, { to: "/", state: __assign({}, state) });
    }
    return children;
};
PrivateRoute.displayName = 'PrivateRoute';
