import axios from 'axios';
import axiosRetry from 'axios-retry';
import { HttpStatus } from '../models';
// Configure axios
export var initializeAxiosConfig = function (config) {
    axios.defaults.timeout = config.timeout;
    // Retry if request fails for any reason
    axiosRetry(axios, {
        retries: config.retries,
        shouldResetTimeout: true,
        retryDelay: function (retryCount) {
            return retryCount * config.retryDelay;
        },
        retryCondition: function (error) {
            var _a, _b;
            // NOTE: Change this if we want to exclude retries for specific methods or statuses
            // Only retry for 503 Service Unavailable / Unknown for now
            return (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === HttpStatus.ServiceUnavailable ||
                ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) === undefined);
        },
    });
};
