import { Administrators } from '@dimatech/features-core/lib/features/administrate-users/Administrators';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetEntitiesWithSurveyQuery } from '@dimatech/features-survey/lib/api/entity/entityApi';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { data: entities, isLoading } = useGetEntitiesWithSurveyQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          _customerId: accessToken.customerId,
          _userId: accessToken.user?.id,
          surveyInstanceId: undefined,
        }
      : skipToken
  );

  const isViewersEnabledFlagOn =
    useFlags()[flags.permanent.app.dimios.isViewersEnabled];

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('Administrate.Users.Title')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === 0}
          handleSelect={() => handleSelectTab(0)}
        >
          {t('UserRoles.CustomerAdmin.Title')}
        </Tab>

        {isViewersEnabledFlagOn && (
          <Tab
            isSelected={selectedTabIndex === 1}
            handleSelect={() => handleSelectTab(1)}
          >
            {t('UserRoles.Reader.Title')}
          </Tab>
        )}
      </Tabs>

      <Card width="100%">
        <CardBody>
          {selectedTabIndex === 0 && (
            <Administrators isLoading={isLoading} entities={entities} />
          )}

          {selectedTabIndex === 1 && (
            <Viewers isLoading={isLoading} entities={entities} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
