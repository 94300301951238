var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';
import { BsMagic } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../api/entity/entitySlice';
import { useGenerateResponsesForRespondentsMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { entitySurveyRespondentActions, selectIsGeneratingResponsesSelectedRespondents, selectSelectedRespondents, } from '../../../api/entity/entitySurveyRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
export var RespondentsSelectedActionsGenerateResponses = function (_a) {
    var selectedEntityId = _a.selectedEntityId, setError = _a.setError;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var isGeneratingResponses = useSelector(selectIsGeneratingResponsesSelectedRespondents);
    var selectedRespondents = useSelector(selectSelectedRespondents);
    var generateResponses = useGenerateResponsesForRespondentsMutation()[0];
    var noOfSelected = selectedRespondents.length;
    var handleConfirmGenerateResponses = function (e) {
        e.stopPropagation();
        if (!selectedEntityId) {
            return;
        }
        if (!selectedRespondents || selectedRespondents.length === 0) {
            return;
        }
        generateResponses({
            entityId: selectedEntityId,
            respondents: selectedRespondents.map(function (r) { return r.email; }),
        });
        dispatch(entitySurveyRespondentActions.setIsGeneratingResponsesSelectedRespondents(false));
        dispatch(entitySurveyRespondentActions.setSelectedRespondents([]));
        dispatch(entityActions.selectedEntityRespondent(null));
    };
    var handleGenerateResponses = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setIsGeneratingResponsesSelectedRespondents(show));
    };
    return (_jsxs(_Fragment, { children: [isGeneratingResponses && (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.GenerateResponses.Title'), handleKeyEnter: handleConfirmGenerateResponses, handleKeyEsc: function (e) { return handleGenerateResponses(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.GenerateResponses.Text') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleGenerateResponses(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleConfirmGenerateResponses }, { children: t('Administrate.Respondent.Button.GenerateResponses') }))] })] }))), _jsx(LinkWithTooltip, { style: { margin: '0 5px' }, handleClick: function (e) {
                    return handleGenerateResponses(e, filter.instance.isCurrentInstance);
                }, title: t("Administrate.Respondent.GenerateResponses.Title"), tooltipTitle: t("Administrate.Respondent.GenerateResponses.TooltipTitle"), tooltip: t("Administrate.Respondent.GenerateResponses.Tooltip"), icon: _jsx(BsMagic, {}), isDisabled: noOfSelected === 0 || !filter.instance.isCurrentInstance })] }));
};
RespondentsSelectedActionsGenerateResponses.displayName =
    'RespondentsSelectedActionsGenerateResponses';
