var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { BsDash, BsPlus } from 'react-icons/bs';
import styled, { css } from 'styled-components';
export var TreeItem = function (_a) {
    var _b;
    var item = _a.item, selected = _a.selected, highlightItems = _a.highlightItems, onSelect = _a.onSelect, dropItem = _a.dropItem, isDraggable = _a.isDraggable;
    var _c = useState(!!item.isOpen), isItemOpen = _c[0], setIsItemOpen = _c[1];
    var hasChildren = item.items && item.items.length > 0;
    var isSelected = selected === item.id;
    var isHighlighted = highlightItems === null || highlightItems === void 0 ? void 0 : highlightItems.includes(item.id);
    var handleToggle = function () {
        if (hasChildren) {
            setIsItemOpen(!isItemOpen);
        }
    };
    var handleSelect = function () {
        if (hasChildren && !isItemOpen) {
            setIsItemOpen(true);
        }
        onSelect && onSelect(item);
    };
    var handleDragStart = function (event) {
        event.stopPropagation();
        event.dataTransfer.setData('text/plain', item.id);
        event.dataTransfer.effectAllowed = 'move';
    };
    var handleDragOver = function (event) {
        event.currentTarget.classList.add('drop-zone');
        event.stopPropagation();
        event.preventDefault();
    };
    var handleDragLeave = function (event) {
        event.currentTarget.classList.remove('drop-zone');
    };
    var handleDrop = function (event) {
        event.stopPropagation();
        event.currentTarget.classList.remove('drop-zone');
        dropItem === null || dropItem === void 0 ? void 0 : dropItem(event.dataTransfer.getData('text/plain'), item.id);
    };
    return (_jsxs(Style, __assign({ draggable: isDraggable, onDragStart: handleDragStart, onDragOver: handleDragOver, onDrop: handleDrop, onDragEnd: handleDragLeave, onDragLeave: handleDragLeave, "$isDraggable": isDraggable }, { children: [_jsx(ToggleIcon, __assign({ "$hasChildren": !!hasChildren, "$isOpen": isItemOpen, onClick: handleToggle }, { children: isItemOpen ? _jsx(BsDash, {}) : _jsx(BsPlus, {}) })), item.icon && _jsx(ItemIcon, { children: item.icon }), _jsx(ContentStyle, __assign({ onClick: handleSelect, "$isSelected": isSelected, "$isHighlighted": isHighlighted }, { children: item.content })), _jsx(ItemStyle, __assign({ "$isOpen": isItemOpen }, { children: (_b = item.items) === null || _b === void 0 ? void 0 : _b.map(function (child, index) { return (_jsx(TreeItem, { item: child, selected: selected, highlightItems: highlightItems, onSelect: onSelect, dropItem: dropItem, isDraggable: isDraggable }, index)); }) }))] })));
};
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  padding: 4px 0px 0px 0px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  vertical-align: middle;\n\n  cursor: ", ";\n\n  .drop-zone {\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  position: relative;\n  padding: 4px 0px 0px 0px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  vertical-align: middle;\n\n  cursor: ", ";\n\n  .drop-zone {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return (props.$isDraggable ? 'move' : 'auto'); }, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
});
var ContentStyle = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n\n  ", "\n\n  padding: 3px 2px;\n\n  &:hover {\n    background-color: ", ";\n\n    color: ", ";\n  }\n\n  vertical-align: middle;\n  user-select: none;\n  cursor: pointer;\n"], ["\n  ", "\n\n  ", "\n\n  padding: 3px 2px;\n\n  &:hover {\n    background-color: ", ";\n\n    color: ", ";\n  }\n\n  vertical-align: middle;\n  user-select: none;\n  cursor: pointer;\n"])), function (props) {
    return props.$isSelected && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.primary;
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors.onPrimary;
    });
}, function (props) {
    return props.$isHighlighted && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      border: 1px dotted\n        ", ";\n    "], ["\n      border: 1px dotted\n        ", ";\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.primary;
    });
}, function (props) {
    return props.$isSelected
        ? props.theme.colors.primary
        : props.theme.colors.secondary;
}, function (props) {
    return props.$isSelected
        ? props.theme.colors.onPrimary
        : props.theme.colors.onSecondary;
});
var ItemStyle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: ", ";\n  margin-left: 6;\n  padding: 2px 0px 2px 14px;\n"], ["\n  display: ", ";\n  margin-left: 6;\n  padding: 2px 0px 2px 14px;\n"])), function (props) { return (props.$isOpen ? 'block' : 'none'); });
var ItemIcon = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 5px 0 0;\n  vertical-align: middle;\n  font-size: 1.6rem;\n"], ["\n  margin: 0 5px 0 0;\n  vertical-align: middle;\n  font-size: 1.6rem;\n"])));
var ToggleIcon = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-right: 3px;\n  font-size: 1.6rem;\n  vertical-align: middle;\n\n  > svg {\n    margin-top: 3px;\n  }\n\n  cursor: ", ";\n  opacity: ", ";\n"], ["\n  margin-right: 3px;\n  font-size: 1.6rem;\n  vertical-align: middle;\n\n  > svg {\n    margin-top: 3px;\n  }\n\n  cursor: ", ";\n  opacity: ", ";\n"])), function (props) { return (props.$hasChildren ? 'pointer' : 'default'); }, function (props) { return (props.$hasChildren ? 1 : 0); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
