var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheck, BsFiles } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { copyToClipboard } from '../utils';
import { BadgeMini, BadgeTiny } from './Badge';
import { ButtonLink } from './form';
import { TooltipContent } from './tooltip';
export var CopyToClipboard = function (_a) {
    var value = _a.value, text = _a.text, icon = _a.icon, tooltipId = _a.tooltipId, style = _a.style, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b;
    var uid = useId();
    var t = useTranslation().t;
    var id = tooltipId || "tt-".concat(uid);
    var _c = useState(false), isCopied = _c[0], setIsCopied = _c[1];
    useEffect(function () {
        if (!isCopied) {
            return;
        }
        var timer = setTimeout(function () {
            setIsCopied(false);
            clearTimeout(timer);
        }, 2000);
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isCopied]);
    var handleCopy = function (value) {
        if (isDisabled) {
            return;
        }
        copyToClipboard(value);
        setIsCopied(true);
    };
    return (_jsxs(ContainerStyle, __assign({ style: style }, { children: [!tooltipId && (_jsx(TooltipContent, { id: id, title: t('Common.Copy.TooltipTitle'), text: t('Common.Copy.Tooltip') })), _jsx(BadgeMini, __assign({ disabled: isDisabled || isCopied, "data-tip": true, "data-for": id, onClick: function () { return handleCopy(value); } }, { children: icon !== null && icon !== void 0 ? icon : _jsx(BsFiles, {}) })), _jsxs(ButtonLink, __assign({ "data-tip": true, "data-for": id, style: { marginLeft: 5 }, disabled: isDisabled || isCopied, onClick: function () {
                    handleCopy(value);
                } }, { children: [_jsx(Style, __assign({ className: isCopied ? 'fade-in' : 'fade-out' }, { children: t('Common.Copy.HasCopied') })), _jsx(Style, __assign({ className: !isCopied ? 'fade-in' : 'fade-out' }, { children: text || t('Common.Copy.Text') }))] }))] })));
};
CopyToClipboard.displayName = 'CopyToClipboard';
export var CopyToClipboardInline = withTheme(function (_a) {
    var value = _a.value, icon = _a.icon, theme = _a.theme, isInverted = _a.isInverted;
    var _b = useState(false), isCopied = _b[0], setIsCopied = _b[1];
    useEffect(function () {
        if (!isCopied) {
            return;
        }
        var timer = setTimeout(function () {
            setIsCopied(false);
            clearTimeout(timer);
        }, 2000);
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isCopied]);
    return (_jsx("div", __assign({ style: { display: 'inline-block', marginLeft: 3 } }, { children: _jsxs(BadgeTiny, __assign({ "$inverted": isInverted, style: { fontSize: theme.fonts.size.xs, paddingTop: 3 }, onClick: function (e) {
                e.stopPropagation();
                copyToClipboard(value);
                setIsCopied(true);
            } }, { children: [_jsx(Style, __assign({ className: isCopied ? 'fade-in' : 'fade-out' }, { children: _jsx(BsCheck, {}) })), _jsx(Style, __assign({ className: !isCopied ? 'fade-in' : 'fade-out' }, { children: icon !== null && icon !== void 0 ? icon : _jsx(BsFiles, {}) }))] })) })));
});
CopyToClipboard.displayName = 'CopyToClipboard';
var Style = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.fade-out {\n    opacity: 0;\n    display: none;\n    transition: opacity 1s;\n  }\n  &.fade-in {\n    opacity: 1;\n    display: block;\n    transition: opacity 0.2s;\n  }\n"], ["\n  &.fade-out {\n    opacity: 0;\n    display: none;\n    transition: opacity 1s;\n  }\n  &.fade-in {\n    opacity: 1;\n    display: block;\n    transition: opacity 0.2s;\n  }\n"])));
var ContainerStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2;
