var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { SurveyMessageType } from '@dimatech/shared/lib/models';
import { nanoid } from 'nanoid';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectFilter } from '../../../api/filter/filterSlice';
export var ScheduleReminderAdd = function (_a) {
    var schedule = _a.schedule, setSchedule = _a.setSchedule;
    var t = useTranslation().t;
    var filter = useSelector(selectFilter);
    var handleAddReminder = function () {
        var reminders = __spreadArray([], schedule.reminders, true);
        reminders.push({
            messageType: SurveyMessageType.SurveyMessage,
            uid: nanoid(6),
            sendDate: null,
            isDirty: true,
            isValid: true,
        });
        setSchedule(__assign(__assign({}, schedule), { reminders: reminders }));
    };
    return (_jsx(_Fragment, { children: _jsxs(ButtonLink, __assign({ style: {
                margin: '15px 30px 5px 100px',
                alignSelf: 'center',
                width: 'fit-content',
                whiteSpace: 'nowrap',
            }, onClick: handleAddReminder, disabled: !filter.instance.isCurrentInstance }, { children: [_jsx(BsPlus, {}), " ", t('Administrate.Schedule.AddReminder')] })) }));
};
ScheduleReminderAdd.displayName = 'ScheduleReminderAdd';
