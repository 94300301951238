var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useGetEntitiesWithSurveyQuery } from '../../api/entity/entityApi';
import { entityActions, selectSelectedEntity, } from '../../api/entity/entitySlice';
import { filterActions, selectFilter } from '../../api/filter/filterSlice';
import { SelectExtendedFilters } from '../../components/SelectExtendedFilters';
import { EntitiesTree } from './components/EntitiesTree';
import { EntityProperties } from './components/EntityProperties';
export var AdministrateOrganisation = function (_a) {
    var _b, _c, _d;
    var isViewersEnabledFlagOn = _a.isViewersEnabledFlagOn;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var selectedEntity = useSelector(selectSelectedEntity);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var location = useLocation();
    var searchedForId = (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.id;
    var _e = useState(searchedForId || ''), selectedEntityId = _e[0], setSelectedEntityId = _e[1];
    var entities = useGetEntitiesWithSurveyQuery(accessToken.customerId && ((_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id) && filter.instance.id
        ? {
            _customerId: accessToken.customerId,
            _userId: (_d = accessToken.user) === null || _d === void 0 ? void 0 : _d.id,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    var hasPermissionsOnRoot = entities === null || entities === void 0 ? void 0 : entities.some(function (e) { return !e.parentId; });
    var handleCreate = function () {
        if (isReadOnly || !filter.instance.isCurrentInstance) {
            return;
        }
        if (!selectedEntity && hasPermissionsOnRoot) {
            return;
        }
        var entity = {
            parentId: null,
            allowIndividualMeasurementPeriods: false,
            type: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.type,
            parentType: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.type,
        };
        if (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) {
            entity.parentId = selectedEntity.id;
        }
        dispatch(entityActions.selectedEntity(entity));
    };
    useEffect(function () {
        if (searchedForId) {
            dispatch(filterActions.resetFilter());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, { children: [_jsx(Heading1, { children: t('Administrate.Entity.Title') }), _jsxs("div", { children: [_jsx(TooltipContent, { id: "tt-entity-add", title: t('Administrate.Entity.Add.TooltipTitle'), text: t('Administrate.Entity.Add.Tooltip') }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-entity-add", onClick: handleCreate, "$inverted": true, disabled: isReadOnly ||
                                    !filter.instance.isCurrentInstance ||
                                    (!selectedEntity && hasPermissionsOnRoot) }, { children: _jsx(BsPlus, {}) }))] }), _jsx(SelectExtendedFilters, { style: { marginLeft: '10px' } })] }), _jsx(CustomerAccountInfo, {}), _jsx(Card, __assign({ width: "100%" }, { children: _jsxs(CardBody, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Entity.Text') })), !filter.instance.isCurrentInstance && (_jsx(AlertWarning, __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.ReadOnly') }))), (entities === null || entities === void 0 ? void 0 : entities.length) === 0 && !selectedEntity ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Entity.NoEntities') })), _jsx(ButtonLink, __assign({ type: "button", onClick: handleCreate, disabled: !filter.instance.isCurrentInstance }, { children: t('Administrate.Entity.NoEntitiesAddFirst') }))] })) : (_jsxs(Style, { children: [_jsx(EntitiesTree, { selectedEntityId: selectedEntityId, setSelectedEntityId: setSelectedEntityId }), selectedEntity && (_jsx("div", __assign({ style: { flexGrow: 1 } }, { children: _jsx(EntityProperties, { isViewersEnabledFlagOn: isViewersEnabledFlagOn }) })))] }))] }) }))] }));
};
AdministrateOrganisation.displayName = 'AdministrateOrganisation';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    padding: 0 30px 30px 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    padding: 0 30px 30px 0;\n  }\n"])));
var templateObject_1;
