var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import styled from 'styled-components';
import { BadgeSmall } from '../Badge';
export var ImageCarousel = function (_a) {
    var style = _a.style, children = _a.children;
    var _b = useState(0), currentIndex = _b[0], setCurrentIndex = _b[1];
    var _c = useState(children.length), length = _c[0], setLength = _c[1];
    var handleNext = function () {
        if (currentIndex < length - 1) {
            setCurrentIndex(function (prevState) { return prevState + 1; });
        }
    };
    var handlePrevious = function () {
        if (currentIndex > 0) {
            setCurrentIndex(function (prevState) { return prevState - 1; });
        }
    };
    useEffect(function () {
        setLength(children.length);
    }, [children]);
    return (_jsx(ContainerStyle, __assign({ style: style }, { children: _jsxs(WrapperStyle, { children: [currentIndex > 0 && (_jsx(LeftButton, __assign({ "$inverted": true, onClick: handlePrevious }, { children: _jsx(BsArrowLeft, {}) }))), _jsx(ContentWrapperStyle, { children: _jsx(ContentStyle, __assign({ style: { transform: "translateX(-".concat(currentIndex * 100, "%)") } }, { children: children })) }), currentIndex < length - 1 && (_jsx(RightButton, __assign({ "$inverted": true, onClick: handleNext }, { children: _jsx(BsArrowRight, {}) })))] }) })));
};
ImageCarousel.displayName = 'ImageCarousel';
var ContainerStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  border-radius: 4px;\n\n  background-color: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  border-radius: 4px;\n\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
var WrapperStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n"], ["\n  display: flex;\n  position: relative;\n"])));
var ContentWrapperStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
var ContentStyle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  transition: all 150ms linear;\n  overflow: none;\n"], ["\n  display: flex;\n  transition: all 150ms linear;\n  overflow: none;\n"])));
var NavigationButton = styled(BadgeSmall)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1;\n  top: 50%;\n  transform: translateY(-80%);\n"], ["\n  position: absolute;\n  z-index: 1;\n  top: 50%;\n  transform: translateY(-80%);\n"])));
var LeftButton = styled(NavigationButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  left: 10px;\n"], ["\n  left: 10px;\n"])));
var RightButton = styled(NavigationButton)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  right: 10px;\n"], ["\n  right: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
