import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { Login as LoginComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { useAppDispatch } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewCentered } from 'views';

export const Login = (): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(appActions.resetApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoggedIn = () => {
    navigate((location.state as { from: string })?.from ?? '/');
  };

  return (
    <ViewCentered title={t('Login.Title')}>
      <LoginComponent handleLoggedIn={handleLoggedIn} />
    </ViewCentered>
  );
};

Login.displayName = 'Login';
