var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { config } from '@dimatech/shared/lib/config';
import { Environment } from '@dimatech/shared/lib/models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckAll, BsX } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { entitySurveyRespondentActions, selectSelectedRespondents, } from '../../../api/entity/entitySurveyRespondentSlice';
import { RespondentsSelectedActionsDelete } from './RespondentsSelectedActionsDelete';
import { RespondentsSelectedActionsGenerateResponses } from './RespondentsSelectedActionsGenerateResponses';
import { RespondentsSelectedActionsMove } from './RespondentsSelectedActionsMove';
export var RespondentsSelectedActions = function (_a) {
    var filtered = _a.filtered, selectedEntityId = _a.selectedEntityId, setError = _a.setError, isReadOnly = _a.isReadOnly, _b = _a.isMoveEnabled, isMoveEnabled = _b === void 0 ? true : _b, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
    var isProduction = config.environment.current === Environment.Production;
    var isGenerateAnswersEnabled = !isProduction && isGlobalAdmin;
    var selectedRespondents = useSelector(selectSelectedRespondents);
    var noOfSelected = selectedRespondents.length;
    var noOfTotal = filtered.length;
    var isAllChecked = noOfSelected === noOfTotal;
    var handleSelectAll = function () {
        var checked = !isAllChecked;
        dispatch(entitySurveyRespondentActions.setSelectedRespondents(checked ? __spreadArray([], filtered, true) : []));
    };
    return (_jsxs(_Fragment, { children: [_jsx(LinkWithTooltip, { style: { margin: '0 5px 0 0' }, handleClick: handleSelectAll, title: isAllChecked
                    ? t("Administrate.Respondent.DeselectAll.Title")
                    : t("Administrate.Respondent.SelectAll.Title"), tooltipTitle: isAllChecked
                    ? t("Administrate.Respondent.DeselectAll.TooltipTitle")
                    : t("Administrate.Respondent.SelectAll.TooltipTitle"), tooltip: isAllChecked
                    ? t("Administrate.Respondent.DeselectAll.Tooltip")
                    : t("Administrate.Respondent.SelectAll.Tooltip"), icon: isAllChecked ? _jsx(BsX, {}) : _jsx(BsCheckAll, {}) }), isMoveEnabled && (_jsx(RespondentsSelectedActionsMove, { isReadOnly: isReadOnly, selectedEntityId: selectedEntityId, setError: setError, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted })), _jsx(RespondentsSelectedActionsDelete, { isReadOnly: isReadOnly, selectedEntityId: selectedEntityId, setError: setError, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted }), isGenerateAnswersEnabled && (_jsx(RespondentsSelectedActionsGenerateResponses, { selectedEntityId: selectedEntityId, setError: setError }))] }));
};
RespondentsSelectedActions.displayName = 'RespondentsSelectedActions';
