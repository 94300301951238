import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Demo as DemoComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ViewCentered } from 'views';

export const Demo = (): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useContext(AuthenticationContext);
  const isDemoEnabled = useFlags()[flags.permanent.app.dimios.isDemoEnabled];

  useEffect(() => {
    dispatch(appActions.resetApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoggedIn = () => {
    navigate((location.state as { from: string })?.from ?? '/');
  };

  if (!isDemoEnabled) {
    logout();
    return <Navigate to="/" />;
  }

  return (
    <ViewCentered
      title={t('Demo.Title')}
      style={{ width: '80%', maxWidth: 350 }}
    >
      <DemoComponent handleLoggedIn={handleLoggedIn} />
    </ViewCentered>
  );
};

Demo.displayName = 'Demo';
