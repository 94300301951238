var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { BsExclamationCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
export var InvalidFieldIcon = function (_a) {
    var invalid = _a.invalid, invalidTooltipId = _a.invalidTooltipId;
    return invalid ? _jsx(Style, { "data-tip": true, "data-for": invalidTooltipId }) : null;
};
InvalidFieldIcon.displayName = 'InvalidFieldIcon';
var Style = styled(BsExclamationCircleFill)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  width: 16px;\n  height: 16px;\n  margin-left: 5px;\n  flex-shrink: 0;\n"], ["\n  color: ", ";\n  width: 16px;\n  height: 16px;\n  margin-left: 5px;\n  flex-shrink: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.error;
});
var templateObject_1;
