import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { PromoCarousel, Tip } from '@dimatech/shared/lib/components/carousel';
import {
  CardFocused,
  CardFocusedBody,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isAccountAdmin } from 'utils/authentication';

export const FeaturesPromo = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [tips, setTips] = useState<Tip[]>();

  const isUserMessagesForCustomersEnabledFlagOn =
    useFlags()[flags.permanent.shared.isUserMessagesForCustomersEnabled];
  const isFeaturesPromoEnabledFlagOn =
    useFlags()[flags.permanent.app.dimios.isFeaturesPromoEnabled];

  useEffect(() => {
    if (!isFeaturesPromoEnabledFlagOn) {
      return;
    }

    const tips = [
      {
        title: t('FeaturesPromo.Tip1.Title'),
        content: (
          <div>
            {t('FeaturesPromo.Tip1.Text')}
            <div style={{ marginTop: 10 }}>
              <Link to="/question-analyze">
                {t('FeaturesPromo.Tip1.LinkText')}
              </Link>
            </div>
          </div>
        ),
      },
      {
        title: t('FeaturesPromo.Tip3.Title'),
        content: (
          <div>
            {t('FeaturesPromo.Tip3.Text')}
            <div style={{ marginTop: 10 }}>
              <Link to="/next-step">{t('FeaturesPromo.Tip3.LinkText')}</Link>
            </div>
          </div>
        ),
      },
    ];

    if (
      isUserMessagesForCustomersEnabledFlagOn &&
      isAccountAdmin(accessToken)
    ) {
      tips.push({
        title: t('FeaturesPromo.Tip2.Title'),
        content: (
          <div>
            {t('FeaturesPromo.Tip2.Text')}
            <div style={{ marginTop: 10 }}>
              <Link to="/administrate/messages">
                {t('FeaturesPromo.Tip2.LinkText')}
              </Link>
            </div>
          </div>
        ),
      });
    }

    setTips(tips);
  }, [
    t,
    accessToken,
    isUserMessagesForCustomersEnabledFlagOn,
    isFeaturesPromoEnabledFlagOn,
  ]);

  if (!isFeaturesPromoEnabledFlagOn) {
    return null;
  }

  if (!tips) {
    return null;
  }

  return (
    <CardFocused>
      <CardFocusedBody>
        <PromoCarousel
          items={tips}
          style={{
            marginTop: 3,
            marginLeft: 3,
            marginBottom: 15,
            maxWidth: 250,
          }}
        />
      </CardFocusedBody>
    </CardFocused>
  );
};

FeaturesPromo.displayName = 'FeaturesPromo';
