var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { Cell, Pie, PieChart } from 'recharts';
import styled, { withTheme } from 'styled-components';
export var Gauge = withTheme(function (_a) {
    var value = _a.value, _b = _a.defaultValue, defaultValue = _b === void 0 ? '0' : _b, name = _a.name, color = _a.color, backgroundColor = _a.backgroundColor, text = _a.text, _c = _a.isAnimationActive, isAnimationActive = _c === void 0 ? true : _c, theme = _a.theme;
    return (_jsxs(Style, { children: [_jsxs("div", { children: [_jsx(PieChart, __assign({ width: 180, height: 90 }, { children: _jsxs(Pie, __assign({ data: [{ value: value, name: name }, { value: 100 - (value !== null && value !== void 0 ? value : 0) }], cx: 80, cy: 80, startAngle: 180, endAngle: 0, innerRadius: 50, outerRadius: 67, stroke: "none", dataKey: "value", animationDuration: 500, isAnimationActive: isAnimationActive }, { children: [_jsx(Cell, { fill: color || theme.colors.primary }), _jsx(Cell, { fill: backgroundColor || theme.colors.secondaryDim })] })) })), _jsxs("div", { children: [_jsx(Heading1, { children: formatAsPercent(value, defaultValue) }), _jsx("div", { children: name })] })] }), _jsx(HelpIcon, { title: name, text: text })] }));
});
Gauge.displayName = 'Gauge';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n\n  > div:first-of-type {\n    width: 170px;\n\n    > div {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      text-align: center;\n      font-size: ", ";\n    }\n\n    h1 {\n      margin-top: -40px;\n      margin-bottom: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  > div:last-of-type {\n    margin-left: -30px;\n    z-index: 10;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n\n  > div:first-of-type {\n    width: 170px;\n\n    > div {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      text-align: center;\n      font-size: ", ";\n    }\n\n    h1 {\n      margin-top: -40px;\n      margin-bottom: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  > div:last-of-type {\n    margin-left: -30px;\n    z-index: 10;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xs;
});
var templateObject_1;
