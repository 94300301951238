import { ReleaseNotes as ReleaseNotesComponent } from '@dimatech/features-core/lib/features/release-notes';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ReleaseNotes = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View title={t('ReleaseNotes.Title')}>
      <ViewHeader>
        <Heading1>{t('ReleaseNotes.Title')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody>
          <ReleaseNotesComponent />
        </CardBody>
      </Card>
    </View>
  );
};

ReleaseNotes.displayName = 'ReleaseNotes';
