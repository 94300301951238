import { Currency, Language, Locale } from '../models';
export var getLocaleSettings = function (locale) {
    switch (locale) {
        case Locale.sv_AX:
        case Locale.sv_FI:
            return {
                language: Language.Swedish,
                currency: Currency.EUR,
            };
        case Locale.en_GB:
            return {
                language: Language.English,
                currency: Currency.SEK,
            };
        default:
            return {
                language: Language.Swedish,
                currency: Currency.SEK,
            };
    }
};
