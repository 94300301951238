var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';
import { BsFillPencilFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectFilter } from '../../../api/filter/filterSlice';
export var RespondentActionEdit = function (_a) {
    var email = _a.email, selectedEntityId = _a.selectedEntityId, setIsUpdatingRespondent = _a.setIsUpdatingRespondent;
    var t = useTranslation().t;
    var filter = useSelector(selectFilter);
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-respondent-edit", title: t('Administrate.Respondent.Edit.TooltipTitle'), text: t('Administrate.Respondent.Edit.Tooltip') }), _jsx(BadgeMini, __assign({ style: { margin: '0 3px 0 0' }, "data-tip": true, "data-for": "tt-respondent-edit", onClick: function () {
                    if (!filter.instance.isCurrentInstance) {
                        return;
                    }
                    else {
                        setIsUpdatingRespondent({ email: email, entityId: selectedEntityId });
                    }
                }, disabled: !filter.instance.isCurrentInstance }, { children: _jsx(BsFillPencilFill, {}) }))] }));
};
RespondentActionEdit.displayName = 'RespondentActionEdit';
