var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { formatAsCurrency } from '@dimatech/shared/lib/utils';
import { useTranslation } from 'react-i18next';
export var LicenseType = function (_a) {
    var licenseType = _a.licenseType, title = _a.title;
    var t = useTranslation().t;
    return (_jsxs("section", { children: [_jsx(Heading3, { children: title !== null && title !== void 0 ? title : t("Register.LicenseType.Title") }), _jsxs("div", { children: [_jsxs("div", __assign({ style: { width: '100%', marginTop: '10px' } }, { children: [t("Register.LicenseType.LicenseType"), ' ', t("LicenseType.".concat(licenseType.name.replace(/\s/g, '_')))] })), _jsxs("div", __assign({ style: { width: '100%', marginTop: '10px' } }, { children: [t('Register.LicenseType.Price'), formatAsCurrency(licenseType.price)] }))] })] }));
};
LicenseType.displayName = 'LicenseType';
