import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AllResult as AllResultFeature } from 'features/all-result/AllResult';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AllResult = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  return (
    <View
      title={t(
        `${accessToken.isInternational ? 'SwedishResult' : 'AllResult'}.Title`
      )}
    >
      <AllResultFeature />
    </View>
  );
};

AllResult.displayName = 'AllResult';
