var _a;
import { createSlice } from '@reduxjs/toolkit';
import { appActions } from '../appSlice';
export var initialState = {
    recipientsToAdd: [],
    recipientsToDelete: [],
    isAdding: false,
    isUpdating: false,
    isRecipientsAdding: false,
    isRecipientsDeleting: false,
    isAddingRecipientsShown: false,
};
export var messageReducer = (_a = createSlice({
    name: 'message',
    initialState: initialState,
    reducers: {
        selectMessage: function (state, action) {
            state.selectedMessage = action.payload;
        },
        setIsUpdating: function (state, action) {
            state.isUpdating = action.payload;
        },
        setIsAdding: function (state, action) {
            state.isAdding = action.payload;
        },
        setIsRecipientsAdding: function (state, action) {
            state.isRecipientsAdding = action.payload;
        },
        setIsRecipientsDeleting: function (state, action) {
            state.isRecipientsDeleting = action.payload;
        },
        setRecipientsToDelete: function (state, action) {
            state.recipientsToDelete = action.payload;
        },
        setRecipientsToAdd: function (state, action) {
            state.recipientsToAdd = state.recipientsToAdd.concat(action.payload.filter(function (r) { return !state.recipientsToAdd.some(function (n) { return n.userId === r.userId; }); }));
        },
        setHasChanges: function (state, action) {
            state.hasChanges = action.payload;
        },
        setIsAddingRecipientsShown: function (state, action) {
            state.isAddingRecipientsShown = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function () { return initialState; });
    },
}), _a.reducer), messageActions = _a.actions;
export var selectSelectedMessage = function (state) { return state.message.selectedMessage; };
export var selectIsPosting = function (state) {
    return state.message.isUpdating ||
        state.message.isAdding ||
        state.message.isRecipientsDeleting ||
        state.message.isRecipientsAdding;
};
export var selectIsAddingRecipientsShown = function (state) { return state.message.isAddingRecipientsShown; };
export var selectRecipientsToDelete = function (state) { return state.message.recipientsToDelete; };
export var selectRecipientsToAdd = function (state) { return state.message.recipientsToAdd; };
export var selectHasChanges = function (state) { return state.message.hasChanges; };
