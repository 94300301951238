var _a;
import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isMovingSelectedRespondents: false,
    isDeletingSelectedRespondents: false,
    isGeneratingResponsesSelectedRespondents: false,
    selectedRespondents: [],
};
export var entitySurveyRespondentReducer = (_a = createSlice({
    name: 'entity/survey/respondent',
    initialState: initialState,
    reducers: {
        setMovingRespondent: function (state, action) {
            state.deletingRespondent = undefined;
            state.movingRespondent = action.payload;
        },
        setDeletingRespondent: function (state, action) {
            state.movingRespondent = undefined;
            state.deletingRespondent = action.payload;
        },
        setIsMovingSelectedRespondents: function (state, action) {
            state.isDeletingSelectedRespondents = false;
            state.isGeneratingResponsesSelectedRespondents = false;
            state.isMovingSelectedRespondents = action.payload;
        },
        setIsDeletingSelectedRespondents: function (state, action) {
            state.isMovingSelectedRespondents = false;
            state.isGeneratingResponsesSelectedRespondents = false;
            state.isDeletingSelectedRespondents = action.payload;
        },
        setSelectedRespondents: function (state, action) {
            state.selectedRespondents = action.payload;
        },
        resetSelectedRespondents: function (state) {
            state.selectedRespondents = initialState.selectedRespondents;
        },
        setIsGeneratingResponsesSelectedRespondents: function (state, action) {
            state.isMovingSelectedRespondents = false;
            state.isDeletingSelectedRespondents = false;
            state.isGeneratingResponsesSelectedRespondents = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function () { return initialState; });
    },
}), _a.reducer), entitySurveyRespondentActions = _a.actions;
export var selectMovingRespondent = function (state) { return state.entitySurveyRespondent.movingRespondent; };
export var selectDeletingRespondent = function (state) { return state.entitySurveyRespondent.deletingRespondent; };
export var selectIsMovingSelectedRespondents = function (state) { return state.entitySurveyRespondent.isMovingSelectedRespondents; };
export var selectIsGeneratingResponsesSelectedRespondents = function (state) {
    return state.entitySurveyRespondent.isGeneratingResponsesSelectedRespondents;
};
export var selectIsDeletingSelectedRespondents = function (state) { return state.entitySurveyRespondent.isDeletingSelectedRespondents; };
export var selectSelectedRespondents = function (state) { return state.entitySurveyRespondent.selectedRespondents; };
