var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { CommonRoles } from '../../../models';
import { Impersonate } from '../../authentication/components/Impersonate';
import { SendForgotPasswordEmail } from '../../authentication/components/SendForgotPasswordEmail';
export var UserEditButtons = function (_a) {
    var user = _a.user, setValidationErrors = _a.setValidationErrors, role = _a.role, deleteUser = _a.deleteUser;
    var t = useTranslation().t;
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var _c = useState(''), roleNameShort = _c[0], setRoleNameShort = _c[1];
    var handleDelete = function (e) {
        e.stopPropagation();
        if (!user.email) {
            return;
        }
        deleteUser(user);
        setIsDeleting(false);
    };
    var handleConfirmDelete = function (e, show) {
        e.stopPropagation();
        setValidationErrors(undefined);
        setIsDeleting(show);
    };
    useEffect(function () {
        if (role === CommonRoles.PortfolioOwner) {
            setRoleNameShort('Owner');
        }
        if (role === CommonRoles.CustomerAdmin) {
            setRoleNameShort('Admin');
        }
        if (role === CommonRoles.Reader) {
            setRoleNameShort('Viewer');
        }
        if (role === CommonRoles.PiosReviewer) {
            setRoleNameShort('Reviewer');
        }
        if (role === CommonRoles.PiosProjectManager) {
            setRoleNameShort("Manager");
        }
        if (role === CommonRoles.PiosEffectRealizationResponsible) {
            setRoleNameShort("EffectRealisationResponsible");
        }
        if (role === CommonRoles.PiosOrderingParty) {
            setRoleNameShort("OrderingParty");
        }
    }, [role]);
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t("Administrate.Users.".concat(roleNameShort, ".Delete.ConfirmTitle")), handleKeyEnter: function (e) { return handleDelete(e); }, handleKeyEsc: function (e) { return handleConfirmDelete(e, false); } }, { children: [_jsx("div", { children: t("Administrate.Users.".concat(roleNameShort, ".Delete.ConfirmText")) }), _jsx(AlertWarning, __assign({ style: { marginTop: 10, marginBottom: 10 } }, { children: t("Administrate.Users.".concat(roleNameShort, ".Delete.Warning")) })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleDelete(e); } }, { children: t('Common.UI.Yes') }))] })] }))), _jsx(Impersonate, { email: user.email }), _jsx(SendForgotPasswordEmail, { email: user.email }), _jsx(TooltipContent, { id: "tt-user-delete-x", title: t("Administrate.Users.".concat(roleNameShort, ".Delete.TooltipTitle")), text: t("Administrate.Users.".concat(roleNameShort, ".Delete.Tooltip")) }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-user-delete-x", onClick: function (e) { return handleConfirmDelete(e, true); }, style: { marginLeft: 7 } }, { children: _jsx(BsTrash, {}) }))] }));
};
UserEditButtons.displayName = 'UserEditButtons';
