var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    errors: [],
    messages: {
        unreadCount: 0,
        isUserMessageToastShown: false,
        isUserMessageShown: false,
    },
    releaseNotes: {
        versions: [],
    },
};
export var appReducer = (_a = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setError: function (state, action) {
            state.errors = __spreadArray(__spreadArray([], state.errors, true), [action.payload], false);
        },
        hideError: function (state, action) {
            var ind = state.errors.findIndex(function (error) { return error.id === action.payload.id; });
            if (ind > -1) {
                state.errors[ind] = __assign(__assign({}, state.errors[ind]), { isHidden: true });
            }
        },
        hideErrors: function (state, action) {
            state.errors.forEach(function (error, index) {
                if (error.message === action.payload.message) {
                    state.errors[index].isHidden = true;
                }
            });
        },
        clearErrors: function (state) {
            state.errors = [];
        },
        resetApplication: function (_state, _action) {
            return initialState;
        },
        setUnreadMessageCount: function (state, action) {
            state.messages.isUserMessageToastShown =
                state.messages.unreadCount !== action.payload;
            state.messages.unreadCount = action.payload;
        },
        setIsUserMessageToastShown: function (state, action) {
            state.messages.isUserMessageToastShown = action.payload;
        },
        setIsUserMessageShown: function (state, action) {
            state.messages.isUserMessageShown = action.payload;
        },
        appendReleaseNotesVersion: function (state, action) {
            if (!state.releaseNotes.versions.includes(action.payload)) {
                state.releaseNotes.versions.push(action.payload);
            }
        },
    },
    extraReducers: {},
}), _a.reducer), appActions = _a.actions;
export var selectErrors = function (state) {
    return state.app.errors;
};
export var selectUnreadMessageCount = function (state) { return state.app.messages.unreadCount; };
export var selectIsUserMessageToastShown = function (state) { return state.app.messages.isUserMessageToastShown; };
export var selectIsUserMessageShown = function (state) { return state.app.messages.isUserMessageShown; };
export var selectReleaseNotesVersions = function (state) { return state.app.releaseNotes.versions; };
