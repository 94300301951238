import { Table, Td, Tr } from '@dimatech/shared/lib/components/table';
import { config } from 'config';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

// NOTE: Allow any here, just for debugging
/* eslint-disable @typescript-eslint/no-explicit-any */

export const GlobalAdministrateAppConfig = (): JSX.Element | null => {
  const { t } = useTranslation();

  const flattenConfig = (): Record<string, string> => {
    const flattened: Record<string, string> = flatten(config);
    return flattened;
  };

  const flatten = (
    source: Record<string, any>,
    path?: string,
    flattened: Record<string, any> = {}
  ): Record<string, string> => {
    for (const key in source) {
      const name = path ? `${path}.${key}` : key;

      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (
          typeof source[key] === 'string' ||
          typeof source[key] === 'number'
        ) {
          flattened[name] = source[key];
        } else {
          flatten(
            (source[key] ?? {}) as Record<string, string>,
            name,
            flattened
          );
        }
      }
    }

    return flattened;
  };

  const flat = flattenConfig();

  return (
    <View title={t('Navigation.GlobalAdministrate.AppConfig')}>
      <Table>
        <tbody>
          {Object.keys(flat).map((key, index) => (
            <Tr
              key={index}
              isHoverable={true}
              isSelected={false}
              onSelect={() => {
                //
              }}
            >
              <Td>{key}</Td>
              <Td>{flat[key] as string}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </View>
  );
};

GlobalAdministrateAppConfig.displayName = 'GlobalAdministrateAppConfig';
