import axios from 'axios';
import { mapAxiosBaseQueryError } from '../utils';
// This will intercept all requests and responses done using axios
export var initializeAxiosResponseInterceptor = function () {
    axios.interceptors.response.use(function (config) {
        return config;
    }, function (error) {
        // All status codes except 2xx causes this function to trigger
        return Promise.reject(mapAxiosBaseQueryError(error, error.response));
    });
};
