import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { PrinciplesByCategory } from 'models';
import { useTranslation } from 'react-i18next';
import {
  BsArrowRepeat,
  BsBricks,
  BsDiagram3,
  BsGear,
  BsLightbulb,
  BsPeople,
} from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const StatisticsDontKnow = withTheme(
  ({
    theme,
    principlesByCategory,
  }: {
    theme: Theme;
    principlesByCategory: PrinciplesByCategory;
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <Style>
        <Number
          icon={<BsArrowRepeat />}
          color={theme.colors.secondary}
          text={t('Overview.StatisticsDontKnow.PercentDontKnowEfficiency')}
          formattedValue={formatAsPercent(
            principlesByCategory.ability.efficiency.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsLightbulb />}
          color={theme.colors.secondary}
          text={t('Overview.StatisticsDontKnow.PercentDontKnowInnovation')}
          formattedValue={formatAsPercent(
            principlesByCategory.ability.innovation.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsBricks />}
          color={theme.colors.secondary}
          text={t('Overview.StatisticsDontKnow.PercentDontKnowBalance')}
          formattedValue={formatAsPercent(
            principlesByCategory.ability.balance.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsDiagram3 />}
          color={theme.colors.secondary}
          text={t('Overview.StatisticsDontKnow.PercentDontKnowOrganisation')}
          formattedValue={formatAsPercent(
            principlesByCategory.heritage.organisation.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsPeople />}
          color={theme.colors.secondary}
          text={t('Overview.StatisticsDontKnow.PercentDontKnowUsers')}
          formattedValue={formatAsPercent(
            principlesByCategory.heritage.users.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsGear />}
          color={theme.colors.secondary}
          text={t('Overview.StatisticsDontKnow.PercentDontKnowTech')}
          formattedValue={formatAsPercent(
            principlesByCategory.heritage.technology.percentageDontKnow,
            '0'
          )}
        />
      </Style>
    );
  }
);

StatisticsDontKnow.displayName = 'StatisticsDontKnow';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  > div {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
