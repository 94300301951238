var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { config } from '@dimatech/shared/lib/config';
import styled from 'styled-components';
export var Image = function (_a) {
    var name = _a.name;
    return (_jsx(Style, { children: _jsx("a", __assign({ href: "".concat(config.products.www.url, "/release-notes/").concat(name), target: "_blank", rel: "noreferrer" }, { children: _jsx("img", { src: "".concat(config.products.www.url, "/release-notes/").concat(name), alt: name }) })) }));
};
Image.displayName = 'Image';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  a {\n    border-bottom: none;\n  }\n\n  img {\n    display: inline-block;\n    max-width: 350px;\n    max-height: 250px;\n    margin: 10px;\n    border: 2px solid transparent;\n\n    &:hover {\n      border: 2px solid ", ";\n    }\n  }\n"], ["\n  a {\n    border-bottom: none;\n  }\n\n  img {\n    display: inline-block;\n    max-width: 350px;\n    max-height: 250px;\n    margin: 10px;\n    border: 2px solid transparent;\n\n    &:hover {\n      border: 2px solid ", ";\n    }\n  }\n"])), function (props) { return props.theme.colors.secondary; });
var templateObject_1;
