import { featuresCoreApi } from '../featuresCoreApi';
var accountApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get organizations by type
         */
        getOrganizationsByType: build.query({
            query: function (_a) {
                var type = _a.type, _b = _a.includeArea, includeArea = _b === void 0 ? false : _b;
                return "account/organizations/".concat(type, "?includeArea=").concat(!!includeArea);
            },
            transformResponse: function (result) {
                return result.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
            },
        }),
        /**
         * Register new customer
         */
        registerAccount: build.mutation({
            query: function (register) { return ({
                url: "account/register",
                method: 'POST',
                body: register,
            }); },
        }),
        /**
         * Order product
         */
        orderProduct: build.mutation({
            query: function (order) { return ({
                url: "account/order",
                method: 'POST',
                body: order,
            }); },
        }),
        /**
         * Upgrade license
         */
        upgradeLicense: build.mutation({
            query: function () { return ({
                url: 'account/license/upgrade',
                method: 'PUT',
            }); },
        }),
    }); },
});
export var useGetOrganizationsByTypeQuery = accountApi.useGetOrganizationsByTypeQuery, useRegisterAccountMutation = accountApi.useRegisterAccountMutation, useOrderProductMutation = accountApi.useOrderProductMutation, useUpgradeLicenseMutation = accountApi.useUpgradeLicenseMutation;
