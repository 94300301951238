import { useEffect, useRef } from 'react';
/* Poll each interval if resume is called within hook */
/* Example usage:
usePolling((resume) => {
  // Code that should run each interval...
  
  // Call resume to continue polling
  resume();
}, 5000);
*/
export var usePolling = function (callback, interval, runOnInit) {
    if (runOnInit === void 0) { runOnInit = true; }
    var first = useRef(true);
    var savedCallback = useRef();
    var abort = useRef(function () {
        return;
    });
    useEffect(function () {
        savedCallback.current = callback;
    });
    useEffect(function () {
        if (!interval) {
            return function () {
                mounted = false;
            };
        }
        var timeoutHandle = null;
        var mounted = true;
        var clearCurrentTimeout = function () {
            timeoutHandle && clearTimeout(timeoutHandle);
            abort.current = function () {
                return;
            };
        };
        abort.current = clearCurrentTimeout;
        var poll = function () {
            var resume = function () {
                timeoutHandle = setTimeout(poll, interval);
            };
            if (runOnInit || !first.current) {
                mounted && (savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current) && savedCallback.current(resume);
            }
            else {
                resume();
            }
            first.current = false;
        };
        if (interval) {
            poll();
        }
        return function () {
            mounted = false;
            clearCurrentTimeout();
        };
    }, [interval]);
    return abort.current;
};
