var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonFooter, ButtonSecondary, Buttons, Label, Radio, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { SurveyResponseStatus } from '@dimatech/shared/lib/models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../api/entity/entitySlice';
import { useDeleteSurveyRespondentMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { entitySurveyRespondentActions, selectIsDeletingSelectedRespondents, selectSelectedRespondents, } from '../../../api/entity/entitySurveyRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
export var RespondentsSelectedActionsDelete = function (_a) {
    var selectedEntityId = _a.selectedEntityId, setError = _a.setError, isReadOnly = _a.isReadOnly, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var isDeletingRespondents = useSelector(selectIsDeletingSelectedRespondents);
    var selectedRespondents = useSelector(selectSelectedRespondents);
    var _b = useDeleteSurveyRespondentMutation(), deleteRespondent = _b[0], isSuccess = _b[1].isSuccess;
    var _c = useState(true), shouldDataBeKept = _c[0], setShouldDataBeKept = _c[1];
    var _d = useState(false), isDeletingAlreadyDeletedRespondents = _d[0], setIsDeletingAlreadyDeletedRespondents = _d[1];
    var _e = useState(false), isDeletingWithResponses = _e[0], setIsDeletingWithResponses = _e[1];
    var noOfSelected = selectedRespondents.length;
    var handleConfirmDelete = function (e) {
        e.stopPropagation();
        if (isReadOnly) {
            return;
        }
        if (!selectedRespondents || selectedRespondents.length === 0) {
            return;
        }
        deleteRespondent({
            entityId: selectedEntityId,
            respondents: selectedRespondents.map(function (r) { return r.email; }),
            keepData: shouldDataBeKept,
        });
        dispatch(entitySurveyRespondentActions.setIsDeletingSelectedRespondents(false));
        dispatch(entitySurveyRespondentActions.setSelectedRespondents([]));
        dispatch(entityActions.selectedEntityRespondent(null));
    };
    var handleDelete = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setIsDeletingSelectedRespondents(show));
    };
    var handleMove = function (e) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setIsMovingSelectedRespondents(true));
    };
    useEffect(function () {
        var isDeletingAlreadyDeletedRespondents = selectedRespondents.some(function (respondent) {
            return respondent.status === SurveyResponseStatus.Finished &&
                respondent.isDeleted;
        });
        var isDeletingWithResponses = selectedRespondents.some(function (respondent) {
            return respondent.status === SurveyResponseStatus.Finished &&
                !respondent.isDeleted;
        });
        setIsDeletingWithResponses(isDeletingWithResponses);
        setIsDeletingAlreadyDeletedRespondents(isDeletingAlreadyDeletedRespondents);
    }, [selectedRespondents]);
    useEffect(function () {
        if (isSuccess) {
            handleRespondentMovedOrDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    return (_jsxs(_Fragment, { children: [isDeletingRespondents && (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.DeleteSelected.ConfirmTitle'), handleKeyEnter: handleConfirmDelete, handleKeyEsc: function (e) { return handleDelete(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.DeleteSelected.ConfirmText', {
                            count: noOfSelected,
                        }) }), isDeletingAlreadyDeletedRespondents && (_jsx(AlertWarning, __assign({ style: { marginTop: 15 } }, { children: t('Administrate.Respondent.DeleteSelected.ConfirmWarningPermanent') }))), isDeletingWithResponses && (_jsxs("div", __assign({ style: { marginTop: 15 } }, { children: [_jsx("div", { children: t('Administrate.Respondent.DeleteSelected.ShouldDataBeKept.Title') }), _jsxs("div", { children: [_jsxs(Label, __assign({ htmlFor: "keepData" }, { children: [_jsx(Radio, { onChange: function (e) {
                                                    setShouldDataBeKept(e.target.checked);
                                                }, id: "keepData", name: "shouldDataBeKept", value: 1, checked: shouldDataBeKept, style: { marginRight: 7 } }), t('Administrate.Respondent.DeleteSelected.ShouldDataBeKept.KeepData')] })), _jsxs(Label, __assign({ htmlFor: "doNotKeepData" }, { children: [_jsx(Radio, { onChange: function (e) {
                                                    setShouldDataBeKept(!e.target.checked);
                                                }, id: "doNotKeepData", name: "shouldDataBeKept", value: 1, checked: !shouldDataBeKept, style: { marginRight: 7 } }), t('Administrate.Respondent.DeleteSelected.ShouldDataBeKept.DoNotKeepData')] }))] })] }))), _jsx(AlertWarning, __assign({ style: { margin: '15px 0' } }, { children: t('Administrate.Respondent.DeleteSelected.ConfirmWarning') })), _jsx("div", __assign({ className: "i size-s" }, { children: t('Administrate.Respondent.Delete.ConfirmNote') })), _jsxs(ButtonFooter, { children: [_jsx(Buttons, { children: _jsx(ButtonSecondary, __assign({ type: "button", onClick: handleMove }, { children: t('Administrate.Respondent.MoveSelected.TooltipTitle') })) }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleConfirmDelete, disabled: isReadOnly }, { children: t('Common.UI.Yes') }))] })] })] }))), _jsx(LinkWithTooltip, { style: { margin: '0 0 0 5px' }, handleClick: function (e) { return handleDelete(e, filter.instance.isCurrentInstance); }, title: t("Administrate.Respondent.DeleteSelected.Title"), tooltipTitle: t("Administrate.Respondent.DeleteSelected.TooltipTitle"), tooltip: t("Administrate.Respondent.DeleteSelected.Tooltip"), icon: _jsx(BsTrash, {}), isDisabled: noOfSelected === 0 || !filter.instance.isCurrentInstance })] }));
};
RespondentsSelectedActionsDelete.displayName =
    'RespondentsSelectedActionsDelete';
