var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToastError, ToastMessage, } from '@dimatech/shared/lib/components/toast';
import { groupBy } from '@dimatech/shared/lib/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { appActions, selectErrors, selectIsUserMessageShown, selectIsUserMessageToastShown, selectUnreadMessageCount, } from '../api/appSlice';
export var Toasts = function () {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var errors = useSelector(selectErrors);
    var unreadMessageCount = useSelector(selectUnreadMessageCount);
    var isUserMessageShown = useSelector(selectIsUserMessageShown);
    var isUserMessageToastShown = useSelector(selectIsUserMessageToastShown);
    var handleCloseError = function (error) {
        dispatch(appActions.hideErrors(error));
    };
    var handleCloseUnreadMessage = function () {
        dispatch(appActions.setIsUserMessageToastShown(false));
    };
    var handleShowUnreadMessages = function () {
        handleCloseUnreadMessage();
        dispatch(appActions.setIsUserMessageShown(true));
    };
    // Only display unique error messages, so we group them and
    // show how many times it has occurred instead
    var filteredErrors = errors.filter(function (error) { return !error.isHidden; });
    var uniqueErrors = groupBy(filteredErrors, function (error) { var _a; return (_a = error.message) !== null && _a !== void 0 ? _a : 'Error'; });
    return Object.keys(uniqueErrors).length > 0 ||
        (isUserMessageToastShown &&
            !isUserMessageShown &&
            unreadMessageCount > 0) ? (_jsxs(Style, { children: [isUserMessageToastShown &&
                !isUserMessageShown &&
                unreadMessageCount > 0 && (_jsx(ToastMessage, { title: t('Messenger.UnreadCount', {
                    count: unreadMessageCount,
                }), handleClose: handleCloseUnreadMessage, handleSelect: handleShowUnreadMessages })), Object.keys(uniqueErrors).map(function (key, index) { return (_jsx(ToastError, { error: __assign(__assign({}, uniqueErrors[key].items[0]), { count: uniqueErrors[key].items.length }), handleClose: handleCloseError }, key)); })] })) : null;
};
Toasts.displayName = 'Toasts';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  position: absolute;\n  top: 90px;\n  right: 15px;\n  z-index: 1000;\n"], ["\n  box-sizing: border-box;\n  position: absolute;\n  top: 90px;\n  right: 15px;\n  z-index: 1000;\n"])));
var templateObject_1;
