var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { config } from '../config';
import { getTheme } from '../themes';
import { appInsights } from './appInsights';
export var trackEvent = function (name, properties) {
    if (config.googleAnalytics.key && gtag) {
        gtag('event', name, __assign({}, properties));
    }
    if (!config.appInsights.logEvents) {
        console.info('AppInsights.trackEvent', { name: name }, __assign(__assign({}, getDefaultTrackingProperties()), properties));
        return;
    }
    if (config.appInsights.key) {
        appInsights.trackEvent({ name: name }, __assign(__assign({}, getDefaultTrackingProperties()), properties));
    }
};
export var trackException = function (exception, handledAt, properties) {
    var isValidationError = exception.name === 'ValidationError';
    var isLoggingEnabled = (isValidationError && config.appInsights.logValidationErrors) ||
        (!isValidationError && config.appInsights.logErrors);
    if (!isLoggingEnabled) {
        console.error('AppInsights.trackException', { exception: exception }, __assign(__assign(__assign({}, getDefaultTrackingProperties()), properties), { handledAt: handledAt }));
        return;
    }
    appInsights.trackException({ exception: exception }, __assign(__assign(__assign({}, getDefaultTrackingProperties()), properties), { handledAt: handledAt }));
};
export var trackPageView = function (name, properties) {
    if (!config.appInsights.logPageViews) {
        console.info('AppInsights.trackException', name, window.location.href, __assign(__assign({ viewportHeight: window.innerHeight, viewportWidth: window.innerWidth, screenHeight: window.screen.availHeight, screenWidth: window.screen.availWidth }, getDefaultTrackingProperties()), properties));
        return;
    }
    appInsights.trackPageView({
        name: name,
        uri: window.location.href,
        properties: __assign(__assign({ viewportHeight: window.innerHeight, viewportWidth: window.innerWidth, screenHeight: window.screen.availHeight, screenWidth: window.screen.availWidth }, getDefaultTrackingProperties()), properties),
    });
};
var getDefaultTrackingProperties = function () {
    var currentUrl = window.location.href;
    var theme = getTheme();
    var environment = config.environment.current;
    var subdomain = config.defaultSubdomain;
    return {
        currentUrl: currentUrl,
        theme: theme,
        environment: environment,
        subdomain: subdomain,
    };
};
