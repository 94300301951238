var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { ThemeType } from '@dimatech/shared/lib/themes';
import { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useAuthenticateMutation } from '../../../api/authentication/authenticationApi';
import { AuthenticationContext } from '../AuthenticationProvider';
export var Demo = withTheme(function (_a) {
    var handleLoggedIn = _a.handleLoggedIn, theme = _a.theme;
    var t = useTranslation().t;
    var _b = useContext(AuthenticationContext), accessToken = _b.accessToken, refreshToken = _b.refreshToken;
    var _c = useAuthenticateMutation(), authenticate = _c[0], _d = _c[1], data = _d.data, error = _d.error, isLoading = _d.isLoading;
    var locale = useContext(LocalizationContext).locale;
    var _e = useState(false), isReCaptchaValid = _e[0], setIsReCaptchaValid = _e[1];
    var _f = useState(''), code = _f[0], setCode = _f[1];
    var handleReCaptchaChange = function (token) {
        setIsReCaptchaValid(!!token);
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        authenticate({
            authentication: {
                login: config.demo.login.userName,
                password: code,
            },
        });
    };
    useEffect(function () {
        if (data) {
            accessToken.update(data.accessToken);
            refreshToken.update(data.refreshToken);
            handleLoggedIn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (_jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(Heading2, { children: t('Demo.Title') }), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: _jsx(Trans, __assign({ i18nKey: "Demo.Text", values: {
                        supportEmail: config.support.email,
                    } }, { children: _jsx("a", __assign({ href: "mailto: ".concat(config.support.email) }, { children: config.support.email })) })) })), _jsx(LinkContainer, { children: _jsx(Link, __assign({ to: "/register" }, { children: t('Login.Register') })) }), error && (_jsx(AlertWarning, __assign({ style: { marginTop: 20, marginBottom: 20 } }, { children: _jsx(Trans, __assign({ i18nKey: "Demo.ValidationError.InvalidCode", values: {
                        supportEmail: config.support.email,
                    } }, { children: _jsx("a", __assign({ href: "mailto: ".concat(config.support.email) }, { children: config.support.email })) })) }))), _jsx("div", __assign({ style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '20px 0',
                } }, { children: _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "code" }, { children: t('Demo.Code') })), _jsx(Input, { type: "code", id: "code", name: "code", defaultValue: code, style: { width: 200 }, onChange: function (e) { return setCode(e.currentTarget.value); } })] }) })), _jsx(ReCaptcha, { children: _jsx(ReCAPTCHA, { size: "normal", hl: locale, theme: theme.type === ThemeType.Dark ? 'dark' : 'light', sitekey: config.authentication.reCaptcha.siteKey, onChange: handleReCaptchaChange }) }), _jsx(Buttons, { children: _jsx(Button, __assign({ onClick: handleSubmit, style: { marginTop: 10 }, disabled: isLoading || !isReCaptchaValid }, { children: t('Demo.Submit') })) }), _jsxs(LinkContainer, { children: [_jsx("div", { children: t('Cookies.Notice.Text') }), _jsx(Link, __assign({ to: "/cookies" }, { children: t('Cookies.Notice.ReadMore') }))] }), _jsx(LinkContainer, { children: _jsx(Link, __assign({ to: "/login" }, { children: t('Register.AccountExistsLink') })) })] })));
});
Demo.displayName = 'Demo';
var Buttons = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  button,\n  a {\n    width: 200px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  button,\n  a {\n    width: 200px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n  }\n"])));
var ReCaptcha = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n\n  margin-top: 30px;\n  margin-bottom: 20px;\n"], ["\n  display: flex;\n  justify-content: center;\n\n  margin-top: 30px;\n  margin-bottom: 20px;\n"])));
var LinkContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 15px;\n"], ["\n  margin-top: 15px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
