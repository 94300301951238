var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isPast, isSameDay } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
export var validateReminders = function (reminders, startDate, endDate) {
    var messages = __spreadArray([], reminders, true);
    messages.forEach(function (reminder, index) {
        messages[index] = __assign(__assign({}, messages[index]), { isValid: true });
        var isOnSameDay = messages.some(function (message) {
            return message.sendDate &&
                reminder.sendDate &&
                message.uid !== reminder.uid &&
                isSameDay(new Date(message.sendDate), new Date(reminder.sendDate));
        });
        // Do not allow multiple reminders on same date
        if (isOnSameDay) {
            messages[index] = __assign(__assign({}, messages[index]), { isValid: false });
        }
        if (reminder.sendDate != null) {
            var sendDate = new Date(reminder.sendDate);
            // Do not allow reminders before or on start date and
            // do not allow reminders after or on end date
            if ((startDate != null && sendDate <= new Date(startDate)) ||
                (endDate != null && sendDate >= new Date(endDate)))
                messages[index] = __assign(__assign({}, messages[index]), { isValid: false });
            // Do not allow reminders in the past (if is dirty)
            if (reminder.isDirty && isPast(sendDate))
                messages[index] = __assign(__assign({}, messages[index]), { isValid: false });
        }
    });
    return messages;
};
export var validateStartEndDate = function (startDate, endDate) {
    if (startDate !== null && endDate !== null) {
        if (new Date(endDate) < new Date(startDate)) {
            return false;
        }
    }
    return true;
};
