var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles, CustomerType } from '@dimatech/features-core/lib/models';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsShuffle } from 'react-icons/bs';
import { useGenerateDemoDataMutation } from '../../api/account/accountApi';
import { Planning } from './components/Planning';
import { Statistics } from './components/Statistics';
export var GlobalAdministrateOverview = function () {
    var _a;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
    var isDemoCustomer = ((_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.currentCustomer.customerType) === CustomerType.Demo;
    var _b = useGenerateDemoDataMutation(), generateDemoData = _b[0], isGeneratingDemoData = _b[1].isLoading;
    var handleGenerateDemoData = function (e) {
        e.preventDefault();
        e.stopPropagation();
        generateDemoData({});
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, __assign({ style: { justifyContent: 'space-between', paddingRight: 35 } }, { children: [_jsx("div", __assign({ style: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                        } }, { children: _jsx(Heading1, { children: t('GlobalAdministrate.Overview.Title') }) })), isGlobalAdmin && isDemoCustomer && (_jsx("div", __assign({ style: { display: 'flex', justifyContent: 'end' } }, { children: _jsx(LinkWithTooltip, { handleClick: handleGenerateDemoData, title: t('GlobalAdministrate.Overview.GenerateDemoData.LinkText'), tooltipTitle: t('GlobalAdministrate.Overview.GenerateDemoData.TooltipTitle'), tooltip: t('GlobalAdministrate.Overview.GenerateDemoData.Tooltip'), icon: _jsx(BsShuffle, {}), isInverted: true, isDisabled: isGeneratingDemoData }) })))] })), _jsx(Card, __assign({ width: "100%" }, { children: _jsx(CardBody, { children: _jsx(Statistics, {}) }) })), _jsx(Card, __assign({ width: "100%" }, { children: _jsx(CardBody, { children: _jsx(Planning, {}) }) }))] }));
};
GlobalAdministrateOverview.displayName = 'GlobalAdministrateOverview';
