var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { Button, ButtonSecondary, Buttons, Checkbox, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
export var EntityUserAdd = function (_a) {
    var handleAdd = _a.handleAdd, setIsAdding = _a.setIsAdding, name = _a.name, includeChildEntities = _a.includeChildEntities, setIncludeChildEntities = _a.setIncludeChildEntities;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useState(null), user = _b[0], setUser = _b[1];
    var selectedEntity = useSelector(selectSelectedEntity);
    var isReadOnly = isAdminReadOnly(accessToken);
    var handleSave = function (e) {
        e.stopPropagation();
        if (isReadOnly || !(user === null || user === void 0 ? void 0 : user.email)) {
            return;
        }
        handleAdd(user);
        setIsAdding(false);
    };
    if (!selectedEntity) {
        return null;
    }
    return (_jsxs(Modal, __assign({ title: t("Administrate.Entity.".concat(name, ".Add.Title")), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsAdding(false); } }, { children: [_jsx("div", { children: t("Administrate.Entity.".concat(name, ".Add.Text")) }), _jsx(Label, { children: t("Administrate.Entity.".concat(name, ".Add.Email")) }), _jsx(SearchUser, { user: user, setUser: setUser }), selectedEntity.childCount !== undefined &&
                selectedEntity.childCount > 0 && (_jsx("div", { children: _jsxs(Label, __assign({ htmlFor: "includeChildEntities" }, { children: [_jsx(Checkbox, { id: "includeChildEntities", name: "includeChildEntities", style: { marginRight: 10 }, checked: includeChildEntities !== null && includeChildEntities !== void 0 ? includeChildEntities : true, onChange: function (e) {
                                return setIncludeChildEntities(e.currentTarget.checked);
                            } }), t("Administrate.Entity.".concat(name, ".Add.IncludeChildEntities"))] })) })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsAdding(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave, disabled: isReadOnly }, { children: t('Common.Form.Save') }))] })] })));
};
EntityUserAdd.displayName = 'EntityUserAdd';
