import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
// This is used to identify current user with launch darkly to enable feature toggling based on login, customer and roles
export var useLaunchDarkly = function () {
    var accessToken = useContext(AuthenticationContext).accessToken;
    var ldClient = useLDClient();
    useEffect(function () {
        var _a, _b, _c, _d;
        if (ldClient && accessToken.user) {
            var customerId = (_b = (_a = accessToken.customerId) !== null && _a !== void 0 ? _a : accessToken.user.currentCustomer.id) !== null && _b !== void 0 ? _b : '';
            var customerName = (_c = accessToken.user.currentCustomer.name) !== null && _c !== void 0 ? _c : '';
            var userContext = {
                kind: 'user',
                key: accessToken.user.id,
                roles: accessToken.roles,
                // NOTE: This is needed to make this compatible with the launch darkly rules we have in place
                customerId: customerId,
                customerName: customerName,
            };
            var multiContext = {
                kind: 'multi',
                user: userContext,
            };
            if (customerId) {
                var customerContext = {
                    kind: 'customer',
                    key: customerId,
                    name: customerName,
                    type: (_d = accessToken.user.currentCustomer.customerType) !== null && _d !== void 0 ? _d : '',
                };
                multiContext.customer = customerContext;
            }
            ldClient.identify(multiContext);
        }
    }, [ldClient, accessToken]);
};
