var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { FileIcon } from '@dimatech/shared/lib/components/FileIcon';
import { Button, ButtonSecondary, Input, Label, } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { config } from '@dimatech/shared/lib/config';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { useImportSurveyRespondentMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { RespondentsImportList } from './RespondentsImportList';
export var RespondentsImport = function (_a) {
    var isImporting = _a.isImporting, entityIdImportRespondents = _a.entityIdImportRespondents, setIsImporting = _a.setIsImporting, setEntityIdImportRespondents = _a.setEntityIdImportRespondents;
    var t = useTranslation().t;
    var selectedEntity = useSelector(selectSelectedEntity);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var _b = useImportSurveyRespondentMutation(), importSurveyRespondent = _b[0], isPosting = _b[1].isLoading;
    var _c = useState(undefined), file = _c[0], setFile = _c[1];
    var _d = useState(''), validationError = _d[0], setValidationError = _d[1];
    var _e = useState([]), dataImportRespondents = _e[0], setDataImportRespondents = _e[1];
    var handleChange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file, sizeInKb;
        var _a;
        return __generator(this, function (_b) {
            setValidationError('');
            file = (_a = event.currentTarget.files) === null || _a === void 0 ? void 0 : _a[0];
            if (file) {
                sizeInKb = file.size / 1024;
                if (sizeInKb > config.ui.fileUpload.maxSizeInKb) {
                    setValidationError(t('Administrate.Respondent.Import.FileToBig', {
                        maxSizeInMb: Math.ceil(config.ui.fileUpload.maxSizeInKb / 1024),
                    }));
                    return [2 /*return*/];
                }
            }
            setFile(file);
            return [2 /*return*/];
        });
    }); };
    var handleCancel = function () {
        setIsImporting(false);
    };
    var handleFileUpload = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var data, dataImport;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = new FormData();
                    if (isReadOnly || file === undefined) {
                        return [2 /*return*/];
                    }
                    data.append('file', file);
                    return [4 /*yield*/, importSurveyRespondent({
                            data: data,
                            entityId: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id,
                        }).unwrap()];
                case 1:
                    dataImport = _a.sent();
                    setDataImportRespondents(dataImport.result);
                    if (dataImport.id !== undefined) {
                        setEntityIdImportRespondents(dataImport.id);
                    }
                    setFile(undefined);
                    setIsImporting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    if (isPosting) {
        return _jsx(LoaderOverlay, {});
    }
    return (_jsxs(_Fragment, { children: [(selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) === entityIdImportRespondents && (_jsx(RespondentsImportList, { dataImportRespondents: dataImportRespondents, entityIdImportRespondents: entityIdImportRespondents, setEntityIdImportRespondents: setEntityIdImportRespondents })), (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) && isImporting && (_jsx(Modal, __assign({ title: t('Administrate.Respondent.Import.Title'), handleKeyEsc: function () { return setIsImporting(false); } }, { children: _jsxs(Style, { children: [_jsx("div", { children: _jsx(Trans, { i18nKey: "Administrate.Respondent.Import.Text" }) }), _jsxs("div", { children: [validationError && (_jsx(AlertWarning, __assign({ style: { marginTop: 10, width: 'fit-content', minWidth: 200 } }, { children: validationError }))), !file && (_jsxs(Upload, __assign({ htmlFor: "file" }, { children: [_jsx(BsUpload, {}), _jsx("span", { children: t('Administrate.Respondent.Import.SelectFile') })] }))), _jsx(Input, { type: "file", id: "file", onChange: function (e) { return handleChange(e); } }), file && (_jsxs("div", __assign({ style: { padding: '20px 0 10px 0' } }, { children: [_jsx(FileIcon, { fileName: file.name }), _jsx("span", __assign({ style: { paddingLeft: 5 } }, { children: file.name }))] }))), _jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ onClick: function () { return handleFileUpload(file); }, type: "button", disabled: isReadOnly || isPosting || !file }, { children: t('Administrate.Respondent.Import.Upload') }))] })] }) })))] }));
};
RespondentsImport.displayName = ' RespondentsImport';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ul {\n    margin-left: 20px;\n  }\n\n  > div:last-of-type {\n    margin: 10px 0 20px 20px;\n    padding: 0 20px 10px 0;\n\n    input[type='file'] {\n      display: none;\n    }\n\n    button {\n      margin-top: 20px;\n      min-width: 100px;\n\n      &:last-of-type {\n        margin-left: 20px;\n      }\n    }\n  }\n"], ["\n  ul {\n    margin-left: 20px;\n  }\n\n  > div:last-of-type {\n    margin: 10px 0 20px 20px;\n    padding: 0 20px 10px 0;\n\n    input[type='file'] {\n      display: none;\n    }\n\n    button {\n      margin-top: 20px;\n      min-width: 100px;\n\n      &:last-of-type {\n        margin-left: 20px;\n      }\n    }\n  }\n"])));
var Upload = styled(Label)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  width: fit-content;\n  display: flex;\n  align-items: baseline;\n  vertical-align: middle;\n\n  margin-top: 20px;\n  margin-bottom: 10px;\n\n  border-bottom: 1px solid\n    ", ";\n\n  span {\n    padding-left: 10px;\n  }\n\n  svg {\n    margin-bottom: 0;\n  }\n"], ["\n  cursor: pointer;\n  width: fit-content;\n  display: flex;\n  align-items: baseline;\n  vertical-align: middle;\n\n  margin-top: 20px;\n  margin-bottom: 10px;\n\n  border-bottom: 1px solid\n    ", ";\n\n  span {\n    padding-left: 10px;\n  }\n\n  svg {\n    margin-bottom: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
});
var templateObject_1, templateObject_2;
