import { Reports as ReportsFeature } from 'features/reports';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Reports = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Reports.Title')}>
      <ReportsFeature />
    </View>
  );
};

Reports.displayName = 'Reports';
