import { config } from '@dimatech/shared/lib/config';
import { featuresCoreApi } from '../featuresCoreApi';
var authenticationApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Authenticate
         */
        authenticate: build.mutation({
            query: function (_a) {
                var authentication = _a.authentication;
                return {
                    url: config.apiUrl.authentication.login,
                    method: 'POST',
                    body: {
                        email: authentication.login,
                        password: authentication.password,
                    },
                };
            },
        }),
        /**
         * Impersonate
         * TODO: This needs to be a separate api since base url differs (config.apiUrl.admin)
         */
        impersonate: build.mutation({
            query: function (email) {
                return {
                    url: 'user/impersonate',
                    method: 'POST',
                    body: { email: email },
                };
            },
        }),
        /**
         * Refresh auth token
         */
        refreshAuthToken: build.mutation({
            query: function (_a) {
                var refreshToken = _a.refreshToken;
                return {
                    url: config.apiUrl.authentication.refresh,
                    method: 'POST',
                    body: {
                        refreshToken: refreshToken,
                    },
                };
            },
        }),
        /**
         * Forgot password
         */
        forgotPassword: build.mutation({
            query: function (_a) {
                var email = _a.email;
                return {
                    url: config.apiUrl.authentication.resetPassword,
                    method: 'PUT',
                    body: {
                        email: email,
                    },
                };
            },
        }),
        /**
         * Reset password
         */
        resetPassword: build.mutation({
            query: function (_a) {
                var password = _a.password, passwordVerification = _a.passwordVerification, resetToken = _a.resetToken;
                return {
                    url: 'user/resetPassword',
                    method: 'POST',
                    body: {
                        password: password,
                        passwordVerification: passwordVerification,
                        resetToken: resetToken,
                    },
                };
            },
        }),
        /**
         * Confirm email
         */
        confirmEmail: build.mutation({
            query: function (token) {
                return {
                    url: 'user/beginConfirm',
                    method: 'POST',
                    body: {
                        token: token,
                    },
                };
            },
        }),
        /**
         * Change password
         */
        changePassword: build.mutation({
            query: function (_a) {
                var password = _a.password, newPassword = _a.newPassword;
                return {
                    url: 'user/changePassword',
                    method: 'POST',
                    body: {
                        password: password,
                        newPassword: newPassword,
                    },
                };
            },
        }),
    }); },
});
export var useAuthenticateMutation = authenticationApi.useAuthenticateMutation, useForgotPasswordMutation = authenticationApi.useForgotPasswordMutation, useImpersonateMutation = authenticationApi.useImpersonateMutation, useRefreshAuthTokenMutation = authenticationApi.useRefreshAuthTokenMutation, useResetPasswordMutation = authenticationApi.useResetPasswordMutation, useChangePasswordMutation = authenticationApi.useChangePasswordMutation, useConfirmEmailMutation = authenticationApi.useConfirmEmailMutation;
