var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    instance: {
        id: undefined,
        hasData: false,
        isCurrentInstance: false,
    },
    isInstancesLoaded: false,
};
export var filterReducer = (_a = createSlice({
    name: 'filter',
    initialState: initialState,
    reducers: {
        setFilterInstance: function (state, action) {
            state.instance = action.payload.instance;
        },
        setFilterIsInstancesLoaded: function (state, action) {
            state.isInstancesLoaded = action.payload;
        },
        resetFilter: function (state) {
            state.instance = initialState.instance;
        },
    },
    extraReducers: {},
}), _a.reducer), filterActions = _a.actions;
export var selectFilter = function (state) { return state.filter; };
export var selectIsInstancesLoaded = function (state) { return state.filter.isInstancesLoaded; };
