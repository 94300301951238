import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { StoriesByCategory } from 'models';
import { useTranslation } from 'react-i18next';
import {
  BsArrowBarUp,
  BsClockHistory,
  BsDiagram3,
  BsGear,
  BsLightbulb,
  BsPeople,
} from 'react-icons/bs';
import styled from 'styled-components';
import { htmlDecode } from 'utils';

export const StoriesCategorized = ({
  storiesByCategory,
}: {
  storiesByCategory: StoriesByCategory[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Style>
      <div>
        <Heading3>
          <BsClockHistory style={{ marginRight: 10 }} />
          {t('CommonTerms.Efficiency')}
        </Heading3>
        <StoriesForCategory
          storiesByCategory={storiesByCategory}
          categoryId={config.dimios.categories.digitalAbility.efficiency.id}
        />
      </div>
      <div>
        <Heading3>
          <BsLightbulb style={{ marginRight: 10 }} />
          {t('CommonTerms.Innovation')}
        </Heading3>
        <StoriesForCategory
          storiesByCategory={storiesByCategory}
          categoryId={config.dimios.categories.digitalAbility.innovation.id}
        />
      </div>
      <div>
        <Heading3>
          <BsArrowBarUp style={{ marginRight: 10 }} />
          {t('CommonTerms.Balance')}
        </Heading3>
        <StoriesForCategory
          storiesByCategory={storiesByCategory}
          categoryId={config.dimios.categories.digitalAbility.balance.id}
        />
      </div>

      <div>
        <Heading3>
          <BsDiagram3 style={{ marginRight: 10 }} />
          {t('CommonTerms.Organisation')}
        </Heading3>
        <StoriesForCategory
          storiesByCategory={storiesByCategory}
          categoryId={config.dimios.categories.digitalHeritage.organisation.id}
        />
      </div>
      <div>
        <Heading3>
          <BsPeople style={{ marginRight: 10 }} />
          {t('CommonTerms.Users')}
        </Heading3>
        <StoriesForCategory
          storiesByCategory={storiesByCategory}
          categoryId={config.dimios.categories.digitalHeritage.users.id}
        />
      </div>
      <div>
        <Heading3>
          <BsGear style={{ marginRight: 10 }} />
          {t('CommonTerms.Technology')}
        </Heading3>
        <StoriesForCategory
          storiesByCategory={storiesByCategory}
          categoryId={config.dimios.categories.digitalHeritage.technology.id}
        />
      </div>
    </Style>
  );
};

StoriesCategorized.displayName = 'StoriesCategorized';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  margin-left: -10px;
  margin-right: -10px;

  h3 {
    margin: 0 5px 5px 5px;
    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
  }

  > div {
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    margin: 0 10px 30px 10px;

    > div {
      > div {
        padding: 5px 10px;
        margin-bottom: 10px;
      }

      > div:nth-child(even) {
        background-color: ${({ theme }: { theme: Theme }) =>
          theme.colors.background};
        color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
      }
    }
  }
`;

const StoriesForCategory = ({
  storiesByCategory,
  categoryId,
}: {
  storiesByCategory: StoriesByCategory[];
  categoryId: number;
}): JSX.Element => {
  const { t } = useTranslation();

  const category = storiesByCategory.find(
    (item) => item.categoryId === categoryId.toString()
  );

  return (
    <div>
      {category?.stories && category.stories.length > 0 ? (
        category.stories.map((story, index) => (
          <div key={index}>
            <strong>{story.entityName}</strong>
            <StoriesStyle
              dangerouslySetInnerHTML={{
                __html: htmlDecode(story.value) ?? '',
              }}
            />
          </div>
        ))
      ) : (
        <i>{t('Stories.NoDataInCategory')}</i>
      )}
    </div>
  );
};

const StoriesStyle = styled.div`
  > p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
