import { trackException } from '../tracking';
// This will intercept all dispatched actions and if rejected/error, log error
export var errorTrackingMiddleware = function () { return function (next) { return function (action) {
    var _a, _b, _c, _d, _e;
    if (((_a = action.type) === null || _a === void 0 ? void 0 : _a.endsWith('/rejected')) &&
        // 'ConditionError' is RTK Querys way of saying that the query is cached
        // and will not run again. This is intended behaviour so we can ignore it
        ((_b = action.error) === null || _b === void 0 ? void 0 : _b.name) !== 'ConditionError') {
        var actionName = action.type.replace('/rejected', '');
        // NOTE: We are excluding the questions from being logged
        // NOTE: Disabled for now, we should not log *everything*. Replace this and select only properties that can be of interest
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const { questions, password, login, refreshToken, token, ...rest } =
        //   action.meta.arg ?? {};
        var properties = {
            actionName: actionName,
            requestId: action.meta.requestId,
            //arguments: rest,
        };
        var error = new Error((_c = action.error) === null || _c === void 0 ? void 0 : _c.message);
        error.name = (_d = action.error) === null || _d === void 0 ? void 0 : _d.name;
        error.stack = (_e = action.error) === null || _e === void 0 ? void 0 : _e.stack;
        trackException(error, actionName, properties);
    }
    next(action);
}; }; };
