import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { SelectEntitiesWithSurvey } from '@dimatech/features-survey/lib/components/SelectEntitiesWithSurvey';
import { SelectSurveyPeriod } from '@dimatech/features-survey/lib/components/SelectSurveyPeriod';
import { Select, Switch } from '@dimatech/shared/lib/components/form';
import { SurveyInstance } from '@dimatech/shared/lib/models';
import { selectFilter, storyActions } from 'api/stories/storySlice';
import { SelectCategory } from 'components/SelectCategory';
import { SelectCustomer } from 'components/SelectCustomer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const StoryFilters = ({
  isCustomerFilterShown = false,
  isEntityFilterShown = false,
}: {
  isCustomerFilterShown?: boolean;
  isEntityFilterShown?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const { accessToken } = useContext(AuthenticationContext);
  const [customerId] = useState(accessToken.customerId);

  const showAllPeriods = accessToken.isInRole(
    CommonRoles.GlobalAdmin || CommonRoles.Researcher
  );

  const handleChangeInstance = (instance: SurveyInstance) => {
    dispatch(storyActions.setFilter({ ...filter, instance }));
  };

  const handleChangeEntity = (entityIds: string[]) => {
    dispatch(storyActions.setFilter({ ...filter, entityIds }));
  };

  const handleChangeCustomer = (customerId: string) => {
    dispatch(storyActions.setFilter({ ...filter, customerId }));
  };

  const handleChangeCategory = (categoryId: string) => {
    dispatch(storyActions.setFilter({ ...filter, categoryId }));
  };

  const handleChangeApproved = (approved: boolean | undefined) => {
    dispatch(storyActions.setFilter({ ...filter, approved }));
  };

  const handleChangeRespondents = (checked: boolean) => {
    dispatch(
      storyActions.setFilter({
        ...filter,
        includeOnlyInvolvedRespondents: checked,
      })
    );
  };

  useEffect(() => {
    if (customerId !== accessToken.customerId) {
      dispatch(storyActions.resetFilter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  return (
    <>
      <div>
        <SelectSurveyPeriod
          instance={filter.instance}
          setInstance={handleChangeInstance}
          style={{ marginRight: 20 }}
          isOnlyPeriodsWithDataShown={!showAllPeriods}
        />

        {isCustomerFilterShown && (
          <SelectCustomer
            name={'customer'}
            style={{ width: 200, marginRight: 20 }}
            customerId={filter.customerId}
            setCustomerId={handleChangeCustomer}
            placeholder={`${t('Administrate.Stories.Filter.SelectCustomer')}`}
          />
        )}

        {isEntityFilterShown && (
          <div style={{ marginRight: 10 }}>
            <SelectEntitiesWithSurvey
              inverted={true}
              style={{ marginRight: 10 }}
              entityIds={filter.entityIds}
              setEntityIds={handleChangeEntity}
              surveyInstanceId={filter.instance?.id}
              placeholder={`${
                !filter.entityIds || filter.entityIds.length === 0
                  ? t('Administrate.Stories.Filter.SelectEntities')
                  : t('Administrate.Stories.Filter.SelectedEntities')
              }`}
            />
          </div>
        )}

        <SelectCategory
          name={'category'}
          style={{ width: 200, marginRight: 20 }}
          categoryId={filter.categoryId}
          setCategoryId={handleChangeCategory}
          placeholder={`${t('Administrate.Stories.Filter.SelectCategory')}`}
        />
      </div>

      <div>
        <Select
          style={{ width: 200, minWidth: 100, marginRight: 20 }}
          name="approved"
          id="approved"
          value={
            filter.approved === true
              ? '1'
              : filter.approved === false
              ? '0'
              : ''
          }
          onChange={(e) =>
            handleChangeApproved(
              e.target.value === '1'
                ? true
                : e.target.value === '0'
                ? false
                : undefined
            )
          }
        >
          <option value="">
            {t('Administrate.Stories.Filter.SelectStatus')}
          </option>
          <option value="1">{t('Administrate.Stories.Status.Approved')}</option>
          <option value="0">
            {t('Administrate.Stories.Status.NotApproved')}
          </option>
        </Select>
      </div>

      <div>
        <Switch
          label={t('Filter.IncludeOnlyInvolvedRespondents.Label')}
          tooltipTitle={t('Filter.IncludeOnlyInvolvedRespondents.TooltipTitle')}
          tooltipText={
            <Trans i18nKey="Filter.IncludeOnlyInvolvedRespondents.Tooltip" />
          }
          setIsChecked={handleChangeRespondents}
          isChecked={filter.includeOnlyInvolvedRespondents}
        />
      </div>
    </>
  );
};

StoryFilters.displayName = 'StoryFilters';
