var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody, ViewHeader, } from '@dimatech/shared/lib/components/workspace';
import { shorten } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetRecipientsQuery, useGetUserMessagesQuery, } from '../../../api/message/messageApi';
import { messageActions, selectIsPosting, selectRecipientsToAdd, selectRecipientsToDelete, selectSelectedMessage, } from '../../../api/message/messageSlice';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { MessageProperties } from './MessageProperties';
import { Recipients } from './Recipients';
import { RecipientsList } from './RecipientsList';
export var MessageEdit = function (_a) {
    var _b;
    var recipients = _a.recipients, setRecipients = _a.setRecipients;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var _c = useParams().userMessageId, userMessageId = _c === void 0 ? '' : _c;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var selectedMessage = useSelector(selectSelectedMessage);
    var userMessage = useSelector(selectSelectedMessage);
    var isPosting = useSelector(selectIsPosting);
    var _d = useGetUserMessagesQuery((_b = accessToken.customerId) !== null && _b !== void 0 ? _b : skipToken), messages = _d.data, isLoading = _d.isLoading, isFetching = _d.isFetching;
    var _e = useGetRecipientsQuery((userMessage === null || userMessage === void 0 ? void 0 : userMessage.id) ? { userMessageId: userMessage.id } : skipToken), data = _e.data, isLoadingRecipients = _e.isLoading, isFetchingRecipients = _e.isFetching;
    var recipientsToDelete = useSelector(selectRecipientsToDelete);
    var recipientsToAdd = useSelector(selectRecipientsToAdd);
    var handleDeleteRecipients = function (newRecipientsToDelete) {
        dispatch(messageActions.setHasChanges(true));
        dispatch(messageActions.setRecipientsToDelete(recipientsToDelete.concat(newRecipientsToDelete)));
        dispatch(messageActions.setRecipientsToAdd(recipientsToAdd.filter(function (r) { return !newRecipientsToDelete.some(function (n) { return n.userId === r.userId; }); })));
    };
    var deleteRecipients = function (users) {
        var allRecipients = recipients.filter(function (recipient) { return !users.some(function (r) { return r.userId === recipient.userId; }); });
        setRecipients(allRecipients);
        handleDeleteRecipients(users);
    };
    useEffect(function () {
        setRecipients(data !== null && data !== void 0 ? data : []);
    }, [data, setRecipients]);
    var handleBack = function () {
        navigate("/administrate/messages");
    };
    useEffect(function () {
        if (messages && userMessageId && !location.pathname.endsWith('new')) {
            var selected = messages.find(function (m) { return m.id === userMessageId; });
            dispatch(messageActions.selectMessage(selected));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);
    useEffect(function () {
        if (location.pathname.endsWith('new')) {
            dispatch(messageActions.selectMessage({}));
            setRecipients([]);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    if (isFetching) {
        return (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: _jsx(Loader, { children: t('Common.UI.Loading') }) })));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, { children: [_jsx("div", { children: _jsx(BackButton, { handleBack: handleBack }) }), _jsx(Heading1, { children: (selectedMessage === null || selectedMessage === void 0 ? void 0 : selectedMessage.title) ? shorten(selectedMessage.title, 50) : '' })] }), _jsx(Card, { children: _jsx(CardBody, __assign({ isLoading: isLoading || isFetching }, { children: _jsxs("div", __assign({ style: {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '30px',
                        } }, { children: [_jsx(MessageProperties, {}), _jsxs("div", __assign({ style: { flexBasis: '40%', flexGrow: 1 } }, { children: [isPosting && _jsx(Loader, { children: t('Common.UI.Loading') }), !isPosting && userMessage && (_jsxs(_Fragment, { children: [_jsx(Recipients, { recipients: recipients, isLoading: isLoadingRecipients || isFetchingRecipients }), _jsx(RecipientsList, { recipients: recipients, setRecipients: setRecipients, handleDeleteRecipients: deleteRecipients })] }))] }))] })) })) })] }));
};
MessageEdit.displayName = 'MessageEdit';
