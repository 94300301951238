var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { AnonymousRespondentsActionDelete } from './AnonymousRespondentsActionDelete';
import { AnonymousRespondentsActionMove } from './AnonymousRespondentsActionMove';
export var AnonymousRespondentsActions = function (_a) {
    var entity = _a.entity, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    return (_jsxs(Style, { children: [_jsx(AnonymousRespondentsActionMove, { entity: entity, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted }), _jsx(AnonymousRespondentsActionDelete, { entity: entity, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted })] }));
};
AnonymousRespondentsActions.displayName = 'AnonymousRespondentsActions';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 30px;\n\n  padding: 10px;\n\n  background-color: ", ";\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 30px;\n\n  padding: 10px;\n\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
var templateObject_1;
