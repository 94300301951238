import { InternalComparison as InternalComparisonFeature } from 'features/internal-comparison/InternalComparison';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const InternalComparison = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('InternalComparison.Title')}>
      <InternalComparisonFeature />
    </View>
  );
};

InternalComparison.displayName = 'InternalComparison';
