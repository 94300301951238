import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetEntitiesWithSurveyQuery } from '@dimatech/features-survey/lib/api/entity/entityApi';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilterByEntity } from 'api/dimiosSlice';
import { useGetEntityReportsQuery } from 'api/report/reportEntityApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import { CommonRoles } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoricalReportList } from './components/HistoricalReportList';
import { ReportList } from './components/ReportList';

export const Reports = (): JSX.Element | null => {
  const { t } = useTranslation();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const { accessToken } = useContext(AuthenticationContext);
  const [selectedEntityName, setSelectedEntityName] = useState<string>();

  const isReader = accessToken.isInRole(CommonRoles.Reader);

  const { data: entities } = useGetEntitiesWithSurveyQuery(
    accessToken.customerId &&
      accessToken.user?.id &&
      filterByEntity.instance?.id
      ? {
          _customerId: accessToken.customerId,
          _userId: accessToken.user?.id,
          surveyInstanceId: filterByEntity.instance.id,
        }
      : skipToken
  );

  useEffect(() => {
    if (entities && filterByEntity && filterByEntity.entityId !== '') {
      const entity = entities.find(
        (entity) => entity.id === filterByEntity.entityId
      );

      setSelectedEntityName(entity?.name);
    } else {
      setSelectedEntityName(undefined);
    }
  }, [filterByEntity, entities]);

  const { data: reports } = useGetEntityReportsQuery(
    filterByEntity.entityId && accessToken.customerId && !isReader
      ? {
          entityId: filterByEntity.entityId,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Reports.Title')}</Heading1>
        <SelectExtendedFilters
          isPooFilterShown={false}
          isRespondentFilterShown={false}
          isCarelessFilterShown={false}
          isOnlyPeriodsFrom2022Shown={true}
        />
      </ViewHeader>

      <>
        <Card width="100%">
          <CardBody>
            <ReportList entityName={selectedEntityName} />
          </CardBody>
        </Card>

        {!isReader && (
          <Card width="100%">
            <CardBody>
              <HistoricalReportList
                reports={reports}
                entityName={selectedEntityName}
              />
            </CardBody>
          </Card>
        )}
      </>
    </>
  );
};

Reports.displayName = 'Reports';
