var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { useDeleteMessageMutation } from '../../../api/message/messageApi';
export var MessageListEditButtons = function (_a) {
    var message = _a.message;
    var t = useTranslation().t;
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var deleteMessage = useDeleteMessageMutation()[0];
    var handleDelete = function (e) {
        e.stopPropagation();
        if (message) {
            deleteMessage({ userMessageId: message.id });
        }
        setIsDeleting(false);
    };
    var handleConfirmDelete = function (e, show) {
        e.stopPropagation();
        setIsDeleting(show);
    };
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Messages.Delete.ConfirmTitle') }, { children: [_jsx("div", { children: t('Administrate.Messages.Delete.ConfirmText') }), _jsx(AlertWarning, __assign({ style: { margin: '10px 0' } }, { children: t('Administrate.Messages.Delete.ConfirmWarning') })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleDelete(e); } }, { children: t('Common.UI.Yes') }))] })] }))), _jsxs("div", __assign({ style: { display: 'flex', justifyContent: 'end' } }, { children: [_jsx(LinkWithTooltip, { tooltipTitle: t('Administrate.Messages.Edit.TooltipTitle'), tooltip: t('Administrate.Messages.Edit.Tooltip'), icon: _jsx(BsPencil, {}), handleClick: function () {
                            //
                        } }), _jsx(LinkWithTooltip, { tooltipTitle: t('Administrate.Messages.Delete.TooltipTitle'), tooltip: t('Administrate.Messages.Delete.Tooltip'), icon: _jsx(BsTrash, {}), style: { marginLeft: 10 }, handleClick: function (e) { return handleConfirmDelete(e, true); } })] }))] }));
};
MessageListEditButtons.displayName = 'MessageListEditButtons';
