var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonSecondary, Buttons, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowsMove } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../api/entity/entitySlice';
import { useMoveSurveyRespondentMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { entitySurveyRespondentActions, selectIsMovingSelectedRespondents, selectSelectedRespondents, } from '../../../api/entity/entitySurveyRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
import { SelectEntityWithSurvey } from '../../../components/SelectEntityWithSurvey';
export var RespondentsSelectedActionsMove = function (_a) {
    var selectedEntityId = _a.selectedEntityId, setError = _a.setError, isReadOnly = _a.isReadOnly, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var isMovingRespondents = useSelector(selectIsMovingSelectedRespondents);
    var selectedRespondents = useSelector(selectSelectedRespondents);
    var _b = useMoveSurveyRespondentMutation(), moveRespondent = _b[0], isSuccess = _b[1].isSuccess;
    var _c = useState(''), entityId = _c[0], setEntityId = _c[1];
    var noOfSelected = selectedRespondents.length;
    var handleConfirmMove = function (e) {
        e.stopPropagation();
        if (isReadOnly || !entityId) {
            return;
        }
        if (!selectedRespondents || selectedRespondents.length === 0) {
            return;
        }
        moveRespondent({
            entityId: selectedEntityId,
            toEntityId: entityId,
            respondents: selectedRespondents.map(function (r) { return r.email; }),
        });
        dispatch(entitySurveyRespondentActions.setIsMovingSelectedRespondents(false));
        dispatch(entitySurveyRespondentActions.setSelectedRespondents([]));
        dispatch(entityActions.selectedEntityRespondent(null));
    };
    var handleMove = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setIsMovingSelectedRespondents(show));
    };
    useEffect(function () {
        if (isSuccess) {
            handleRespondentMovedOrDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    return (_jsxs(_Fragment, { children: [isMovingRespondents && (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.MoveSelected.Title'), handleKeyEnter: handleConfirmMove, handleKeyEsc: function (e) { return handleMove(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.MoveSelected.Text') }), _jsx(Label, { children: t('Administrate.Respondent.MoveSelected.To', {
                            count: noOfSelected,
                        }) }), _jsx(SelectEntityWithSurvey, { autoFocus: true, entityId: entityId, setEntityId: setEntityId, surveyInstanceId: filter.instance.id, placeholder: t('Administrate.Respondent.MoveSelected.SelectEntity') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleMove(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleConfirmMove, disabled: isReadOnly }, { children: t('Administrate.Respondent.Button.Move') }))] })] }))), _jsx(LinkWithTooltip, { style: { margin: '0 5px' }, handleClick: function (e) { return handleMove(e, filter.instance.isCurrentInstance); }, title: t("Administrate.Respondent.MoveSelected.Title"), tooltipTitle: t("Administrate.Respondent.MoveSelected.TooltipTitle"), tooltip: t("Administrate.Respondent.MoveSelected.Tooltip"), icon: _jsx(BsArrowsMove, {}), isDisabled: noOfSelected === 0 || !filter.instance.isCurrentInstance })] }));
};
RespondentsSelectedActionsMove.displayName = 'RespondentsSelectedActionsMove';
