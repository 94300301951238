var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
export var Tabs = function (_a) {
    var children = _a.children;
    return (_jsx(Style, { children: _jsx("div", { children: children }) }));
};
Tabs.displayName = 'Tabs';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-left: 5px;\n  margin-right: 10px;\n\n  > div {\n    display: flex;\n    flex-wrap: wrap;\n    align-content: stretch;\n  }\n"], ["\n  display: flex;\n  margin-left: 5px;\n  margin-right: 10px;\n\n  > div {\n    display: flex;\n    flex-wrap: wrap;\n    align-content: stretch;\n  }\n"])));
var templateObject_1;
