var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '@dimatech/shared/lib/components/form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectReleaseNotesVersions } from '../../api/appSlice';
import { ReleaseNotes as ReleaseNotesList } from './ReleaseNotes';
export var ReleaseNotes = function (_a) {
    var style = _a.style;
    var _b = useParams().version, version = _b === void 0 ? '' : _b;
    var navigate = useNavigate();
    var _c = useState(version), selectedVersion = _c[0], setSelectedVersion = _c[1];
    var versions = useSelector(selectReleaseNotesVersions);
    useEffect(function () {
        if (selectedVersion) {
            navigate("/release-notes/".concat(selectedVersion));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion]);
    useEffect(function () {
        if (version === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(function () {
                var element = document.getElementById(version);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [version]);
    return (_jsxs("div", { children: [_jsx(Select, __assign({ name: "version", value: selectedVersion !== null && selectedVersion !== void 0 ? selectedVersion : '', onChange: function (e) { return setSelectedVersion(e.currentTarget.value); }, style: { maxWidth: 200, marginBottom: 20 } }, { children: __spreadArray([], versions, true).map(function (version) { return (_jsx("option", __assign({ value: version }, { children: version }), version)); }) })), _jsx(ReleaseNotesList, {})] }));
};
ReleaseNotes.displayName = 'ReleaseNotes';
