import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const ViewHeader = styled.div`
  width: 100%;
  margin-left: 10px;
  margin-bottom: 10px;
  max-width: ${({ theme }: { theme: Theme }) => theme.size.viewMaxWidth};

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  h1 {
    margin-right: 10px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    select,
    label {
      margin-right: 10px;
    }

    select {
      max-width: 150px;

      color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
      background-color: ${({ theme }: { theme: Theme }) =>
        theme.colors.background};

      &:hover {
        border: 1px solid
          ${({ theme }: { theme: Theme }) => theme.colors.border};
        border-bottom: 1px solid
          ${({ theme }: { theme: Theme }) => theme.colors.secondary};
      }
    }

    > select:first-of-type {
      max-width: 80px;
    }
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    > div:first-of-type,
    h1 {
      margin-bottom: 10px;
    }
  }
`;

ViewHeader.displayName = 'ViewHeader';
