var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { config } from '@dimatech/shared/lib/config';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
export var RespondentCopyLinkButton = function (_a) {
    var _b;
    var respondent = _a.respondent, isReadOnly = _a.isReadOnly;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-link-copy", title: t('Administrate.Respondent.CopyLink.TooltipTitle'), text: t('Administrate.Respondent.CopyLink.Tooltip') }), _jsx("div", __assign({ style: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                } }, { children: _jsx(CopyToClipboard, { value: "".concat(config.products.survey.url, "/").concat(isReadOnly
                        ? "preview/".concat((_b = config.survey) === null || _b === void 0 ? void 0 : _b.id, "/").concat(accessToken.customerId)
                        : "go/".concat(respondent.token)), text: t('Administrate.Respondent.CopyLink.Text'), tooltipId: "tt-link-copy" }) }))] }));
};
RespondentCopyLinkButton.displayName = 'RespondentCopyLinkButton';
