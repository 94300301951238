var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ComboBoxList, ComboBoxListItem, Input, } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { SortDirection } from '@dimatech/shared/lib/models';
import { isValidEmail } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCombobox } from 'downshift';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useSearchQuery } from '../api/user/userApi';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
import { CommonRoles } from '../models';
export var SearchUser = function (_a) {
    var user = _a.user, setUser = _a.setUser, admin = _a.admin;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useState(true), isValid = _b[0], setIsValid = _b[1];
    var _c = useState(''), search = _c[0], setSearch = _c[1];
    var debouncedSearch = useDebounce(search, 300)[0];
    var _d = useSearchQuery(accessToken.customerId && debouncedSearch
        ? {
            search: {
                paginator: {
                    page: 1,
                    pageSize: 50,
                    orderBy: 'email',
                    orderDirection: SortDirection.Asc,
                },
                email: debouncedSearch,
                excludeRoles: [CommonRoles.Researcher],
            },
            _customerId: accessToken.customerId,
        }
        : admin
            ? {
                search: {
                    paginator: {
                        page: 1,
                        pageSize: 50,
                        orderBy: 'email',
                        orderDirection: SortDirection.Asc,
                    },
                    email: debouncedSearch,
                },
                _customerId: '',
            }
            : skipToken), users = _d.data, isFetching = _d.isFetching;
    var _e = useCombobox({
        items: users !== null && users !== void 0 ? users : [],
        itemToString: function (user) { var _a; return (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : ''; },
        onInputValueChange: function (changes) {
            var value = (changes.inputValue || '').trim();
            var valid = isValidEmail(value);
            var user = changes.selectedItem;
            setIsValid(valid);
            setSearch(value);
            setUser(valid && admin && user ? user : valid ? { email: value } : null);
        },
        onSelectedItemChange: function (changes) {
            var _a;
            setUser((_a = changes.selectedItem) !== null && _a !== void 0 ? _a : null);
        },
    }), isOpen = _e.isOpen, getMenuProps = _e.getMenuProps, getInputProps = _e.getInputProps, getComboboxProps = _e.getComboboxProps, highlightedIndex = _e.highlightedIndex, getItemProps = _e.getItemProps;
    useEffect(function () {
        if (search.length > 0 && user !== null) {
            setIsValid(true);
        }
        if (user === null) {
            setIsValid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, search]);
    return (_jsxs("div", { children: [_jsx("div", __assign({}, getComboboxProps(), { children: _jsx(Input, __assign({ autoFocus: true, invalid: !isValid }, getInputProps(), { placeholder: t('Search.EmailPlaceholder') })) })), _jsxs(ComboBoxList, __assign({}, getMenuProps(), { children: [isOpen && isFetching && (_jsx(ComboBoxListItem, { children: _jsx(LoaderSmall, {}) })), isOpen &&
                        (users === null || users === void 0 ? void 0 : users.map(function (user, index) { return (_jsx(ComboBoxListItem, __assign({ "$highlighted": highlightedIndex === index }, getItemProps({ item: user, index: index }), { children: user.email }), "".concat(user.id).concat(index))); }))] }))] }));
};
SearchUser.displayName = 'SearchUser';
