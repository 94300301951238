import {
  features_core_en,
  features_core_sv,
} from '@dimatech/features-core/lib/localization/';
import {
  features_survey_en,
  features_survey_sv,
} from '@dimatech/features-survey/lib/localization/';
import { defaultOptions } from '@dimatech/shared/lib/localization';
import shared_en from '@dimatech/shared/lib/localization/lang/en.json';
import survey_en from '@dimatech/shared/lib/localization/lang/en_surveys.json';
import shared_sv from '@dimatech/shared/lib/localization/lang/sv.json';
import survey_sv from '@dimatech/shared/lib/localization/lang/sv_surveys.json';
import { Language, Translations } from '@dimatech/shared/lib/models';
import { enGB as en, sv } from 'date-fns/locale';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { mergeJson } from 'utils';
import dimios_features_promo_en from './lang/features-promo/en.json';
import dimios_features_promo_sv from './lang/features-promo/sv.json';
import dimios_maturity_staircase_en from './lang/maturity-staircase/en.json';
import dimios_maturity_staircase_sv from './lang/maturity-staircase/sv.json';

import dimios_en from './lang/en.json';
import dimios_sv from './lang/sv.json';

registerLocale(Language.English, en);
registerLocale(Language.Swedish, sv);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(defaultOptions);

// Merge all shared language files with the language files in this app
i18n.addResourceBundle(
  Language.Swedish,
  Translations.Common,
  mergeJson(features_core_sv, [
    features_survey_sv,
    survey_sv,
    shared_sv,
    dimios_maturity_staircase_sv,
    dimios_features_promo_sv,
    dimios_sv,
  ])
);

i18n.addResourceBundle(
  Language.English,
  Translations.Common,
  mergeJson(features_core_en, [
    features_survey_en,
    survey_en,
    shared_en,
    dimios_maturity_staircase_en,
    dimios_features_promo_en,
    dimios_en,
  ])
);

export default i18n;
