import { Register as RegisterComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { UserAgreementDimios } from '@dimatech/shared/lib/components/user-agreement';
import { config } from 'config';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const Register = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <ViewCentered
      title={t('Register.Title')}
      style={{ width: '80%', maxWidth: 650 }}
    >
      <RegisterComponent
        productId={config.products.dimios.id as string}
        userAgreement={<UserAgreementDimios />}
      />
    </ViewCentered>
  );
};

Register.displayName = 'Register';
