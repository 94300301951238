import { ScheduleStatus, SurveyStatus } from '@dimatech/shared/lib/models';
import { isFuture, isPast } from 'date-fns';
export var datesToStatus = function (startDate, endDate) {
    if (!startDate || !endDate) {
        return null;
    }
    var start = new Date(startDate);
    var end = new Date(endDate);
    return isPast(end)
        ? SurveyStatus.Finished
        : isPast(start) && isFuture(end)
            ? SurveyStatus.Started
            : null;
};
export var datesToScheduleStatus = function (startDate, endDate) {
    if (!startDate && !endDate) {
        return null;
    }
    if (!startDate || !endDate) {
        return ScheduleStatus.NotStarted;
    }
    var now = new Date();
    var start = new Date(startDate);
    var end = new Date(endDate);
    if (now > start && now < end) {
        return ScheduleStatus.Started;
    }
    if (now > end) {
        return ScheduleStatus.Closed;
    }
    return ScheduleStatus.NotStarted;
};
