var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { ApplicationContext } from '@dimatech/shared/lib//application';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGetEntitiesWithSurveyQuery } from '../../api/entity/entityApi';
import { selectSelectedEntity } from '../../api/entity/entitySlice';
import { selectFilter } from '../../api/filter/filterSlice';
import { getLinks } from '../../api/survey/surveyLinksSlice';
import { SelectExtendedFilters } from '../../components/SelectExtendedFilters';
import { EntitiesTree } from '../administrate-organisation/components/EntitiesTree';
import { Respondents } from './components/Respondents';
import { RespondentsFailedMessagesBanner } from './components/RespondentsFailedMessagesBanner';
export var AdministrateRespondents = function (_a) {
    var _b, _c;
    var enableAllowEntitySelectionForAnonymousUsers = _a.enableAllowEntitySelectionForAnonymousUsers, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var selectedEntity = useSelector(selectSelectedEntity);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var application = useContext(ApplicationContext);
    var isReadOnly = isAdminReadOnly(accessToken);
    var filter = useSelector(selectFilter);
    var _d = useState(), root = _d[0], setRoot = _d[1];
    var entities = useGetEntitiesWithSurveyQuery(accessToken.customerId && ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) && filter.instance.id
        ? {
            _customerId: accessToken.customerId,
            _userId: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    var handleLinksDownload = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!accessToken.user || isReadOnly || !filter.instance.isCurrentInstance) {
            return;
        }
        dispatch(getLinks({
            fileName: t('Administrate.Respondent.ExportLinks.FileName', {
                productName: application.title,
            }),
        }));
    };
    useEffect(function () {
        var _a;
        if (entities && entities.length > 0) {
            var rootEntity = (_a = entities.find(function (entity) { return !entity.parentId; })) !== null && _a !== void 0 ? _a : entities[0];
            setRoot(rootEntity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities, selectedEntity]);
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, __assign({ style: { justifyContent: 'space-between', paddingRight: 15 } }, { children: [_jsxs("div", __assign({ style: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                        } }, { children: [_jsx(Heading1, { children: t('Administrate.Respondent.Title') }), _jsx(SelectExtendedFilters, {})] })), _jsx(LinkWithTooltip, { handleClick: handleLinksDownload, title: t('Administrate.Respondent.ExportLinks.LinkText'), tooltipTitle: t('Administrate.Respondent.ExportLinks.TooltipTitle'), tooltip: t('Administrate.Respondent.ExportLinks.Tooltip'), icon: _jsx(BsFileExcel, {}), isInverted: true, isDisabled: isReadOnly || !filter.instance.isCurrentInstance })] })), _jsx(CustomerAccountInfo, {}), _jsx(Card, __assign({ width: "100%", innerStyle: { display: 'table', width: '100%' } }, { children: _jsxs(CardBody, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Respondent.Text') })), !filter.instance.isCurrentInstance && (_jsx(AlertWarning, __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.ReadOnly') }))), _jsx(RespondentsFailedMessagesBanner, {}), (entities === null || entities === void 0 ? void 0 : entities.length) === 0 && !selectedEntity ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Entity.NoEntities') })), _jsx(ButtonLink, __assign({ type: "button", onClick: function () {
                                        navigate('/administrate/organisation');
                                    } }, { children: t('Administrate.Entity.NoEntitiesAddFirst') }))] })) : (_jsxs(Style, { children: [_jsx(EntitiesTree, { isDraggable: false }), selectedEntity && (_jsx("div", __assign({ style: { flexGrow: 1 } }, { children: _jsx(Respondents, { enableAllowEntitySelectionForAnonymousUsers: enableAllowEntitySelectionForAnonymousUsers, root: root, handleRespondentMovedOrDeleted: handleRespondentMovedOrDeleted }) })))] }))] }) }))] }));
};
AdministrateRespondents.displayName = 'AdministrateRespondents';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    padding: 0 30px 30px 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    padding: 0 30px 30px 0;\n  }\n"])));
var templateObject_1;
