var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertError, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonFooterWithToast, ButtonSecondary, Buttons, Select, } from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Locale } from '@dimatech/shared/lib/models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateLocaleMutation } from '../../../api/customer/customerApi';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
export var LocaleInfo = function () {
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _a = useState(false), isChangesSaved = _a[0], setIsChangesSaved = _a[1];
    var _b = useState(false), isChanged = _b[0], setIsChanged = _b[1];
    var _c = useState(false), hasChanges = _c[0], setHasChanges = _c[1];
    var _d = useState(accessToken.locale.locale), locale = _d[0], setLocale = _d[1];
    var _e = useUpdateLocaleMutation(), updateLocale = _e[0], error = _e[1].error;
    useEffect(function () {
        setLocale(accessToken.locale.locale);
    }, [accessToken.locale.locale]);
    var handleSubmit = function (e) {
        e.preventDefault();
        updateLocale(locale)
            .unwrap()
            .then(function () {
            setIsChangesSaved(true);
            setIsChanged(true);
            setHasChanges(false);
        });
    };
    var handleCancel = function () {
        setLocale(accessToken.locale.locale);
        setIsChanged(false);
        setHasChanges(false);
    };
    return (_jsx(_Fragment, { children: locale && (_jsxs(_Fragment, { children: [_jsx(Heading3, { children: t('Localisation.Locale.Title') }), _jsx("div", { children: t('Administrate.Account.LocaleInfo.Text') }), error && (_jsx(AlertError, __assign({ style: { marginTop: 10 } }, { children: error.message }))), isChanged && (_jsx(AlertWarning, __assign({ style: { marginTop: 10 } }, { children: t('Administrate.Account.LocaleInfo.IsChangedInfo') }))), _jsx(Select, __assign({ name: "locale", value: locale, style: { width: 'fit-content', minWidth: 200, marginTop: 20 }, onChange: function (e) {
                        setLocale(e.currentTarget.value);
                        setHasChanges(true);
                    } }, { children: __spreadArray([], Object.keys(Locale), true).sort(function (a, b) { return (a > b ? 1 : -1); })
                        .map(function (key) { return (_jsx("option", __assign({ value: Locale[key] }, { children: t("Localisation.Locale.Locales.".concat(key, ".Name")) }), key)); }) })), _jsx("div", __assign({ className: "i size-s" }, { children: t("Localisation.Locale.Locales.".concat(locale.replace('-', '_'), ".Description")) })), _jsx(ButtonFooterWithToast, __assign({ isSaved: isChangesSaved, setIsSaved: setIsChangesSaved }, { children: _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel, disabled: !hasChanges }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSubmit, disabled: !hasChanges }, { children: t('Common.Form.Save') }))] }) }))] })) }));
};
LocaleInfo.displayName = 'LocaleInfo';
