var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import styled from 'styled-components';
import { Breakpoints } from '../themes';
import { Input } from './form';
export var SearchBox = function (_a) {
    var style = _a.style, name = _a.name, setName = _a.setName, placeholder = _a.placeholder;
    var t = useTranslation().t;
    var handleSearch = function () {
        //
    };
    var handleKeyDown = function (e) {
        if ((e === null || e === void 0 ? void 0 : e.key) === 'Enter') {
            handleSearch();
        }
    };
    return (_jsxs(Style, __assign({ style: style }, { children: [_jsx(Input, { value: name, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : t('Search.Placeholder'), onChange: function (e) { return setName(e.currentTarget.value); }, onKeyDown: handleKeyDown }), _jsx(BsSearch, { onClick: handleSearch })] })));
};
SearchBox.displayName = 'SearchBox';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 300px;\n  display: flex;\n  align-items: center;\n  margin-right: 40px;\n\n  > input {\n    max-width: 300px;\n    padding-right: 20px;\n  }\n\n  > svg {\n    margin-left: -20px;\n    cursor: pointer;\n  }\n\n  @media screen and (max-width: ", ") {\n    order: 2;\n    margin-right: 0;\n    width: 100%;\n\n    > input {\n      width: 100%;\n      max-width: unset;\n    }\n  }\n"], ["\n  min-width: 300px;\n  display: flex;\n  align-items: center;\n  margin-right: 40px;\n\n  > input {\n    max-width: 300px;\n    padding-right: 20px;\n  }\n\n  > svg {\n    margin-left: -20px;\n    cursor: pointer;\n  }\n\n  @media screen and (max-width: ", ") {\n    order: 2;\n    margin-right: 0;\n    width: 100%;\n\n    > input {\n      width: 100%;\n      max-width: unset;\n    }\n  }\n"])), Breakpoints.small);
var templateObject_1;
