import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetSurveyInstances } from '@dimatech/features-survey/lib/hooks/useGetSurveyInstances';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilterByEntity } from 'api/dimiosSlice';
import { useGetStoriesQuery } from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoriesCategorized } from './components/StoriesCategorized';

export const Stories = (): JSX.Element | null => {
  const { t } = useTranslation();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const { accessToken } = useContext(AuthenticationContext);
  const customerId = accessToken.customerId;

  const { instances, isSuccess: isSuccessInstances } =
    useGetSurveyInstances(true);

  const {
    data: storiesByCategory,
    isFetching,
    isLoading,
  } = useGetStoriesQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const hasData =
    storiesByCategory &&
    storiesByCategory.some((category) => category.stories.length > 0);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Stories.Title')}</Heading1>
        <SelectExtendedFilters />
      </ViewHeader>

      <Card width="100%">
        <CardBody>{t('Stories.Text')}</CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {storiesByCategory && !hasData && (
            <Card width="100%">
              <AlertInfo>{t('Stories.NoData')}</AlertInfo>
            </Card>
          )}

          {(!instances || instances.length === 0) && isSuccessInstances && (
            <Card width="100%">
              <AlertWarning>{t('Stories.NoInstances')}</AlertWarning>
            </Card>
          )}

          {storiesByCategory && hasData && (
            <Card width="100%">
              <CardBody isLoading={isFetching} width="100%">
                <StoriesCategorized storiesByCategory={storiesByCategory} />
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  );
};

Stories.displayName = 'Stories';
