var _a;
import Cookies from 'universal-cookie';
import { config } from '../config';
var options = {
    path: '/',
    domain: ".".concat((_a = config.defaultDomainCookies) !== null && _a !== void 0 ? _a : config.defaultDomain),
    sameSite: 'strict',
};
var cookie = new Cookies(null, options);
export var storeCookie = function (name, value) {
    cookie.set(name, value, options);
};
export var getCookie = function (name) {
    return cookie.get(name);
};
export var removeCookie = function (name) {
    cookie.remove(name, options);
};
