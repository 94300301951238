var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loader } from '@dimatech/shared/lib/components/loader';
import { nextBankDayAtHours } from '@dimatech/shared/lib/utils';
import { addHours } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDeleteSurveyMessageMutation } from '../../../api/entity/entitySurveyMessageApi';
import { validateReminders } from '../../administrate-schedule/validation';
import { ScheduleDate } from './ScheduleDate';
import { ScheduleReminderAdd } from './ScheduleReminderAdd';
export var ScheduleReminders = function (_a) {
    var _b, _c;
    var schedule = _a.schedule, setSchedule = _a.setSchedule, setIsValidReminder = _a.setIsValidReminder, setHasChanges = _a.setHasChanges, canEditSchedule = _a.canEditSchedule, maxEndDate = _a.maxEndDate;
    var t = useTranslation().t;
    var deleteSurveyMessage = useDeleteSurveyMessageMutation()[0];
    var handleDeleteReminder = function (reminder) {
        if (!(schedule === null || schedule === void 0 ? void 0 : schedule.reminders)) {
            return;
        }
        var reminders = __spreadArray([], schedule.reminders, true);
        var index = reminders.findIndex(function (item) { return item.uid === reminder.uid; });
        if (index === -1) {
            return;
        }
        reminders.splice(index, 1);
        setSchedule(__assign(__assign({}, schedule), { reminders: reminders }));
        if (reminder.messageId) {
            deleteSurveyMessage({
                entityId: schedule.entityId,
                messageId: reminder.messageId,
            });
        }
    };
    var handleChangeReminder = function (date, reminder) {
        if (!(schedule === null || schedule === void 0 ? void 0 : schedule.reminders) || !date) {
            return;
        }
        var reminders = __spreadArray([], schedule.reminders, true);
        var index = reminders.findIndex(function (item) { return item.uid === reminder.uid; });
        if (index === -1) {
            return;
        }
        reminders[index] = __assign(__assign({}, reminder), { sendDate: date.toISOString(), isDirty: true });
        setSchedule(__assign(__assign({}, schedule), { reminders: reminders }));
        var validatedReminders = validateReminders(reminders, schedule.startDate, schedule.endDate);
        setIsValidReminder(!(validatedReminders === null || validatedReminders === void 0 ? void 0 : validatedReminders.some(function (reminder) { return reminder.isValid === false; })));
        setHasChanges(true);
    };
    if (!(schedule === null || schedule === void 0 ? void 0 : schedule.reminders)) {
        return _jsx(Loader, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(_Fragment, { children: [(_c = (_b = __spreadArray([], schedule.reminders, true)) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) {
                var _a, _b;
                return new Date((_a = a.sendDate) !== null && _a !== void 0 ? _a : '').getTime() -
                    new Date((_b = b.sendDate) !== null && _b !== void 0 ? _b : '').getTime();
            })) === null || _c === void 0 ? void 0 : _c.map(function (reminder, index) {
                var _a, _b, _c;
                return (_jsx(ScheduleDate, { date: reminder.sendDate, label: "".concat(t('Administrate.Schedule.Reminder'), " ").concat(index + 1), handleChange: function (date) {
                        handleChangeReminder(date, reminder);
                    }, handleDelete: function () { return handleDeleteReminder(reminder); }, defaultDateOnFocus: nextBankDayAtHours(addHours(new Date(), 1).getHours()), isReadOnly: !canEditSchedule, isValid: reminder.isValid, max: maxEndDate, min: new Date(), isDirty: reminder.isDirty, openToDate: new Date((_c = (_b = (_a = reminder.sendDate) !== null && _a !== void 0 ? _a : schedule.endDate) !== null && _b !== void 0 ? _b : schedule.startDate) !== null && _c !== void 0 ? _c : new Date()) }, reminder.uid));
            }), canEditSchedule && (_jsx(ScheduleReminderAdd, { schedule: schedule, setSchedule: setSchedule }))] }));
};
ScheduleReminders.displayName = 'ScheduleReminders';
