var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TreeItem } from './TreeItem';
export var Tree = function (_a) {
    var items = _a.items, onSelect = _a.onSelect, selected = _a.selected, highlightItems = _a.highlightItems, onDrop = _a.onDrop, isDraggable = _a.isDraggable;
    var _b = useState(selected), selectedItem = _b[0], setSelectedItem = _b[1];
    useEffect(function () {
        setSelectedItem(selected);
    }, [selected]);
    var handleSelect = function (item) {
        setSelectedItem(item.id);
        onSelect && onSelect(item);
    };
    var handleDrop = function (id, parentId) {
        onDrop === null || onDrop === void 0 ? void 0 : onDrop(id, parentId);
    };
    return (_jsx(Style, { children: items.map(function (item, index) { return (_jsx("div", { children: _jsx(TreeItem, { item: item, selected: selectedItem, highlightItems: highlightItems, onSelect: handleSelect, dropItem: handleDrop, isDraggable: isDraggable !== null && isDraggable !== void 0 ? isDraggable : !!onDrop }, item.id) }, index)); }) }));
};
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;
