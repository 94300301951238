import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { formatAsPercent } from 'utils';

export const ChartTooltipSplit = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    name: string;
    label: string;
    value: string;
    payload: { key: string; label: string };
  }[];
}): JSX.Element | null => {
  if (active && payload && payload.length) {
    return (
      <TooltipStyle>
        <div className="tooltip-title">
          {payload[0].payload.key ?? payload[0].payload.label}
        </div>

        {payload.map((item, index) => (
          <div key={index}>
            {`${item.name}: ${formatAsPercent(item.value)}`}
          </div>
        ))}
      </TooltipStyle>
    );
  }

  return null;
};

ChartTooltipSplit.displayName = 'ChartTooltipSplit';
