var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CountryCode, Currency, Language, Locale, } from '@dimatech/shared/lib/models';
import { trackException } from '@dimatech/shared/lib/tracking';
import { getCookie, getLocaleSettings, removeCookie, storeCookie, } from '@dimatech/shared/lib/utils';
import { differenceInSeconds, fromUnixTime, parseISO } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { TokenName, } from './';
var Token = /** @class */ (function () {
    function Token(name, token, handleTokenUpdate) {
        this.name = name;
        this.token = token;
        this.handleTokenUpdate = handleTokenUpdate;
    }
    Token.prototype.set = function (token) {
        this.token = token;
    };
    Token.prototype.isValid = function () {
        return this.validate().isValid;
    };
    Token.prototype.validate = function () {
        var expiresInSeconds = this.expiresInSeconds();
        return { isValid: expiresInSeconds > 0, expiresInSeconds: expiresInSeconds };
    };
    Token.prototype.expiresInSeconds = function () {
        if (!this.token) {
            return 0;
        }
        try {
            var expires = getTokenExpires(this.name, this.token);
            if (!expires) {
                return 0;
            }
            return differenceInSeconds(expires, new Date());
        }
        catch (error) {
            return 0;
        }
    };
    return Token;
}());
export { Token };
var RefreshToken = /** @class */ (function (_super) {
    __extends(RefreshToken, _super);
    function RefreshToken(name, handleTokenUpdate) {
        return _super.call(this, name, getCookie(name), handleTokenUpdate) || this;
    }
    RefreshToken.prototype.refresh = function () {
        this.update(this.get());
    };
    RefreshToken.prototype.get = function () {
        var _a, _b;
        return (_b = (_a = getRefreshToken(this.name)) !== null && _a !== void 0 ? _a : this.token) !== null && _b !== void 0 ? _b : '';
    };
    RefreshToken.prototype.update = function (token) {
        this.set(token);
        this.store();
        this.handleTokenUpdate();
    };
    RefreshToken.prototype.store = function () {
        var _a;
        storeRefreshToken(this.name, (_a = this.token) !== null && _a !== void 0 ? _a : '');
    };
    RefreshToken.prototype.logout = function () {
        removeRefreshToken(this.name);
        this.update(null);
    };
    return RefreshToken;
}(Token));
export { RefreshToken };
var AccessToken = /** @class */ (function (_super) {
    __extends(AccessToken, _super);
    function AccessToken(name, handleTokenUpdate) {
        var _this = _super.call(this, name, sessionStorage.getItem(name), handleTokenUpdate) || this;
        _this.user = null;
        _this.isLoggedIn = false;
        _this.customerId = null;
        _this.roles = [];
        _this.isInternational = false;
        _this.isBaseLicense = false;
        _this.locale = {
            locale: Locale.sv_SE,
            language: Language.Swedish,
            currency: Currency.SEK,
        };
        _this.parse = function () {
            var _a, _b, _c;
            _this.user = null;
            _this.isLoggedIn = false;
            _this.customerId = null;
            _this.roles = [];
            if (!_this.token) {
                return;
            }
            try {
                var decoded = jwtDecode(_this.token);
                var expires = decoded.exp ? fromUnixTime(decoded.exp) : undefined;
                var roles = decoded.role
                    ? Array.isArray(decoded.role)
                        ? decoded.role
                        : [decoded.role]
                    : [];
                var customer = JSON.parse(decoded.currentCustomer || '{}');
                var customerProducts = JSON.parse(decoded.customerProducts || '{products: []}');
                var userProducts = JSON.parse(decoded.userProducts || '{products: []}');
                var _d = getLocaleSettings(customer.locale), currency = _d.currency, language = _d.language;
                _this.user = {
                    id: decoded.userId,
                    email: decoded.email,
                    currentCustomer: {
                        id: customer.id,
                        name: customer.name,
                        countryCode: customer.countryCode,
                        roles: roles,
                        isApproved: customer.isApproved,
                        customerType: customer.customerType,
                        license: customer.license,
                    },
                    userProducts: userProducts.products,
                    customerProducts: customerProducts.products,
                };
                _this.expires = expires;
                _this.isLoggedIn = !!_this.user.id;
                _this.customerId = _this.user.currentCustomer.id;
                _this.roles = roles;
                _this.isInternational = customer.countryCode !== CountryCode.SE;
                _this.locale = {
                    locale: customer.locale,
                    currency: currency,
                    language: language,
                };
                _this.isBaseLicense =
                    ((_a = _this.user) === null || _a === void 0 ? void 0 : _a.currentCustomer.license) === null ||
                        !((_b = _this.user) === null || _b === void 0 ? void 0 : _b.currentCustomer.license)
                        ? false
                        : (_c = _this.user) === null || _c === void 0 ? void 0 : _c.currentCustomer.license.isBase;
            }
            catch (error) {
                trackException(error, 'parseToken');
            }
        };
        _this.store();
        _this.parse();
        return _this;
    }
    AccessToken.prototype.refresh = function () {
        this.update(this.get());
    };
    AccessToken.prototype.get = function () {
        var _a;
        return (_a = getAccessToken(this.name)) !== null && _a !== void 0 ? _a : this.token;
    };
    AccessToken.prototype.update = function (token) {
        this.set(token);
        this.store();
        this.parse();
        this.handleTokenUpdate();
    };
    AccessToken.prototype.store = function () {
        var _a;
        storeAccessToken(this.name, (_a = this.token) !== null && _a !== void 0 ? _a : '');
    };
    AccessToken.prototype.logout = function () {
        removeAccessToken(this.name);
        this.update(null);
    };
    AccessToken.prototype.isInRole = function (roles) {
        var _this = this;
        return Array.isArray(roles)
            ? roles.some(function (role) { return _this.roles.includes(role); })
            : this.roles.includes(roles);
    };
    AccessToken.prototype.hasUserProductAccess = function (id) {
        var _a;
        return ((_a = this.user) === null || _a === void 0 ? void 0 : _a.userProducts)
            ? this.user.userProducts.some(function (product) { return product.id === id; })
            : false;
    };
    AccessToken.prototype.hasCustomerProductAccess = function (id) {
        var _a;
        return ((_a = this.user) === null || _a === void 0 ? void 0 : _a.customerProducts)
            ? this.user.customerProducts.some(function (product) { return product.id === id; })
            : false;
    };
    return AccessToken;
}(Token));
export { AccessToken };
var WebToken = /** @class */ (function (_super) {
    __extends(WebToken, _super);
    function WebToken(name, handleTokenUpdate) {
        var _this = _super.call(this, name, getCookie(name), handleTokenUpdate) || this;
        _this.isLoggedIn = false;
        _this.locale = {
            locale: Locale.sv_SE,
            language: Language.Swedish,
            currency: Currency.SEK,
        };
        _this.parse = function () {
            _this.expires = getTokenExpiresFromWebToken(_this.token);
            _this.isLoggedIn =
                !!_this.token && !!_this.expires && _this.expires > new Date();
        };
        _this.store();
        _this.parse();
        return _this;
    }
    WebToken.prototype.refresh = function () {
        this.update(this.get());
    };
    WebToken.prototype.get = function () {
        var _a;
        return (_a = getWebToken(this.name)) !== null && _a !== void 0 ? _a : this.token;
    };
    WebToken.prototype.update = function (token) {
        this.set(token);
        this.store();
        this.parse();
        this.handleTokenUpdate();
    };
    WebToken.prototype.store = function () {
        var _a;
        storeWebToken(this.name, (_a = this.token) !== null && _a !== void 0 ? _a : '');
    };
    WebToken.prototype.logout = function () {
        removeWebToken(this.name);
        this.update(null);
    };
    return WebToken;
}(Token));
export { WebToken };
export var getRefreshToken = function (name) {
    return getCookie(name);
};
export var getAccessToken = function (name) {
    return sessionStorage.getItem(name);
};
export var getWebToken = function (name) {
    return getCookie(name);
};
export var storeRefreshToken = function (name, token) {
    storeCookie(name, token);
};
export var storeAccessToken = function (name, token) {
    sessionStorage.setItem(name, token);
};
export var storeWebToken = function (name, token) {
    storeCookie(name, token);
};
export var removeRefreshToken = function (name) {
    removeCookie(name);
};
export var removeAccessToken = function (name) {
    sessionStorage.removeItem(name);
};
export var removeWebToken = function (name) {
    removeCookie(name);
};
export var getTokenExpires = function (name, token) {
    return name === TokenName.Web
        ? getTokenExpiresFromWebToken(token)
        : getTokenExpiresFromJwt(token);
};
var getTokenExpiresFromJwt = function (token) {
    if (!token) {
        return undefined;
    }
    try {
        var decoded = jwtDecode(token);
        if (!decoded.exp) {
            return undefined;
        }
        return fromUnixTime(decoded.exp);
    }
    catch (error) {
        return undefined;
    }
};
var getTokenExpiresFromWebToken = function (token) {
    if (!token) {
        return undefined;
    }
    try {
        var uriDecoded = decodeURIComponent(token);
        var parts = uriDecoded.split('.');
        var part = parts ? parts[parts.length - 1] : undefined;
        var decoded = part ? window.atob(part) : undefined;
        var expires = decoded ? parseISO(decoded) : undefined;
        return expires;
    }
    catch (error) {
        return undefined;
    }
};
