import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowClockwise } from 'react-icons/bs';
import { LinkWithTooltip } from './tooltip';
export var RefreshData = function (_a) {
    var handleRefresh = _a.handleRefresh, _b = _a.isInverted, isInverted = _b === void 0 ? false : _b, style = _a.style;
    var t = useTranslation().t;
    var _c = useState(false), isDisabled = _c[0], setIsDisabled = _c[1];
    var handleClick = function (e) {
        if (isDisabled) {
            return;
        }
        setIsDisabled(true);
        handleRefresh(e);
        var timer = setTimeout(function () {
            setIsDisabled(false);
            clearTimeout(timer);
        }, 2000);
    };
    return (_jsx(LinkWithTooltip, { style: style, handleClick: handleClick, isInverted: isInverted, isDisabled: isDisabled, title: isDisabled
            ? t('CommonTerms.Refresh.Completed')
            : t('CommonTerms.Refresh.Title'), tooltipTitle: t('CommonTerms.Refresh.TooltipTitle'), tooltip: t('CommonTerms.Refresh.Tooltip'), icon: _jsx(BsArrowClockwise, {}) }));
};
RefreshData.displayName = 'RefreshData';
