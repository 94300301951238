import { featuresSurveyApi } from '../featuresSurveyApi';
var accountApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get planning
         */
        getPlanning: build.query({
            query: function (_a) {
                var startDate = _a.startDate, endDate = _a.endDate;
                return "account/planning?startDate=".concat(startDate, "&endDate=").concat(endDate);
            },
        }),
        /**
         * Get message statistics
         */
        getStatistics: build.query({
            query: function (_a) {
                var startYear = _a.startYear, endYear = _a.endYear;
                return "account/statistics?startYear=".concat(startYear, "&endYear=").concat(endYear);
            },
        }),
        /**
         * Generate demo data for current customer
         */
        generateDemoData: build.mutation({
            query: function () { return ({
                url: "account/generatedemo",
                method: 'PUT',
            }); },
        }),
    }); },
});
export var useGetPlanningQuery = accountApi.useGetPlanningQuery, useGetStatisticsQuery = accountApi.useGetStatisticsQuery, useGenerateDemoDataMutation = accountApi.useGenerateDemoDataMutation;
