var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { config } from '../../config';
import { Environment } from '../../models';
import { Color } from '../../themes';
import { toTitleCase } from '../../utils';
export var EnvironmentBanner = function () {
    var t = useTranslation().t;
    if (config.environment.current === Environment.Production) {
        return null;
    }
    return (_jsxs(Style, { children: [' ', t("Common.Environment.".concat(toTitleCase(config.environment.current)))] }));
};
EnvironmentBanner.displayName = 'EnvironmentBanner';
var Style = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font: ", ";\n  color: ", ";\n"], ["\n  font: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.h1;
}, function (_a) {
    var theme = _a.theme;
    return getColorByEnvironment(theme);
});
export var getColorByEnvironment = function (theme) {
    switch (config.environment.current) {
        case Environment.Development:
            return theme.colors.success;
        case Environment.Test:
        case Environment.Staging:
            return Color.KojiOrange;
        case Environment.Production:
            return theme.colors.error;
        default:
            return theme.colors.background;
    }
};
var templateObject_1;
