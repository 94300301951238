var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { scaler } from '../../utils';
import { Tooltip } from '../tooltip';
import { HeatmapTooltip } from './HeatmapTooltip';
export var Heatmap = function (_a) {
    var _b;
    var data = _a.data, config = _a.config, handleClick = _a.handleClick;
    var matrix = new Array(config.gridSize.y);
    var sizeX = config.calcX(config.gridSize.x);
    var sizeY = config.calcY(config.gridSize.y);
    var max = 0;
    var min = Infinity;
    var isWithin = function (value, range, maxSize) {
        var current = range + config.cellSize;
        var isInRange = value >= range && value < range + config.cellSize;
        var isOverMax = value >= maxSize && current === maxSize;
        return isOverMax || isInRange;
    };
    for (var i = 0; i < config.gridSize.y; i++) {
        matrix[i] = [];
        var _loop_1 = function (j) {
            var x = config.calcX(j);
            var y = config.calcY(i);
            var items = data.filter(function (item) { return isWithin(item.x, x, sizeX) && isWithin(item.y, y, sizeY); });
            var z = items.reduce(function (z, item) { return item.z + z; }, 0);
            min = Math.min(min, z);
            max = Math.max(max, z);
            matrix[i][j] = { z: z, x: x, y: y, items: items, id: (_b = items === null || items === void 0 ? void 0 : items[0]) === null || _b === void 0 ? void 0 : _b.id };
        };
        for (var j = 0; j < config.gridSize.x; j++) {
            _loop_1(j);
        }
    }
    var scale = scaler({
        colorFrom: config.colorFrom,
        colorTo: config.colorTo,
        start: min,
        end: max,
        min: 0.2,
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Style, { children: [_jsx(Tooltip, {}), config.tooltip.labelY && _jsx(AxisY, { children: config.tooltip.labelY }), _jsx(Table, __assign({ xAxisRow: config.gridSize.y / 2, yAxisRow: config.gridSize.x / 2 }, { children: _jsx("tbody", { children: matrix.reverse().map(function (columns, index1) {
                                return (_jsx("tr", { children: columns.map(function (column, index2) { return (_jsx("td", { style: {
                                            backgroundColor: column.z > 0
                                                ? scale(column.z).hex()
                                                : config.colorFrom,
                                            width: config.cellSizePixels,
                                            height: config.cellSizePixels,
                                            cursor: column.z > 0 && handleClick ? 'pointer' : 'default',
                                        }, onClick: function () { return handleClick && handleClick(column); }, "data-html": true, "data-tip-disable": column.z === 0, "data-tip": ReactDOMServer.renderToString(_jsx(HeatmapTooltip, { config: config, item: column })) }, index2)); }) }, index1));
                            }) }) }))] }), config.tooltip.labelX && (_jsx(AxisX, __assign({ style: {
                    width: config.cellSizePixels * config.gridSize.x,
                } }, { children: config.tooltip.labelX })))] }));
};
Heatmap.displayName = 'Heatmap';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var AxisX = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 15px;\n  margin-left: 35px;\n  text-align: center;\n\n  font-size: ", ";\n"], ["\n  margin-top: 15px;\n  margin-left: 35px;\n  text-align: center;\n\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xxs;
});
var AxisY = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  transform: translateX(-50%) translateY(-50%) rotate(-90deg);\n  position: absolute;\n  top: 50%;\n  left: 15px;\n\n  font-size: ", ";\n"], ["\n  transform: translateX(-50%) translateY(-50%) rotate(-90deg);\n  position: absolute;\n  top: 50%;\n  left: 15px;\n\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xxs;
});
var Table = styled.table(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid\n    ", ";\n  border-collapse: collapse;\n  line-height: normal;\n  margin-left: 35px;\n\n  th,\n  td {\n    text-align: center;\n\n    border: 1px dashed\n      ", ";\n    border-collapse: collapse;\n  }\n\n  tr:nth-of-type(", ") {\n    border-bottom: 1px solid\n      ", ";\n  }\n\n  td:nth-of-type(", ") {\n    border-right: 1px solid\n      ", ";\n  }\n"], ["\n  border: 1px solid\n    ", ";\n  border-collapse: collapse;\n  line-height: normal;\n  margin-left: 35px;\n\n  th,\n  td {\n    text-align: center;\n\n    border: 1px dashed\n      ", ";\n    border-collapse: collapse;\n  }\n\n  tr:nth-of-type(", ") {\n    border-bottom: 1px solid\n      ", ";\n  }\n\n  td:nth-of-type(", ") {\n    border-right: 1px solid\n      ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.chart.gridStroke;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.chart.gridStroke;
}, function (props) { return props.xAxisRow; }, function (_a) {
    var theme = _a.theme;
    return theme.colors.chart.axisStroke;
}, function (props) { return props.yAxisRow; }, function (_a) {
    var theme = _a.theme;
    return theme.colors.chart.axisStroke;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
