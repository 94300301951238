var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InvalidFieldIcon } from '@dimatech/shared/lib/components/InvalidFieldIcon';
import { Label, Select } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';
import { CustomerPrincipalOwnerType, CustomerType, } from '../../../models';
export var RegisterOrganisationOwner = function (_a) {
    var customer = _a.customer, handleChange = _a.handleChange, options = _a.options, handleBlur = _a.handleBlur, validation = _a.validation;
    var t = useTranslation().t;
    return (_jsx(_Fragment, { children: customer.type === CustomerType.PubliclyOwnedOrganization && (_jsxs("div", { children: [_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "name" }, { children: t('Organisation.PrincipalOwnerType') })), _jsxs(Select, __assign({ name: "principalOwnerType", id: "principalOwnerType", value: customer.principalOwnerType, onChange: handleChange, invalid: !!(validation === null || validation === void 0 ? void 0 : validation.principalOwnerType), "data-tip": true, "data-for": "tt-ownerType", onBlur: function (e) { return handleBlur(e); } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('Organisation.SelectPrincipalOwnerType') })), Object.values(CustomerPrincipalOwnerType).map(function (value) { return (_jsx("option", __assign({ value: value }, { children: t("Customer.PrincipalOwnerType.".concat(value)) }), value)); })] })), _jsx(TooltipContent, { id: "tt-ownerType", text: validation === null || validation === void 0 ? void 0 : validation.principalOwnerType })] }), customer.principalOwnerType ===
                    CustomerPrincipalOwnerType.Municipality && (_jsxs("div", { children: [_jsxs(Label, __assign({ htmlFor: "name" }, { children: [t('Organisation.PrincipalOwnerName'), _jsx(InvalidFieldIcon, { invalid: !!(validation === null || validation === void 0 ? void 0 : validation.principalOwner), invalidTooltipId: "tt-principalOwner" })] })), _jsxs(Select, __assign({ name: "principalOwner", id: "principalOwner", value: customer.principalOwner, onChange: handleChange, invalid: !!(validation === null || validation === void 0 ? void 0 : validation.principalOwner), "data-tip": true, "data-for": "tt-principalOwner", onBlur: function (e) { return handleBlur(e); } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('Organisation.SelectMunicipality') })), options] })), _jsx(TooltipContent, { id: "tt-principalOwner", text: validation === null || validation === void 0 ? void 0 : validation.principalOwner })] })), customer.principalOwnerType ===
                    CustomerPrincipalOwnerType.Region && (_jsxs("div", { children: [_jsxs(Label, __assign({ htmlFor: "name" }, { children: [t('Organisation.PrincipalOwnerName'), _jsx(InvalidFieldIcon, { invalid: !!(validation === null || validation === void 0 ? void 0 : validation.principalOwner), invalidTooltipId: "tt-principalOwner" })] })), _jsxs(Select, __assign({ name: "principalOwner", id: "principalOwner", value: customer.principalOwner, onChange: handleChange, invalid: !!(validation === null || validation === void 0 ? void 0 : validation.principalOwner), "data-tip": true, "data-for": "tt-principalOwner", onBlur: function (e) { return handleBlur(e); } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('Organisation.SelectRegion') })), options] })), _jsx(TooltipContent, { id: "tt-principalOwner", text: validation === null || validation === void 0 ? void 0 : validation.principalOwner })] }))] })) }));
};
RegisterOrganisationOwner.displayName = 'RegisterOrganisationOwner';
