// See https://intelleqt.visualstudio.com/Manakin%202.0/_wiki/wikis/Manakin-2.0.wiki/110/Feature-flags-using-LaunchDarkly
// Permanent flags that will never be removed
var permanent = {
    app: {
        dimios: {
            isDemoEnabled: 'app-dimios-is-demo-enabled',
            isMaintenanceOn: 'app-dimios-is-maintenance-on',
            maintenanceMessage: 'app-dimios-maintenance-message',
            isViewersEnabled: 'app-dimios-is-viewers-enabled',
            isCarelessRespondentEnabled: 'app-dimios-is-careless-respondent-enabled',
            isStoriesModerationEnabled: 'app-dimios-is-stories-moderation-enabled',
            isAllowEntitySelectionForAnonymousUsersEnabled: 'app-dimios-allow-entity-selection-for-anonymous-users',
            isStandardDeviationEnabled: 'app-dimios-is-standard-deviation-enabled',
            isMaturityStaircaseEnabled: 'app-dimios-is-maturity-staircase-enabled',
            isFeaturesPromoEnabled: 'app-dimios-is-features-promo-enabled',
        },
        dikios: {
            isDemoEnabled: 'app-dikios-is-demo-enabled',
            isMaintenanceOn: 'app-dikios-is-maintenance-on',
            maintenanceMessage: 'app-dikios-maintenance-message',
            isViewersEnabled: 'app-dikios-is-viewers-enabled',
            isCarelessRespondentEnabled: 'app-dikios-is-careless-respondent-enabled',
            isAllowEntitySelectionForAnonymousUsersEnabled: 'app-dikios-allow-entity-selection-for-anonymous-users',
            isOccupationFilterEnabled: 'app-dikios-is-occupation-filter-enabled',
        },
        dios: {
            isDemoEnabled: 'app-dios-is-demo-enabled',
            isMaintenanceOn: 'app-dios-is-maintenance-on',
            maintenanceMessage: 'app-dios-maintenance-message',
            scheduleEnableTagsForReminders: 'app-dios-schedule-enable-tags-for-reminders',
            isPublicApiEnabled: 'app-dios-is-public-api-enabled',
            isFailedMessagesEnabled: 'app-dios-is-failed-messages-enabled',
            isSystemConnectionsEnabled: 'app-dios-is-system-connections-enabled',
            isHistoryEnabled: 'app-dios-is-history-enabled',
        },
        pios: {
            isDemoEnabled: 'app-pios-is-demo-enabled',
            isMaintenanceOn: 'app-pios-is-maintenance-on',
            maintenanceMessage: 'app-pios-maintenance-message',
            isViewersEnabled: 'app-pios-is-viewers-enabled',
            isTagsEnabled: 'app-pios-is-tags-enabled',
            isPortfolioExportEveryonesEnabled: 'app-pios-is-portfolio-export-everyones-enabled',
            isProjectIdeasReviewersEnabled: 'app-pios-is-project-ideas-reviewers-enabled',
            isPublicApiEnabled: 'app-pios-is-public-api-enabled',
            isProjectDocumentUploadEnabled: 'app-pios-is-project-document-upload-enabled',
            administrateReviewers: 'app-pios-administrate-reviewers',
            isHistoryEnabled: 'app-pios-is-history-enabled',
            isProjectEffectRealisationEnabled: 'app-pios-is-project-effect-realisation-enabled',
            isGoalAiEvaluationEnabled: 'app-pios-is-goal-ai-evaluation-enabled',
            isProjectRolesEnabled: 'app-pios-is-project-roles-enabled',
            isStructuredNavigationEnabled: 'app-pios-is-structured-navigation-enabled',
            isProjectRoleOrderingPartyEnabled: 'app-pios-is-project-role-ordering-party-enabled',
            isGanttEnabled: 'app-pios-is-gantt-enabled',
            isStartAndEndMonthEnabled: 'app-pios-is-start-and-end-month-enabled',
            isPublishPubliclyEnabled: 'app-pios-is-publish-publicly-enabled',
            publishPubliclyMinProjectsRequirement: 'app-pios-public-min-published-projects-requirement',
            isBaseLicenseEnabled: 'app-pios-is-base-license-enabled',
            isPublicWebEnabled: 'app-pios-is-public-web-enabled',
            isPublishPubliclyByDefaultEnabled: 'app-pios-is-publish-publicly-by-default-enabled',
            isAreasOfOperationEnabled: 'app-pios-is-areas-of-operation-enabled',
        },
        pcv: {},
        survey: {
            isMaintenanceOn: 'app-survey-is-maintenance-on',
        },
    },
    shared: {
        showDevTools: 'shared-show-dev-tools',
        showDevDebugInfo: 'shared-show-dev-debuginfo',
        isUserMessagesEnabled: 'shared-is-user-messages-enabled',
        isQrCodesEnabled: 'shared-is-qr-codes-enabled',
        isUserMessagesForCustomersEnabled: 'shared-is-user-messages-for-customers-enabled',
        isFeatureFlagsEditEnabled: 'shared-is-feature-flags-edit-enabled',
        isLocaleSelectionEnabled: 'shared-is-locale-selection-enabled',
        isCountrySelectionEnabled: 'shared-is-country-selection-enabled',
    },
    test: {
        isTestFlag1Enabled: 'test-is-test-flag-1-enabled',
        isTestFlag2Enabled: 'test-is-test-flag-2-enabled',
        isTestFlag3Enabled: 'test-is-test-flag-3-enabled',
    },
};
// Regular active flags that will eventually be removed
// usually when a feature is well tested and published to all users
var active = {
    app: {
        dimios: {},
        dikios: {},
        dios: {},
        pios: {
            isProjectIdeasQualifyStepEnabled: 'app-pios-is-project-ideas-qualify-step-enabled',
        },
        pcv: {},
        survey: {},
    },
    shared: {},
    test: {},
};
// Move flags to archived when all code references are removed
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var archived = {
    app: {
        dimios: {
            exportTableAsCsv: 'app-dimios-export-table-as-csv',
            researcherHistoricalReports: 'app-dimios-researcher-historical-reports',
            administrateViewers: 'app-dimios-administrate-viewers',
            isNextStepEnabled: 'app-dimios-is-next-step-enabled',
        },
        dikios: {},
        dios: {},
        pios: {
            projectDimensions: 'app-pios-project-dimensions',
            administratePortfolioOwners: 'app-pios-administrate-portfolio-owners',
            projectStaffingBudget: 'app-pios-project-staffing-budget',
            administrateReviewers: 'app-pios-administrate-reviewers',
            administrateViewers: 'app-pios-administrate-viewers',
            isProjectIdeasCustomCalculationsEnabled: 'app-pios-is-project-ideas-custom-calculations-enabled',
            isProjectEffectTakeHomeEnabled: 'app-pios-is-project-effect-take-home-enabled',
            isProjectIdeasEstimatedClassificationsEnabled: 'app-pios-is-project-ideas-estimated-classifications-enabled',
            isProjectIdeasStakeAndBenefitHelpEnabled: 'app-pios-is-project-ideas-stake-and-benefit-help-enabled',
            isProjectIdeasGoalsEnabled: 'app-pios-is-project-ideas-goals-enabled',
            isProjectViewIdeaDataEnabled: 'app-pios-is-project-view-idea-data-enabled',
            isGoalAiEvaluationPromptEnabled: 'app-pios-is-goal-ai-evaluation-prompt-enabled',
            isProjectIdeasEnabled: 'app-pios-is-project-ideas-enabled',
            isProjectStaffingBudgetEnabled: 'app-pios-is-project-staffing-budget-enabled',
            isProjectNotesEnabled: 'app-pios-is-project-notes-enabled',
            isProjectEffectRealisationExperimentalEnabled: 'app-pios-is-project-effect-realisation-experimental-enabled',
            isAdministrateUsersEnabled: 'app-pios-is-administrate-users-enabled',
        },
        pcv: {},
        survey: {
            serveTestData: 'app-survey-serve-test-data',
        },
    },
    shared: {
        orderPage: 'shared-order-page',
        orderDios: 'shared-order-dios',
        isVersionCheckEnabled: 'shared-is-version-check-enabled',
        isAdministrateUsersEmailExportEnabled: 'shared-is-administrate-users-email-export-enabled',
        isReleaseNotesEnabled: 'shared-is-release-notes-enabled',
        isOrderDikiosEnabled: 'shared-order-dikios-enabled',
        isVideoLibraryEnabled: 'shared-is-video-library-enabled',
    },
    test: {},
};
export var flags = {
    permanent: permanent,
    active: active,
};
