import { QuestionAnalyze as QuestionAnalyzeFeature } from 'features/question-analyze';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const QuestionAnalyze = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('QuestionAnalyze.Title')}>
      <QuestionAnalyzeFeature />
    </View>
  );
};

QuestionAnalyze.displayName = 'QuestionAnalyze';
