import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { PrinciplesByEntity, SortDirection } from 'models';
import { useTranslation } from 'react-i18next';
import { formatAsPercent } from 'utils';

export const ChildResultDontKnow = ({
  principlesByEntity,
  saveToPngElementId,
}: {
  principlesByEntity: PrinciplesByEntity[];
  saveToPngElementId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const formatter = (value: string | number | undefined) =>
    formatAsPercent(value, '-') as string;

  const { items, sorter } = useSortableData(principlesByEntity, {
    key: 'entityName',
    direction: SortDirection.Asc,
  });

  return (
    <TableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th sortKey="entityName" sorter={sorter}>
              {t('CommonTerms.Entity')}
            </Th>

            <ThRight
              sortKey="efficiency"
              sorter={sorter}
              sortPredicate={({ ability: a }, { ability: b }) => {
                return {
                  a: a.efficiency.percentageDontKnow,
                  b: b.efficiency.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Efficiency')}
            </ThRight>

            <ThRight
              sortKey="innovation"
              sorter={sorter}
              sortPredicate={({ ability: a }, { ability: b }) => {
                return {
                  a: a.innovation.percentageDontKnow,
                  b: b.innovation.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Innovation')}
            </ThRight>

            <ThRight
              sortKey="balance"
              sorter={sorter}
              sortPredicate={({ ability: a }, { ability: b }) => {
                return {
                  a: a.balance.percentageDontKnow,
                  b: b.balance.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Balance')}
            </ThRight>

            <ThRight
              sortKey="organisation"
              sorter={sorter}
              sortPredicate={({ heritage: a }, { heritage: b }) => {
                return {
                  a: a.organisation.percentageDontKnow,
                  b: b.organisation.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Organisation')}
            </ThRight>

            <ThRight
              sortKey="users"
              sorter={sorter}
              sortPredicate={({ heritage: a }, { heritage: b }) => {
                return {
                  a: a.users.percentageDontKnow,
                  b: b.users.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Users')}
            </ThRight>

            <ThRight
              sortKey="technology"
              sorter={sorter}
              sortPredicate={({ heritage: a }, { heritage: b }) => {
                return {
                  a: a.technology.percentageDontKnow,
                  b: b.technology.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Technology')}
            </ThRight>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <Tr key={index}>
              <Td>{item.entityName}</Td>
              <TdRight data-value={item.ability.efficiency.percentageDontKnow}>
                {formatter(item.ability.efficiency.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.ability.innovation.percentageDontKnow}>
                {formatter(item.ability.innovation.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.ability.balance.percentageDontKnow}>
                {formatter(item.ability.balance.percentageDontKnow)}
              </TdRight>
              <TdRight
                data-value={item.heritage.organisation.percentageDontKnow}
              >
                {formatter(item.heritage.organisation.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.heritage.users.percentageDontKnow}>
                {formatter(item.heritage.users.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.heritage.technology.percentageDontKnow}>
                {formatter(item.heritage.technology.percentageDontKnow)}
              </TdRight>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

ChildResultDontKnow.displayName = 'ChildResultDontKnow';
