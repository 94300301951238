var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { LoaderOverlay } from '../loader';
import { CardBase } from './CardBase';
export var Card = function (cardProps) {
    return (_jsx(Style, __assign({ width: cardProps.width, height: cardProps.height, style: cardProps.style }, { children: _jsx(CardBase, __assign({}, cardProps)) })));
};
Card.displayName = 'Card';
export var CardBody = function (_a) {
    var children = _a.children, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, style = _a.style, width = _a.width, height = _a.height;
    return (_jsxs(CardBodyStyle, __assign({ width: width, height: height, style: style }, { children: [isLoading && _jsx(LoaderOverlay, {}), _jsx("div", { children: children })] })));
};
CardBody.displayName = 'CardBody';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: fit-content;\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n\n  margin: 5px 10px 15px 10px;\n\n  box-shadow: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-radius: 3px;\n\n  > div {\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  min-width: fit-content;\n  width: ", ";\n  height: ", ";\n  max-width: ", ";\n\n  margin: 5px 10px 15px 10px;\n\n  box-shadow: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-radius: 3px;\n\n  > div {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return props.width; }, function (props) { return props.height; }, function (_a) {
    var theme = _a.theme;
    return theme.size.viewMaxWidth;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.light;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
});
var CardBodyStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  position: relative;\n  padding: 10px;\n"], ["\n  width: ", ";\n  height: ", ";\n  position: relative;\n  padding: 10px;\n"])), function (props) { return props.width; }, function (props) { return props.height; });
var templateObject_1, templateObject_2;
