var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsEntity, featuresSurveyApi } from '../featuresSurveyApi';
var entityApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get all entities for current customer
         */
        getEntitiesWithSurvey: build.query({
            // _customerId and _userId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_a) {
                var surveyInstanceId = _a.surveyInstanceId, _customerId = _a._customerId, _userId = _a._userId;
                return "entities".concat(surveyInstanceId ? "?surveyInstanceId=".concat(surveyInstanceId) : '');
            },
            providesTags: [CacheTagsEntity.EntityWithSurvey],
            transformResponse: function (result, _meta, arg) {
                return result.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
            },
        }),
        /**
         * Create new entity
         */
        addEntityWithSurvey: build.mutation({
            query: function (entity) { return ({
                url: "entities",
                method: 'POST',
                body: entity,
            }); },
            invalidatesTags: [CacheTagsEntity.EntityWithSurvey],
            transformResponse: function (result, _meta, arg) {
                return __assign(__assign({}, arg), result);
            },
        }),
        /**
         * Update entity
         */
        updateEntityWithSurvey: build.mutation({
            query: function (entity) { return ({
                url: "entities/".concat(entity.id),
                method: 'PUT',
                body: {
                    name: entity.name,
                    allowIndividualMeasurementPeriods: entity.allowIndividualMeasurementPeriods,
                },
            }); },
            invalidatesTags: [CacheTagsEntity.EntityWithSurvey],
        }),
        /**
         * Delete entity
         */
        deleteEntityWithSurvey: build.mutation({
            query: function (entityId) { return ({
                url: "entities/".concat(entityId),
                method: 'DELETE',
            }); },
            invalidatesTags: [CacheTagsEntity.EntityWithSurvey],
        }),
        /**
         * Move entity
         */
        moveEntityWithSurvey: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, parentId = _a.parentId;
                return ({
                    url: "entities/".concat(entityId, "/move/").concat(parentId),
                    method: 'PUT',
                });
            },
            invalidatesTags: [CacheTagsEntity.EntityWithSurvey],
            transformResponse: function (_result, _meta, arg) {
                return {
                    id: arg.entityId,
                    parentId: !arg.parentId ? null : arg.parentId,
                };
            },
        }),
        /**
         * Update entity individual measurement period
         */
        updateIndividualMeasurementPeriod: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, allowIndividualMeasurementPeriods = _a.allowIndividualMeasurementPeriods;
                return ({
                    url: "entities/".concat(entityId, "/individualmeasurementperiod"),
                    method: 'PUT',
                    body: {
                        allowIndividualMeasurementPeriods: allowIndividualMeasurementPeriods,
                    },
                });
            },
            invalidatesTags: [
                CacheTagsEntity.EntityWithSurvey,
                CacheTagsEntity.EntitySurvey,
                CacheTagsEntity.ProgressByEntity,
            ],
        }),
        /**
         * Update entity allow selection for anonymous users flag
         */
        updateAllowEntitySelectionForAnonymousUsers: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, allowEntitySelectionForAnonymousUsers = _a.allowEntitySelectionForAnonymousUsers;
                return ({
                    url: "entities/".concat(entityId, "/allowEntitySelectionForAnonymousUsers"),
                    method: 'PUT',
                    body: {
                        allowEntitySelectionForAnonymousUsers: allowEntitySelectionForAnonymousUsers,
                    },
                });
            },
            invalidatesTags: [
                CacheTagsEntity.EntityWithSurvey,
                CacheTagsEntity.EntitySurvey,
                CacheTagsEntity.ProgressByEntity,
            ],
        }),
    }); },
});
export var useGetEntitiesWithSurveyQuery = entityApi.useGetEntitiesWithSurveyQuery, useAddEntityWithSurveyMutation = entityApi.useAddEntityWithSurveyMutation, useUpdateEntityWithSurveyMutation = entityApi.useUpdateEntityWithSurveyMutation, useUpdateIndividualMeasurementPeriodMutation = entityApi.useUpdateIndividualMeasurementPeriodMutation, useUpdateAllowEntitySelectionForAnonymousUsersMutation = entityApi.useUpdateAllowEntitySelectionForAnonymousUsersMutation, useDeleteEntityWithSurveyMutation = entityApi.useDeleteEntityWithSurveyMutation, useMoveEntityWithSurveyMutation = entityApi.useMoveEntityWithSurveyMutation;
