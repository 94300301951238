var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, ButtonSecondary, Buttons, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowsMove } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../api/entity/entitySlice';
import { useMoveSurveyRespondentMutation } from '../../../api/entity/entitySurveyRespondentApi';
import { entitySurveyRespondentActions, selectMovingRespondent, } from '../../../api/entity/entitySurveyRespondentSlice';
import { selectFilter } from '../../../api/filter/filterSlice';
import { SelectEntityWithSurvey } from '../../../components/SelectEntityWithSurvey';
export var RespondentActionMove = function (_a) {
    var respondent = _a.respondent, selectedEntityId = _a.selectedEntityId, setError = _a.setError, isReadOnly = _a.isReadOnly, handleRespondentMovedOrDeleted = _a.handleRespondentMovedOrDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var movingRespondent = useSelector(selectMovingRespondent);
    var _b = useMoveSurveyRespondentMutation(), moveRespondent = _b[0], isSuccess = _b[1].isSuccess;
    var _c = useState(false), isMoving = _c[0], setIsMoving = _c[1];
    var _d = useState(''), entityId = _d[0], setEntityId = _d[1];
    var handleConfirmMove = function (e) {
        e.stopPropagation();
        if (isReadOnly || !entityId || !movingRespondent) {
            return;
        }
        moveRespondent({
            entityId: selectedEntityId,
            toEntityId: entityId,
            respondents: [movingRespondent],
        });
        dispatch(entitySurveyRespondentActions.setMovingRespondent());
        dispatch(entityActions.selectedEntityRespondent(null));
    };
    var handleMove = function (e, show) {
        e.stopPropagation();
        setError(undefined);
        dispatch(entitySurveyRespondentActions.setMovingRespondent(show ? respondent.email : undefined));
    };
    useEffect(function () {
        setIsMoving(!!movingRespondent && movingRespondent === respondent.email);
    }, [movingRespondent, respondent]);
    useEffect(function () {
        if (isSuccess) {
            handleRespondentMovedOrDeleted === null || handleRespondentMovedOrDeleted === void 0 ? void 0 : handleRespondentMovedOrDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    return (_jsxs(_Fragment, { children: [isMoving && (_jsxs(Modal, __assign({ title: t('Administrate.Respondent.Move.Title'), handleKeyEnter: handleConfirmMove, handleKeyEsc: function (e) { return handleMove(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Respondent.Move.Text') }), _jsx(Label, { children: t('Administrate.Respondent.Move.To', {
                            name: respondent.isDeleted
                                ? "\"".concat(t('Administrate.Respondent.DeletedRespondent'), "\"")
                                : respondent.email,
                        }) }), _jsx(SelectEntityWithSurvey, { autoFocus: true, entityId: entityId, setEntityId: setEntityId, surveyInstanceId: filter.instance.id, placeholder: t('Administrate.Respondent.Move.SelectEntity') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleMove(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleConfirmMove, disabled: isReadOnly }, { children: t('Administrate.Respondent.Button.Move') }))] })] }))), _jsx(TooltipContent, { id: "tt-respondent-move", title: t('Administrate.Respondent.Move.TooltipTitle'), text: t('Administrate.Respondent.Move.Tooltip') }), _jsx(BadgeMini, __assign({ style: { margin: '0 6px' }, "data-tip": true, "data-for": "tt-respondent-move", onClick: function (e) { return handleMove(e, filter.instance.isCurrentInstance); }, disabled: !filter.instance.isCurrentInstance }, { children: _jsx(BsArrowsMove, {}) }))] }));
};
RespondentActionMove.displayName = 'RespondentActionMove';
