var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { RespondentMessageStatus as MessageStatus } from '@dimatech/shared/lib/models';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { withTheme } from 'styled-components';
export var RespondentMessageStatus = withTheme(function (_a) {
    var theme = _a.theme, status = _a.status, error = _a.error;
    var t = useTranslation().t;
    var id = useId();
    var getColor = function (status) {
        switch (status) {
            case MessageStatus.Scheduled:
                return theme.colors.surface;
            case MessageStatus.Sent:
                return theme.colors.primary;
            case MessageStatus.InProgress:
            case MessageStatus.Deffered:
                return theme.colors.warning;
            case MessageStatus.Delivered:
            case MessageStatus.Opened:
                return theme.colors.success;
            case MessageStatus.Blocked:
            case MessageStatus.Bounced:
            case MessageStatus.Dropped:
            case MessageStatus.Error:
                return theme.colors.error;
            default:
                return theme.colors.error;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { whiteSpace: 'break-spaces' } }, { children: _jsx(TooltipContent, { id: id, title: t("Administrate.Respondent.Messages.MessageStatus.".concat(status)), text: _jsxs(_Fragment, { children: [t("Administrate.Respondent.Messages.MessageStatusTooltip.".concat(status)), error && (_jsxs("div", __assign({ style: { marginTop: 10 } }, { children: [_jsxs("strong", { children: [t("Administrate.Respondent.Messages.Error"), ":"] }), ' ', error] })))] }) }) })), _jsx(BsCircleFill, { "data-tip": true, "data-for": id, style: { marginRight: 5, fontSize: theme.fonts.size.s }, color: getColor(status) }), _jsx("span", __assign({ "data-tip": true, "data-for": id }, { children: t("Administrate.Respondent.Messages.MessageStatus.".concat(status)) }))] }));
});
RespondentMessageStatus.displayName = 'RespondentMessageStatus';
