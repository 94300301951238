var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { SearchBox } from '@dimatech/shared/lib/components/Search';
import { Checkbox, Label } from '@dimatech/shared/lib/components/form';
import { Pagination, useUiSortablePaginator, } from '@dimatech/shared/lib/components/paginator';
import { Table, Td, TdRight, Th, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip, TooltipContent, } from '@dimatech/shared/lib/components/tooltip';
import { formatDate } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import produce from 'immer';
import { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckAll, BsCircleFill, BsPerson, BsTrash, BsX, } from 'react-icons/bs';
import { withTheme } from 'styled-components';
export var RecipientsList = withTheme(function (_a) {
    var recipients = _a.recipients, setRecipients = _a.setRecipients, handleDeleteRecipients = _a.handleDeleteRecipients, theme = _a.theme;
    var t = useTranslation().t;
    var _b = useState(''), search = _b[0], setSearch = _b[1];
    var _c = useState([]), filtered = _c[0], setFiltered = _c[1];
    var _d = useState(false), showSelectAll = _d[0], setShowSelectAll = _d[1];
    var _e = useUiSortablePaginator({
        orderBy: 'email',
        pageSize: 25,
        data: {
            totalRecords: filtered.length,
            records: filtered,
        },
    }), sortedRecipients = _e.items, setPage = _e.setPage, paginator = _e.paginator, sorter = _e.sorter;
    var noOfSelected = recipients.filter(function (recipient) { return recipient.isSelected; }).length;
    var noOfTotal = recipients.length;
    var isAllChecked = noOfSelected === noOfTotal;
    var handleSelectAll = function (checked) {
        var newRecipients = recipients.map(function (recipient) { return (__assign(__assign({}, recipient), { isSelected: checked })); });
        setRecipients(newRecipients);
    };
    var handleSelect = function (e, recipient) {
        var index = recipients.findIndex(function (r) { return r.userId === recipient.userId; });
        var newRecipients = produce(recipients, function (draftState) {
            draftState[index] = __assign(__assign({}, recipient), { isSelected: e.target.checked });
        });
        setRecipients(newRecipients);
    };
    useEffect(function () {
        var _a;
        var newRecipients = recipients.filter(function (recipient) { return recipient.read === null || recipient.read === undefined; });
        setShowSelectAll(newRecipients.length > 0);
        setFiltered((_a = recipients.filter(function (respondent) { var _a; return (_a = respondent.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase()); })) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, recipients]);
    return (_jsxs(_Fragment, { children: [recipients.length === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('Administrate.Messages.Message.Recipient.NoRecipients') }))), showSelectAll && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                            margin: '10px 0',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        } }, { children: [_jsx(LinkWithTooltip, { handleClick: function () { return handleSelectAll(!isAllChecked); }, title: isAllChecked
                                    ? t("Common.UI.DeselectAll")
                                    : t("Common.UI.SelectAll"), isInverted: true, icon: isAllChecked ? _jsx(BsX, {}) : _jsx(BsCheckAll, {}), disabledTimerInMs: 1 }), recipients.filter(function (r) { return r.isSelected; }).length > 0 && (_jsx(LinkWithTooltip, { style: { marginLeft: 15 }, handleClick: function () {
                                    handleDeleteRecipients(recipients.filter(function (r) { return r.isSelected; }));
                                }, title: t("Administrate.Messages.Message.Recipient.Delete.ForRemoval", {
                                    count: recipients.filter(function (r) { return r.isSelected; }).length,
                                }), isInverted: true, icon: _jsx(BsTrash, {}), isDisabled: noOfSelected === 0, disabledTimerInMs: 500 }))] })), recipients.length > 0 && (_jsx(SearchBox, { style: {
                            margin: '20px 0',
                        }, name: search, setName: setSearch, placeholder: t('Search.EmailPlaceholder') }))] })), _jsx(Pagination, { currentPage: paginator.page, totalCount: filtered.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); } }), recipients && recipients.length > 0 && (_jsxs(Table, __assign({ style: { marginTop: 20, marginBottom: 30 } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, { style: { width: 35 } }), _jsx(Th, __assign({ sortKey: "email", sorter: sorter, className: "narrow" }, { children: t('Administrate.Messages.Message.Recipient.Email') })), _jsx(Th, __assign({ sortKey: "read", sorter: sorter, className: "narrow" }, { children: t('Administrate.Messages.Message.Recipient.Read') })), _jsx(ThRight, { style: { width: 35 } })] }) }), _jsx("tbody", { children: sortedRecipients === null || sortedRecipients === void 0 ? void 0 : sortedRecipients.map(function (recipient, index) { return (_jsxs(Tr, { children: [_jsx(Td, { children: recipient.read === null || recipient.read === undefined ? (_jsx(Checkbox, { onChange: function (e) {
                                            handleSelect(e, recipient);
                                        }, id: index + "_".concat(recipient.userId), name: recipient.userId, value: recipient.userId, checked: !!recipient.isSelected })) : (_jsx(BsPerson, {})) }), _jsx(Td, __assign({ className: "narrow" }, { children: _jsx(Label, __assign({ style: { marginTop: 0 }, htmlFor: index + "_".concat(recipient.userId) }, { children: recipient.email })) })), _jsxs(Td, __assign({ className: "narrow" }, { children: [recipient.read && (_jsx(BsCircleFill, { style: {
                                                marginRight: 8,
                                                color: theme.colors.success,
                                            } })), recipient.read
                                            ? formatDate(parseISO(recipient.read))
                                            : '-'] })), _jsx(TdRight, { children: !recipient.read && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-recipient-delete", title: t('Administrate.Messages.Message.Recipient.Delete.TooltipTitle'), text: t('Administrate.Messages.Message.Recipient.Delete.Tooltip') }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-recipient-delete", onClick: function () { return handleDeleteRecipients([recipient]); } }, { children: _jsx(BsTrash, {}) }))] })) })] }, index)); }) })] }))), _jsx(Pagination, { currentPage: paginator.page, totalCount: filtered.length, pageSize: paginator.pageSize, handlePageChange: function (page) { return setPage(page); }, style: { marginBottom: 20 } })] }));
});
RecipientsList.displayName = 'RecipientsList';
