var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var CarouselItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  width: 100%;\n  flex-shrink: 0;\n  flex-grow: 1;\n\n  img {\n    max-width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  width: 100%;\n  flex-shrink: 0;\n  flex-grow: 1;\n\n  img {\n    max-width: 100%;\n  }\n"])));
CarouselItem.displayName = 'CarouselItem';
export var CarouselBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: hidden;\n\n  padding: 10px 10px 20px 10px;\n  height: 100%;\n"], ["\n  overflow: hidden;\n\n  padding: 10px 10px 20px 10px;\n  height: 100%;\n"])));
CarouselBody.displayName = 'CarouselBody';
export var CarouselHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n\n  width: 100%;\n  padding: 10px 10px 0 10px;\n\n  font-weight: bold;\n"], ["\n  display: flex;\n\n  width: 100%;\n  padding: 10px 10px 0 10px;\n\n  font-weight: bold;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
