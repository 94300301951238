var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Gets path name for each entity in array
 * @param entities
 * @param entity
 * @returns
 */
export var getParentPathName = function (entities, entity) {
    var parent = entities.find(function (e) { return e.id === entity.parentId; });
    return "".concat(parent && parent.parentId ? "".concat(getParentPathName(entities, parent), "/") : '').concat(entity.name);
};
/**
 * Returns a flat array of all the entities that is in parentId's path
 * @param entities
 * @param parentId
 * @returns
 */
export var getEntitiesInPath = function (entities, parentId) {
    if (entities.some(function (e) { return e.parentId === parentId; })) {
        return entities.reduce(function (all, entity) {
            if (entity.parentId === parentId) {
                all.push(__assign(__assign({}, entity), { path: getParentPathName(entities, entity) }));
                all = __spreadArray(__spreadArray([], all, true), getEntitiesInPath(__spreadArray([], entities, true), entity.id), true);
            }
            return all;
        }, []);
    }
    else if (parentId === '') {
        return entities
            .filter(function (e) { return !entities.some(function (ei) { return e.parentId === ei.id; }); })
            .reduce(function (all, orphan) {
            return __spreadArray(__spreadArray([], all, true), getEntitiesInPath(entities, orphan.parentId), true);
        }, []);
    }
    else {
        //
        return [];
    }
};
