var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CacheTagsEntity, CacheTagsSurvey, featuresSurveyApi, } from '../featuresSurveyApi';
var surveyApi = featuresSurveyApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get all survey instances
         */
        getSurveyInstances: build.query({
            query: function (_a) {
                var _customerId = _a._customerId, _userId = _a._userId;
                return 'survey/instances';
            },
            transformResponse: function (surveyInstances) {
                // Store list of sorted instances in state
                return __spreadArray([], (surveyInstances !== null && surveyInstances !== void 0 ? surveyInstances : []), true).reverse();
            },
            providesTags: [CacheTagsSurvey.SurveyInstances],
        }),
        /**
         * Get survey structure
         */
        getSurveyStructure: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_a) {
                var surveyId = _a.surveyId, _customerId = _a._customerId;
                return "survey/form?surveyId=".concat(surveyId);
            },
            transformResponse: function (form) {
                // Store list of sorted questions in state
                var questions = form.pages.reduce(function (question, page, pageIndex) {
                    return question.concat(page.questions.map(function (question, questionIndex) {
                        return __assign(__assign({}, question), { sortOrder: pageIndex * 10 + questionIndex });
                    }));
                }, []);
                return questions;
            },
        }),
        /**
         * Get all surveys for current customer
         */
        getSurveys: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_customerId) { return 'survey'; },
        }),
        /**
         * Get all failed respondent messages for current customer
         */
        getFailedMessages: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_a) {
                var _customerId = _a._customerId, paginator = _a.paginator;
                return "overview/failedemails?pageNumber=".concat(paginator.page, "&pageSize=").concat(paginator.pageSize, "&orderBy.propertyName=").concat(paginator.orderBy, "&orderBy.direction=").concat(paginator.orderDirection);
            },
            providesTags: [CacheTagsEntity.SurveyFailedMessages],
        }),
    }); },
});
export var useGetSurveysQuery = surveyApi.useGetSurveysQuery, useGetSurveyStructureQuery = surveyApi.useGetSurveyStructureQuery, useGetSurveyInstancesQuery = surveyApi.useGetSurveyInstancesQuery, useLazyGetFailedMessagesQuery = surveyApi.useLazyGetFailedMessagesQuery, useGetFailedMessagesQuery = surveyApi.useGetFailedMessagesQuery;
