var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeTiny } from '@dimatech/shared/lib/components/Badge';
import { SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext } from 'react';
import { BsExclamation } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { useGetFailedMessagesQuery } from '../../../api/survey/surveyApi';
export var RespondentsFailedMessagesBadge = withTheme(function (_a) {
    var theme = _a.theme;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useGetFailedMessagesQuery(accessToken.customerId
        ? {
            _customerId: accessToken.customerId,
            paginator: {
                page: 1,
                pageSize: 25,
                orderBy: 'email',
                orderDirection: SortDirection.Asc,
            },
        }
        : skipToken), data = _b.data, isFetching = _b.isFetching;
    return !isFetching && data && data.totalRecords > 0 ? (_jsx(BadgeTiny, __assign({ style: {
            marginLeft: 5,
            color: theme.colors.onError,
            backgroundColor: theme.colors.error,
        } }, { children: _jsx(BsExclamation, {}) }))) : null;
});
RespondentsFailedMessagesBadge.displayName = 'RespondentsFailedMessagesBadge';
