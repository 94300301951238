var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Breakpoints } from '../themes';
import { HelpIcon } from './HelpIcon';
export var Number = function (_a) {
    var icon = _a.icon, formattedValue = _a.formattedValue, text = _a.text, color = _a.color, help = _a.help, style = _a.style;
    return (_jsxs(Style, __assign({ style: style }, { children: [_jsxs("div", { children: [icon && _jsx("div", __assign({ style: color ? { color: color } : undefined }, { children: icon })), _jsx("div", { children: formattedValue }), _jsx("div", { children: _jsx(HelpIcon, { title: text, text: help }) })] }), _jsx("div", { children: text })] })));
};
Number.displayName = 'Number';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 50px;\n\n  > div:first-of-type {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: center;\n    gap: 10px;\n\n    > div:first-of-type {\n      padding-top: 0.2em;\n      vertical-align: middle;\n    }\n\n    > div:not(:last-of-type) {\n      white-space: nowrap;\n      font: ", ";\n    }\n\n    > div:last-of-type {\n      z-index: 10;\n    }\n  }\n\n  > div:last-of-type {\n    text-align: center;\n    font-size: ", ";\n  }\n\n  @media screen and (max-width: ", ") {\n    > div {\n      justify-content: center;\n      text-align: center;\n    }\n  }\n"], ["\n  height: 50px;\n\n  > div:first-of-type {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: center;\n    gap: 10px;\n\n    > div:first-of-type {\n      padding-top: 0.2em;\n      vertical-align: middle;\n    }\n\n    > div:not(:last-of-type) {\n      white-space: nowrap;\n      font: ", ";\n    }\n\n    > div:last-of-type {\n      z-index: 10;\n    }\n  }\n\n  > div:last-of-type {\n    text-align: center;\n    font-size: ", ";\n  }\n\n  @media screen and (max-width: ", ") {\n    > div {\n      justify-content: center;\n      text-align: center;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.h1;
}, function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xs;
}, Breakpoints.small);
var templateObject_1;
