var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { BsBorderStyle, BsBuilding, BsFileText, BsGit, BsPerson, BsPersonBadge, } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ApplicationContext } from '../../application';
import { flags } from '../../feature-flags';
import { Breakpoints } from '../../themes';
import { copyToClipboard, formatDate, storeCookie } from '../../utils';
import { BadgeMini } from '../Badge';
import { TooltipContent } from '../tooltip';
export var DevToolbar = function (_a) {
    var token = _a.token, webToken = _a.webToken, userId = _a.userId, customerId = _a.customerId, tokenExpires = _a.tokenExpires, webTokenExpires = _a.webTokenExpires;
    var navigate = useNavigate();
    var application = useContext(ApplicationContext);
    var showDevTools = useFlags()[flags.permanent.shared.showDevTools];
    return showDevTools ? (_jsxs(Style, { children: [token && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-copy-bearer", text: _jsxs("div", { children: ["Copy bearer token", tokenExpires && _jsxs("p", { children: ["Expires ", formatDate(tokenExpires)] })] }) }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-copy-bearer", onClick: function () {
                            copyToClipboard(token);
                        }, "$inverted": true }, { children: _jsx(BsPersonBadge, {}) }))] })), webToken && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-copy-web", text: _jsxs("div", { children: ["Copy web token", webTokenExpires && (_jsxs("p", { children: ["Expires ", formatDate(webTokenExpires)] }))] }) }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-copy-web", onClick: function () {
                            copyToClipboard(webToken);
                        }, "$inverted": true }, { children: _jsx(BsPersonBadge, {}) }))] })), userId && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-copy-user", text: "Copy current user id" }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-copy-user", onClick: function () {
                            copyToClipboard(userId);
                        }, "$inverted": true }, { children: _jsx(BsPerson, {}) }))] })), customerId && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-copy-customer", text: "Copy current customer id" }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-copy-customer", onClick: function () {
                            copyToClipboard(customerId);
                        }, "$inverted": true }, { children: _jsx(BsBuilding, {}) }))] })), _jsx(TooltipContent, { id: "tt-open-theme", text: "Open theme guide" }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-open-theme", onClick: function () {
                    navigate('/style-guide');
                }, "$inverted": true }, { children: _jsx(BsBorderStyle, {}) })), _jsx(TooltipContent, { id: "tt-open-appconfig", text: "Open app config" }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-open-appconfig", onClick: function () {
                    navigate('/global-administrate/appconfig');
                }, "$inverted": true }, { children: _jsx(BsFileText, {}) })), application.subdomain && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-set-version", text: "Set local app version to 3.1.0 to test version check banner" }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-set-version", onClick: function () {
                            storeCookie("version.".concat(application.subdomain), '3.1.0');
                        }, "$inverted": true }, { children: _jsx(BsGit, {}) }))] }))] })) : null;
};
DevToolbar.displayName = 'DevToolbar';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  background-color: ", ";\n  border-radius: 5px;\n\n  margin-right: 10px;\n  padding: 5px 5px 5px 5px;\n\n  > div {\n    margin: 3px;\n  }\n\n  @media screen and (max-width: ", ") {\n    display: none;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  background-color: ", ";\n  border-radius: 5px;\n\n  margin-right: 10px;\n  padding: 5px 5px 5px 5px;\n\n  > div {\n    margin: 3px;\n  }\n\n  @media screen and (max-width: ", ") {\n    display: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, Breakpoints.medium);
var templateObject_1;
