export var copyToClipboard = function (text) {
    if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(text).then(function () {
            // do nothing
        }, function (e) { return console.error(e); });
        return;
    }
    // Create a <textarea> element
    var element = document.createElement('textarea');
    element.value = text;
    // Make it readonly to be tamper-proof and move outside the screen to make it invisible
    element.setAttribute('readonly', '');
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    // Append the <textarea> element to the HTML document and select the content
    document.body.appendChild(element);
    element.select();
    // Copy - only works as a result of a user action (e.g. click events)
    document.execCommand('copy');
    // Remove the <textarea> element
    document.body.removeChild(element);
};
