// NOTE: Naming conventions is basically borrowed from the Material Design color system.
// We don't want to name colors somethingDark or somethingLight since it would
// probably only be dark and light in *that* specific theme. I.e. a color that is
// light in a regular theme would probably be dark in a dark theme.
// Read more: https://material.io/design/color/the-color-system.html#color-theme-creation
export var Color;
(function (Color) {
    Color["Black"] = "#000";
    Color["Grey10"] = "#1A1A1A";
    Color["Grey15"] = "#262626";
    Color["Grey20"] = "#333333";
    Color["Grey40"] = "#666666";
    Color["Grey60"] = "#999999";
    Color["Grey70"] = "#A1A1A1";
    Color["Grey80"] = "#CCCCCC";
    Color["Grey90"] = "#E5E5E5";
    Color["Grey95"] = "#F2F2F2";
    Color["Grey98"] = "#F9F9F9";
    Color["White"] = "#FFF";
    Color["DimatechBlue"] = "#237BCF";
    Color["DimatechBlueDark"] = "#2D51FB";
    Color["DimatechBlueLight"] = "#1A9DFB";
    Color["DimiosBlue"] = "#4EADF5";
    Color["DimiosBlueDark"] = "#3574E0";
    Color["DimiosBlueDarker"] = "#1A5B99";
    Color["DimiosBlueLight"] = "#99CEF7";
    Color["CaribbeanGreen"] = "#008F5D";
    Color["CaribbeanGreenDark"] = "#006642";
    Color["CaribbeanGreenLight"] = "#00b374";
    Color["SeaGreen"] = "#458A8B";
    Color["SeaGreenDark"] = "#2E6161";
    Color["SeaGreenLight"] = "#B1CECE";
    Color["RadicalRed"] = "#FF2E63";
    Color["RadicalRedDark"] = "#E60039";
    Color["RadicalRedLight"] = "#FF809F";
    Color["Pippin"] = "#FFE6EA";
    Color["KojiOrange"] = "#F5B24E";
    Color["KojiOrangeLight"] = "#FFC46B";
    Color["KojiOrangeDark"] = "#A87425";
    Color["MonzaRed"] = "#B00020";
    Color["BrightYellow"] = "#F2F536";
    Color["PorticaYello"] = "#F9ED69";
    Color["SandyBrown"] = "#F08A5D";
    Color["NightShadz"] = "#B83B5E";
    Color["Eminence"] = "#9D40A5";
    Color["Amethyst"] = "#BE6CC6";
    Color["BrightTurquoise"] = "#08D9D6";
    Color["DarkTurquoise"] = "#07C5C2";
    Color["Burgundy"] = "#AA0055";
})(Color || (Color = {}));
// TODO: Reference these when needing to use a product color
export var ProductPrimaryColor;
(function (ProductPrimaryColor) {
    ProductPrimaryColor["Pios"] = "#FF2E63";
    ProductPrimaryColor["Dimatech"] = "#237BCF";
    ProductPrimaryColor["Dimios"] = "#4EADF5";
    ProductPrimaryColor["Dios"] = "#F08A5D";
    ProductPrimaryColor["Dikios"] = "#BE6CC6";
    ProductPrimaryColor["Pcv"] = "#AA0055";
})(ProductPrimaryColor || (ProductPrimaryColor = {}));
export var Breakpoints = {
    // xs-mall: '480px',
    small: '600px',
    // small: '768px',
    medium: '768px',
    // medium: '992px',
    large: '1025px',
    xl: '1200px',
    xxl: '1400px',
};
export var ThemeType;
(function (ThemeType) {
    ThemeType[ThemeType["Light"] = 0] = "Light";
    ThemeType[ThemeType["Dark"] = 1] = "Dark";
    ThemeType[ThemeType["HighContrast"] = 2] = "HighContrast";
})(ThemeType || (ThemeType = {}));
