import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetSurveyInstances } from '@dimatech/features-survey/lib/hooks/useGetSurveyInstances';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewRow,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilterByEntity } from 'api/dimiosSlice';
import { useGetOverviewByEntityQuery } from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import { useContext, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { ChildResult } from './components/ChildResult';
import { ChildResultCompared } from './components/ChildResultCompared';
import { ChildResultDontKnow } from './components/ChildResultDontKnow';
import { ChildResultPerSubCategory } from './components/ChildResultPerSubCategory';

export const InternalComparison = (): JSX.Element | null => {
  const { t } = useTranslation();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const { accessToken } = useContext(AuthenticationContext);
  const customerId = accessToken.customerId;

  const childResultPerSubCategoryId = useId();
  const childResultDontKnowId = useId();

  const { instances, isSuccess: isSuccessInstances } =
    useGetSurveyInstances(true);

  const {
    data: principlesByEntity,
    isFetching,
    isLoading,
  } = useGetOverviewByEntityQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  return (
    <>
      <ViewHeader>
        <Heading1>{t('InternalComparison.Title')}</Heading1>
        <SelectExtendedFilters />
      </ViewHeader>

      <Card width="100%">
        <CardBody>{t('InternalComparison.Text')}</CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {principlesByEntity && principlesByEntity.length === 0 && (
            <Card width="100%">
              <AlertInfo>{t('InternalComparison.NoData')}</AlertInfo>
            </Card>
          )}

          {(!instances || instances.length === 0) && isSuccessInstances && (
            <Card width="100%">
              <AlertWarning>{t('InternalComparison.NoInstances')}</AlertWarning>
            </Card>
          )}

          {principlesByEntity &&
            principlesByEntity.length > 0 &&
            instances &&
            instances.length > 0 && (
              <>
                <ViewRow style={{ width: '100%' }}>
                  <Card
                    title={t('InternalComparison.ChildResultCompared.Title')}
                    canBeSavedAsPng={true}
                  >
                    <CardBody isLoading={isFetching}>
                      <ChildResultCompared
                        principlesByEntity={principlesByEntity}
                      />
                    </CardBody>
                  </Card>

                  <Card
                    title={t('InternalComparison.ChildResult.Title')}
                    canBeSavedAsPng={true}
                  >
                    <CardBody isLoading={isFetching}>
                      <ChildResult principlesByEntity={principlesByEntity} />
                    </CardBody>
                  </Card>
                </ViewRow>

                <Card
                  title={t(
                    'InternalComparison.ChildResultPerSubCategory.Title'
                  )}
                  width="100%"
                  canBeSavedAsPng={true}
                  canBeSavedAsCsv={true}
                  saveToPngElementId={childResultPerSubCategoryId}
                >
                  <CardBody isLoading={isFetching}>
                    <ChildResultPerSubCategory
                      saveToPngElementId={childResultPerSubCategoryId}
                      principlesByEntity={principlesByEntity}
                    />
                  </CardBody>
                </Card>

                <Card
                  title={t('InternalComparison.ChildResultDontKnow.Title')}
                  width="100%"
                  canBeSavedAsPng={true}
                  canBeSavedAsCsv={true}
                  saveToPngElementId={childResultDontKnowId}
                >
                  <CardBody isLoading={isFetching}>
                    <ChildResultDontKnow
                      saveToPngElementId={childResultDontKnowId}
                      principlesByEntity={principlesByEntity}
                    />
                  </CardBody>
                </Card>
              </>
            )}
        </>
      )}
    </>
  );
};

InternalComparison.displayName = 'InternalComparison';
