var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Breakpoints } from '../../themes';
export var ToastStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n\n  width: 500px;\n  min-height: 50px;\n  margin-bottom: 10px;\n\n  h4 {\n    margin-bottom: 5px;\n  }\n\n  z-index: 1000;\n  padding: 15px 20px;\n\n  border-radius: 3px;\n  color: ", ";\n  background-color: ", ";\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  box-shadow: ", ";\n\n  > div:first-child {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .error-extra {\n    padding: 10px;\n    height: 100px;\n    overflow: scroll;\n    margin-top: 10px;\n\n    color: ", ";\n    background-color: ", ";\n    border-radius: 3px;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 300px;\n    left: calc(50% - 150px);\n  }\n"], ["\n  box-sizing: border-box;\n\n  width: 500px;\n  min-height: 50px;\n  margin-bottom: 10px;\n\n  h4 {\n    margin-bottom: 5px;\n  }\n\n  z-index: 1000;\n  padding: 15px 20px;\n\n  border-radius: 3px;\n  color: ", ";\n  background-color: ", ";\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  box-shadow: ", ";\n\n  > div:first-child {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .error-extra {\n    padding: 10px;\n    height: 100px;\n    overflow: scroll;\n    margin-top: 10px;\n\n    color: ", ";\n    background-color: ", ";\n    border-radius: 3px;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 300px;\n    left: calc(50% - 150px);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onError;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.error;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.error;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onError;
}, Breakpoints.small);
var templateObject_1;
