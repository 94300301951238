var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Breakpoints } from '../../themes';
export var Tab = function (_a) {
    var children = _a.children, handleSelect = _a.handleSelect, isSelected = _a.isSelected, isDisabled = _a.isDisabled, style = _a.style;
    return (_jsx(Style, __assign({ style: style, isDisabled: isDisabled, className: isSelected ? 'selected' : '', onClick: handleSelect }, { children: children })));
};
Tab.displayName = 'Tab';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n  flex-basis: 1;\n\n  margin-left: 5px;\n  margin-bottom: -5px;\n  padding: 10px 20px 8px 8px;\n  cursor: ", ";\n\n  border-radius: 3px 3px 0px 0px;\n  background-color: ", ";\n\n  color: ", ";\n\n  border-bottom: 2px solid\n    ", ";\n\n  &.selected {\n    border-bottom: 2px solid\n      ", ";\n    color: ", ";\n\n    > div {\n      background-color: ", ";\n      color: ", ";\n    }\n  }\n\n  @media screen and (max-width: ", ") {\n    margin-top: 5px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n  flex-basis: 1;\n\n  margin-left: 5px;\n  margin-bottom: -5px;\n  padding: 10px 20px 8px 8px;\n  cursor: ", ";\n\n  border-radius: 3px 3px 0px 0px;\n  background-color: ", ";\n\n  color: ", ";\n\n  border-bottom: 2px solid\n    ", ";\n\n  &.selected {\n    border-bottom: 2px solid\n      ", ";\n    color: ", ";\n\n    > div {\n      background-color: ", ";\n      color: ", ";\n    }\n  }\n\n  @media screen and (max-width: ", ") {\n    margin-top: 5px;\n  }\n"])), function (props) { return (props.isDisabled ? 'unset' : 'pointer'); }, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (props) {
    return props.isDisabled
        ? props.theme.colors.border
        : props.theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, Breakpoints.small);
var templateObject_1;
