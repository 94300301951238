var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Input } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCaretDown } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useGetMyCustomersQuery, useSelectCustomerMutation, } from '../../../api/customer/customerApi';
import { AuthenticationContext } from '../AuthenticationProvider';
export var SelectCustomer = withTheme(function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var handleCustomerChange = _a.handleCustomerChange, isVisible = _a.isVisible, setIsVisible = _a.setIsVisible, theme = _a.theme;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _h = useParams(), _j = _h.id, id = _j === void 0 ? '' : _j, _k = _h.projectId, projectId = _k === void 0 ? '' : _k;
    var _l = useState(''), search = _l[0], setSearch = _l[1];
    var _m = useState(), customers = _m[0], setCustomers = _m[1];
    var _o = useSelectCustomerMutation(), selectCustomer = _o[0], selectedCustomer = _o[1].data;
    var _p = useContext(AuthenticationContext), refreshToken = _p.refreshToken, accessToken = _p.accessToken;
    var data = useGetMyCustomersQuery((_c = (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : skipToken).data;
    var currentCustomerId = (_e = (_d = accessToken.user) === null || _d === void 0 ? void 0 : _d.currentCustomer) === null || _e === void 0 ? void 0 : _e.id;
    var currentCustomerName = (_g = (_f = accessToken.user) === null || _f === void 0 ? void 0 : _f.currentCustomer) === null || _g === void 0 ? void 0 : _g.name;
    var handleToggleOpen = function () {
        setIsVisible(!isVisible);
    };
    var handleSelect = function (customerId) {
        if (refreshToken) {
            handleCustomerChange(customerId);
            selectCustomer({
                customerId: customerId,
                refreshToken: refreshToken.get(),
            });
        }
    };
    useEffect(function () {
        if (selectedCustomer) {
            accessToken.update(selectedCustomer.accessToken);
            refreshToken.update(selectedCustomer.refreshToken);
            setIsVisible(false);
            // TODO: This is Pios specific and should be moved to Pios
            // Need to redirect from a selected project or portfolio when switching customer
            if (!!id || !!projectId) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);
    useEffect(function () {
        var _a;
        var filtered = (_a = data === null || data === void 0 ? void 0 : data.filter(function (customer) { var _a; return (_a = customer.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase()); })) !== null && _a !== void 0 ? _a : [];
        setCustomers(filtered);
    }, [search, data]);
    return !accessToken.user ? null : (_jsxs(_Fragment, { children: [(!data || data.length <= 1) && accessToken.user.currentCustomer.name, data && data.length > 1 && (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-account-switch", text: t('Profile.SwitchAccount.TooltipText') }), _jsxs(Style, { children: [_jsxs("div", __assign({ onClick: handleToggleOpen, "data-tip": true, "data-for": "tt-account-switch" }, { children: [currentCustomerName, " ", _jsx(BsCaretDown, {})] })), isVisible && (_jsxs("div", { children: [_jsx("div", { children: _jsx(Input, { autoFocus: true, value: search, placeholder: t('Common.UI.Search'), onChange: function (e) { return setSearch(e.currentTarget.value); }, onClick: function (e) {
                                                e.stopPropagation();
                                            } }) }), customers === null || customers === void 0 ? void 0 : customers.map(function (customer) { return (_jsx("div", __assign({ style: currentCustomerId === customer.id
                                            ? {
                                                fontWeight: 'bold',
                                                color: theme.colors.primary,
                                            }
                                            : undefined, onClick: function () { return handleSelect(customer.id); } }, { children: customer.name }), customer.id)); })] }))] })] }))] }));
});
SelectCustomer.displayName = 'SelectCustomer';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n\n  > div:first-of-type {\n    svg {\n      vertical-align: middle;\n      font-size: ", ";\n    }\n\n    &:hover {\n      color: ", ";\n    }\n\n    @media screen and (max-width: ", ") {\n      display: none;\n    }\n  }\n\n  > div:not(:first-of-type) {\n    right: 1px;\n    padding: 5px;\n    position: absolute;\n    min-width: 100px;\n    width: 180px;\n    max-height: 90%;\n    min-height: 50px;\n    overflow: hidden auto;\n    z-index: 100;\n    opacity: 0.9;\n\n    background-color: ", ";\n    color: ", ";\n\n    > div {\n      padding: 2px;\n\n      &:hover {\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  cursor: pointer;\n\n  > div:first-of-type {\n    svg {\n      vertical-align: middle;\n      font-size: ", ";\n    }\n\n    &:hover {\n      color: ", ";\n    }\n\n    @media screen and (max-width: ", ") {\n      display: none;\n    }\n  }\n\n  > div:not(:first-of-type) {\n    right: 1px;\n    padding: 5px;\n    position: absolute;\n    min-width: 100px;\n    width: 180px;\n    max-height: 90%;\n    min-height: 50px;\n    overflow: hidden auto;\n    z-index: 100;\n    opacity: 0.9;\n\n    background-color: ", ";\n    color: ", ";\n\n    > div {\n      padding: 2px;\n\n      &:hover {\n        color: ", ";\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, Breakpoints.small, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var templateObject_1;
