var _a;
import { createSlice } from '@reduxjs/toolkit';
import { appActions } from '../../api/appSlice';
var initialState = {};
export var messageTemplateReducer = (_a = createSlice({
    name: 'messageTemplate/get',
    initialState: initialState,
    reducers: {
        selectedMessageTemplate: function (state, action) {
            state.selectedMessageTemplate = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function (state) {
            state.selectedMessageTemplate = initialState.selectedMessageTemplate;
        });
    },
}), _a.reducer), messageTemplateActions = _a.actions;
export var selectSelectedMessageTemplate = function (state) {
    return state.messageTemplate.selectedMessageTemplate;
};
