import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { AdministrateStory } from 'models';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { withTheme } from 'styled-components';

export const StoryStatus = withTheme(
  ({
    story,
    theme,
  }: {
    story: AdministrateStory;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    return (
      <>
        <TooltipContent
          id="tt-status-approved"
          text={t('Administrate.Stories.Status.Approved')}
        />
        <TooltipContent
          id="tt-status-notapproved"
          text={t('Administrate.Stories.Status.NotApproved')}
        />
        {story.approved && (
          <>
            <BsCircleFill
              data-tip
              data-for="tt-status-approved"
              style={{
                marginRight: 8,
                color: theme.colors.success,
              }}
            />
            {t('Administrate.Stories.Status.Approved')}
          </>
        )}
        {!story.approved && (
          <>
            <BsCircleFill
              data-tip
              data-for="tt-status-notapproved"
              style={{
                marginRight: 8,
                color: theme.colors.warning,
              }}
            />
            {t('Administrate.Stories.Status.NotApproved')}
          </>
        )}
      </>
    );
  }
);

StoryStatus.displayName = 'StoryStatus';
