var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles, CustomerLineOfBusiness, } from '@dimatech/features-core/lib/models';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Button, ButtonFooterWithToast, ButtonSecondary, Buttons, Input, Label, } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAddEntityWithSurveyMutation, useDeleteEntityWithSurveyMutation, useGetEntitiesWithSurveyQuery, useMoveEntityWithSurveyMutation, useUpdateEntityWithSurveyMutation, } from '../../../api/entity/entityApi';
import { entityActions, selectSelectedEntity, } from '../../../api/entity/entitySlice';
import { useGetEntitySurveyQuery } from '../../../api/entity/entitySurveyApi';
import { selectFilter } from '../../../api/filter/filterSlice';
import { SelectEntityWithSurvey } from '../../../components/SelectEntityWithSurvey';
import { ScheduleStatusBanner } from '../../administrate-schedule/components/ScheduleStatusBanner';
import { EntityAdmins } from './EntityAdmins';
import { EntityTypeInfo } from './EntityTypeInfo';
import { EntityViewers } from './EntityViewers';
// TODO: Fix, split code
/* eslint-disable max-lines-per-function */
export var EntityProperties = function (_a) {
    var _b, _c, _d, _e;
    var isViewersEnabledFlagOn = _a.isViewersEnabledFlagOn;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var selectedEntity = useSelector(selectSelectedEntity);
    var filter = useSelector(selectFilter);
    var _f = useAddEntityWithSurveyMutation(), addEntity = _f[0], _g = _f[1], addPosting = _g.isLoading, addErrors = _g.error;
    var _h = useUpdateEntityWithSurveyMutation(), updateEntity = _h[0], _j = _h[1], updatePosting = _j.isLoading, updateErrors = _j.error;
    var _k = useMoveEntityWithSurveyMutation(), moveEntity = _k[0], moveErrors = _k[1].error;
    var entities = useGetEntitiesWithSurveyQuery(accessToken.customerId && ((_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id) && filter.instance.id
        ? {
            _customerId: accessToken.customerId,
            _userId: (_c = accessToken.user) === null || _c === void 0 ? void 0 : _c.id,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    var _l = useGetEntitySurveyQuery(selectedEntity &&
        selectedEntity.id &&
        accessToken.customerId &&
        filter.instance.id
        ? {
            entityId: selectedEntity.id,
            _customerId: accessToken.customerId,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken), entitySurvey = _l.data, isFetching = _l.isFetching, isLoading = _l.isLoading;
    var _m = useState(), entity = _m[0], setEntity = _m[1];
    var _o = useState(false), isPoo = _o[0], setIsPoo = _o[1];
    var isAdding = selectedEntity && !selectedEntity.id;
    var isPosting = updatePosting || addPosting;
    var _p = useState(false), isChangesSaved = _p[0], setIsChangesSaved = _p[1];
    var _q = useState(true), isValid = _q[0], setIsValid = _q[1];
    var _r = useState(false), hasDuplicateName = _r[0], setHasDuplicateName = _r[1];
    var _s = useState(false), isMoving = _s[0], setIsMoving = _s[1];
    var _t = useState(''), entityId = _t[0], setEntityId = _t[1];
    var _u = useState(null), status = _u[0], setStatus = _u[1];
    var _v = useState(''), selectedEntityType = _v[0], setSelectedEntityType = _v[1];
    useEffect(function () {
        if (selectedEntity) {
            setEntity(__assign({}, selectedEntity));
            setIsPoo(!!selectedEntity.type &&
                Object.keys(CustomerLineOfBusiness).includes(selectedEntity.type));
        }
        else {
            setEntity(undefined);
            setIsPoo(false);
        }
        setHasDuplicateName(false);
        setIsValid(true);
    }, [selectedEntity]);
    useEffect(function () {
        setHasDuplicateName(false);
    }, [entityId]);
    var validate = function () {
        setIsValid(true);
        if (!(entity === null || entity === void 0 ? void 0 : entity.name)) {
            setIsValid(false);
            return false;
        }
        if ((entity === null || entity === void 0 ? void 0 : entity.id) === undefined && isPoo && selectedEntityType === '') {
            setIsValid(false);
            return false;
        }
        var hasDuplicateName = !!(entities === null || entities === void 0 ? void 0 : entities.some(function (sibling) {
            return sibling.parentId === entity.parentId &&
                sibling.name === entity.name &&
                sibling.id !== entity.id;
        }));
        setHasDuplicateName(hasDuplicateName);
        if (hasDuplicateName) {
            setIsValid(false);
            return false;
        }
        if (!isPoo) {
            entity.type = undefined;
        }
        return true;
    };
    var handleSave = function (e) {
        if (isReadOnly || !entity || !validate()) {
            return;
        }
        if (entity === null || entity === void 0 ? void 0 : entity.id) {
            updateEntity(__assign({}, entity))
                .unwrap()
                .then(function () {
                setIsChangesSaved(true);
                dispatch(entityActions.selectedEntity(__assign(__assign({}, selectedEntity), { name: entity.name })));
            });
        }
        else {
            addEntity(__assign({}, entity))
                .unwrap()
                .then(function (result) {
                setIsChangesSaved(true);
                dispatch(entityActions.selectedEntity(result));
            })
                .catch(function () {
                // Do nothing
            });
        }
    };
    var handleCancel = function () {
        dispatch(entityActions.selectedEntity());
    };
    var handleMove = function () {
        if (isReadOnly ||
            !(entity === null || entity === void 0 ? void 0 : entity.id) ||
            entity.parentId === entityId ||
            entity.id === entityId ||
            !entityId) {
            return;
        }
        var hasDuplicateName = entities === null || entities === void 0 ? void 0 : entities.some(function (entity) {
            return entity.parentId === entityId && entity.name === (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.name);
        });
        if (hasDuplicateName) {
            setHasDuplicateName(hasDuplicateName);
            return;
        }
        moveEntity({ entityId: entity === null || entity === void 0 ? void 0 : entity.id, parentId: entityId })
            .unwrap()
            .then(function (result) {
            return dispatch(entityActions.selectedEntity(__assign(__assign({}, selectedEntity), { parentId: result.parentId })));
        });
        setIsMoving(false);
        setEntityId('');
    };
    return (_jsxs(_Fragment, { children: [entity && (_jsx(Heading2, __assign({ style: { marginBottom: 10 } }, { children: entity.name }))), (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id) && entitySurvey && !isLoading && !isFetching && (_jsx(ScheduleStatusBanner, { startDate: entitySurvey.startDate, endDate: entitySurvey.endDate, status: status, setStatus: setStatus })), (isFetching || isLoading) && (_jsx(LoaderOverlay, { children: t('Common.UI.Loading') })), isValid && (_jsx(AlertErrors, { error: (_d = addErrors !== null && addErrors !== void 0 ? addErrors : updateErrors) !== null && _d !== void 0 ? _d : moveErrors, style: { marginBottom: 10 }, translationPath: "Administrate.Entity.ValidationError" })), !isValid && hasDuplicateName && !isMoving && (_jsx(AlertWarning, __assign({ style: { marginTop: 10, marginBottom: 10, width: '100%' } }, { children: t("Administrate.Entity.ValidationError.DuplicateName") }))), (entity || isAdding) && (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(Label, __assign({ style: { marginTop: 0 }, htmlFor: "name" }, { children: t('Administrate.Entity.Name') })), _jsx(Input, { type: "text", id: "name", name: "name", placeholder: t('Administrate.Entity.NamePlaceholder'), value: (_e = entity === null || entity === void 0 ? void 0 : entity.name) !== null && _e !== void 0 ? _e : '', invalid: !isValid && !(entity === null || entity === void 0 ? void 0 : entity.name), onChange: function (e) {
                                    setEntity(__assign(__assign({}, entity), { name: e.currentTarget.value }));
                                    setHasDuplicateName(false);
                                } })] }), entity && (_jsx(EntityTypeInfo, { isPoo: isPoo, setIsPoo: setIsPoo, entity: entity, setEntity: setEntity, isValid: isValid, setIsValid: setIsValid, selectedEntityType: selectedEntityType, setSelectedEntityType: setSelectedEntityType }))] })), !accessToken.isInRole(CommonRoles.DemoUser) && entity && !isAdding && (_jsx(EntityAdmins, {})), isViewersEnabledFlagOn && entity && !isAdding && _jsx(EntityViewers, {}), isMoving && !isAdding && (_jsxs(Modal, __assign({ title: t('Administrate.Entity.Move.Title'), handleKeyEnter: handleMove, handleKeyEsc: function () { return setIsMoving(false); } }, { children: [_jsx("div", { children: t('Administrate.Entity.Move.Text') }), hasDuplicateName && (_jsx(AlertWarning, __assign({ style: { marginBottom: 10, marginTop: 10, width: '100%' } }, { children: t("Administrate.Entity.ValidationError.DuplicateName") }))), _jsx(Label, { children: t('Administrate.Entity.Move.To', { name: entity === null || entity === void 0 ? void 0 : entity.name }) }), _jsx(SelectEntityWithSurvey, { autoFocus: true, entityId: entityId, setEntityId: setEntityId, surveyInstanceId: filter.instance.id, filter: function (e) {
                            return e.id !== (entity === null || entity === void 0 ? void 0 : entity.id) && !e.isReadOnly;
                        }, placeholder: t('Administrate.Entity.Move.NoParent') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsMoving(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleMove, disabled: isReadOnly ||
                                    !(entity === null || entity === void 0 ? void 0 : entity.id) ||
                                    entity.parentId === entityId ||
                                    entity.id === entityId ||
                                    !entityId }, { children: t('Administrate.Entity.Button.Move') }))] })] }))), _jsxs(ButtonFooterWithToast, __assign({ isSaved: isChangesSaved, setIsSaved: setIsChangesSaved }, { children: [!isAdding && entity && (_jsxs(Buttons, __assign({ style: { justifyContent: 'flex-start' } }, { children: [_jsx(EditButtons, { item: entity, setSelectedItem: setEntity, isReadOnly: isReadOnly, entitySurvey: entitySurvey }), _jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsMoving(true); }, disabled: !filter.instance.isCurrentInstance }, { children: t('Administrate.Entity.Button.Move') }))] }))), ((!isAdding && entity) || isAdding) && (_jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", disabled: isReadOnly || isPosting || !filter.instance.isCurrentInstance, onClick: handleSave }, { children: t('Common.Form.Save') }))] }))] }))] }));
};
EntityProperties.displayName = ' EntityProperties';
var EditButtons = function (_a) {
    var item = _a.item, setSelectedItem = _a.setSelectedItem, isReadOnly = _a.isReadOnly, entitySurvey = _a.entitySurvey;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var deleteEntity = useDeleteEntityWithSurveyMutation()[0];
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var _c = useState(false), hasChildren = _c[0], setHasChildren = _c[1];
    var _d = useState(false), hasRespondents = _d[0], setHasRespondents = _d[1];
    var handleDelete = function (e) {
        e.stopPropagation();
        if (isReadOnly) {
            return;
        }
        deleteEntity(item.id);
        dispatch(entityActions.selectedEntity());
        setSelectedItem(undefined);
        setIsDeleting(false);
    };
    var handleConfirmDelete = function (e, show) {
        e.stopPropagation();
        setHasChildren(false);
        setHasRespondents(false);
        setIsDeleting(show);
        if (!show) {
            setIsDeleting(false);
            return;
        }
        if (item.childCount && item.childCount > 0) {
            setHasChildren(true);
            setIsDeleting(true);
            return;
        }
        if ((entitySurvey === null || entitySurvey === void 0 ? void 0 : entitySurvey.respondents) && entitySurvey.respondents.length > 0) {
            setHasRespondents(true);
            setIsDeleting(true);
            return;
        }
    };
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Entity.Delete.ConfirmTitle'), handleKeyEnter: function (e) { return handleDelete(e); }, handleKeyEsc: function (e) { return handleConfirmDelete(e, false); } }, { children: [hasChildren && (_jsxs(_Fragment, { children: [_jsx(AlertWarning, { children: t('Administrate.Entity.Delete.HasChildren') }), _jsx(Buttons, { children: _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.UI.Ok') })) })] })), hasRespondents && (_jsxs(_Fragment, { children: [_jsx(AlertWarning, { children: t('Administrate.Entity.Delete.HasRespondents') }), _jsx(Buttons, { children: _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.UI.Ok') })) })] })), !hasChildren && !hasRespondents && (_jsxs(_Fragment, { children: [_jsx("div", { children: t('Administrate.Entity.Delete.ConfirmText') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleDelete(e); }, disabled: isReadOnly }, { children: t('Common.UI.Yes') }))] }), ' '] }))] }))), _jsx(ButtonSecondary, __assign({ type: "button", disabled: !item.parentId || !filter.instance.isCurrentInstance, onClick: function (e) { return handleConfirmDelete(e, true); }, style: { marginLeft: '0' } }, { children: t('Common.Form.Delete') }))] }));
};
