import { AdministrateStories as AdministrateStoriesFeature } from 'features/administrate-stories';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateStories = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.Stories.Title')}>
      <AdministrateStoriesFeature />
    </View>
  );
};

AdministrateStories.displayName = 'AdministrateStories';
