var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertSuccess } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Buttons, ButtonSecondary } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Table, Td, TdRight, Th, Tr, } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { formatDate } from '@dimatech/shared/lib/utils';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsEnvelope } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectSelectedEntity } from '../../../api/entity/entitySlice';
import { useResendUserSurveyMessageMutation } from '../../../api/entity/entitySurveyMessageApi';
import { selectFilter } from '../../../api/filter/filterSlice';
import { RespondentMessageStatus } from './RespondentMessageStatus';
export var RespondentMessageHistory = function (_a) {
    var respondent = _a.respondent, setIsShowingMessagesForRespondent = _a.setIsShowingMessagesForRespondent, entityName = _a.entityName;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var filter = useSelector(selectFilter);
    var selectedEntity = useSelector(selectSelectedEntity);
    var _b = useState(false), isResending = _b[0], setIsResending = _b[1];
    var _c = useState(respondent.messages), messages = _c[0], setMessages = _c[1];
    var _d = useResendUserSurveyMessageMutation(), resendSurveyMessage = _d[0], _e = _d[1], error = _e.error, isSuccess = _e.isSuccess;
    var handleResendMessage = function (e, messageId) {
        e.stopPropagation();
        if (isResending) {
            return;
        }
        setIsResending(true);
        resendSurveyMessage({
            entityId: selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.id,
            userId: respondent.userId,
            messageId: messageId,
            _customerId: accessToken.customerId,
        })
            .unwrap()
            .then(function (result) { return setMessages(__spreadArray(__spreadArray([], messages, true), [__assign({}, result)], false)); })
            .finally(function () { return setIsResending(false); });
    };
    return (_jsxs(Modal, __assign({ style: { width: '70%', maxWidth: 800, minWidth: 300 }, title: t('Administrate.Respondent.Messages.Title'), handleKeyEsc: function () { return setIsShowingMessagesForRespondent(null); } }, { children: [_jsx("div", __assign({ style: { marginBottom: 10 } }, { children: t('Administrate.Respondent.Messages.Text', {
                    email: respondent.isDeleted
                        ? "\"".concat(t('Administrate.Respondent.DeletedRespondent'), "\"")
                        : respondent.email,
                    entityName: entityName,
                }) })), error && (_jsx(AlertErrors, { style: { margin: '10px 0' }, error: error, translationPath: "Administrate.Respondent.Messages.ValidationError" })), isSuccess && (_jsx(AlertSuccess, __assign({ style: { margin: '10px 0' } }, { children: t('Administrate.Respondent.Messages.Resend.Success') }))), _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, { children: t('Administrate.Respondent.Messages.Status') }), _jsx(Th, { children: t('Administrate.Respondent.Messages.DateSent') }), _jsx(Th, { children: t('Administrate.Respondent.Messages.Error') }), _jsx(Th, { style: { width: 25 } })] }) }), _jsx("tbody", { children: messages.map(function (message, index) { return (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(RespondentMessageStatus, { status: message.status }) }), _jsx(Td, { children: message.sentDate && formatDate(new Date(message.sentDate)) }), _jsx(Td, { children: message.error ? message.error : '-' }), _jsx(TdRight, { children: !respondent.isDeleted && message.sentDate && (_jsx(LinkWithTooltip, { style: { margin: '0 0 0 5px' }, handleClick: function (e) {
                                            return handleResendMessage(e, message.messageId);
                                        }, tooltipTitle: t("Administrate.Respondent.Messages.Resend.TooltipTitle", {
                                            email: respondent.email,
                                        }), tooltip: t("Administrate.Respondent.Messages.Resend.Tooltip", {
                                            email: respondent.email,
                                        }), icon: _jsx(BsEnvelope, {}), isDisabled: isResending ||
                                            isSuccess ||
                                            !filter.instance.isCurrentInstance })) })] }, index)); }) })] }), _jsx(Buttons, { children: _jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsShowingMessagesForRespondent(null); } }, { children: t('Common.UI.Ok') })) })] })));
};
RespondentMessageHistory.displayName = 'RespondentMessageHistory';
