var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BsX } from 'react-icons/bs';
import styled from 'styled-components';
import { BadgeMini } from '../Badge';
import { ToastStyle } from './ToastStyle';
export var ToastMessage = function (_a) {
    var title = _a.title, style = _a.style, handleClose = _a.handleClose, handleSelect = _a.handleSelect;
    return (_jsx(ToastMessageStyle, __assign({ style: style, onClick: handleSelect }, { children: _jsxs("div", { children: [_jsx("div", { children: title }), _jsx(BadgeMini, __assign({ onClick: function (e) {
                        e.stopPropagation();
                        handleClose();
                    }, "$inverted": true }, { children: _jsx(BsX, {}) }))] }) })));
};
ToastMessage.displayName = 'ToastMessage';
var ToastMessageStyle = styled(ToastStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 300px;\n  cursor: pointer;\n\n  color: ", ";\n  background-color: ", ";\n"], ["\n  width: 300px;\n  cursor: pointer;\n\n  color: ", ";\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onInfo;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.info;
});
var templateObject_1;
