var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { messageTemplateActions, selectSelectedMessageTemplate, } from '@dimatech/features-core/lib/api/message-template/messageTemplateSlice';
import { MessageTemplateProperties } from '@dimatech/features-core/lib/features/administrate-message-template/components/MessageTemplateProperties';
import { MessageTemplatesList } from '@dimatech/features-core/lib/features/administrate-message-template/components/MessageTemplatesList';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGetEntitiesWithSurveyQuery } from '../../api/entity/entityApi';
import { selectSelectedEntity } from '../../api/entity/entitySlice';
import { selectFilter } from '../../api/filter/filterSlice';
import { SelectExtendedFilters } from '../../components/SelectExtendedFilters';
import { EntitiesTree } from '../administrate-organisation/components/EntitiesTree';
export var AdministrateMessageTemplate = function () {
    var _a, _b;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var selectedMessageTemplate = useSelector(selectSelectedMessageTemplate);
    var selectedEntity = useSelector(selectSelectedEntity);
    var navigate = useNavigate();
    var filter = useSelector(selectFilter);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var entities = useGetEntitiesWithSurveyQuery(accessToken.customerId && ((_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.id) && filter.instance.id
        ? {
            _customerId: accessToken.customerId,
            _userId: (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id,
            surveyInstanceId: filter.instance.id,
        }
        : skipToken).data;
    var handleEntitySelect = function () {
        dispatch(messageTemplateActions.selectedMessageTemplate());
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, { children: [_jsx(Heading1, { children: t('Administrate.MessageTemplate.MessageTemplates') }), _jsx(SelectExtendedFilters, {})] }), _jsx(CustomerAccountInfo, {}), _jsx(Card, __assign({ width: "100%" }, { children: _jsxs(CardBody, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.MessageTemplate.Text') })), !filter.instance.isCurrentInstance && (_jsx(AlertWarning, __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.ReadOnly') }))), (entities === null || entities === void 0 ? void 0 : entities.length) === 0 && !selectedEntity ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.Entity.NoEntities') })), _jsx(ButtonLink, __assign({ type: "button", onClick: function () {
                                        navigate('/administrate/organisation');
                                    } }, { children: t('Administrate.Entity.NoEntitiesAddFirst') }))] })) : (_jsxs(Style, { children: [_jsx(EntitiesTree, { handleEntitySelect: handleEntitySelect, isDraggable: false }), _jsxs("div", __assign({ style: { flexGrow: 1, maxWidth: 700 } }, { children: [selectedEntity && _jsx(MessageTemplatesList, {}), selectedMessageTemplate && (_jsx(MessageTemplateProperties, { isCurrentInstance: filter.instance.isCurrentInstance }))] }))] }))] }) }))] }));
};
AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplates';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    padding: 0 30px 30px 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    padding: 0 30px 30px 0;\n  }\n"])));
var templateObject_1;
