/*
We allow this here since this is the entry point of
getting all settings from the .env files
*/
/* eslint-disable no-process-env */
import { Currency, DeployEnvironment, Environment, Language, NodeEnvironment, } from '../models';
export var config = {
    version: process.env.REACT_APP_VERSION,
    support: {
        email: process.env.REACT_APP_SUPPORT_EMAIL,
        url: process.env.REACT_APP_SUPPORT_URL,
        productEmail: process.env.REACT_APP_SUPPORT_PRODUCT_EMAIL,
    },
    demo: {
        login: {
            userName: process.env.REACT_APP_DEMO_LOGIN_USERNAME,
            password: process.env.REACT_APP_DEMO_LOGIN_PASSWORD,
        },
    },
    defaultDomain: process.env.REACT_APP_DEFAULT_DOMAIN,
    defaultDomainCookies: process.env.REACT_APP_DEFAULT_DOMAIN_COOKIES,
    defaultSubdomain: process.env.REACT_APP_DEFAULT_SUBDOMAIN,
    defaultDomainPiosPublic: process.env
        .REACT_APP_DEFAULT_DOMAIN_PIOS_PUBLIC,
    defaultSubdomainPiosPublic: process.env
        .REACT_APP_DEFAULT_SUBDOMAIN_PIOS_PUBLIC,
    authentication: {
        allowCommonUserInPrivateRoutes: false,
        tokenPollInterval: parseInt(process.env.REACT_APP_AUTHENTICATION_TOKEN_POLLINTERVALINMS || '60000'),
        reCaptcha: {
            siteKey: process.env.REACT_APP_AUTHENTICATION_RECAPTCHA_SITEKEY,
        },
    },
    messenger: {
        pollInterval: parseInt(process.env.REACT_APP_MESSENGER_POLLINTERVALINMS || '60000'),
    },
    localisation: {
        defaultCurrency: Currency.SEK,
        defaultLanguage: Language.Swedish,
        supportedLanguages: [Language.Swedish, Language.English],
    },
    versionCheck: {
        pollInterval: parseInt(process.env.REACT_APP_VERSION_CHECK_POLLINTERVALINMS || '60000'),
    },
    survey: { id: process.env.REACT_APP_SURVEY_ID },
    apiUrl: {
        survey: {
            dimios: process.env.REACT_APP_API_URL_SURVEY_DIMIOS,
            dikios: process.env.REACT_APP_API_URL_SURVEY_DIKIOS,
        },
        admin: process.env.REACT_APP_API_URL_ADMIN,
        authentication: {
            login: process.env.REACT_APP_API_URL_AUTHENTICATION_LOGIN,
            refresh: process.env.REACT_APP_API_URL_AUTHENTICATION_REFRESH,
            resetPassword: process.env
                .REACT_APP_API_URL_AUTHENTICATION_RESET_PASSWORD,
        },
        product: {
            current: process.env.REACT_APP_API_URL_PRODUCT_CURRENT,
            pios: process.env.REACT_APP_API_URL_PRODUCT_PIOS,
            dimios: process.env.REACT_APP_API_URL_PRODUCT_DIMIOS,
            dios: process.env.REACT_APP_API_URL_PRODUCT_DIOS,
            dikios: process.env.REACT_APP_API_URL_PRODUCT_DIKIOS,
            pcv: process.env.REACT_APP_API_URL_PRODUCT_PCV,
        },
    },
    products: {
        survey: {
            url: process.env.REACT_APP_PRODUCTS_SURVEY_URL,
        },
        dimios: {
            url: process.env.REACT_APP_PRODUCTS_DIMIOS_URL,
            id: process.env.REACT_APP_PRODUCTS_DIMIOS_ID,
        },
        dios: {
            url: process.env.REACT_APP_PRODUCTS_DIOS_URL,
            id: process.env.REACT_APP_PRODUCTS_DIOS_ID,
        },
        pios: {
            url: process.env.REACT_APP_PRODUCTS_PIOS_URL,
            id: process.env.REACT_APP_PRODUCTS_PIOS_ID,
        },
        admin: {
            url: process.env.REACT_APP_PRODUCTS_ADMIN_URL,
        },
        www: {
            url: process.env.REACT_APP_PRODUCTS_WWW_URL,
        },
        dikios: {
            url: process.env.REACT_APP_PRODUCTS_DIKIOS_URL,
            id: process.env.REACT_APP_PRODUCTS_DIKIOS_ID,
        },
        pcv: {
            url: process.env.REACT_APP_PRODUCTS_PCV_URL,
            id: process.env.REACT_APP_PRODUCTS_PCV_ID,
        },
        piosPublic: {
            url: process.env.REACT_APP_PRODUCTS_PIOS_PUBLIC_URL,
        },
    },
    appInsights: {
        key: process.env.REACT_APP_APPINSIGHTS_KEY,
        disabled: process.env.REACT_APP_APPINSIGHTS_DISABLED === 'true',
        logErrors: process.env.REACT_APP_APPINSIGHTS_LOG_ERRORS === 'true',
        logValidationErrors: process.env.REACT_APP_APPINSIGHTS_LOG_VALIDATION_ERRORS === 'true',
        logPageViews: process.env.REACT_APP_APPINSIGHTS_LOG_PAGE_VIEWS === 'true',
        logEvents: process.env.REACT_APP_APPINSIGHTS_LOG_EVENTS === 'true',
    },
    launchDarkly: {
        key: process.env.REACT_APP_LAUNCHDARKLY_KEY,
    },
    googleAnalytics: {
        key: process.env.REACT_APP_GOOGLEANALYTICS_KEY,
    },
    axiosDefaults: {
        timeout: parseInt(process.env.REACT_APP_AXIOS_DEFAULTS_TIMEOUT || '0'),
        retries: parseInt(process.env.REACT_APP_AXIOS_DEFAULTS_RETRIES || '0'),
        retryDelay: parseInt(process.env.REACT_APP_AXIOS_DEFAULTS_RETRYDELAY || '1000'),
    },
    environment: {
        current: process.env.NODE_ENV === NodeEnvironment.Production
            ? (function () {
                // If environment/build is production, check REACT_APP_ENVIRONMENT
                // variable for current environment
                switch (process.env.REACT_APP_ENVIRONMENT) {
                    case DeployEnvironment.Development:
                        return Environment.Development;
                    case DeployEnvironment.Production:
                        return Environment.Production;
                    case DeployEnvironment.Staging:
                        return Environment.Staging;
                }
            })()
            : process.env.NODE_ENV,
        node: process.env.NODE_ENV,
        deploy: process.env.REACT_APP_ENVIRONMENT,
    },
    ui: {
        textEditor: {
            maxBytes: parseInt(process.env.REACT_APP_UI_TEXT_EDITOR_MAX_BYTES || '1048576'),
        },
        fileUpload: {
            maxSizeInKb: parseInt(process.env.REACT_APP_UI_FILE_UPLOAD_MAX_SIZE_IN_KB || '0'),
        },
    },
};
/**
 * Verify configuration settings and throws error if something is wrong
 */
export var verifyConfiguration = function () {
    // Make sure domain settings are correct
    if (window.location.hostname !==
        "".concat(config.defaultSubdomain, ".").concat(config.defaultDomain) &&
        window.location.hostname !==
            "".concat(config.defaultSubdomainPiosPublic, ".").concat(config.defaultDomainPiosPublic)) {
        throw "Configured defaultSubdomain '".concat(config.defaultSubdomain, "' and/or defaultDomain '").concat(config.defaultDomain, "' is not matching the actual domain");
    }
};
