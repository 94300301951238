var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
export var Attribution = function () {
    var t = useTranslation().t;
    return (_jsx(Style, { children: _jsx("a", __assign({ href: t('Attribution.Link') }, { children: t('Attribution.Text', { date: new Date() }) })) }));
};
Attribution.displayName = 'Attribution';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  a {\n    border-bottom: none;\n  }\n\n  a:hover {\n    color: ", ";\n  }\n"], ["\n  a {\n    border-bottom: none;\n  }\n\n  a:hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var templateObject_1;
