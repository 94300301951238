var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertError, AlertLowPrio, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Button, ButtonFooterWithToast, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { ScheduleStatus, SurveyMessageType } from '@dimatech/shared/lib/models';
import { nextBankDayAtHours } from '@dimatech/shared/lib/utils';
import { addDays } from 'date-fns';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Trans, useTranslation } from 'react-i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { entityActions } from '../../../api/entity/entitySlice';
import { useAddSurveyMessageMutation, useUpdateSurveyMessageMutation, } from '../../../api/entity/entitySurveyMessageApi';
import { useDeleteSurveyScheduleMutation, useUpdateSurveyScheduleMutation, } from '../../../api/entity/entitySurveyScheduleApi';
import { selectFilter } from '../../../api/filter/filterSlice';
import { useGetSurveyInstances } from '../../../hooks/useGetSurveyInstances';
import { validateReminders, validateStartEndDate, } from '../../administrate-schedule/validation';
import { ScheduleDate } from './ScheduleDate';
import { ScheduleIndividualSchedule } from './ScheduleIndividualSchedule';
import { ScheduleReminders } from './ScheduleReminders';
import { ScheduleStatusBanner } from './ScheduleStatusBanner';
// TODO: Sort this out, split code into smaller components
// eslint-disable-next-line max-lines-per-function
export var Schedule = function (_a) {
    var _b, _c, _d;
    var entity = _a.entity, entitySurvey = _a.entitySurvey, allowIndividualMeasurementPeriods = _a.allowIndividualMeasurementPeriods;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
    var _e = useAddSurveyMessageMutation(), addSurveyMessage = _e[0], addMessageError = _e[1].error;
    var _f = useUpdateSurveyMessageMutation(), updateSurveyMessage = _f[0], updateMessageError = _f[1].error;
    var _g = useUpdateSurveyScheduleMutation(), updateSurveySchedule = _g[0], updateSurveyError = _g[1].error;
    var deleteSurveySchedule = useDeleteSurveyScheduleMutation()[0];
    var _h = useState(false), useIndividualMeasurementPeriods = _h[0], setUseIndividualMeasurementPeriods = _h[1];
    var _j = useState(false), hasChanges = _j[0], setHasChanges = _j[1];
    var _k = useState(false), isStartDateDirty = _k[0], setIsStartDateDirty = _k[1];
    var _l = useState(false), isEndDateDirty = _l[0], setIsEndDateDirty = _l[1];
    var _m = useState(false), isChangesSaved = _m[0], setIsChangesSaved = _m[1];
    var _o = useState(false), canEditSchedule = _o[0], setCanEditSchedule = _o[1];
    var _p = useState(true), isValidStartEndDate = _p[0], setIsValidStartEndDate = _p[1];
    var _q = useState(true), isValidReminder = _q[0], setIsValidReminder = _q[1];
    var _r = useState(null), status = _r[0], setStatus = _r[1];
    var _s = useState(), schedule = _s[0], setSchedule = _s[1];
    var _t = useState(''), maxEndDate = _t[0], setMaxEndDate = _t[1];
    var instances = useGetSurveyInstances(false).instances;
    var canEditIndividualMeasurementPeriods = accessToken.isInRole(CommonRoles.CustomerAccountAdmin) ||
        accessToken.isInRole(CommonRoles.GlobalAdmin);
    useEffect(function () {
        var _a, _b;
        var endDate = (_b = (_a = instances === null || instances === void 0 ? void 0 : instances.find(function (instance) {
            if (instance.startDate && instance.endDate) {
                var now = new Date();
                var startDate = new Date(instance.startDate);
                var endDate_1 = new Date(instance.endDate);
                if (startDate <= now && endDate_1 >= now) {
                    return instance;
                }
            }
            return null;
        })) === null || _a === void 0 ? void 0 : _a.endDate) !== null && _b !== void 0 ? _b : '';
        setMaxEndDate(endDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instances]);
    useEffect(function () {
        var _a, _b;
        var reminders = (_b = (_a = entitySurvey.messages) === null || _a === void 0 ? void 0 : _a.filter(function (message) { return message.messageType === SurveyMessageType.SurveyReminder; })) !== null && _b !== void 0 ? _b : [];
        setSchedule({
            status: ScheduleStatus.NotStarted,
            entityId: entitySurvey.entityId,
            startDate: entitySurvey.startDate,
            endDate: entitySurvey.endDate,
            reminders: reminders.map(function (reminder) {
                var _a;
                return (__assign(__assign({}, reminder), { uid: (_a = reminder.messageId) !== null && _a !== void 0 ? _a : nanoid(6), isValid: true }));
            }),
        });
        setIsValidReminder(!(reminders === null || reminders === void 0 ? void 0 : reminders.some(function (reminder) { return reminder.isValid === false; })));
        setIsValidStartEndDate(validateStartEndDate(entitySurvey.startDate, entitySurvey.endDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entitySurvey]);
    useEffect(function () {
        setUseIndividualMeasurementPeriods(!!entity.allowIndividualMeasurementPeriods);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.allowIndividualMeasurementPeriods]);
    useEffect(function () {
        var canEditRootSchedule = !entity.parentId &&
            (canEditIndividualMeasurementPeriods || useIndividualMeasurementPeriods);
        setCanEditSchedule(useIndividualMeasurementPeriods || canEditRootSchedule);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        entity,
        useIndividualMeasurementPeriods,
        allowIndividualMeasurementPeriods,
    ]);
    useEffect(function () {
        if (schedule) {
            var validatedReminders = validateReminders(schedule.reminders, schedule.startDate, schedule.endDate);
            setIsValidReminder(!(validatedReminders === null || validatedReminders === void 0 ? void 0 : validatedReminders.some(function (reminder) { return reminder.isValid === false; })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedule === null || schedule === void 0 ? void 0 : schedule.reminders]);
    var handleChangeStartDate = function (date) {
        if (!schedule) {
            return;
        }
        var startDate = date ? date.toISOString() : null;
        setSchedule(__assign(__assign({}, schedule), { startDate: startDate }));
        if (!startDate) {
            return;
        }
        setIsValidStartEndDate(validateStartEndDate(startDate, schedule.endDate));
        var validatedReminders = validateReminders(schedule.reminders, startDate, schedule.endDate);
        setIsValidReminder(!(validatedReminders === null || validatedReminders === void 0 ? void 0 : validatedReminders.some(function (reminder) { return reminder.isValid === false; })));
        setIsStartDateDirty(true);
        setHasChanges(true);
    };
    var handleChangeEndDate = function (date) {
        if (!schedule) {
            return;
        }
        var endDate = date ? date.toISOString() : null;
        setSchedule(__assign(__assign({}, schedule), { endDate: endDate }));
        if (!endDate) {
            return;
        }
        setIsValidStartEndDate(validateStartEndDate(schedule.startDate, endDate));
        var validatedReminders = validateReminders(schedule.reminders, schedule.startDate, endDate);
        setIsValidReminder(!(validatedReminders === null || validatedReminders === void 0 ? void 0 : validatedReminders.some(function (reminder) { return reminder.isValid === false; })));
        setIsEndDateDirty(true);
        setHasChanges(true);
    };
    var handleClearSchedule = function () {
        if (isReadOnly || !(schedule === null || schedule === void 0 ? void 0 : schedule.entityId)) {
            return;
        }
        deleteSurveySchedule({
            entityId: schedule.entityId,
        })
            .unwrap()
            .then(function () {
            setSchedule(__assign(__assign({}, schedule), { startDate: null, endDate: null, reminders: [] }));
        });
        setIsStartDateDirty(false);
        setIsEndDateDirty(false);
        setHasChanges(false);
    };
    var handleSave = function () {
        var _a;
        if (isReadOnly || !isValidStartEndDate || !isValidReminder || !hasChanges) {
            return;
        }
        (_a = schedule === null || schedule === void 0 ? void 0 : schedule.reminders) === null || _a === void 0 ? void 0 : _a.forEach(function (reminder) {
            if (reminder.isDirty) {
                if (reminder.messageId) {
                    updateSurveyMessage({
                        entityId: schedule.entityId,
                        sendDate: reminder.sendDate,
                        messageId: reminder.messageId,
                    });
                }
                else if (reminder.sendDate) {
                    addSurveyMessage({
                        entityId: schedule.entityId,
                        sendDate: reminder.sendDate,
                        messageType: SurveyMessageType.SurveyMessage,
                    });
                }
            }
        });
        if ((schedule === null || schedule === void 0 ? void 0 : schedule.startDate) && (schedule === null || schedule === void 0 ? void 0 : schedule.endDate)) {
            updateSurveySchedule({
                entityId: schedule.entityId,
                startDate: schedule.startDate,
                endDate: schedule.endDate,
            });
        }
        setIsStartDateDirty(false);
        setIsEndDateDirty(false);
        setHasChanges(false);
        setIsChangesSaved(true);
    };
    if (!(schedule === null || schedule === void 0 ? void 0 : schedule.reminders)) {
        return _jsx(Loader, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(_Fragment, { children: [canEditIndividualMeasurementPeriods && (_jsx(Heading2, __assign({ style: {
                    marginBottom: 10,
                } }, { children: entity.name }))), _jsx(ScheduleStatusBanner, { startDate: schedule.startDate, endDate: schedule.endDate, status: status, setStatus: setStatus }), _jsx(ScheduleIndividualSchedule, { useIndividualMeasurementPeriods: useIndividualMeasurementPeriods, setUseIndividualMeasurementPeriods: setUseIndividualMeasurementPeriods, entity: entity, style: { marginBottom: 5 }, status: status, handleClearSchedule: handleClearSchedule }), _jsxs(Style, { children: [_jsxs("div", { children: [isGlobalAdmin && (_jsx(AlertWarning, { children: t('Administrate.Schedule.GlobalAdminInfo') })), (!isValidReminder || !isValidStartEndDate) && (_jsxs(AlertError, __assign({ style: { marginTop: 10, maxWidth: 300 } }, { children: [!isValidReminder &&
                                        t('Administrate.Schedule.ValidationErrors.InvalidReminder'), !isValidStartEndDate &&
                                        t('Administrate.Schedule.ValidationErrors.InvalidStartEndDate')] }))), _jsx(AlertErrors, { error: (_b = addMessageError !== null && addMessageError !== void 0 ? addMessageError : updateMessageError) !== null && _b !== void 0 ? _b : updateSurveyError, translationPath: "Administrate.Schedule.ValidationErrors" }), _jsx(ScheduleDate, { style: { marginTop: 10 }, date: schedule.startDate, label: t('Administrate.Schedule.StartDate'), handleChange: function (date) {
                                    handleChangeStartDate(date);
                                }, defaultDateOnFocus: nextBankDayAtHours(7), isReadOnly: !canEditSchedule, isValid: isValidStartEndDate, isDirty: isStartDateDirty, 
                                // Allow global admins to set start date to yesterday
                                min: isGlobalAdmin ? addDays(new Date(), -1) : null, max: maxEndDate }), _jsxs("div", __assign({ style: { marginTop: 20, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(ScheduleReminders, { schedule: schedule, setSchedule: setSchedule, canEditSchedule: canEditSchedule, setIsValidReminder: setIsValidReminder, maxEndDate: maxEndDate, setHasChanges: setHasChanges }), _jsx(ScheduleDate, { style: { marginTop: 20 }, date: schedule.endDate, label: t('Administrate.Schedule.EndDate'), allowEditAfterOccurrence: true, handleChange: function (date) {
                                            handleChangeEndDate(date);
                                        }, openToDate: new Date((_d = (_c = schedule.endDate) !== null && _c !== void 0 ? _c : schedule.startDate) !== null && _d !== void 0 ? _d : new Date()), isReadOnly: !canEditSchedule, isValid: isValidStartEndDate, isDirty: isEndDateDirty, min: schedule.startDate ? new Date(schedule.startDate) : null, max: maxEndDate }), canEditSchedule && (_jsx(EditButtons, { canSave: !!(hasChanges &&
                                            schedule.startDate &&
                                            schedule.endDate &&
                                            isValidReminder &&
                                            isValidStartEndDate), canClearSchedule: schedule.startDate
                                            ? new Date(schedule.startDate) > new Date()
                                            : false, handleSave: handleSave, handleClearSchedule: handleClearSchedule, isSaved: isChangesSaved, setIsSaved: setIsChangesSaved, isReadOnly: isReadOnly }))] }))] }), _jsx(Help, {})] })] }));
};
Schedule.displayName = 'Schedule';
var EditButtons = function (_a) {
    var canSave = _a.canSave, canClearSchedule = _a.canClearSchedule, handleSave = _a.handleSave, handleClearSchedule = _a.handleClearSchedule, isSaved = _a.isSaved, setIsSaved = _a.setIsSaved, isReadOnly = _a.isReadOnly;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var handleCancel = function (e) {
        dispatch(entityActions.selectedEntity());
    };
    return (_jsx(_Fragment, { children: _jsxs(ButtonFooterWithToast, __assign({ isSaved: isSaved, setIsSaved: setIsSaved, style: { flexWrap: 'wrap-reverse', marginTop: 30 } }, { children: [_jsx(Buttons, __assign({ style: { marginTop: 30, justifyContent: 'flex-start' } }, { children: _jsx(ButtonSecondary, __assign({ type: "button", disabled: isReadOnly ||
                            !canClearSchedule ||
                            !filter.instance.isCurrentInstance, onClick: handleClearSchedule }, { children: t('Administrate.Schedule.ClearSchedule.Title') })) })), _jsxs(Buttons, __assign({ style: { marginTop: 30 } }, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", disabled: isReadOnly || !canSave || !filter.instance.isCurrentInstance, onClick: handleSave }, { children: t('Common.Form.Save') }))] }))] })) }));
};
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap-reverse;\n\n  > div:first-of-type {\n    justify-content: flex-end;\n    flex-grow: 1;\n    flex-basis: 400px;\n    padding: 0 50px 0 10px;\n  }\n\n  > div:last-of-type {\n    flex-grow: 6;\n    flex-basis: 250px;\n    padding: 5px 30px 0 10px;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap-reverse;\n\n  > div:first-of-type {\n    justify-content: flex-end;\n    flex-grow: 1;\n    flex-basis: 400px;\n    padding: 0 50px 0 10px;\n  }\n\n  > div:last-of-type {\n    flex-grow: 6;\n    flex-basis: 250px;\n    padding: 5px 30px 0 10px;\n  }\n"])));
var Help = function () {
    var t = useTranslation().t;
    return (_jsxs("div", { children: [_jsxs(AlertLowPrio, { children: [_jsx(BsInfoCircleFill, { className: "size-xl", style: { marginRight: 8 } }), t('Administrate.Schedule.Help.Whitelisting', {
                        email: config.support.productEmail,
                    })] }), _jsx("p", { children: _jsx(Trans, { i18nKey: "Administrate.Schedule.Help.General" }) }), _jsx("p", { children: _jsx(Trans, { i18nKey: "Administrate.Schedule.Help.TestMessage" }) }), _jsxs("p", { children: [_jsx("strong", { children: t('Administrate.Schedule.StartDate') }), _jsx("br", {}), _jsx(Trans, { i18nKey: "Administrate.Schedule.Help.StartDate" })] }), _jsxs("p", { children: [_jsx("strong", { children: t('Administrate.Schedule.Reminder') }), _jsx("br", {}), _jsx(Trans, { i18nKey: "Administrate.Schedule.Help.Reminders" })] }), _jsxs("p", { children: [_jsx("strong", { children: t('Administrate.Schedule.EndDate') }), _jsx("br", {}), _jsx(Trans, { i18nKey: "Administrate.Schedule.Help.EndDate" })] })] }));
};
var templateObject_1;
